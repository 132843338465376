import React, {useContext} from 'react';
import {Row, Col, InputNumber, Form} from 'antd';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import {Control, Space} from '~src/components';
import {LocaleContext} from '~src/context';
import {IFacade} from '~src/services/Calculation2Service';

interface ISection2ControlsControlsProps {
  section2Index: number;
}

const PlasterFacadeInput: React.FC<ISection2ControlsControlsProps> = ({section2Index}) => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {userValues} = calculations[2];
  const {
    pages: {
      calculation2: {brickAndBlocksSections},
    },
  } = useContext(LocaleContext);

  const getHandleInputChange = (name: keyof IFacade | string) => (value: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'facades',
      values: [
        {
          name,
          value,
          index: section2Index,
        },
      ],
    });
  };

  return (
    <Row align="top" justify="space-between">
      <ContentWrapperStyled>
        <Col span={24}>
          <Space direction={'vertical'} size={10}>
            <Row gutter={[20, 0]}>
              <Col span={24} xs={24}>
                <Control label={'Толщина слоя, мм'}>
                  <FormItemStyled
                    validateStatus={userValues.isInvalid && userValues.facades[0].facadeBrickWidth === 0 ? 'error' : ''}
                    help={
                      userValues.isInvalid && userValues.facades[0].facadeBrickWidth === 0 ? 'Обязательное поле' : ''
                    }
                  >
                    <InputNumberStyled
                      min={0}
                      size={'large'}
                      placeholder={brickAndBlocksSections.section1.bearingWallSize.userSize.brickLength.placeholder}
                      onChange={getHandleInputChange('facadeBrickWidth')}
                    />
                  </FormItemStyled>
                </Control>
              </Col>
            </Row>
          </Space>
        </Col>
      </ContentWrapperStyled>
    </Row>
  );
};

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`;

const ContentWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;

  @media screen and (max-width: 320px) {
    flex-direction: column-reverse;
  }

  @media screen and (min-width: 321px) and (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

const FormItemStyled = styled(Form.Item)`
  margin-bottom: 0 !important;
`;

export default PlasterFacadeInput;
