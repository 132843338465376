import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import {Routes} from '~src/utils/routes';
import {LocaleContext} from '~src/context';

import {Link} from 'gatsby';
import {Layout, Button} from 'antd';
import {Logo, Menu, ContentView} from '~src/components';
import {MenuUnfoldOutlined} from '@ant-design/icons';
import {$tablet} from '~src/theme/media';
import MenuModal from '~src/components/organisms/Modals/MenuModal';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

const {Header: AntdHeader} = Layout;

export const Header: React.FC = () => {
  const {
    components: {menu},
  } = useContext(LocaleContext);
  const {dispatch} = useStoreon<IState, IEvents>('modals');
  const [isVisibleMenu, setIsVisibleMenu] = useState(false);
  return (
    <HeaderStyled theme="light">
      <ContentViewStyled>
        <Link to={Routes.index}>
          <Logo color="#096dd9" />
        </Link>
        <MenuMobileButtonStyled
          type="primary"
          onClick={() => {
            setIsVisibleMenu(true);
          }}
        >
          <MenuUnfoldOutlined />
        </MenuMobileButtonStyled>
        <RightSideStyled>
          <MenuStyled items={menu.app} />
          <ButtonContainerStyled>
            <Button
              type="primary"
              onClick={() => {
                dispatch('modals/show-modal', {key: 'feedbackModal', value: true});
              }}
            >
              Обратная связь
            </Button>
          </ButtonContainerStyled>
        </RightSideStyled>
        {isVisibleMenu && (
          <MenuModal
            visible={isVisibleMenu}
            onCancel={() => {
              setIsVisibleMenu(false);
            }}
          />
        )}
      </ContentViewStyled>
    </HeaderStyled>
  );
};

const ContentViewStyled = styled(ContentView)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ButtonContainerStyled = styled.div`
  align-items: center;
`;

const MenuMobileButtonStyled = styled(Button)`
  display: none;
  @media ${$tablet} {
    display: block;
  }
`;

const HeaderStyled = styled(AntdHeader)`
  background: white;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #f0f0f0;
  padding: 0px;

  @media ${$tablet} {
    padding: 0 10px;
  }
`;

const MenuStyled = styled(Menu)`
  margin-right: 30px !important;
  line-height: 63px;
`;

const RightSideStyled = styled.div`
  display: flex;
  box-sizing: unset;
  @media ${$tablet} {
    display: none;
  }
`;
