import React, {useCallback, useEffect, useState, Fragment, useContext} from 'react';
import {UploadChangeParam, UploadFile} from 'antd/lib/upload/interface';
import styled, {css} from 'styled-components';
import {IModalsProps} from './index';

import {Button, Modal, Space, Typography, Upload, Progress} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {LocaleContext} from '~src/context';

interface ILoadXlsModalProps extends IModalsProps {
  visible: boolean;
}

const LoadXlsModal: React.FC<ILoadXlsModalProps> = ({visible, navigate, path}) => {
  const {
    components: {modals},
  } = useContext(LocaleContext);
  const [progressPercent, setProgressPercent] = useState(0);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const reset = useCallback(() => {
    setProgressPercent(0);
    setFileList([]);
    setErrorMessage('');
  }, []);
  useEffect(() => {
    reset();
  }, [visible]);

  const handleChangeUpload = (info: UploadChangeParam<UploadFile<any>>) => {
    setFileList([...info.fileList]);
    setProgressPercent(Math.round(info.file.percent as number));
    if (info.file.status === 'error') {
      setErrorMessage(info.file.response.message);
    }
  };
  const handleCancel = () => {
    navigate(path);
  };

  return (
    <Modal
      bodyStyle={{minHeight: 175, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      width={380}
      title={modals.loadXls.title}
    >
      <SpaceStyled size={20} direction={'vertical'}>
        <UploadStyled
          hide={progressPercent > 0}
          name={modals.loadXls.fileName}
          action={modals.loadXls.uploadUrl}
          fileList={fileList}
          headers={{
            Authorization:
              'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiLQnNC40YXQsNC40LsiLCJ1c2VyX2VtYWlsIjoibXRzeW1sb3ZAeWFuZGV4LnJ1Iiwicm9sZSI6Im1hbmFnZXIiLCJpYXQiOjE1ODgxOTc3Nzh9.CUA8Zn3M79jZNpRX6wwvw6NfQO0MwadVxut6BKWN_wCc--dM32JdD3J4dYC6igbp72HTD1o_BIO7z0f3BA9yCzCq9rtvDJmFpH2inBgJcjEZOLrgFPoPwS4mDhOhjLrKr_ArUH6_I4ESlSxCLVCqrJ9DEHTq0gS1lZdvN6m0swc',
          }}
          onChange={handleChangeUpload}
        >
          <Button icon={<DownloadOutlined />}>{modals.loadXls.loadButton}</Button>
        </UploadStyled>
        {progressPercent !== 0 && (
          <Fragment>
            <Progress
              type="circle"
              width={80}
              percent={progressPercent}
              status={errorMessage ? 'exception' : undefined}
            />
            {progressPercent === 100 && !errorMessage && <Button href={''}>{modals.loadXls.reloadPageButton}</Button>}
          </Fragment>
        )}
        {errorMessage && (
          <Fragment>
            <Typography.Text type={'danger'}>{errorMessage}</Typography.Text>
            <Button onClick={reset}>{modals.loadXls.tryAgainButton}</Button>
          </Fragment>
        )}
      </SpaceStyled>
    </Modal>
  );
};

const UploadStyled = styled(Upload)<{hide: boolean}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${props =>
    props.hide &&
    css`
      display: none;
    `}
`;
const SpaceStyled = styled(props => <Space {...props} />)`
  & .ant-space-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
`;

export default LoadXlsModal;
