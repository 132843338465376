import React, {useContext, useState} from 'react';
import {LocaleContext} from '~src/context';
import {IModalsProps} from './index';
import {useMediaQuery} from 'react-responsive';

import {Button, Checkbox, Col, Input, Modal, Row, Space, Typography, Form, message} from 'antd';
import {Control} from '~src/components';
import {CheckboxChangeEvent} from 'antd/es/checkbox';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import pdf from '~src/assets/misc/privacy.pdf';

import {deviceSizes} from '~src/theme/media';

const {TextArea} = Input;

interface IFeedbackModalProps {
  visible: boolean;
  onCancel: () => void;
}

const validateMessages = {
  required: 'Обязательное поле',
  types: {
    number: 'Это должно быть число',
    email: 'Введите действительный E-Mail',
  },
};
const requiredRule = {required: true};

const FeedbackModal: React.FC<IFeedbackModalProps> = ({visible, onCancel}) => {
  const {dispatch} = useStoreon<IState, IEvents>('calculations', 'materials');
  const isTablet = useMediaQuery({maxWidth: deviceSizes.desktop - 1});

  const {
    components: {modals},
  } = useContext(LocaleContext);
  const [isChecked, setIsChecked] = useState(false);
  const [form] = Form.useForm();
  const handleCancel = () => {
    onCancel();
  };
  const handleChangeCheckbox = (e: CheckboxChangeEvent) => {
    setIsChecked(e.target.checked);
  };
  const handleClickAtButton = () => {
    form.validateFields().then(async values => {
      form.submit();
      dispatch('leads/send-feedback', {
        ...values,
        onSuccess: () => {
          handleCancel();
          message.success('Письмо отправлено');
        },
        onFail: () => {
          message.error('Ошибка, попробуйте еще раз');
        },
      } as {
        userName: string;
        userEmail: string;
        message: string;
        onFail: () => void;
        onSuccess: () => void;
      });
    });
  };
  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      width={950}
      bodyStyle={{padding: isTablet ? '10px' : '50px'}}
    >
      <Form form={form} validateMessages={validateMessages}>
        <Row gutter={[25, isTablet ? 0 : 25]}>
          <Col span={24}>
            <Typography.Title>{modals.feedback.title}</Typography.Title>
            <Typography.Paragraph>{modals.feedback.caption}</Typography.Paragraph>
          </Col>
          <Col xs={24} md={12}>
            <Control label={modals.feedback.nameInput.label}>
              <Form.Item name={'userName'} rules={[requiredRule]}>
                <Input size={'large'} placeholder={modals.feedback.nameInput.placeholder} />
              </Form.Item>
            </Control>
          </Col>
          <Col xs={24} md={12}>
            <Control label={modals.feedback.emailInput.label}>
              <Form.Item name={'userEmail'} rules={[requiredRule, {type: 'email'}]}>
                <Input size={'large'} placeholder={modals.feedback.emailInput.placeholder} />
              </Form.Item>
            </Control>
          </Col>
          <Col span={24}>
            <Control label={modals.feedback.commentInput.label}>
              <Form.Item name={'message'} rules={[requiredRule]}>
                <TextArea rows={4} placeholder={modals.feedback.textAreaPlaceholder} />
              </Form.Item>
            </Control>
          </Col>
          <Col span={24}>
            <Space size={25} direction={isTablet ? 'vertical' : 'horizontal'}>
              <Button type={'primary'} size={'large'} disabled={!isChecked} onClick={handleClickAtButton}>
                {modals.feedback.button}
              </Button>
              <Space>
                <Checkbox onChange={handleChangeCheckbox} checked={isChecked} />
                <Typography.Text>
                  {modals.feedback.checkbox[0]}{' '}
                  <a href={pdf} target="_blank" rel="noreferrer">
                    {modals.feedback.checkbox[1].title}{' '}
                  </a>
                  {modals.feedback.checkbox[2]}
                </Typography.Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default FeedbackModal;
