import React, {useContext} from 'react';
import {Button, Col, Row, Space, Typography} from 'antd';
import ResultLiterature from './ResultLiterature';
import ResultUserValues from './ResultUserValues';
import styled from 'styled-components';
import {Formula, Legend, StatusMessage} from '~src/components';
import {IUserValuesAtCalculation1} from '~src/store/Calculations';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {ArrowRightOutlined, DownloadOutlined} from '@ant-design/icons';
import FormulaElement from '~src/components/molecules/Formula/FormulaElement';
import ResultMaterialsTable from '~src/components/organisms/Calculation1Organism/ResultMaterialsTable';
import {getAxis, getBarDataSource, getLinesForResult2} from '~src/utils/chart';
import {Colors} from '~src/components/molecules/Chart/DiagramService';
import Chart from '~src/components/molecules/Chart';
import {IResultValues} from '~src/services/Calculation1Service';
import {LocaleContext} from '~src/context';
import MobileWarning from '~src/components/organisms/Calculation1Organism/MobileWarning';
import {Routes} from '~src/utils/routes';

interface IResult2Props {
  onClickAtDownloadReportButton: () => void;
}

const Result2: React.FC<IResult2Props> = props => {
  const {
    pages: {
      calculation1: {result},
    },
  } = useContext(LocaleContext);
  const {calculations} = useStoreon<IState, IEvents>('calculations');
  const userValues = calculations[1].userValues as IUserValuesAtCalculation1;
  const resultValues = calculations[1].resultValues as IResultValues;
  const lines = getLinesForResult2(userValues, resultValues);
  const barDataSource = getBarDataSource(userValues, lines);
  const axis = getAxis(barDataSource);

  const intersectionPoints = [
    {
      x: resultValues.with_x_mu
        ? resultValues.x_mu * 1000 + userValues.layers.filter(layer => layer.isEnabled)[resultValues.x_mu_index].t
        : userValues.layers
            .filter(layer => layer.isEnabled)
            .reduce((acc, layer, index, arr) => {
              if (index === resultValues.x_mu_index) arr.splice(index);
              return acc + layer.t;
            }, 0),
      y: 50,
      color: Colors.red,
    },
  ];

  return (
    <Row gutter={[0, 50]}>
      <Col span={24}>
        <Row gutter={[20, 20]}>
          <Col md={12} sm={24}>
            <ChartWrapper>
              <Chart
                canvasId={'chart-result2'}
                axis={axis}
                type={'outlined'}
                kind={'bar'}
                width={450}
                height={350}
                dataSource={barDataSource}
                dataSourceId={calculations['1'].id}
                columnLinesColors={[Colors.green, Colors.blue]}
                intersectionColor={Colors.red}
                intersectionPoints={intersectionPoints}
              />
            </ChartWrapper>
            <MobileWarning />
          </Col>
          <Col md={12} sm={24}>
            <Space size={50} direction={'vertical'}>
              <Legend resultType={1} layers={userValues.layers} axis={result.legendAxis[1]} />
              <Space direction={'horizontal'} size={20}>
                <Button
                  icon={<DownloadOutlined />}
                  size={'large'}
                  type={'primary'}
                  onClick={props.onClickAtDownloadReportButton}
                >
                  {'Скачать отчёт'}
                </Button>
                <Col>
                  <Button icon={<ArrowRightOutlined />} size={'large'} href={Routes.calculation2} target={'_blank'}>
                    {'Расчёт материалов'}
                  </Button>
                </Col>
              </Space>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>{'Характеристики объекта'}</Typography.Title>
          </Col>
          <Col span={24}>
            <ResultUserValues />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Typography.Title level={3}>{'1. Вычисление зоны максимального увлажнения'}</Typography.Title>
          </Col>
          <Col span={24}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Typography.Paragraph>
                  Для определения плоскости возможной конденсации определим для каждого слоя значение fi(tм.у.) согласно
                  СП 50.13330.2012 по формуле (8.7)
                </Typography.Paragraph>
                <ResultSection>
                  <Col span={24}>
                    <Formula name={'f_i'} />
                  </Col>
                </ResultSection>
              </Col>
              <Col span={24}>
                <Typography.Paragraph>
                  Определим общее сопротивление паропроницаемости ограждающей конструкции
                </Typography.Paragraph>
                <ResultSection>
                  <Col span={24}>
                    <Space direction={'horizontal'} size={20}>
                      <Space direction={'horizontal'} size={0}>
                        <Formula name={'R_ni'} />,
                      </Space>
                      <Formula name={'r_op'} value={resultValues.r_op} />
                    </Space>
                  </Col>
                </ResultSection>
                <Typography.Paragraph>
                  где δ — толщина слоя, μ_i — расчетный коэффициент паропроницаемости материала слоя ограждающей
                  конструкции, мг/(м·ч·Па)
                </Typography.Paragraph>
              </Col>
              <Col span={24}>
                <Typography.Paragraph>Определяем парциальное давление насыщенного водяного пара</Typography.Paragraph>
                <ResultSection>
                  <Col span={24}>
                    <Formula name={'E_b'} value={resultValues.E_b} />
                  </Col>
                </ResultSection>
              </Col>
              <Col span={24}>
                <Typography.Paragraph>
                  Определяем парциальное давление водяного пара внутреннего воздуха
                </Typography.Paragraph>
                <ResultSection>
                  <Col span={24}>
                    <Formula name={'e_b'} value={resultValues.e_b} />
                  </Col>
                </ResultSection>
              </Col>
              <Col span={24}>
                <Typography.Paragraph>
                  Определяем среднюю температуру наружного воздуха для периода с отрицательными среднемесячными
                  температурами
                </Typography.Paragraph>
                <ResultSection>
                  <Col span={24}>
                    <Formula name={'t_notr'} value={resultValues.t_notr} />
                  </Col>
                </ResultSection>
              </Col>
              <Col span={24}>
                <Typography.Paragraph>
                  Определяем среднее парциальное давление водяного пара наружного воздуха периода месяцев с
                  отрицательными среднемесячными температурами
                </Typography.Paragraph>
                <ResultSection>
                  <Col span={24}>
                    <Formula name={'e_notr'} value={resultValues.e_notr} />
                  </Col>
                </ResultSection>
              </Col>
              <Col span={24}>
                <Typography.Paragraph>
                  Для каждого значения f i (t м.у. ) определим по таблице 11 СП 50.13330.2012 значение t м.у. и
                  температуру на границе слоев t_н и t_к, определенную по формуле (8.10) СП 50.13330.2012
                </Typography.Paragraph>
                <ResultSection>
                  <Col span={24}>
                    <ResultMaterialsTable />
                  </Col>
                </ResultSection>
              </Col>
              {resultValues.with_x_mu && (
                <Col span={24}>
                  <Typography.Paragraph>Определим координаты плоскости максимального увлажнения:</Typography.Paragraph>
                  <ResultSection>
                    <Col span={24}>
                      <Formula name={'x_mu'} value={resultValues.x_mu} />
                    </Col>
                  </ResultSection>
                </Col>
              )}
              <Col span={24}>
                <Typography.Paragraph>
                  Определим паропроницаемость ограждающей конструкции (в пределах от внутренней поверхности до плоскости
                  возможной конденсации)
                </Typography.Paragraph>
                <ResultSection>
                  <Col span={24}>
                    <Formula name={'R_ni'} value={resultValues.R_ni} />
                  </Col>
                </ResultSection>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Typography.Title level={3}>
              {'2. Возможность накопления влаги за годовой период эксплуатации'}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Typography.Paragraph>
                  Определяем Е — парциальное давление водяного пара, в плоскости возможной конденсации за годовой период
                  эксплуатации
                </Typography.Paragraph>
                <ResultSection>
                  <Col span={24}>
                    <Formula name={'E'} />
                  </Col>
                </ResultSection>
                <Typography.Paragraph>
                  E1, Е2, Е3 - парциальные давления водяного пара, Па, принимаемые по температуре ti, в плоскости
                  возможной конденсации, определяемой при средней температуре наружного воздуха соответственно зимнего,
                  весенне-осеннего и летнего периодов;
                </Typography.Paragraph>
                <Typography.Paragraph>
                  z1, z2, z3, - продолжительность, мес, соответственно зимнего, весенне-осеннего и летнего периодов,
                  определяемая с учетом следующих условий:
                </Typography.Paragraph>
                <Typography.Paragraph>
                  а) к зимнему периоду относятся месяцы со средними температурами наружного воздуха ниже минус 5 °С;
                </Typography.Paragraph>
                <Typography.Paragraph>
                  б) к весенне-осеннему периоду относятся месяцы со средними температурами наружного воздуха от минус 5
                  до плюс 5 °С;
                </Typography.Paragraph>
                <Typography.Paragraph>
                  в) к летнему периоду относятся месяцы со средними температурами наружного воздуха выше плюс 5 °С.
                </Typography.Paragraph>
              </Col>
              <Col span={24}>
                <Typography.Paragraph>
                  Для определения ti определим ∑R-термическое сопротивление слоя ограждения в пределах от внутренней
                  поверхности до плоскости возможной конденсации:
                </Typography.Paragraph>
                <ResultSection>
                  <Formula name={'termResist'} value={resultValues.termResist} />
                </ResultSection>
              </Col>
              <Col span={24}>
                <Typography.Title level={4}>{`2.1. Зимний период${
                  resultValues.z_n![0].length === 0 ? result.result2.emptyZLabel : ''
                }`}</Typography.Title>
                {resultValues.z_n![0].length > 0 && (
                  <ResultSection>
                    <Space direction={'vertical'}>
                      <Formula name={'z1'} value={resultValues.z_n![0].length} />
                      <Formula name={'to1'} value={resultValues.t_on![0]} />
                      <Formula name={'t1'} value={resultValues.t_n![0]} />
                      <Formula name={'E1'} value={resultValues.E_n![0]} />
                    </Space>
                  </ResultSection>
                )}
              </Col>
              <Col span={24}>
                <Typography.Title level={4}>{`2.2. Весенне-осенний период${
                  resultValues.z_n![1].length === 0 ? result.result2.emptyZLabel : ''
                }`}</Typography.Title>
                {resultValues.z_n![1].length > 0 && (
                  <ResultSection>
                    <Space direction={'vertical'}>
                      <Formula name={'z2'} value={resultValues.z_n![1].length} />
                      <Formula name={'to2'} value={resultValues.t_on![1]} />
                      <Formula name={'t2'} value={resultValues.t_n![1]} />
                      <Formula name={'E2'} value={resultValues.E_n![1]} />
                    </Space>
                  </ResultSection>
                )}
              </Col>
              <Col span={24}>
                <Typography.Title level={4}>{`2.3. Летний период${
                  resultValues.z_n![2].length === 0 ? result.result2.emptyZLabel : ''
                }`}</Typography.Title>
                {resultValues.z_n![2].length > 0 && (
                  <ResultSection>
                    <Space direction={'vertical'}>
                      <Formula name={'z3'} value={resultValues.z_n![2].length} />
                      <Formula name={'to3'} value={resultValues.t_on![2]} />
                      <Formula name={'t3'} value={resultValues.t_n![2]} />
                      <Formula name={'E3'} value={resultValues.E_n![2]} />
                    </Space>
                  </ResultSection>
                )}
              </Col>
              <Col span={24}>
                <Typography.Paragraph>
                  Определим парциальное давление водяного пара в плоскости возможной конденсации за годовой период
                  эксплуатации ограждающей конструкции для соответствующих продолжительностей периодов z1, z2, z3:
                </Typography.Paragraph>
                <ResultSection>
                  <Formula name={'E'} value={resultValues.E} />
                </ResultSection>
              </Col>
              <Col span={24}>
                <Typography.Paragraph>
                  Определим общее сопротивление паропроницаемости ограждающей конструкции
                </Typography.Paragraph>
                <ResultSection>
                  <Formula name={'R_pn'} value={resultValues.R_pn} />
                </ResultSection>
              </Col>
              <Col span={24}>
                <Typography.Paragraph>
                  Расчёт требуемого сопротивления паропроницанию (из условия недопустимости накопления влаги в
                  ограждающей конструкции за годовой период эксплуатации)
                </Typography.Paragraph>
                <ResultSection>
                  <Formula name={'R_tr_n1'} value={resultValues.R_tr_n1} />
                </ResultSection>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Typography.Title level={3}>
              {'3. Возможность накопления влаги за период с отрицательными среднемесячными температурами'}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Typography.Paragraph>
                  Для расчета нормируемого сопротивления паропроницанию Rn2^тр из условия ограничения влаги за период с
                  отрицательными средними месячными температурами наружного воздуха берем определенную по таблице 5.1 СП
                  131.13330.2018 продолжительность этого периода z0, среднюю температуру этого периода t0:
                </Typography.Paragraph>
                <ResultSection>
                  <Formula name={'z_o'} value={userValues.z0} />
                  ,&nbsp;
                  <Formula name={'t_o'} value={userValues.t0} />
                </ResultSection>
              </Col>
              <Col span={24}>
                <Typography.Paragraph>
                  Температуру t_0, в плоскости возможной конденсации для этого периода определяют по формуле (8.10) СП
                  50.13330.2012
                </Typography.Paragraph>
                <ResultSection>
                  <Col span={24}>
                    <Formula name={'t_o'} value={resultValues.t_o1} />
                  </Col>
                </ResultSection>
              </Col>
              <Col span={24}>
                <Typography.Paragraph>
                  Парциальное давление водяного пара Е0 в плоскости возможной конденсации определяют по формуле (8.8) СП
                  50.13330.2012
                </Typography.Paragraph>
                <ResultSection>
                  <Col span={24}>
                    <Formula name={'E_o'} value={resultValues.E_o} />
                  </Col>
                </ResultSection>
              </Col>
              <Col span={24}>
                <Typography.Paragraph>
                  Коэффициент η определяется по формуле (8.5) СП 50.13330.2012
                </Typography.Paragraph>
                <ResultSection>
                  <Col span={24}>
                    <Formula name={'eta'} value={resultValues.eta} />
                  </Col>
                </ResultSection>
              </Col>
              <Col span={24}>
                <Typography.Paragraph>
                  Делаем расчет требуемого сопротивления паропроницанию (из условия ограничения влаги в ограждающей
                  конструкции за период с отрицательными средними месячными температурами наружного воздуха)
                </Typography.Paragraph>
                <ResultSection>
                  <Col span={24}>
                    <Formula name={'R_tr_n2'} value={resultValues.R_tr_n2} />
                  </Col>
                </ResultSection>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Typography.Title level={3}>{'Выводы'}</Typography.Title>
          </Col>
          <Col span={24}>
            <Typography.Paragraph>
              Сопротивление паропроницанию конструкции стены в пределах от внутренней поверхности до плоскости возможной
              конденсации
            </Typography.Paragraph>
            <ResultSection>
              <Col span={24}>
                <Space direction={'horizontal'} size={15}>
                  <div>
                    <FormulaElement type={'var'} value={'R'} sub={'ni'} fontStyle={'italic'} />
                    &nbsp;
                    <FormulaElement type={'symb'} value={resultValues.conclusion5!.symbol} />
                    &nbsp;
                    <FormulaElement type={'symb'} value={'R'} sup={'тр'} sub={'n'} fontStyle={'italic'} />
                  </div>
                  <div>
                    <FormulaElement type={'symb'} value={'('} />
                    <FormulaElement type={'var'} value={`${resultValues.R_ni} м²·ч·Па/мг`} />
                    &nbsp;
                    <FormulaElement type={'symb'} value={resultValues.conclusion5!.symbol} />
                    &nbsp;
                    <FormulaElement
                      type={'symb'}
                      value={`${Math.max(resultValues.R_tr_n1!, resultValues.R_tr_n2!)} м²·ч·Па/мг`}
                    />
                    <FormulaElement type={'symb'} value={')'} />
                  </div>
                </Space>
              </Col>
            </ResultSection>

            <Typography.Paragraph>
              <StatusMessage
                type={resultValues.conclusion5!.type}
                description={resultValues.conclusion5!.description}
              />
            </Typography.Paragraph>

            {userValues.containsAirGap && (
              <Typography.Paragraph>
                <StatusMessage
                  type={'warning'}
                  description={
                    'Расчёт проведён без учёта вентилируемого фасада для определения зоны максимального увлажнения'
                  }
                />
              </Typography.Paragraph>
            )}
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>{'Список литературы'}</Typography.Title>
            <ResultLiterature />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const ResultSection = styled(Row)`
  display: flex;
  align-items: center;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 10px;
  padding: 15px 30px;
`;

const ChartWrapper = styled.div`
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default Result2;
