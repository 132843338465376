import React, {useContext} from 'react';
import * as colors from '@ant-design/colors';
import {Typography} from 'antd';
import styled, {css} from 'styled-components';
import {LocaleContext} from '~src/context';
import theme from '~src/theme/theme';
import { $phone } from '~src/theme/media';

const {Text} = Typography;

interface ILogo {
  color?: string;
  size?: 'normal' | 'large';
}

const Logo: React.FC<ILogo> = ({color = colors.presetPrimaryColors.blue, size = 'normal'}) => {
  const {
    components: {logo},
  } = useContext(LocaleContext);

  return (
    <TextStyled color={color} size={size}>
      {logo.caption}
    </TextStyled>
  );
};

const TextStyled = styled(Text)<ILogo>`
  color: ${props => props.color};
  font-family: ${theme.fontFamily};
  font-size: 16px;
  ${props =>
    props.size === 'large' &&
    css`
      font-size: 30px;
    `};
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-weight: 900;

  @media ${$phone} {
    font-size: 12px;
  }
`;

export default Logo;
