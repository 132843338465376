import React from 'react';
import styled from 'styled-components';

import {Layout, PageHeader, Typography, Tabs, Button} from 'antd';
import Header from './Header';
import Sider from './Sider';

interface ITabsProps {
  tabName: string;
  key: string;
}

interface IButtonsProps {
  key: number;
  buttonText: string;
  // TODO: подобрать тип для иконки из AntD
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buttonIcon: any;
}

interface IAdminTemplateProps {
  breadcrumbs?: string[];
  pageTitle?: string;
  tabs?: ITabsProps[];
  defaultActiveTab?: string;
  buttons?: IButtonsProps[];
  onChangeTabs?: (tabKey: string) => void;
  onClickAtButton?: (buttonKey: number) => void;
}

const {Content} = Layout;
const {TabPane} = Tabs;

const AdminTemplate: React.FC<IAdminTemplateProps> = ({
  children,
  onClickAtButton,
  onChangeTabs,
  pageTitle,
  tabs,
  buttons,
  defaultActiveTab,
}) => {
  const getHandlerClickAtButton = (buttonKey: number) => () => {
    onClickAtButton && onClickAtButton(buttonKey);
  };
  return (
    <LayoutStyled>
      <Sider />
      <Content>
        <Layout>
          <Header />
          <PageHeaderStyled title={''} backIcon={false} ghost={false}>
            <ContentWrapperStyled>
              <Typography.Title level={3}>{pageTitle}</Typography.Title>
              {buttons &&
                buttons.map(button => (
                  <Button key={button.key} type="primary" onClick={getHandlerClickAtButton(button.key)}>
                    <button.buttonIcon />
                    {button.buttonText}
                  </Button>
                ))}
            </ContentWrapperStyled>
            {tabs && (
              <Tabs defaultActiveKey={defaultActiveTab} tabBarStyle={{marginBottom: 0}} onChange={onChangeTabs}>
                {tabs?.map(tab => (
                  <TabPane tab={tab.tabName} key={tab.key} />
                ))}
              </Tabs>
            )}
          </PageHeaderStyled>
          <MainStyled>
            <ContentStyled>{children}</ContentStyled>
          </MainStyled>
        </Layout>
      </Content>
    </LayoutStyled>
  );
};

export default AdminTemplate;

const LayoutStyled = styled(Layout)`
  height: 100%;
`;

const PageHeaderStyled = styled(PageHeader)`
  padding: 0 30px;
`;

const MainStyled = styled.div`
  padding: 30px;
`;

const ContentStyled = styled.div`
  background: #fff;
  padding: 30px;
`;

const ContentWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
`;
