import React, {Fragment} from 'react';
import {Row, Col, Button} from 'antd';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import {FileAddOutlined} from '@ant-design/icons';

import {Space} from '~src/components';
import DoorsControls from './Content';

import AdditionalMaterialPreview from '~src/components/molecules/AdditionalMaterialPreview';

const Doors: React.FC = () => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {userValues} = calculations[3];

  const handleAddDoors = () => {
    dispatch('calculations/3/add-item', {itemName: 'doors'});
  };
  return (
    <Fragment>
      <SpaceStyled size={70} direction="vertical">
        <RowStyled>
          <Col md={12} xs={24}>
            {userValues.doors.map((item, index) => {
              return <DoorsControls doorIndex={index} doorItem={item} key={index} />;
            })}
          </Col>
          <Col md={6} sm={24} xs={24}>
            <AdditionalMaterialPreview sectionToRender="balkDoors" />
          </Col>
        </RowStyled>
        <Row gutter={20}>
          <Col span={6}>
            <Button onClick={handleAddDoors}>
              <FileAddOutlined />
              Добавить двери
            </Button>
          </Col>
        </Row>
      </SpaceStyled>
    </Fragment>
  );
};

const SpaceStyled = styled(props => <Space {...props} />)`
  margin-bottom: 50px;
`;

const RowStyled = styled(Row)`
  @media screen and (max-width: 320px) {
    margin-bottom: 25px;
  }

  @media screen and (min-width: 321px) and (max-width: 812px) {
    margin-bottom: 150px;
  }
`;

export default Doors;
