import vectorImage from './vector.png';

export enum Colors {
  black = '#242424',
  gray = '#E8E8E8',
  grayDarkness = '#8C8C8C',
  orange = '#CC642D',
  blue = '#1890FF',
  blueDarkness = '#002766',
  white = '#FFFFFF',
  red = '#F5222D',
  green = '#52C41A',
  intersection = 'rgba(120,167,255,0.7)',
}
enum Font {
  default = '10px Verdana',
  axis = '14px Verdana',
}

export type DiagramType = 'filled' | 'outlined';
export type DiagramKind = 'bar' | 'line';
interface DiagramConstructor {
  kind?: DiagramKind;
  type?: DiagramType;
  canvas: HTMLCanvasElement;
  height: number;
  width: number;
  axis?: Axis;
  hiddenAxis?: boolean;
  withFulcrumLines?: boolean;
}
export interface ColumnLine {
  lineStart: number;
  lineEnd: number;
}
export interface Column {
  width: number;
  defaultWidth?: number;
  type: 'finishingMaterial' | 'bearingWall' | 'insulation' | 'airGap' | 'facadeMaterial' | 'waterproofing';
  index: number;
  line1?: ColumnLine;
  line2?: ColumnLine;
}
export interface LinePoint {
  x: number;
  y: number;
  color: string;
}
export interface Axis {
  xMax?: number;
  xMin?: number;
  xTick?: number;
  yMax?: number;
  yMin?: number;
  yTick?: number;
}

export class Diagram {
  columnBorderWidth = 2;
  diagramBaseGap = 20;
  gap = 0;
  gaps: number[] = [];
  defaultGap = 0;
  padding = 0;
  type: DiagramType = 'filled';
  kind: DiagramKind = 'bar';
  ctx: CanvasRenderingContext2D;
  canvasHeight: number = 0;
  canvasWidth: number = 0;
  heightCoefficient: number = 0;
  widthCoefficient: number = 1;
  axis?: Axis;
  hiddenAxis?: boolean;
  withFulcrumLines?: boolean;
  meta: {
    columnLinesColors?: Colors[];
    withDiagramBase?: boolean;
    intersectionColor?: Colors;
  } = {};

  constructor({type, canvas, height, width, kind, axis, hiddenAxis, withFulcrumLines}: DiagramConstructor) {
    this.initKind(kind);
    this.initType(type);
    this.ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    this.canvasHeight = height;
    this.canvasWidth = width;
    this.axis = axis;
    this.hiddenAxis = hiddenAxis;
    this.withFulcrumLines = withFulcrumLines;

    if (this.axis && this.hiddenAxis !== true) {
      this.padding = 45;
      this.canvasHeight -= this.padding;
    }
  }
  private initGap = () => {
    this.gaps = [];
    if (!this.axis) return;
    this.gap = this.padding;
    this.defaultGap = this.padding;
  };
  private initType = (type?: DiagramType) => {
    this.type = type || this.type;
  };
  private initKind = (kind?: DiagramKind) => {
    this.kind = kind || this.kind;
  };
  private defineHeightCoefficient = () => {
    if (!this.axis || this.axis.yMin === undefined || this.axis.yMax === undefined) return;
    this.heightCoefficient =
      this.canvasHeight / (this.axis.yMin >= 0 ? this.axis.yMax : this.axis.yMax + Math.abs(this.axis.yMin));
  };
  private defineWidthCoefficient = () => {
    if (!this.axis || this.axis.xMin === undefined || this.axis.xMax === undefined) return;
    this.widthCoefficient =
      this.canvasWidth / (this.axis.xMin >= 0 ? this.axis.xMax : this.axis.xMax + Math.abs(this.axis.xMin));
  };
  private addGap(column: Column) {
    this.gaps.push(this.gap);
    this.gap = this.gap + column.width;
  }
  private drawBlock(rect: any) {
    const {x, y, width, height} = rect;
    const cornerRadius = 16;

    this.ctx.beginPath();

    this.ctx.fillStyle = Colors.white;
    this.ctx.strokeStyle = Colors.white;
    if (this.type === 'filled') {
      this.ctx.fillStyle = Colors.orange;
      this.ctx.strokeStyle = Colors.orange;
    }
    this.ctx.lineWidth = this.columnBorderWidth;
    this.ctx.lineJoin = 'round';
    this.ctx.lineWidth = cornerRadius;
    this.ctx.strokeRect(x + cornerRadius / 2, y - 5 + cornerRadius / 2, width - cornerRadius, height - cornerRadius);
    this.ctx.rect(x + cornerRadius / 2, y - 5 + cornerRadius / 2, width - cornerRadius, height - cornerRadius);
    this.ctx.fill();

    this.ctx.closePath();
  }

  private drawBrick(rect: any) {
    const {x, y, width, height} = rect;
    const cornerRadius = 12;

    this.ctx.beginPath();
    this.ctx.fillStyle = Colors.white;
    this.ctx.strokeStyle = Colors.white;
    if (this.type === 'filled') {
      this.ctx.fillStyle = '#D0AD8B';
      this.ctx.strokeStyle = '#D0AD8B';
    }
    this.ctx.lineWidth = this.columnBorderWidth;

    this.ctx.lineJoin = 'round';
    this.ctx.lineWidth = cornerRadius;

    this.ctx.strokeRect(x + cornerRadius / 2, y - 5 + cornerRadius / 2, width - cornerRadius, height - cornerRadius);
    this.ctx.rect(x + cornerRadius / 2, y - 5 + cornerRadius / 2, width - cornerRadius, height - cornerRadius);

    this.ctx.fill();
    this.ctx.closePath();
  }

  private drawHorizontalLine(rect: any) {
    const {x, y, width, height} = rect;

    this.ctx.beginPath();
    this.ctx.fillStyle = Colors.white;
    this.ctx.strokeStyle = Colors.white;

    this.ctx.rect(x, y, width, height);
    this.ctx.fill();
    this.ctx.closePath();
  }

  private drawNumberIndexes(columns: Column[]) {
    columns.forEach((column, index) => {
      this.drawNumberIndex(column, this.gaps[index], index);
    });
  }

  private drawNumberIndex(column: Column, gap: number, index: number) {
    this.ctx.beginPath();

    const indexGap = index % 2 === 0 ? 0 : 27;

    const numberHeight = 16;
    const numberWidth = 16;
    const numberX = gap + column.width / 2 - numberWidth / 2;
    const numberY = 10;
    this.ctx.fillStyle = Colors.blue;
    this.ctx.fillRect(numberX, numberY + indexGap, numberWidth, numberHeight);
    this.ctx.fillStyle = Colors.white;
    this.ctx.textAlign = 'center';
    this.ctx.font = Font.default;
    this.ctx.fillText(String(column.index), numberX + 8, numberY + 12 + indexGap);

    this.ctx.closePath();
  }

  private drawIntersectionPoints(points: LinePoint[]) {
    if (!points || points.length === 0) return;

    const gap = this.defaultGap;
    const width =
      points.length > 1
        ? points.slice(0, -1).reduceRight((acc, point) => acc - point.x, points[points.length - 1].x)
        : 2;

    const drawSection = () => {
      this.ctx.beginPath();
      this.ctx.fillStyle = this.meta.intersectionColor || Colors.intersection;
      this.ctx.rect(gap + points[0].x, this.canvasHeight, width, -this.canvasHeight);
      this.ctx.fill();
      this.ctx.closePath();
    };

    const drawLine = () => {
      this.ctx.beginPath();
      this.ctx.fillStyle = this.meta.intersectionColor || Colors.intersection;
      this.ctx.rect(gap + points[0].x, this.canvasHeight, 2, -this.canvasHeight);
      this.ctx.fill();
      this.ctx.closePath();
    };

    if (points.length > 1) {
      drawSection();
    } else {
      drawLine();
    }
  }

  private drawColumnLine(column: Column, xAxisGap?: number) {
    let yAxisGap = 0;
    if (this.axis?.yMin && this.axis.yMin < 0) {
      yAxisGap = Math.abs(this.axis.yMin) * this.heightCoefficient;
    }
    if (column.line1 && this.meta.columnLinesColors) {
      this.ctx.beginPath();
      this.ctx.strokeStyle = this.meta.columnLinesColors[0];
      this.ctx.lineWidth = 4;
      this.ctx.moveTo(
        xAxisGap || this.gap,
        this.canvasHeight - yAxisGap - this.heightCoefficient * column.line1.lineStart,
      );
      this.ctx.lineTo(
        (xAxisGap || this.gap) + column.width,
        this.canvasHeight - yAxisGap - this.heightCoefficient * column.line1.lineEnd,
      );
      this.ctx.stroke();
      this.ctx.closePath();
    }
    if (column.line2 && this.meta.columnLinesColors) {
      this.ctx.beginPath();
      this.ctx.strokeStyle = this.meta.columnLinesColors![1];
      this.ctx.lineWidth = 4;
      this.ctx.moveTo(
        xAxisGap || this.gap,
        this.canvasHeight - yAxisGap - this.heightCoefficient * column.line2.lineStart,
      );
      this.ctx.lineTo(
        (xAxisGap || this.gap) + column.width,
        this.canvasHeight - yAxisGap - this.heightCoefficient * column.line2.lineEnd,
      );
      this.ctx.stroke();
      this.ctx.closePath();
    }
  }

  private drawColumn1Type(column: Column) {
    this.ctx.beginPath();
    this.ctx.fillStyle = Colors.gray;
    this.ctx.rect(this.gap, this.canvasHeight, column.width, -this.canvasHeight);
    if (this.type === 'filled') {
      this.ctx.fill();
    }
    this.ctx.closePath();

    this.ctx.beginPath();
    this.ctx.strokeStyle = Colors.black;
    this.ctx.lineWidth = this.columnBorderWidth;
    this.ctx.rect(this.gap, this.canvasHeight, column.width, -this.canvasHeight);
    this.ctx.stroke();
    this.ctx.closePath();

    this.drawColumnLine(column);
    this.addGap(column);
  }

  private drawColumn2Type(column: Column) {
    const blockCount = 4;
    const blockGap = 5;

    this.ctx.beginPath();
    this.ctx.fillStyle = Colors.gray;
    this.ctx.rect(this.gap, this.canvasHeight, column.width, -this.canvasHeight);
    this.ctx.fill();
    this.ctx.closePath();

    const oneBlockHeight = this.canvasHeight / blockCount - blockGap + 2;
    for (let i = 0; i < blockCount; i++) {
      let y = 0;
      if (i > 0) {
        y = i * oneBlockHeight + i + i * blockGap;
      }
      this.drawBlock({x: this.gap, y: y, width: column.width, height: oneBlockHeight});
    }

    this.ctx.beginPath();
    this.ctx.strokeStyle = Colors.black;
    this.ctx.lineWidth = this.columnBorderWidth;
    this.ctx.rect(this.gap, this.canvasHeight, column.width, -this.canvasHeight);
    this.ctx.stroke();
    this.ctx.closePath();

    this.drawColumnLine(column);
    this.addGap(column);
  }

  private drawColumn4Type(column: Column) {
    const brickCount = 8;
    const brickGap = 4;

    this.ctx.beginPath();
    this.ctx.fillStyle = Colors.gray;
    this.ctx.rect(this.gap, this.canvasHeight, column.width, -this.canvasHeight);
    this.ctx.fill();
    this.ctx.closePath();

    const oneBrickHeight = this.canvasHeight / brickCount - brickGap;
    for (let i = 0; i < brickCount; i++) {
      let y = 0;
      if (i > 0) {
        y = i * oneBrickHeight + i + i * brickGap;
      }
      this.drawBrick({x: this.gap, y: y, width: column.width, height: oneBrickHeight});
    }

    this.ctx.beginPath();
    this.ctx.strokeStyle = Colors.black;
    this.ctx.lineWidth = this.columnBorderWidth;
    this.ctx.rect(this.gap, this.canvasHeight, column.width, -this.canvasHeight);
    this.ctx.stroke();
    this.ctx.closePath();

    this.drawColumnLine(column);
    this.addGap(column);
  }

  private drawColumn3Type(column: Column) {
    const img = new Image();
    const gap = this.gap;
    img.src = vectorImage;
    img.onload = () => {
      const pattern = this.ctx.createPattern(img, 'repeat') as CanvasPattern;
      this.ctx.beginPath();
      this.ctx.fillStyle = pattern;
      this.ctx.fillRect(
        gap + this.columnBorderWidth / 2,
        this.columnBorderWidth / 2,
        column.width - this.columnBorderWidth / 2,
        this.canvasHeight - this.columnBorderWidth,
      );
      this.ctx.closePath();
      if (column.line1) this.drawColumnLine(column, gap);
      this.drawNumberIndex(column, gap, column.index - 1);
    };

    this.ctx.beginPath();
    this.ctx.fillStyle = '#FFFBCA';
    this.ctx.rect(this.gap, this.canvasHeight, column.width, -this.canvasHeight);
    if (this.type === 'filled') {
      this.ctx.fill();
    }
    this.ctx.closePath();

    this.ctx.beginPath();
    this.ctx.strokeStyle = Colors.black;
    this.ctx.lineWidth = this.columnBorderWidth;
    this.ctx.rect(this.gap, this.canvasHeight, column.width, -this.canvasHeight);
    this.ctx.stroke();
    this.ctx.closePath();

    this.addGap(column);
  }

  private drawColumn5Type(column: Column) {
    this.ctx.beginPath();
    this.ctx.fillStyle = '#BAE7FF';
    this.ctx.rect(this.gap, this.canvasHeight, column.width, -this.canvasHeight);
    if (this.type === 'filled') {
      this.ctx.fill();
    }
    this.ctx.closePath();

    this.ctx.beginPath();
    this.ctx.strokeStyle = Colors.black;
    this.ctx.lineWidth = this.columnBorderWidth;
    this.ctx.rect(this.gap, this.canvasHeight, column.width, -this.canvasHeight);
    this.ctx.stroke();
    this.ctx.closePath();

    this.drawColumnLine(column);
    this.addGap(column);
  }

  private drawColumn6Type(column: Column) {
    const brickCount = 10;
    const brickGap = 20;

    this.ctx.beginPath();
    this.ctx.fillStyle = Colors.gray;
    this.ctx.rect(this.gap, this.canvasHeight, column.width, -this.canvasHeight);
    if (this.type === 'filled') {
      this.ctx.fillStyle = Colors.black;
    }
    this.ctx.fill();
    this.ctx.closePath();

    const oneBrickHeight = this.canvasHeight / brickCount - brickGap;
    for (let i = 0; i < brickCount; i++) {
      let y = 0;
      if (i > 0) {
        y = i * oneBrickHeight + i + i * brickGap;
      }
      this.drawHorizontalLine({x: this.gap, y: y, width: column.width, height: oneBrickHeight});
    }

    this.ctx.beginPath();
    this.ctx.strokeStyle = Colors.black;
    this.ctx.lineWidth = this.columnBorderWidth;
    this.ctx.rect(this.gap, this.canvasHeight, column.width, -this.canvasHeight);
    this.ctx.stroke();
    this.ctx.closePath();

    this.drawColumnLine(column);
    this.addGap(column);
  }

  private drawDiagramBase(maxWidth: number) {
    this.ctx.beginPath();
    this.ctx.strokeStyle = Colors.black;
    this.ctx.lineWidth = 7;
    this.ctx.moveTo(0, 0);
    this.ctx.lineTo(maxWidth + this.diagramBaseGap, 0);
    this.ctx.stroke();
    this.ctx.closePath();

    this.ctx.beginPath();
    this.ctx.strokeStyle = Colors.black;
    this.ctx.lineWidth = 7;
    this.ctx.moveTo(0, this.canvasHeight);
    this.ctx.lineTo(maxWidth + this.diagramBaseGap, this.canvasHeight);
    this.ctx.stroke();
    this.ctx.closePath();
  }

  private drawAxis(maxWidth: number) {
    if (!this.axis || this.hiddenAxis === true) return;
    if (this.axis.xMax !== undefined && this.axis.xTick !== undefined && this.axis.xMin !== undefined) {
      // x
      this.ctx.beginPath();
      this.ctx.strokeStyle = Colors.grayDarkness;
      this.ctx.lineWidth = 2;
      this.ctx.moveTo(this.padding, this.canvasHeight);
      this.ctx.lineTo(maxWidth * this.widthCoefficient + this.padding, this.canvasHeight);
      this.ctx.stroke();
      this.ctx.closePath();

      this.ctx.beginPath();
      this.ctx.textAlign = 'center';
      this.ctx.fillStyle = Colors.black;
      this.ctx.font = Font.axis;
      this.ctx.fillText('X', this.axis.xMax * this.widthCoefficient + this.padding - 50, this.canvasHeight + 15);
      this.ctx.closePath();

      // x ticks
      let xIter = this.axis.xMin;
      while (xIter < this.axis.xMax - this.axis.xTick) {
        let xStart = xIter * this.widthCoefficient + this.padding;
        let yStart = this.canvasHeight;
        let xEnd = xIter * this.widthCoefficient + this.padding;
        let yEnd = this.canvasHeight + 10;

        this.ctx.beginPath();
        this.ctx.strokeStyle = Colors.grayDarkness;
        this.ctx.lineWidth = 2;
        this.ctx.moveTo(xStart, yStart);
        this.ctx.lineTo(xEnd, yEnd);
        this.ctx.stroke();
        this.ctx.closePath();

        this.ctx.beginPath();
        this.ctx.textAlign = 'center';
        this.ctx.fillStyle = Colors.black;
        this.ctx.font = Font.default;
        this.ctx.fillText(String(xIter), xEnd, yEnd + 15);
        this.ctx.closePath();

        xIter += this.axis.xTick;
      }
    }

    if (this.axis.yMax !== undefined && this.axis.yTick !== undefined && this.axis.yMin !== undefined) {
      // y
      this.ctx.beginPath();
      this.ctx.strokeStyle = Colors.grayDarkness;
      this.ctx.lineWidth = 2;
      this.ctx.moveTo(this.padding, 0);
      this.ctx.lineTo(this.padding, this.canvasHeight);
      this.ctx.stroke();
      this.ctx.closePath();

      this.ctx.beginPath();
      this.ctx.textAlign = 'center';
      this.ctx.fillStyle = Colors.black;
      this.ctx.font = Font.axis;
      this.ctx.fillText('Y', this.padding - 10, 12);
      this.ctx.closePath();

      // y ticks
      let yIter = 0;
      let yTickCount = Math.ceil(
        (this.axis.yMin >= 0 ? this.axis.yMax : this.axis.yMax + Math.abs(this.axis.yMin)) / this.axis.yTick,
      );
      let yTickAbsolute = this.canvasHeight / yTickCount;
      while (yIter < yTickCount) {
        let xStart = this.padding;
        let yStart = this.canvasHeight - yIter * yTickAbsolute;
        let xEnd = this.padding - 10;
        let yEnd = this.canvasHeight - yIter * yTickAbsolute;
        let index = yIter === 0 ? this.axis.yMin : this.axis.yMin + yIter * this.axis.yTick;

        this.ctx.beginPath();
        this.ctx.strokeStyle = Colors.grayDarkness;
        this.ctx.lineWidth = 2;
        this.ctx.moveTo(xStart, yStart);
        this.ctx.lineTo(xEnd, yEnd);
        this.ctx.stroke();
        this.ctx.closePath();

        this.ctx.beginPath();
        this.ctx.fillStyle = Colors.black;
        this.ctx.font = Font.default;
        this.ctx.textAlign = 'right';
        this.ctx.fillText(String(index), this.padding - 15, yEnd + 5);
        this.ctx.closePath();
        yIter++;
      }
    }
  }

  private getDefaultGap() {
    return this.columnBorderWidth + this.diagramBaseGap;
  }

  drawLine(data: LinePoint[]) {
    const drawCurve = (tension: number) => {
      const t = tension != null ? tension : 1;

      this.ctx.beginPath();
      this.ctx.lineWidth = 2.5;
      this.ctx.moveTo(
        data[0].x * this.widthCoefficient + this.defaultGap,
        this.canvasHeight - data[0].y * this.heightCoefficient,
      );
      for (let i = 0; i < data.length - 1; i++) {
        const p0 = i > 0 ? data[i - 1] : data[0];
        const p1 = data[i];
        const p2 = data[i + 1];
        const p3 = i !== data.length - 2 ? data[i + 2] : p2;

        const cp1x = p1.x + ((p2.x - p0.x) / 6) * t;
        const cp1y = p1.y + ((p2.y - p0.y) / 6) * t;

        const cp2x = p2.x - ((p3.x - p1.x) / 6) * t;
        const cp2y = p2.y - ((p3.y - p1.y) / 6) * t;

        this.ctx.bezierCurveTo(
          cp1x * this.widthCoefficient + this.defaultGap,
          this.canvasHeight - cp1y * this.heightCoefficient,
          cp2x * this.widthCoefficient + this.defaultGap,
          this.canvasHeight - cp2y * this.heightCoefficient,
          p2.x * this.widthCoefficient + this.defaultGap,
          this.canvasHeight - p2.y * this.heightCoefficient,
        );
      }
      this.ctx.strokeStyle = Colors.blueDarkness;
      this.ctx.stroke();
      this.ctx.closePath();
    };

    data.forEach(point => {
      if (this.withFulcrumLines) {
        this.ctx.beginPath();

        this.ctx.strokeStyle = point.color;
        this.ctx.lineWidth = 2;
        this.ctx.moveTo(
          point.x * this.widthCoefficient + this.defaultGap,
          this.canvasHeight - point.y * this.heightCoefficient - 2.5,
        );
        this.ctx.lineTo(point.x * this.widthCoefficient + this.defaultGap, this.canvasHeight);
        this.ctx.stroke();
        this.ctx.closePath();
      }

      this.ctx.beginPath();
      this.ctx.fillStyle = Colors.blueDarkness;
      this.ctx.fillRect(
        point.x * this.widthCoefficient - 2.5 + this.defaultGap,
        this.canvasHeight - point.y * this.heightCoefficient - 2.5,
        5,
        5,
      );
      this.ctx.closePath();
    });

    drawCurve(1);

    if (this.axis && this.axis.xMax) {
      this.drawAxis(this.axis.xMax);
    }
  }

  draw(
    data: Column[] | LinePoint[],
    props?: {
      withDiagramBase?: boolean;
      intersectionPoints?: LinePoint[];
      columnLineColors?: Colors[];
      intersectionColor?: Colors;
      axis?: Axis;
    },
  ) {
    this.axis = props?.axis;
    this.initGap();
    this.defineHeightCoefficient();
    this.defineWidthCoefficient();

    if (props?.columnLineColors) {
      this.meta.columnLinesColors = props.columnLineColors;
    }
    if (props?.withDiagramBase) {
      this.meta.withDiagramBase = props.withDiagramBase;
    }
    if (props?.intersectionColor) {
      this.meta.intersectionColor = props.intersectionColor;
    }
    if (this.kind === 'line') {
      const lines = data as LinePoint[];
      this.drawLine(lines);
      return;
    }
    if (this.kind === 'bar') {
      const columns = (data as Column[]).map(column => ({
        ...column,
        defaultWidth: column.width,
        width: column.width * this.widthCoefficient,
      }));

      let maxWidth = columns.reduce(
        (acc, column) => acc + column.width,
        props?.withDiagramBase ? this.diagramBaseGap : 0,
      );

      if (props?.withDiagramBase) {
        this.gap = this.getDefaultGap();
      }

      columns.forEach(column => {
        switch (column.type) {
          case 'finishingMaterial':
            this.drawColumn1Type(column);
            break;
          case 'bearingWall':
            this.drawColumn2Type(column);
            break;
          case 'insulation':
            this.drawColumn3Type(column);
            break;
          case 'facadeMaterial':
            this.drawColumn4Type(column);
            break;
          case 'airGap':
            this.drawColumn5Type(column);
            break;
          case 'waterproofing':
            this.drawColumn6Type(column);
            break;
        }
      });
      this.gap = this.defaultGap;

      if (props?.withDiagramBase) {
        this.drawDiagramBase(maxWidth);
      }
      if (props?.intersectionPoints) {
        this.drawIntersectionPoints(
          props.intersectionPoints.map(point => ({...point, x: point.x * this.widthCoefficient})),
        );
      }
      this.drawAxis(maxWidth);
      this.drawNumberIndexes(columns);
    }
  }
  clear() {
    this.ctx.clearRect(0, 0, 10000, 10000);
  }
}
