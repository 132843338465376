import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import {Layout, Button, Typography, Divider} from 'antd';
import {MenuUnfoldOutlined} from '@ant-design/icons';

import {ContentView, Logo, Menu} from '~src/components';
import {LocaleContext} from '~src/context';
import {$tablet} from '~src/theme/media';
import MenuModal from '~src/components/organisms/Modals/MenuModal';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

const {Text} = Typography;
const {Footer: AntdFooter} = Layout;

interface IFooterProps {
  className?: string;
}

export const Footer: React.FC<IFooterProps> = ({className}) => {
  const {
    components: {menu},
  } = useContext(LocaleContext);
  const {dispatch} = useStoreon<IState, IEvents>('modals');
  const [isVisibleMenu, setIsVisibleMenu] = useState(false);
  return (
    <FooterStyled className={className}>
      <ContentView>
        <FooterMainStyled>
          <Logo color="#ffffff" />
          <MenuMobileButtonStyled
            onClick={() => {
              setIsVisibleMenu(true);
            }}
          >
            <MenuUnfoldOutlined />
          </MenuMobileButtonStyled>
          <RightSideStyled>
            <MenuStyled items={menu.app} />
            <ButtonContainerStyled>
              <Button
                type="primary"
                onClick={() => {
                  dispatch('modals/show-modal', {key: 'feedbackModal', value: true});
                }}
              >
                Обратная связь
              </Button>
            </ButtonContainerStyled>
          </RightSideStyled>
        </FooterMainStyled>
        <FooterCopyrightStyled>
          <Divider />
          <CopyrightStyled>Расчетстен.рф © 2021. Все права защищены.</CopyrightStyled>
        </FooterCopyrightStyled>
      </ContentView>
      {isVisibleMenu && (
        <MenuModal
          visible={isVisibleMenu}
          onCancel={() => {
            setIsVisibleMenu(false);
          }}
        />
      )}
    </FooterStyled>
  );
};

const MenuStyled = styled(Menu)`
  border-bottom: none;
  background: transparent;
  margin-right: 30px !important;
  a {
    color: #ffffff !important;
  }
`;

const ButtonContainerStyled = styled.div``;

const FooterStyled = styled(AntdFooter)`
  display: flex;
  flex-direction: row;
  background: #096dd9;
  line-height: 64px;
  padding-top: 0;
`;

const FooterMainStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const FooterCopyrightStyled = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1120px;
  line-height: 24px;
  white-space: pre-wrap;
`;

const CopyrightStyled = styled(Text)`
  color: #ffffff;
`;

const MenuMobileButtonStyled = styled(Button)`
  display: none;
  @media ${$tablet} {
    display: block;
  }
`;

const RightSideStyled = styled.div`
  display: flex;
  align-items: center;
  @media ${$tablet} {
    display: none;
  }
`;
