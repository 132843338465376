import React from 'react';
import {Menu as AntdMenu} from 'antd';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {ClickParam} from 'antd/lib/menu';

const {SubMenu, Item} = AntdMenu;
interface IMenuProps {
  value: string;
  onClick: (key: string) => void;
}

const Menu: React.FC<IMenuProps> = ({onClick, value}) => {
  const {
    calculations,
    materials: {groupedValues},
  } = useStoreon<IState, IEvents>('calculations', 'materials');

  const {userValues} = calculations['1'];

  const handleClick = (e: ClickParam) => {
    onClick(e.key);
  };

  const [defaultOpenKeys, defaultSelectedKeys] = value.split('--');

  return (
    <AntdMenu
      onClick={handleClick}
      defaultOpenKeys={[defaultOpenKeys]}
      defaultSelectedKeys={[defaultSelectedKeys]}
      mode="inline"
      theme={'light'}
    >
      {Object.keys(groupedValues).map(key => {
        return (
          <SubMenu key={key} title={<span>{key}</span>}>
            {Object.keys(groupedValues[key]).map(groupKey => {
              if (
                (userValues.typeConstruction !== 0 && groupKey === 'Вентилируемая воздушная прослойка') ||
                groupKey === 'Экран'
              ) {
                return;
              }
              return <Item key={`${key}--${groupKey}`}>{groupKey}</Item>;
            })}
          </SubMenu>
        );
      })}
    </AntdMenu>
  );
};

export default Menu;
