import React, {useContext} from 'react';
import {LocaleContext} from '~src/context';
import {Table} from 'antd';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {createDataSourceResult3} from '~src/utils/calculations';

interface IResultHeatLossParamsProps {
  className?: string;
}

const ResultHeatLossParams: React.FC<IResultHeatLossParamsProps> = ({className}) => {
  const {calculations} = useStoreon<IState, IEvents>('calculations');
  const {
    pages: {
      calculation1: {result},
    },
  } = useContext(LocaleContext);
  const dataSource = createDataSourceResult3(calculations[1].resultValues, result);
  return (
    <Table
      className={className}
      dataSource={dataSource}
      columns={result.result3TableGeneration.heatLossParams.columns}
      pagination={false}
      rowKey={record => {
        return record.name;
      }}
    />
  );
};

export default ResultHeatLossParams;
