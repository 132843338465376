import React from 'react';
import {Col, Row, Typography, Space, Collapse as AntdCollapse} from 'antd';
import {Formula, StatusMessage} from '~src/components';
import * as colors from '@ant-design/colors';
import styled from 'styled-components';

import ResultUserValues from './ResultUserValues';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {IResultValues} from '~src/services/Calculation1Service';
import {$phone, $tablet, deviceSizes} from '~src/theme/media';
import {useMediaQuery} from 'react-responsive';
import ResultHumidityRegime from './ResultHumidityRegime';
import ResultHumidityAnnualCycle from './ResultHumidityAnnualCycle';
import FormulaElement from '~src/components/molecules/Formula/FormulaElement';

interface IResult4Props {
  onClickAtDownloadReportButton: () => void;
}

const Result4: React.FC<IResult4Props> = () => {
  const {calculations} = useStoreon<IState, IEvents>('calculations');
  const resultValues = calculations[1].resultValues as IResultValues;
  const isTablet = useMediaQuery({maxWidth: deviceSizes.desktop - 1});

  return (
    <Row gutter={[0, isTablet ? 25 : 50]}>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>{'Характеристики объекта'}</Typography.Title>
          </Col>
          <Col span={24}>
            <ResultUserValues />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Typography.Title level={3}>
              {'Результат расчёта влагонакопления с учётом неоднородности экрана'}
            </Typography.Title>
          </Col>

          <Col span={24}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Typography.Title level={4}>
                  {'Определение влажностного режима стены в зоне стыковых швов экранов'}
                </Typography.Title>

                <Typography.Text type={'secondary'}>
                  {`на основе «Рекомендаций по проектированию и применению для строительства и реконструкции
                    зданий в г. Москва фасадной системы с вентилируемым воздушным зазором «Краспан ВСт о(н)».
                    2003`}
                </Typography.Text>
              </Col>

              <Col span={24}>
                <Typography.Paragraph>
                  {`Определим условное приведенное сопротивления R_пр_п по формуле (7.23) и коэффициент 
                    паропроницаемости μ по формуле (7.24) с учетом швов-зазоров между панелями экранами`}
                </Typography.Paragraph>
                <ResultSection gutter={[20, 20]}>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Определяется сопротивление R′'}
                      <sub>{'п'}</sub>
                      {' паропроницаемости швов-зазоров:'}
                    </FormulaNameStyled>
                    <Formula name={'R_prime_p'} value={resultValues.R_prime_p} />
                  </Col>

                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Сопротивление R'}
                      <sub>{'п'}</sub>
                      {' паропроницаемости плит экрана по его глади (без учёта швов-зазоров):'}
                    </FormulaNameStyled>
                    <Formula name={'R_p'} value={resultValues.R_p} />
                  </Col>

                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Определяется сопротивление R'}
                      <sub>{'п'}</sub>
                      <sup>{'пр'}</sup>
                      {'′ паропроницаемости экрана с учётом швов-зазоров:'}
                    </FormulaNameStyled>
                    <Formula name={'R_pr_p_prime'} value={resultValues.R_pr_p_prime} />
                  </Col>

                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Определяется условный приведенный коэффициент паропроницанию экрана с учетом зазоров:'}
                    </FormulaNameStyled>
                    <Formula name={'Mu'} value={resultValues.Mu} />
                  </Col>
                </ResultSection>
              </Col>

              <Col span={24}>
                <Typography.Paragraph>
                  {`Заново определим нормируемое сопротивление паропроницанию с учётом полученных 
                    данных, в соответствии с СП 131.13330-2012`}
                </Typography.Paragraph>
                <ResultSection gutter={[20, 20]}>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {`Определяется сопротивление паропроницанию части ограждающей конструкции, расположенной
                        между наружной поверхностью ограждающей конструкции и плоскостью максимального
                        увлажнения в зоне стыковых швов экранов`}
                    </FormulaNameStyled>
                    <Formula name={'R_prime_op'} value={resultValues.R_prime_op} />
                  </Col>

                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Переопределим нормируемое сопротивление паропроницанию R'}
                      <sub>{'n1'}</sub>
                      <sup>{'тр'}</sup>
                      {'′, м²·ч·Па/мг из условия недопустимости накопления влаги за год эксплуатации:'}
                    </FormulaNameStyled>
                    <Formula name={'R_tr_n1_prime'} value={resultValues.R_tr_n1_prime} />
                  </Col>

                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Переопределим нормируемое сопротивление паропроницанию R'}
                      <sub>{'n2'}</sub>
                      <sup>{'тр'}</sup>
                      {`′, м²·ч·Па/мг из условия ограничения влаги в наружной стене за период 
                        с отрицательными температурами наружного воздуха:`}
                    </FormulaNameStyled>
                    <Formula name={'R_tr_n2_prime'} value={resultValues.R_tr_n2_prime} />
                  </Col>

                  <Col span={24}>
                    <FormulaNameStyled>{'Заново рассчитываем коэффициент:'}</FormulaNameStyled>
                    <Formula name={'eta_prime'} value={resultValues.eta_prime} />
                  </Col>

                  <Col span={24}>
                    <Row gutter={[20, 20]}>
                      <Col span={24}>
                        <Typography.Title level={3}>{'Выводы'}</Typography.Title>
                      </Col>
                      <Col span={24}>
                        <Typography.Paragraph>
                          Произведём сравнение полученного значения сопротивления пароприницанию ограждающей конструкции
                          (в пределах от внутренней поверхности до плоскости максимального увлажнения)
                        </Typography.Paragraph>
                        <ResultSection>
                          <Col span={24}>
                            <Space direction={'horizontal'} size={15}>
                              <div>
                                <FormulaElement type={'var'} value={'R′'} sub={'о,п'} fontStyle={'italic'} />
                                &nbsp;
                                <FormulaElement type={'symb'} value={resultValues.conclusion6!.symbol} />
                                &nbsp;
                                <FormulaElement
                                  type={'symb'}
                                  value={'R'}
                                  sub={'n'}
                                  sup={'тр'}
                                  postfix={'′'}
                                  fontStyle={'italic'}
                                />
                              </div>
                              <div>
                                <FormulaElement type={'symb'} value={'('} />
                                <FormulaElement type={'var'} value={`${resultValues.R_prime_op} м²·ч·Па/мг`} />
                                &nbsp;
                                <FormulaElement type={'symb'} value={resultValues.conclusion6!.symbol} />
                                &nbsp;
                                <FormulaElement
                                  type={'symb'}
                                  value={`${Math.max(
                                    resultValues.R_tr_n1_prime!,
                                    resultValues.R_tr_n2_prime!,
                                  )} м²·ч·Па/мг`}
                                />
                                <FormulaElement type={'symb'} value={')'} />
                              </div>
                            </Space>
                          </Col>
                        </ResultSection>
                        <Typography.Paragraph>
                          <StatusMessage
                            type={resultValues.conclusion6!.type}
                            description={resultValues.conclusion6!.description}
                          />
                        </Typography.Paragraph>
                      </Col>
                    </Row>
                  </Col>
                </ResultSection>
                <Typography.Text type={'secondary'}>
                  {`Условие недействительно для зон, находящихся в отдалении от горизонтальных швов. В зоне
                    глухих экранов, при отсутствии движения воздуха, сохраняются значения нормируемого
                    сопротивления, установленные на этапах 2 и 3 расчёта влагонакопления. Таким образом,
                    условия паропроницаемости для данных зон не могут быть выполнены без учёта движения
                    воздуха внутри прослойки. Модель движения влаги за счёт диффузии до внутренней
                    поверхности глухих панелей  экрана(без учёта движения воздуха) в годовом цикле будет
                    рассмотрена далее`}
                </Typography.Text>
              </Col>
            </Row>
          </Col>

          <Col span={24}></Col>

          <Col span={0}>
            <AntdCollapse style={{marginBottom: '20px'}}>
              <AntdCollapse.Panel header="Расчет влажностного режима стены по годовому балансу влаги" key="1">
                <Row gutter={[20, 20]}>
                  <Col span={24}>
                    <Typography.Title level={4}>
                      {'Расчет влажностного режима стены по годовому балансу влаги'}
                    </Typography.Title>
                    <Typography.Text type={'secondary'}>
                      {`на основе методик, описанных в «Рекомендаций по проектированию и применению для строительства 
                        и реконструкции зданий в г. Москва фасадной системы с вентилируемым воздушным зазором 
                        «Краспан ВСт о(н)». 2003  (раздел 7.6.3).`}
                    </Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Typography.Paragraph>
                      {'Исходные данные для расчета влажностного режима стены в годовом цикле в табличной форме'}
                    </Typography.Paragraph>
                    <Row gutter={[20, 20]}>
                      <Col span={24}>
                        <ResultHumidityRegimeStyled />
                      </Col>
                    </Row>
                    <Col span={24}>
                      <Typography.Text type={'secondary'}>{'* Условные'}</Typography.Text>
                    </Col>
                    <Col span={24}>
                      <Typography.Text type={'secondary'}>
                        {
                          '** Коэффициент паропроницаемости/сопротивление паропроницанию, с учетом открытых швов-панелей'
                        }
                      </Typography.Text>
                    </Col>
                  </Col>
                  <Col span={24}>
                    <Typography.Paragraph>
                      {`Определим сопротивления паропроницанию наружной стены, параметры внутреннего и наружного воздуха, упругости
                    водяного пара в годовом цикле по месяцам:`}
                    </Typography.Paragraph>
                    <ResultSection gutter={[20, 20]}>
                      <Col span={24}>
                        <FormulaNameStyled>
                          {'t'}
                          <sub>{'н'}</sub>
                          {' — среднемесячная температура по таблицам СП 131.13330.2018 для каждого месяца;'}
                        </FormulaNameStyled>
                      </Col>
                      <Col span={24}>
                        <FormulaNameStyled>
                          {'t'}
                          <sub>{'в'}</sub>
                          {
                            ' — температура внутри помещения по заданным параметрам помещения (в период без отопления - по СП 131.13330.2018);'
                          }
                        </FormulaNameStyled>
                      </Col>
                      <Col span={24}>
                        <Space direction={'horizontal'} size={20}>
                          <FormulaNameStyled>
                            {'Δt'}
                            {' — температурный перепад по формуле:'}
                          </FormulaNameStyled>
                          <Formula name={'delta_t'} value={' '} />
                        </Space>
                      </Col>
                      <Col span={24}>
                        <FormulaNameStyled>
                          {'e'}
                          <sub>{'н'}</sub>
                          {
                            ' — упругость водяного пара наружного воздуха по таблицам СП 131.13330.2018 для каждого месяца;'
                          }
                        </FormulaNameStyled>
                      </Col>
                      <Col span={24}>
                        <Space direction={'horizontal'} size={20}>
                          <FormulaNameStyled>
                            {'e'}
                            <sub>{'в'}</sub>
                            <sup>{'55'}</sup>
                            {' — упругость водяного пара за каждый месяц по формуле:'}
                          </FormulaNameStyled>
                          <Formula name={'e_v'} value={' '} />
                          <FormulaNameStyled>{' (в период без отопления - по СП 131.13330.2018);'}</FormulaNameStyled>
                        </Space>
                      </Col>
                      <Col span={24}>
                        <Space direction={'horizontal'} size={20}>
                          <FormulaNameStyled>
                            {'Δe'}
                            {' — разность упругости водяного пара снаружи и внутри помещения по формуле:'}
                          </FormulaNameStyled>
                          <Formula name={'delta_e'} value={' '} />
                        </Space>
                      </Col>
                    </ResultSection>
                    <Typography.Text type={'secondary'}>
                      {'Полученные результаты занесём в таблицу распределения по месяцам'}
                    </Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Typography.Paragraph>
                      {'Рассчитаем движение влаги через рассматриваемое сечение по формулам (7.4) и (7.5)'}
                    </Typography.Paragraph>
                    <ResultSection gutter={[20, 20]}>
                      <Col span={24}>
                        <FormulaNameStyled>
                          {
                            'Обозначим рассматриваемое сечение, это будет внутренняя поверхность экрана вентилируемого фасада'
                          }
                        </FormulaNameStyled>
                      </Col>
                      <Col span={24}>
                        <FormulaNameStyled>
                          {'Определим сумму сопротивлений слоёв паропроницанию '}
                          {'ΣR'}
                          <sub>{'ni'}</sub>
                          <sup>{'сеч'}</sup>
                          {' от внутренней поверхности до рассматриваемого сечения, м²·ч·Па/мг:'}
                        </FormulaNameStyled>
                        <Formula name={'sum_R_ni_section'} value={resultValues.sum_R_ni_section} />
                      </Col>
                      <Col span={24}>
                        <FormulaNameStyled>
                          {`Определим расчётную упругость водяного пара в рассматриваемом сечении для каждого месяца,
                            Па, по формуле:`}
                        </FormulaNameStyled>
                        <Formula name={'e_tau'} />
                      </Col>
                      <Col span={24}>
                        <FormulaNameStyled>
                          {'Определим сумму термического сопротивлений слоёв '}
                          {'ΣR'}
                          <sub>{'i'}</sub>
                          <sup>{'сеч'}</sup>
                          {' от внутренней поверхности до рассматриваемого сечения, м²·°С/Вт:'}
                        </FormulaNameStyled>
                        <Formula name={'sum_R_i_section'} value={resultValues.sum_R_i_section} />
                      </Col>
                      <Col span={24}>
                        <FormulaNameStyled>
                          {'Определяем расчётную температуру в рассматриваемом сечении для каждого месяца, °С'}
                        </FormulaNameStyled>
                        <Formula name={'tau_p'} />
                      </Col>
                      <Col span={24}>
                        <FormulaNameStyled>
                          {'Определяем  максимальную упругость насыщенного водяного пара, Па'}
                        </FormulaNameStyled>
                        <Formula name={'E_tau'} />
                      </Col>
                      <Col span={24}>
                        <FormulaNameStyled>
                          {
                            'Определим баланс влаги в рассматриваемом сечении для каждого месяца по формуле (7.4), г/м²·час:'
                          }
                        </FormulaNameStyled>
                        <Space direction={'horizontal'} size={20}>
                          <Formula name={'Q_v_sl'} />
                          <Formula name={'Q_n_sl'} />
                        </Space>
                      </Col>
                    </ResultSection>
                  </Col>
                  <Col span={24}>
                    <Typography.Paragraph>
                      {
                        'Количественный расчёт распределения влаги в стене в годовом цикле проводится для каждого месяца '
                      }
                    </Typography.Paragraph>
                    <Row gutter={[20, 20]}>
                      <Col span={24}>
                        {/* Таблица: расчёт распределения влаги в стене в годовом цикле */}
                        <ResultHumidityAnnualCycleStyled />
                      </Col>
                    </Row>
                    <ResultSection gutter={[20, 20]}>
                      <Col span={24}>
                        <Space direction={'horizontal'} size={20}>
                          <Formula name={'delta_Q'} />
                          <FormulaNameStyled>{' — баланс влаги на текущий месяц'}</FormulaNameStyled>
                        </Space>
                      </Col>
                      <Col span={24}>
                        <Space direction={'horizontal'} size={20}>
                          <Formula name={'sum_delta_Q'} />
                          <FormulaNameStyled>
                            {' — баланс полного цикла распределения влаги по итогу текущего месяца'}
                          </FormulaNameStyled>
                        </Space>
                      </Col>
                      <Col span={24}>
                        <FormulaNameStyled>{'М-1 — предыдущего месяц. М — текущего месяца'}</FormulaNameStyled>
                      </Col>
                    </ResultSection>
                    <Col span={24}>
                      <FormulaNameStyled>{`Если в годовом цикле окажется увеличение Σ∆Q накопления влаги в конструкции, то ее надо корректировать, добиваясь исключения
                    влагонакопления в годовом цикле. Если, проведенных упомянутых расчетов для определения применимости конструкции, недостаточно,
                    то расчет продолжают в следующей последовательности.`}</FormulaNameStyled>
                    </Col>
                  </Col>
                </Row>
              </AntdCollapse.Panel>
            </AntdCollapse>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const ResultHumidityRegimeStyled = styled(ResultHumidityRegime)`
  @media ${$tablet} {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const ResultHumidityAnnualCycleStyled = styled(ResultHumidityAnnualCycle)`
  @media ${$tablet} {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const ResultSection = styled(Row)`
  display: flex;
  align-items: center;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 15px 30px;
  @media ${$phone} {
    padding: 15px 15px;
  }
`;

const FormulaNameStyled = styled(Typography.Paragraph)`
  margin-bottom: 5px !important;
  color: ${colors.grey[1]};
`;

export default Result4;
