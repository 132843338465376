import React, {useContext} from 'react';
import styled from 'styled-components';
import {LocaleContext} from '~src/context';

interface IResultLiteratureProps {}

const ResultLiterature: React.FC<IResultLiteratureProps> = () => {
  const {pages} = useContext(LocaleContext);
  return (
    <ListStyled>
      {pages.calculation1.result.literature.map((item, index) => (
        <ItemStyled key={index}>{item}</ItemStyled>
      ))}
    </ListStyled>
  );
};

const ListStyled = styled.ul``;

const ItemStyled = styled.li``;

export default ResultLiterature;
