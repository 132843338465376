import React, {useContext, Fragment, useState, useEffect} from 'react';
import styled from 'styled-components';
import {Row, Col, Select, InputNumber, Radio, Tabs, Collapse, Switch, Form} from 'antd';

import Doors from './Doors/index';
import Jumpers from './Jumpers/index';

import {Control, Space, InputSelect} from '~src/components';
import {LocaleContext} from '~src/context';

import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {IPartition} from '~src/services/Calculation2Service';

import AdditionalMaterialPreview from '~src/components/molecules/AdditionalMaterialPreview';

const {Panel} = Collapse;
const {TabPane} = Tabs;

interface IPartitionControlsProps {
  partitionIndex: number;
}

const PartitionsControls: React.FC<IPartitionControlsProps> = ({partitionIndex}) => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {materials} = useStoreon<IState, IEvents>('materials');
  const {userValues} = calculations[2];
  const {calculation2} = materials;
  const {enabledFlags, enabledSubFlags} = calculations[2].userValues;
  const [brickMaterials, setBrickMaterials] = useState([{label: '', value: '', voidness: false, inputType: 0}]);
  const [chosenOption, setChosenOption] = useState(null);
  const {
    pages: {
      calculation2: {brickAndBlocksSections},
    },
  } = useContext(LocaleContext);
  const setEnabledSubFlags = (key: string | string[]) => {
    dispatch('calculations/2/set-values', [{name: 'enabledSubFlags', value: key}]);
  };

  const collapseToggle = (panelKey: string) => {
    const isSwitchChecked = enabledFlags.includes(panelKey) || enabledSubFlags.includes(panelKey);
    return <Switch checked={isSwitchChecked} />;
  };

  const handleInputChange = (e: any) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'partitions',
      values: [
        {
          name: e.target.name,
          value: e.target.value,
          index: partitionIndex,
        },
      ],
    });
  };

  const getHandleInputChange = (name: keyof IPartition | string) => (value: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'partitions',
      values: [
        {
          name,
          value,
          index: partitionIndex,
        },
      ],
    });
  };

  const handleBrickTypeChange = (e: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'partitions',
      values: [
        {
          name: 'partitionBrickType',
          value: e,
          index: partitionIndex,
        },
      ],
    });
    dispatch('materials/get-materials-by-id', {
      filterKey: 'brickType',
      filter_value: e,
      calculationType: 2,
    });
    setChosenOption(null);
  };

  const handleDefaultSizeChange = (e: string) => {
    let selectedMaterial = calculation2.brickType.materials.find(item => {
      return item.id === e;
    });
    selectedMaterial && setChosenOption(selectedMaterial.id);
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'partitions',
        values: [
          {
            name: 'partitionBrickLength',
            value: selectedMaterial.brickLength,
            index: partitionIndex,
          },
        ],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'partitions',
        values: [
          {
            name: 'partitionBrickWidth',
            value: selectedMaterial.brickWidth,
            index: partitionIndex,
          },
        ],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'partitions',
        values: [
          {
            name: 'partitionBrickHeight',
            value: selectedMaterial.brickHeight,
            index: partitionIndex,
          },
        ],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'partitions',
        values: [
          {
            name: 'partitionBrickWeight',
            value: selectedMaterial.brickWeight,
            index: partitionIndex,
          },
        ],
      });
  };

  const handleTrimMarginOptionChange = (e: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'partitions',
      values: [
        {
          name: 'partitionTrimMargin',
          value: e,
          index: partitionIndex,
        },
      ],
    });
  };

  const handleMasonryOptionChange = (e: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'partitions',
      values: [
        {
          name: 'partitionMasonryOption',
          value: e,
          index: partitionIndex,
        },
      ],
    });
  };

  const handleSeamThicknessChange = (e: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'partitions',
      values: [
        {
          name: 'partitionSeamThickness',
          value: e,
          index: partitionIndex,
        },
      ],
    });
  };

  const handleMasonryNetChange = (e: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'partitions',
      values: [
        {
          name: 'partitionMasonryNet',
          value: e,
          index: partitionIndex,
        },
      ],
    });
  };

  useEffect(() => {
    let materialLabels = [];
    materialLabels = calculation2.brickType.materials.map(item => {
      let materialLabel = '';
      materialLabel =
        item.executionType +
        '- ' +
        item.brickLength.toString() +
        'x' +
        item.brickWidth.toString() +
        'x' +
        item.brickHeight.toString() +
        'мм, ' +
        item.brickWeight.toString() +
        'кг';
      return {
        label: materialLabel,
        value: item.id,
        voidness: item.voidness,
        inputType: item.inputType,
      };
    });
    setBrickMaterials(materialLabels);

    let normalizedExecutionType = userValues.partitions[partitionIndex].partitionExecutionType === 0 ? false : true;
    if (brickMaterials.some(item => item.inputType === 0)) {
      let filteredMaterialLabels = materialLabels.filter(item => {
        return item.voidness === normalizedExecutionType;
      });
      setBrickMaterials(filteredMaterialLabels);
    } else {
      setBrickMaterials(materialLabels);
    }

    if (brickMaterials.some(item => item.inputType === 1)) {
      handleMasonryOptionChange(0.5);
    }
  }, [calculation2, userValues.partitions[0].partitionExecutionType]);

  const determineExecutionTypeRender = () => {
    if (brickMaterials.some(item => item.inputType === 0)) {
      return (
        <Control label={brickAndBlocksSections.section1.executionType.label}>
          <Radio.Group
            value={userValues.partitions[partitionIndex].partitionExecutionType}
            onChange={handleInputChange}
            name="partitionExecutionType"
          >
            {brickAndBlocksSections.section1.executionType.values.map(item => (
              <Radio key={item.value} value={item.value}>
                {item.label}
              </Radio>
            ))}
          </Radio.Group>
        </Control>
      );
    }
  };

  return (
    <Fragment>
      <Row gutter={[20, 20]}>
        <Col md={10} sm={12} xs={24}>
          <Space direction="vertical" size={30}>
            <Control label={brickAndBlocksSections.section5.partitions.brickType.label}>
              <FormItemStyled
                validateStatus={
                  userValues.isInvalid && userValues.partitions[0].partitionBrickType === -1 ? 'error' : ''
                }
                help={
                  userValues.isInvalid && userValues.partitions[0].partitionBrickType === -1 ? 'Обязательное поле' : ''
                }
              >
                <Select
                  placeholder={brickAndBlocksSections.section5.partitions.brickType.placeholder}
                  size={'large'}
                  options={calculation2.brickType.options}
                  onChange={handleBrickTypeChange}
                />
              </FormItemStyled>
            </Control>
            {determineExecutionTypeRender()}
            <Tabs animated={false} defaultActiveKey="1">
              <TabPaneStyled tab={brickAndBlocksSections.section2.facadeSize.defaultSize.label} key="1">
                <Row gutter={[20, 20]}>
                  <Col span={24}>
                    <Control label={brickAndBlocksSections.section2.facadeSize.defaultSize.facadeSize.label}>
                      <FormItemStyled
                        validateStatus={
                          userValues.isInvalid &&
                          (userValues.partitions[0].partitionBrickLength === 0 ||
                            userValues.partitions[0].partitionBrickWidth === 0 ||
                            userValues.partitions[0].partitionBrickLength === 0 ||
                            userValues.partitions[0].partitionBrickHeight === 0 ||
                            userValues.partitions[0].partitionBrickWeight === 0)
                            ? 'error'
                            : ''
                        }
                        help={
                          userValues.isInvalid &&
                          (userValues.partitions[0].partitionBrickLength === 0 ||
                            userValues.partitions[0].partitionBrickWidth === 0 ||
                            userValues.partitions[0].partitionBrickLength === 0 ||
                            userValues.partitions[0].partitionBrickHeight === 0 ||
                            userValues.partitions[0].partitionBrickWeight === 0)
                            ? 'Обязательное поле'
                            : ''
                        }
                      >
                        <Select
                          size={'large'}
                          options={brickMaterials}
                          value={chosenOption}
                          placeholder={brickAndBlocksSections.section5.partitions.brickType.placeholder}
                          onChange={handleDefaultSizeChange}
                        />
                      </FormItemStyled>
                    </Control>
                  </Col>
                </Row>
              </TabPaneStyled>
              <TabPaneStyled tab={brickAndBlocksSections.section2.facadeSize.userSize.label} key="2">
                <Row gutter={[20, 20]}>
                  <Col md={12} xs={24}>
                    <Space size={20} direction={'vertical'}>
                      <Control label={brickAndBlocksSections.section2.facadeSize.userSize.facadeWidth.label}>
                        <FormItemStyled
                          validateStatus={
                            userValues.isInvalid && userValues.partitions[0].partitionBrickWidth === 0 ? 'error' : ''
                          }
                          help={
                            userValues.isInvalid && userValues.partitions[0].partitionBrickWidth === 0
                              ? 'Обязательное поле'
                              : ''
                          }
                        >
                          <InputNumberStyled
                            min={0}
                            size={'large'}
                            placeholder={brickAndBlocksSections.section2.facadeSize.userSize.facadeWidth.placeholder}
                            onChange={getHandleInputChange(
                              brickAndBlocksSections.section5.partitions.userSize.facadeWidth.name,
                            )}
                          />
                        </FormItemStyled>
                      </Control>
                      <Control label={brickAndBlocksSections.section2.facadeSize.userSize.facadeHeight.label}>
                        <FormItemStyled
                          validateStatus={
                            userValues.isInvalid && userValues.partitions[0].partitionBrickLength === 0 ? 'error' : ''
                          }
                          help={
                            userValues.isInvalid && userValues.partitions[0].partitionBrickLength === 0
                              ? 'Обязательное поле'
                              : ''
                          }
                        >
                          <InputNumberStyled
                            min={0}
                            size={'large'}
                            placeholder={brickAndBlocksSections.section2.facadeSize.userSize.facadeHeight.placeholder}
                            onChange={getHandleInputChange(
                              brickAndBlocksSections.section5.partitions.userSize.facadeLength.name,
                            )}
                          />
                        </FormItemStyled>
                      </Control>
                    </Space>
                  </Col>
                  <Col md={12} xs={24}>
                    <Space size={20} direction={'vertical'}>
                      <Control label={brickAndBlocksSections.section2.facadeSize.userSize.facadeLength.label}>
                        <FormItemStyled
                          validateStatus={
                            userValues.isInvalid && userValues.partitions[0].partitionBrickLength === 0 ? 'error' : ''
                          }
                          help={
                            userValues.isInvalid && userValues.partitions[0].partitionBrickLength === 0
                              ? 'Обязательное поле'
                              : ''
                          }
                        >
                          <InputNumberStyled
                            min={0}
                            size={'large'}
                            placeholder={brickAndBlocksSections.section2.facadeSize.userSize.facadeLength.placeholder}
                            onChange={getHandleInputChange(
                              brickAndBlocksSections.section5.partitions.userSize.facadeLength.name,
                            )}
                          />
                        </FormItemStyled>
                      </Control>
                      <Control label={brickAndBlocksSections.section2.facadeSize.userSize.facadeWeight.label}>
                        <FormItemStyled
                          validateStatus={
                            userValues.isInvalid && userValues.partitions[0].partitionBrickWeight === 0 ? 'error' : ''
                          }
                          help={
                            userValues.isInvalid && userValues.partitions[0].partitionBrickWeight === 0
                              ? 'Обязательное поле'
                              : ''
                          }
                        >
                          <InputNumberStyled
                            min={0}
                            size={'large'}
                            placeholder={brickAndBlocksSections.section2.facadeSize.userSize.facadeWeight.placeholder}
                            onChange={getHandleInputChange(
                              brickAndBlocksSections.section5.partitions.userSize.facadeWeight.name,
                            )}
                          />
                        </FormItemStyled>
                      </Control>
                    </Space>
                  </Col>
                </Row>
              </TabPaneStyled>
            </Tabs>
            <Control label={brickAndBlocksSections.section5.partitions.trimMargin.label}>
              <FormItemStyled
                validateStatus={
                  userValues.isInvalid && userValues.partitions[0].partitionTrimMargin === 0 ? 'error' : ''
                }
                help={
                  userValues.isInvalid && userValues.partitions[0].partitionTrimMargin === 0 ? 'Обязательное поле' : ''
                }
              >
                <InputSelect
                  inputProps={{
                    size: 'large',
                    placeholder: brickAndBlocksSections.section5.partitions.trimMargin.placeholder,
                    inputButton: 'Выбрать значение',
                    min: 0,
                    max: 100,
                  }}
                  selectProps={{
                    size: 'large',
                    placeholder: brickAndBlocksSections.section5.partitions.trimMargin.placeholder,
                    options: brickAndBlocksSections.section5.partitions.trimMargin.values,
                    selectButton: 'Ввести значение',
                  }}
                  onInputChange={getHandleInputChange(brickAndBlocksSections.section5.partitions.trimMargin.name)}
                  onSelectChange={handleTrimMarginOptionChange}
                />
              </FormItemStyled>
            </Control>
            <Row gutter={[20, 15]} justify="space-between">
              {brickMaterials.some(item => item.inputType === 1) ? (
                ''
              ) : (
                <Col md={12} sm={24} xs={24}>
                  <Control label={brickAndBlocksSections.section1.masonryOption.label}>
                    <FormItemStyled
                      validateStatus={
                        userValues.isInvalid && userValues.partitions[0].partitionMasonryOption === 0 ? 'error' : ''
                      }
                      help={
                        userValues.isInvalid && userValues.partitions[0].partitionMasonryOption === 0
                          ? 'Обязательное поле'
                          : ''
                      }
                    >
                      <Select
                        size={'large'}
                        options={brickAndBlocksSections.section1.masonryOption.values}
                        placeholder={brickAndBlocksSections.section1.masonryOption.placeholder}
                        onChange={handleMasonryOptionChange}
                      />
                    </FormItemStyled>
                  </Control>
                </Col>
              )}
              <Col md={brickMaterials.some(item => item.inputType === 1) ? 24 : 12} sm={24} xs={24}>
                <Control label={brickAndBlocksSections.section1.seamThickness.label}>
                  <FormItemStyled
                    validateStatus={
                      userValues.isInvalid && userValues.partitions[0].partitionSeamThickness === 0 ? 'error' : ''
                    }
                    help={
                      userValues.isInvalid && userValues.partitions[0].partitionSeamThickness === 0
                        ? 'Обязательное поле'
                        : ''
                    }
                  >
                    {brickMaterials.some(item => item.inputType === 1) ? (
                      <Select
                        size={'large'}
                        // options={brickAndBlocksSections.section1.seamThickness.glueValues}
                        placeholder={brickAndBlocksSections.section1.seamThickness.placeholder}
                        onChange={handleSeamThicknessChange}
                      >
                        <Select.OptGroup label="Клей">
                          {brickAndBlocksSections.section1.seamThickness.glueValues.map((item, index) => {
                            return (
                              <Select.Option value={item.value} key={index}>
                                {item.label}
                              </Select.Option>
                            );
                          })}
                        </Select.OptGroup>
                        <Select.OptGroup label="Ц.п. раствор">
                          {brickAndBlocksSections.section1.seamThickness.plasterValues.map((item, index) => {
                            return (
                              <Select.Option value={item.value} key={index}>
                                {item.label}
                              </Select.Option>
                            );
                          })}
                        </Select.OptGroup>
                      </Select>
                    ) : (
                      <Select
                        size={'large'}
                        options={brickAndBlocksSections.section1.seamThickness.plasterValues}
                        placeholder={brickAndBlocksSections.section1.seamThickness.placeholder}
                        onChange={handleSeamThicknessChange}
                      />
                    )}
                  </FormItemStyled>
                </Control>
              </Col>
            </Row>
            <Control label={brickAndBlocksSections.section5.partitions.masonryNet.label}>
              <FormItemStyled
                validateStatus={
                  userValues.isInvalid && userValues.partitions[0].partitionMasonryNet === -1 ? 'error' : ''
                }
                help={
                  userValues.isInvalid && userValues.partitions[0].partitionMasonryNet === -1 ? 'Обязательное поле' : ''
                }
              >
                <Select
                  placeholder={brickAndBlocksSections.section5.partitions.masonryNet.placeholder}
                  size={'large'}
                  options={brickAndBlocksSections.section5.partitions.masonryNet.values}
                  onChange={handleMasonryNetChange}
                />
              </FormItemStyled>
            </Control>
            <Row gutter={[20, 20]} justify="space-between">
              <Col md={12} xs={24}>
                <Control label={brickAndBlocksSections.section5.partitions.partitionWallsLength.label}>
                  <FormItemStyled
                    validateStatus={
                      userValues.isInvalid && userValues.partitions[0].partitionWallsLength === 0 ? 'error' : ''
                    }
                    help={
                      userValues.isInvalid && userValues.partitions[0].partitionWallsLength === 0
                        ? 'Обязательное поле'
                        : ''
                    }
                  >
                    <InputNumberStyled
                      min={0}
                      size={'large'}
                      placeholder={brickAndBlocksSections.section5.partitions.partitionWallsLength.placeholder}
                      onChange={getHandleInputChange(
                        brickAndBlocksSections.section5.partitions.partitionWallsLength.name,
                      )}
                    />
                  </FormItemStyled>
                </Control>
              </Col>
              <Col md={12} xs={24}>
                <Control label={brickAndBlocksSections.section5.partitions.partitionWallsHeight.label}>
                  <FormItemStyled
                    validateStatus={
                      userValues.isInvalid && userValues.partitions[0].partitionWallsHeight === 0 ? 'error' : ''
                    }
                    help={
                      userValues.isInvalid && userValues.partitions[0].partitionWallsHeight === 0
                        ? 'Обязательное поле'
                        : ''
                    }
                  >
                    <InputNumberStyled
                      min={0}
                      size={'large'}
                      placeholder={brickAndBlocksSections.section5.partitions.partitionWallsHeight.placeholder}
                      onChange={getHandleInputChange(
                        brickAndBlocksSections.section5.partitions.partitionWallsHeight.name,
                      )}
                    />
                  </FormItemStyled>
                </Control>
              </Col>
            </Row>
            <CollapseStyled bordered={false} expandIconPosition="right" onChange={setEnabledSubFlags}>
              <Panel
                header={brickAndBlocksSections.section5.partitions.doors.label}
                key="partitionDoors"
                className="collapse-custom-panel"
                extra={collapseToggle('partitionDoors')}
                showArrow={false}
              >
                <Doors />
              </Panel>
              <Panel
                header={brickAndBlocksSections.section5.partitions.jumpers.label}
                key="partitionJumpers"
                className="collapse-custom-panel"
                extra={collapseToggle('partitionJumpers')}
                showArrow={false}
              >
                <Jumpers />
              </Panel>
            </CollapseStyled>
          </Space>
        </Col>
        <ColStyled md={6} sm={24} xs={24}>
          <AdditionalMaterialPreview sectionToRender="partitions" />
        </ColStyled>
      </Row>
    </Fragment>
  );
};

const TabPaneStyled = styled(TabPane)`
  width: 100%;
`;

const CollapseStyled = styled(Collapse)`
  background: #fafafa;
  border: none;
  padding: 0 !important;

  .ant-collapse-header {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;
    border-top: 1px solid #e8e8e8;
    margin-top: 0 - 1px;
    border-bottom: 1px solid #e8e8e8;
    padding: 16px 25px !important;
  }

  .ant-collapse-content-box {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff !important;
    padding: 20px 25px !important;
  }

  .collapse-custom-panel {
    border: none !important;
  }
`;

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`;

const ColStyled = styled(Col)`
  @media screen and (min-width: 1024px) {
    margin-left: 80px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    margin-left: 60px;
  }
  @media screen and (min-width: 321px) and (max-width: 767px) {
    margin-left: 0px;
  }
`;

const FormItemStyled = styled(Form.Item)`
  margin-bottom: 0 !important;
`;

export default PartitionsControls;
