import React from 'react';
import styled from 'styled-components';
import {Layout} from 'antd';
const {Content} = Layout;

import {Header} from './Header';
import {Footer} from './Footer';
import {Breadcrumbs} from '~src/components';
import {$tablet} from '~src/theme/media';

interface IPageProps {
  breadcrumbs?: string[];
  breadcrumbsSource?: string[];
}

const Page: React.FC<IPageProps> = ({children, breadcrumbs, breadcrumbsSource}) => {
  return (
    <LayoutStyled>
      <Header />
      <MainStyled>
        <SectionContentStyled>
          {breadcrumbs && breadcrumbsSource && (
            <BreadcrumbsStyled>
              <Breadcrumbs value={breadcrumbs} source={breadcrumbsSource} />
            </BreadcrumbsStyled>
          )}
          <ContentStyled>{children}</ContentStyled>
        </SectionContentStyled>
      </MainStyled>
      <FooterStyled />
    </LayoutStyled>
  );
};

const LayoutStyled = styled.div`
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
const MainStyled = styled.div`
  flex-grow: 1;
  @media ${$tablet} {
    padding: 0 10px;
  }
`;
const FooterStyled = styled(Footer)`
  flex-shrink: 0;
`;
const BreadcrumbsStyled = styled.section`
  padding: 40px 0;
`;
const ContentStyled = styled(Content)``;
const SectionContentStyled = styled.div`
  max-width: 1120px;
  margin: 0 auto;
`;

export default Page;
