import React, {useContext} from 'react';
import {LocaleContext} from '~src/context';
import {Table} from 'antd';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {IResultValues} from '~src/services/Calculation1Service';
import styled from 'styled-components';
import {$tablet} from '~src/theme/media';
import {createDataSourceResult2} from '~src/utils/calculations';

interface IResultMaterialsTableProps {
  className?: string;
}

const ResultMaterialsTable: React.FC<IResultMaterialsTableProps> = ({className}) => {
  const {calculations} = useStoreon<IState, IEvents>('calculations');
  const dataSource = createDataSourceResult2(calculations[1].userValues, calculations[1].resultValues as IResultValues);
  const {
    pages: {
      calculation1: {result},
    },
  } = useContext(LocaleContext);
  return (
    <TableStyled
      className={className}
      dataSource={dataSource}
      columns={result.result2TableGeneration.heatLossParams.columns}
      pagination={false}
      rowKey={record => {
        // @ts-ignore
        return record.layerId;
      }}
    />
  );
};

const TableStyled = styled(Table)`
  @media ${$tablet} {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export default ResultMaterialsTable;
