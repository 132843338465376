import React, {useContext} from 'react';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import {Modal, Button, Space} from 'antd';
import {LocaleContext} from '~src/context';
import {Menu} from '~src/components';

interface Props {
  visible: boolean;
  onCancel: () => void;
}

const MenuModal: React.FC<Props> = ({visible, onCancel}) => {
  const {dispatch} = useStoreon<IState, IEvents>('modals');

  const {
    components: {menu},
  } = useContext(LocaleContext);

  const handleCancel = () => {
    onCancel();
  };

  const showFeedbackModal = () => {
    dispatch('modals/show-modal', {key: 'feedbackModal', value: true});
  };

  return (
    <Modal
      bodyStyle={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        height: '100%',
      }}
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      title={'Меню'}
    >
      <Space size={20} direction={'vertical'}>
        <MenuStyled items={menu.app} mode={'vertical'} onClick={handleCancel} />
        <ButtonStyled type="primary" href={'#feedback-modal'} onClick={showFeedbackModal}>
          Обратная связь
        </ButtonStyled>
      </Space>
    </Modal>
  );
};

const MenuStyled = styled(Menu)`
  border: none;
`;

const ButtonStyled = styled(Button)`
  margin-left: 16px;
`;

export default MenuModal;
