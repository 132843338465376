import React, {Fragment, useContext, useState} from 'react';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import {Typography, Row, Col, Space, Form, Input, Button, Checkbox} from 'antd';
import {LocaleContext} from '~src/context';
import {LogoLarge} from '~src/components';
import styled from 'styled-components';
const {Title} = Typography;

interface IAuthProps {}

const IconStyled = {
  color: '#1890FF',
};

const Auth: React.FC<IAuthProps> = props => {
  const {dispatch, auth} = useStoreon<IState, IEvents>('auth');
  // eslint-disable-next-line no-console

  const {
    pages: {auth: authContext},
  } = useContext(LocaleContext);

  const [resetPassword, setResetPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberCheckbox, setRememberCheckbox] = useState(false);

  const handleResetPassword = () => {
    setResetPassword(!resetPassword);
    dispatch('auth/set-reset', true);
  };

  // TODO: разобраться с типами - в AntD по дефолту стоит any
  const handleAuthSubmit = values => {
    dispatch('auth/send-login', values);
  };

  return (
    <Fragment>
      <SpaceStyled size={100} direction="vertical">
        <Row justify="center" align="middle">
          <ColStyled span={24}>
            <Space direction="vertical" size={50}>
              <Row justify="center" align="middle">
                <LogoLarge color="#096dd9" />
              </Row>
              <FormContainerStyled>
                <TitleStyled level={4}>{authContext.login.loginTitle}</TitleStyled>
                <FormStyled name="normal_login" onFinish={handleAuthSubmit} size="large">
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: authContext.userName.usernameMessage,
                        type: 'email',
                      },
                    ]}
                  >
                    <Input
                      prefix={<authContext.icons.emailIcon style={IconStyled} />}
                      value={email}
                      placeholder={authContext.userName.usernamePlaceholder}
                    />
                  </Form.Item>
                  <Form.Item name="password" rules={[{required: true, message: authContext.password.passwordMessage}]}>
                    <Input
                      prefix={<authContext.icons.passwordIcon style={IconStyled} />}
                      type="password"
                      placeholder={authContext.password.passwordPlaceholder}
                      value={password}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox value={rememberCheckbox}>{authContext.controls.rememberCheckboxText}</Checkbox>
                    </Form.Item>

                    <LinkStyled onClick={handleResetPassword}>
                      {authContext.passwordReset.passwordResetLinkText}
                    </LinkStyled>
                  </Form.Item>

                  <Form.Item>
                    <ButtonStyled type="primary" htmlType="submit">
                      {authContext.controls.submitButtonText}
                    </ButtonStyled>
                  </Form.Item>
                </FormStyled>
              </FormContainerStyled>
            </Space>
          </ColStyled>
        </Row>
      </SpaceStyled>
    </Fragment>
  );
};

const SpaceStyled = styled(props => <Space {...props} />)`
  display: flex;
  align-content: center;
`;

const ColStyled = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
  height: 360px;
  padding: 40px 24px;
  background: #fff;
`;

const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TitleStyled = styled(Title)`
  display: flex;
  justify-content: center;
  margin-bottom: 24px !important;
`;

const ButtonStyled = styled(Button)`
  width: 100%;
`;

const LinkStyled = styled.a`
  float: right;
  padding: 0;
`;

export default Auth;
