import React, {useContext, Fragment} from 'react';
import {Row, Col, InputNumber, Form} from 'antd';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import Space from '~src/components/molecules/Space';
import {Control} from '~src/components';
import {LocaleContext} from '~src/context';
import {IGable} from '~src/services/Calculation2Service';
import styled from 'styled-components';

interface IGablesControlsProps {
  gableIndex: number;
  gableItem: IGable;
}

const TrapezeGableControls: React.FC<IGablesControlsProps> = ({gableIndex, gableItem}) => {
  const {
    pages: {
      calculation2: {brickAndBlocksSections},
    },
  } = useContext(LocaleContext);
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {userValues} = calculations[2];

  const getHandleInputChange = (name: keyof IGable | string) => (value: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'gables',
      values: [
        {
          name,
          value,
          index: gableIndex,
        },
      ],
    });
  };

  const getHeightFocus = () => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'gables',
      values: [
        {
          name: 'enabledGableInput',
          value: 'gableHeightTrapezoidal',
          index: gableIndex,
        },
      ],
    });
  };

  const getHeightBlur = () => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'gables',
      values: [
        {
          name: 'enabledGableInput',
          value: '',
          index: gableIndex,
        },
      ],
    });
  };

  const getWidthFocus = () => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'gables',
      values: [
        {
          name: 'enabledGableInput',
          value: 'gableWidthTrapezoidal',
          index: gableIndex,
        },
      ],
    });
  };

  const getWidthBlur = () => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'gables',
      values: [
        {
          name: 'enabledGableInput',
          value: '',
          index: gableIndex,
        },
      ],
    });
  };

  const getSmallerWidthFocus = () => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'gables',
      values: [
        {
          name: 'enabledGableInput',
          value: 'gableWidthSmallerTrapezoidal',
          index: gableIndex,
        },
      ],
    });
  };

  const getSmallerWidthBlur = () => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'gables',
      values: [
        {
          name: 'enabledGableInput',
          value: '',
          index: gableIndex,
        },
      ],
    });
  };

  return (
    <Fragment>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Space size={20} direction="vertical">
            <Control label={brickAndBlocksSections.section5.gables.gableHeight.label}>
              <FormItemStyled
                validateStatus={
                  userValues.isInvalid &&
                  userValues.gables[gableIndex].gableType === 1 &&
                  (userValues.gables[gableIndex].gableHeight === 0 ||
                    userValues.gables[gableIndex].gableHeight === null)
                    ? 'error'
                    : ''
                }
                help={
                  userValues.isInvalid &&
                  userValues.gables[gableIndex].gableType === 1 &&
                  (userValues.gables[gableIndex].gableHeight === 0 ||
                    userValues.gables[gableIndex].gableHeight === null)
                    ? 'Обязательное поле'
                    : ''
                }
              >
                <InputNumberStyled
                  min={0}
                  size={'large'}
                  placeholder={brickAndBlocksSections.section5.gables.gableHeight.placeholder}
                  onChange={getHandleInputChange(brickAndBlocksSections.section5.gables.gableHeight.name)}
                  value={gableItem && (gableItem.gableHeight === 0 ? undefined : gableItem.gableHeight)}
                  onFocus={getHeightFocus}
                  onBlur={getHeightBlur}
                />
              </FormItemStyled>
            </Control>
          </Space>
        </Col>
        <Col md={12} xs={24}>
          <Space size={20} direction={'vertical'}>
            <Control label={brickAndBlocksSections.section5.gables.gableSmallerWidth.label}>
              <FormItemStyled
                validateStatus={
                  userValues.isInvalid &&
                  userValues.gables[gableIndex].gableType === 1 &&
                  (userValues.gables[gableIndex].gableSmallerWidth === 0 ||
                    userValues.gables[gableIndex].gableSmallerWidth === null)
                    ? 'error'
                    : ''
                }
                help={
                  userValues.isInvalid &&
                  userValues.gables[gableIndex].gableType === 1 &&
                  (userValues.gables[gableIndex].gableSmallerWidth === 0 ||
                    userValues.gables[gableIndex].gableSmallerWidth === null)
                    ? 'Обязательное поле'
                    : ''
                }
              >
                <InputNumberStyled
                  min={0}
                  size="large"
                  placeholder={brickAndBlocksSections.section5.gables.gableSmallerWidth.placeholder}
                  onChange={getHandleInputChange(brickAndBlocksSections.section5.gables.gableSmallerWidth.name)}
                  value={gableItem && (gableItem.gableSmallerWidth === 0 ? undefined : gableItem.gableSmallerWidth)}
                  onFocus={getSmallerWidthFocus}
                  onBlur={getSmallerWidthBlur}
                />
              </FormItemStyled>
            </Control>
          </Space>
        </Col>
        <Col md={12} xs={24}>
          <Space size={20} direction={'vertical'}>
            <Control label={brickAndBlocksSections.section5.gables.gableBiggerWidth.label}>
              <FormItemStyled
                validateStatus={
                  userValues.isInvalid &&
                  userValues.gables[gableIndex].gableType === 1 &&
                  (userValues.gables[gableIndex].gableBiggerWidth === 0 ||
                    userValues.gables[gableIndex].gableBiggerWidth === null)
                    ? 'error'
                    : ''
                }
                help={
                  userValues.isInvalid &&
                  userValues.gables[gableIndex].gableType === 1 &&
                  (userValues.gables[gableIndex].gableBiggerWidth === 0 ||
                    userValues.gables[gableIndex].gableBiggerWidth === null)
                    ? 'Обязательное поле'
                    : ''
                }
              >
                <InputNumberStyled
                  min={0}
                  size="large"
                  placeholder={brickAndBlocksSections.section5.gables.gableBiggerWidth.placeholder}
                  onChange={getHandleInputChange(brickAndBlocksSections.section5.gables.gableBiggerWidth.name)}
                  value={gableItem && (gableItem.gableBiggerWidth === 0 ? undefined : gableItem.gableBiggerWidth)}
                  onFocus={getWidthFocus}
                  onBlur={getWidthBlur}
                />
              </FormItemStyled>
            </Control>
          </Space>
        </Col>
      </Row>
    </Fragment>
  );
};

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`;

const FormItemStyled = styled(Form.Item)`
  margin-bottom: 0 !important;
`;

export default TrapezeGableControls;
