import styled, {css} from 'styled-components';

export const MapStyled = styled.div<{svgWidth?: string; svgHeight?: string}>`
  position: relative;

  // ${props => css`
  //   svg {
  //     width: ${props.svgWidth} !important;
  //     height: ${props.svgHeight} !important;
  //   }
  // `}

  .jqvmap-zoomin,
  .jqvmap-zoomout {
    color: transparent;
    content: '';
    position: absolute;
    height: 26px;
    width: 26px;
    bottom: 5px;
    background-size: contain;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 20px;
    padding: 7px;
  }
  .jqvmap-zoomin {
    left: 15px;
    background-image: url('./assets/images/ico/plus.png');
  }

  .jqvmap-zoomout {
    left: 60px;
    background-image: url('./assets/images/ico/minus.png');
  }
`;
