import React, {useContext} from 'react';
import {Col, Row, Typography} from 'antd';
import styled from 'styled-components';

import {LocaleContext} from '~src/context';

import scrollIcon from '~src/assets/images/scrollIcon.png';

const MobileWarning: React.FC = () => {
  const {
    pages: {mobileWarning},
  } = useContext(LocaleContext);

  return (
    <ContentWrapperStyled justify="center" align="middle">
      <Col span={4}>
        <img src={scrollIcon} alt="" />
      </Col>
      <Col span={20} push={1}>
        <Typography.Text>{mobileWarning.title}</Typography.Text>
      </Col>
    </ContentWrapperStyled>
  );
};

const ContentWrapperStyled = styled(Row)`
  padding: 16px 20px;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 15px 0;

  @media screen and (min-width: 414px) {
    display: none;
  }
`;

export default MobileWarning;
