import React, {Fragment, useContext, useState, useEffect} from 'react';
import styled from 'styled-components';
import {LocaleContext} from '~src/context';

import {Typography, Row, Col, Collapse, Switch, Button, message} from 'antd';
import Space from '~src/components/molecules/Space';
import {Armobelts, Doors, Gables, Jumpers, Partitions, Trim, Windows} from './AdditionalSections/index';
import Section1 from './Sections/Section1/index';
import Section2 from './Sections/Section2/index';
import Section3 from './Sections/Section3/index';
import ResultsBrick from './Results';

import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import calculation2Service from '~src/services/Calculation2Service';

const {Title} = Typography;
const {Panel} = Collapse;

interface IContentProps {}

declare global {
  interface Window {
    ym: any;
  }
}

const Calculation2Brick: React.FC<IContentProps> = () => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {enabledFlags} = calculations[2].userValues;
  const {userValues} = calculations[2];
  const [showCalculation, setShowCalculation] = useState(false);
  const [resultValues, setResultValues] = useState({});
  const [isInvalid, setIsInvalid] = useState(true);
  const {
    pages: {
      calculation2: {brickAndBlocksSections},
    },
  } = useContext(LocaleContext);

  useEffect(() => {
    dispatch('materials/fetch-materials-and-options', {
      page: 1,
      count: 10000,
      orderBy: 'ASC',
      calculationType: 2,
    });
  }, []);

  const setEnabled = (key: string | string[]) => {
    dispatch('calculations/2/set-values', [{name: 'enabledFlags', value: key}]);
    if (calculations[2].userValues.windows.length === 0) {
      dispatch('calculations/2/add-item', {itemName: 'windows'});
    } else if (calculations[2].userValues.doors.length === 0) {
      dispatch('calculations/2/add-item', {itemName: 'doors'});
    } else if (calculations[2].userValues.gables.length === 0) {
      dispatch('calculations/2/add-item', {itemName: 'gables'});
    } else if (calculations[2].userValues.jumpers.length === 0) {
      dispatch('calculations/2/add-item', {itemName: 'jumpers'});
    } else if (calculations[2].userValues.armobelts.length === 0) {
      dispatch('calculations/2/add-item', {itemName: 'armobelts'});
    }
  };

  const collapseToggle = (panelKey: string) => {
    const isSwitchChecked = enabledFlags.includes(panelKey);
    return <Switch checked={isSwitchChecked} />;
  };

  const formValidate = () => {
    window.ym(
      68282989,
      'reachGoal',
      '%3cbutton+type%3d%22button%22+class%3d%22ant-btn+ant-btn-primary%22+ant-click-animating-without-extra-node%3d%22false%22%3e%3cspan%3e%d0%a0%d0%b0%d1%81%d1%81%d1%87%d0%b8%d1%82%d0%b0%d1%82%d1%8c%3c%2fspan%3e%3c%2fbutton%3e',
    );

    let formIsInvalid = false;
    for (let property in userValues.bearingWalls[0]) {
      if (userValues.bearingWalls[0][property] === -1 || userValues.bearingWalls[0][property] === '') {
        formIsInvalid = true;
      }
    }
    for (let property in userValues.facades[0]) {
      if (userValues.facades[0][property] === 0 || userValues.facades[0][property] === -1) {
        formIsInvalid = true;
      }
    }
    for (let property in userValues.walls[0]) {
      if (userValues.walls[0][property] === null || userValues.walls[0][property] === -1) {
        formIsInvalid = true;
      }
    }
    for (let property in userValues.insulation[0]) {
      if (userValues.enabledFlags.find(item => item === 'insulationPanel')) {
        if (userValues.insulation[0][property] === 0 || userValues.insulation[0][property] === '') {
          formIsInvalid = true;
        }
      }
    }
    // Секция дополнительно
    userValues.windows.map(additionalItem => {
      for (let property in additionalItem) {
        if (userValues.enabledFlags.find(item => item === 'windows')) {
          if (additionalItem[property] === 0 || additionalItem[property] === null) {
            formIsInvalid = true;
          }
        }
      }
    });

    userValues.doors.map(additionalItem => {
      for (let property in additionalItem) {
        if (userValues.enabledFlags.find(item => item === 'doors')) {
          if (additionalItem[property] === 0 || additionalItem[property] === null) {
            formIsInvalid = true;
          }
        }
      }
    });

    userValues.gables.map(additionalItem => {
      if (userValues.enabledFlags.find(item => item === 'gables')) {
        if (additionalItem.gableCount === 0) {
          formIsInvalid = true;
        }
        switch (additionalItem.gableType) {
          case 0:
            if (additionalItem.gableHeight === 0 || additionalItem.gableWidth === 0) {
              formIsInvalid = true;
            }
            break;
          case 1:
            if (
              additionalItem.gableHeight === 0 ||
              additionalItem.gableSmallerWidth === 0 ||
              additionalItem.gableBiggerWidth === 0
            ) {
              formIsInvalid = true;
            }
            break;
          case 2:
            if (
              additionalItem.gableSmallerWidth === 0 ||
              additionalItem.gableBiggerWidth === 0 ||
              additionalItem.gablePentaTriangleHeight === 0 ||
              additionalItem.gablePentaHeightBetween === 0
            ) {
              formIsInvalid = true;
            }
            break;
        }
      }
    });

    userValues.jumpers.map(additionalItem => {
      for (let property in additionalItem) {
        if (userValues.enabledFlags.find(item => item === 'jumpers')) {
          if (additionalItem[property] === 0 || additionalItem[property] === null) {
            formIsInvalid = true;
          }
        }
      }
    });

    userValues.armobelts.map(additionalItem => {
      for (let property in additionalItem) {
        if (userValues.enabledFlags.find(item => item === 'armobelt')) {
          if (additionalItem[property] === 0 || additionalItem[property] === null) {
            formIsInvalid = true;
          }
        }
      }
    });

    for (let property in userValues.trims[0]) {
      if (userValues.enabledFlags.find(item => item === 'trim')) {
        switch (userValues.trims[0].trimType) {
          case 'Гипсокатонный лист':
          case 'Гипсокартонный лист':
            if (
              userValues.trims[0].trimHeight === 0 ||
              userValues.trims[0].trimWeight === 0 ||
              userValues.trims[0].trimWidth === 0 ||
              userValues.trims[0].trimLength === 0 ||
              userValues.trims[0].trimHeight === null ||
              userValues.trims[0].trimWeight === null ||
              userValues.trims[0].trimWidth === null ||
              userValues.trims[0].trimLength === null
            ) {
              formIsInvalid = true;
            }
            break;
          case 'Штукатурка для внутренних работ':
            if (userValues.trims[0].trimThickness === null || userValues.trims[0].trimThickness === 0) {
              formIsInvalid = true;
            }
            break;
          default:
            formIsInvalid = true;
        }
      }
    }

    for (let property in userValues.partitions[0]) {
      if (userValues.enabledFlags.find(item => item === 'partitions')) {
        if (userValues.partitions[0][property] === -1 || userValues.partitions[0][property] === '') {
          formIsInvalid = true;
        }
      }
    }

    userValues.partitionDoors.map(additionalItem => {
      for (let property in additionalItem) {
        if (userValues.enabledSubFlags.find(item => item === 'partitionDoors')) {
          if (additionalItem[property] === 0 || additionalItem[property] === null) {
            formIsInvalid = true;
          }
        }
      }
    });

    userValues.partitionJumpers.map(additionalItem => {
      for (let property in additionalItem) {
        if (userValues.enabledSubFlags.find(item => item === 'partitionJumpers')) {
          if (additionalItem[property] === 0 || additionalItem[property] === null) {
            formIsInvalid = true;
          }
        }
      }
    });

    if (formIsInvalid) {
      dispatch('calculations/2/set-values', [{name: 'isInvalid', value: true}]);
      setIsInvalid(true);
      message.error('Необходимо заполнить все обязательные поля');
      setShowCalculation(false);
    } else {
      dispatch('calculations/2/set-values', [{name: 'isInvalid', value: false}]);
      dispatch('calculations/2/calculate');
      setIsInvalid(false);
      setResultValues(calculation2Service.calculate(calculations[2].userValues));
      setShowCalculation(true);
    }
  };

  useEffect(() => {
    if (calculations[2].userValues.windows.length === 0) {
      const removedIndex = enabledFlags.indexOf('windows');
      if (removedIndex !== -1) {
        dispatch('calculations/2/remove-item', {itemName: 'enabledFlags', index: removedIndex});
      }
    }
    if (calculations[2].userValues.doors.length === 0) {
      const removedIndex = enabledFlags.indexOf('doors');
      if (removedIndex !== -1) {
        dispatch('calculations/2/remove-item', {itemName: 'enabledFlags', index: removedIndex});
      }
    }
    if (calculations[2].userValues.gables.length === 0) {
      const removedIndex = enabledFlags.indexOf('gables');
      if (removedIndex !== -1) {
        dispatch('calculations/2/remove-item', {itemName: 'enabledFlags', index: removedIndex});
      }
    }
    if (calculations[2].userValues.jumpers.length === 0) {
      const removedIndex = enabledFlags.indexOf('jumpers');
      if (removedIndex !== -1) {
        dispatch('calculations/2/remove-item', {itemName: 'enabledFlags', index: removedIndex});
      }
    }
    if (calculations[2].userValues.armobelts.length === 0) {
      const removedIndex = enabledFlags.indexOf('armobelt');
      if (removedIndex !== -1) {
        dispatch('calculations/2/remove-item', {itemName: 'enabledFlags', index: removedIndex});
      }
    }
  });

  return (
    <Fragment>
      <SpaceStyled size={20} direction={'vertical'}>
        <Section1 />
        <Section2 />
        <Section3 />
        <Row>
          <Space size={30} direction="vertical">
            <Col span={24}>
              <Title level={2}>{brickAndBlocksSections.section5.title}</Title>
              <CollapseStyled
                bordered={false}
                expandIconPosition="right"
                onChange={setEnabled}
                activeKey={enabledFlags}
              >
                <Panel
                  header="Окна"
                  key="windows"
                  className="collapse-custom-panel"
                  extra={collapseToggle('windows')}
                  showArrow={false}
                >
                  <Windows />
                </Panel>
                <Panel
                  header="Двери"
                  key="doors"
                  className="collapse-custom-panel"
                  extra={collapseToggle('doors')}
                  showArrow={false}
                >
                  <Doors />
                </Panel>
                <Panel
                  header="Фронтоны"
                  key="gables"
                  className="collapse-custom-panel"
                  extra={collapseToggle('gables')}
                  showArrow={false}
                >
                  <Gables />
                </Panel>
                <Panel
                  header="Перемычки"
                  key="jumpers"
                  className="collapse-custom-panel"
                  extra={collapseToggle('jumpers')}
                  showArrow={false}
                >
                  <Jumpers />
                </Panel>
                <Panel
                  header="Армопояс"
                  key="armobelt"
                  className="collapse-custom-panel"
                  extra={collapseToggle('armobelt')}
                  showArrow={false}
                >
                  <Armobelts />
                </Panel>
                <Panel
                  header="Отделка"
                  key="trim"
                  className="collapse-custom-panel"
                  extra={collapseToggle('trim')}
                  showArrow={false}
                >
                  <Trim />
                </Panel>
                <Panel
                  header="Перегородки"
                  key="partitions"
                  className="collapse-custom-panel"
                  extra={collapseToggle('partitions')}
                  showArrow={false}
                >
                  <Partitions />
                </Panel>
              </CollapseStyled>
            </Col>
            <Col span={24}>
              <Space size={140} direction="vertical">
                <Button type="primary" onClick={formValidate}>
                  Рассчитать
                </Button>
                {showCalculation && <ResultsBrick values={resultValues} />}
              </Space>
            </Col>
          </Space>
        </Row>
      </SpaceStyled>
    </Fragment>
  );
};

const CollapseStyled = styled(Collapse)`
  background: #fafafa;
  border: none;
  padding: 0 !important;

  .ant-collapse-header {
    /* margin-bottom: 0.5em; */
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;
    border-top: 1px solid #e8e8e8;
    margin-top: 0 - 1px;
    border-bottom: 1px solid #e8e8e8;
    padding: 16px 25px !important;
  }

  .ant-collapse-content-box {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff !important;
    padding: 20px 25px !important;
  }

  .collapse-custom-panel {
    border: none !important;
  }
`;

const SpaceStyled = styled(props => <Space {...props} />)`
  margin-bottom: 50px;
`;

export default Calculation2Brick;
