import React from 'react';

import {Modal, Space, Typography} from 'antd';

interface Props {
  visible: boolean;
  onCancel: () => void;
}

const RoomParamsModal: React.FC<Props> = ({visible, onCancel}) => {
  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal
      bodyStyle={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      // width={380}
      title={'Информация'}
    >
      <Space size={10} direction={'vertical'}>
        <Typography.Title level={4}>Параметры внутреннего воздуха для теплотехнического расчета</Typography.Title>
        <Typography.Paragraph>
          <Typography.Paragraph>
            При теплотехническом расчете, особенно, что касается расчета точки росы и сопротивления паропроницаемости
            очень важно правильно принять при расчете влажность и температуру внутреннего воздуха, т.к. от этого во
            многом зависит результат расчета. Если эти параметры не указаны в задании на проектирование (исходные
            данные) то их следуют принимать согласно нормативных документов. Для удобства представлены выдержки из них
          </Typography.Paragraph>
          <Typography.Paragraph>
            <strong>Влажность воздуха:</strong>
          </Typography.Paragraph>
          <ul>
            <li>Жилое помещение → 55%</li>
            <li>Кухня → 60%</li>
            <li>Ванная → 65%</li>
            <li>Техническое помещение → 50%</li>
          </ul>
          <Typography.Paragraph>
            <strong>Температура внутреннего воздуха:</strong>
          </Typography.Paragraph>
          <ul>
            <li>Жилое помещение → 20 – 23°С</li>
            <li>Кухня → 19 – 21°С</li>
            <li>Ванная → 24 – 26°С</li>
            <li>Техническое помещение → 16 – 20°С</li>
          </ul>
        </Typography.Paragraph>
      </Space>
    </Modal>
  );
};

export default RoomParamsModal;
