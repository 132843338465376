import React from 'react';

import {Modal, Space, Typography} from 'antd';

interface Props {
  visible: boolean;
  onCancel: () => void;
}

const MaterialsParamsModal: React.FC<Props> = ({visible, onCancel}) => {
  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal
      bodyStyle={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      title={'Информация'}
    >
      <Space size={10} direction={'vertical'}>
        <Typography.Paragraph>
          <Typography.Paragraph>
            Значения коэффициента теплотехнической однородности некоторых типов ограждающих конструкций, используемого
            для теплотехнического расчета согласно указаниям Мосгосэксперизы:
          </Typography.Paragraph>
          <ol>
            <li>
              для стен с оконными проемами r = 0,75 - 0,85 в зависимости от соотношения площади окон к площади фасада
              (для соотношения 0,18 величина r = 0,8)
            </li>
            <li>для глухих участков стен r = 0,92;</li>
            <li>для перекрытий верхнего этажа, совмещенных с покрытием кровли r = 0,95;</li>
            <li>для утепленного чердачного или цокольного перекрытия r = 0,97.</li>
          </ol>
          <Typography.Paragraph>
            Дополнительно советуем обратиться к следующей нормативной документации:
          </Typography.Paragraph>
          <ol>
            <li>
              ГОСТ Р 54851-2011. Конструкции строительные ограждающие неоднородные. Расчет приведенного сопротивления
              теплопередаче. Таблица 1
            </li>
            <li>СТО 00044807-001-2006 Теплозащитные свойства ограждающих конструкций зданий. Таблица 8</li>
            <li>
              СТО 17532043-001-2005 Нормы теплотехнического проектирования ограждающих конструкций и оценки
              энергоэффективности зданий. Таблица 6
            </li>
          </ol>
        </Typography.Paragraph>
      </Space>
    </Modal>
  );
};

export default MaterialsParamsModal;
