const constructionsParams = [
  {n: 1, alfa_h: 23, delta_t_h: 4},
  {n: 1, alfa_h: 12, delta_t_h: 3},
  {n: 0.9, alfa_h: 17, delta_t_h: 2},
  {n: 0.75, alfa_h: 12, delta_t_h: 2},
  {n: 0.6, alfa_h: 6, delta_t_h: 2},
  {n: 1, alfa_h: 23, delta_t_h: 2},
];

export default constructionsParams;
export const ventilationFacadeParams = {n: 1, alfa_h: 12, delta_t_h: 4};
