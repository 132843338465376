import React from 'react';
import {SpaceProps} from 'antd/es/space';

import {Space as AntdSpace} from 'antd';
import styled from 'styled-components';

interface ISpaceProps extends SpaceProps {
  className?: string;
}

const Space: React.FC<ISpaceProps> = ({children, ...restProps}) => {
  return <SpaceStyled {...restProps}>{children}</SpaceStyled>;
};

const SpaceStyled = styled(AntdSpace)`
  width: 100%;
`;

export default Space;
