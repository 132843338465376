import React, {Fragment} from 'react';

class ErrorBoundary extends React.Component<{}> {
  public componentDidCatch(): void {}

  public render(): JSX.Element {
    return <Fragment>{this.props.children}</Fragment>;
  }
}

export default ErrorBoundary;
