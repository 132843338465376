import React, {useState} from 'react';
import styled from 'styled-components';
import {Button, InputNumber, Select} from 'antd';
import {InputProps} from 'antd/es/input';
import {SelectProps} from 'antd/es/select';

interface IInputProps extends InputProps {
  inputButton: string;
}

interface ISelectProps extends SelectProps<'string'> {
  selectButton: string;
}

interface IInputSelectProps {
  inputProps: IInputProps;
  selectProps: ISelectProps;
  onInputChange?: (value: ReactText) => void;
  onSelectChange?: (e: number) => void;
}

const InputSelect: React.FC<IInputSelectProps> = ({inputProps, selectProps, onInputChange, onSelectChange}) => {
  const [type, setType] = useState<'select' | 'input'>('select');
  const {inputButton, ...restInputProps} = inputProps;
  const {selectButton, ...restSelectProps} = selectProps;
  const isSelect = type === 'select';
  const handleClickAtButton = () => {
    setType(isSelect ? 'input' : 'select');
  };
  return (
    <InputSelectStyled>
      {isSelect ? (
        <Select {...restSelectProps} onChange={onSelectChange} />
      ) : (
        <InputNumberStyled {...restInputProps} onChange={onInputChange} />
      )}
      <div>
        <Button type={'link'} onClick={handleClickAtButton}>
          {isSelect ? selectButton : inputButton}
        </Button>
      </div>
    </InputSelectStyled>
  );
};

const InputSelectStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`;

export default InputSelect;
