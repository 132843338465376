import React from 'react';
import {InputNumber as InputNumberAntd} from 'antd';
import {InputNumberProps} from 'antd/lib/input-number';
import styled from 'styled-components';

interface IInputNumberProps extends InputNumberProps {}

const InputNumber: React.FC<IInputNumberProps> = props => {
  return <InputNumberStyled {...props} />;
};

const InputNumberStyled = styled(InputNumberAntd)`
  width: 100%;
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
`;

export default InputNumber;
