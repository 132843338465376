import React, {Fragment} from 'react';
import {Row, Col, Button} from 'antd';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import {FileAddOutlined} from '@ant-design/icons';

import {Space} from '~src/components';
import GablesControls from './Content';

import AdditionalMaterialPreview from '~src/components/molecules/AdditionalMaterialPreview';

const Gables: React.FC = () => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {userValues} = calculations[2];

  const handleAddGable = () => {
    dispatch('calculations/2/add-item', {itemName: 'gables'});
  };
  return (
    <Fragment>
      <SpaceStyled size={70} direction="vertical">
        {userValues.gables.map((item, index) => {
          return (
            <FragmentStyled key={index}>
              <RowStyled>
                <Col md={12} sm={12} xs={24}>
                  <GablesControls gableIndex={index} gableItem={item} key={index} />
                </Col>
                <Col md={6} sm={12} xs={24}>
                  <AdditionalMaterialPreview sectionToRender="gables" gableIndex={index} />
                </Col>
              </RowStyled>
            </FragmentStyled>
          );
        })}
        <Row gutter={20}>
          <Col span={6}>
            <Button onClick={handleAddGable}>
              <FileAddOutlined />
              Добавить фронтон
            </Button>
          </Col>
        </Row>
      </SpaceStyled>
    </Fragment>
  );
};

const SpaceStyled = styled(props => <Space {...props} />)`
  margin-bottom: 50px;
`;

const RowStyled = styled(Row)`
  @media screen and (max-width: 320px) {
    margin-bottom: 25px;
  }

  @media screen and (min-width: 321px) and (max-width: 414px) {
    margin-bottom: 190px;
  }

  @media screen and (min-width: 415px) and (max-width: 768px) {
    margin-bottom: 25px;
  }

  @media screen and (min-width: 769px) {
    margin-bottom: 150px;
  }
`;

const FragmentStyled = styled(Fragment)`
  margin-bottom: 50px;
`;

export default Gables;
