import React, {Fragment, useContext} from 'react';
import {Col, Row, Typography} from 'antd';
import {IUserValuesAtCalculation1} from '~src/store/Calculations';
import {LocaleContext} from '~src/context';

interface IRegionParamsProps {
  params: {title: string; key: string; unit: string}[];
  values: IUserValuesAtCalculation1;
}

const RegionParams: React.FC<IRegionParamsProps> = ({params, values}) => {
  const {
    pages: {
      calculation1: {section1},
    },
  } = useContext(LocaleContext);
  return (
    <Row gutter={[10, 5]}>
      {params.map(param => (
        <Fragment key={param.key}>
          <Col offset={1} span={11} xs={14}>
            {param.title}
          </Col>
          <Col span={12} xs={9}>
            <Typography.Paragraph strong={true}>
              {param.key === 'zona' ? section1.zonaLabels[values[param.key]] : values[param.key]}
              {param.unit}
            </Typography.Paragraph>
          </Col>
        </Fragment>
      ))}
    </Row>
  );
};

export default RegionParams;
