import React, {useContext} from 'react';
import {LocaleContext} from '~src/context';
import {Button, Col, Row, Space, Table, Typography} from 'antd';
import ResultLiterature from './ResultLiterature';
import ResultUserValues from './ResultUserValues';
import ResultHeatLossParams from './ResultHeatLossParams';
import {Legend} from '~src/components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {IResultValuesAtCalculation1} from '~src/store/Calculations';
import {DownloadOutlined, ArrowRightOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import {$tablet} from '~src/theme/media';
import Chart from '~src/components/molecules/Chart';
import {getAxis, getLineDataSource} from '~src/utils/chart';
import MobileWarning from './MobileWarning';
import {Routes} from '~src/utils/routes';

interface IResult3Props {
  onClickAtDownloadReportButton: () => void;
}

const conclusion = {
  columns: [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      width: '80%',
    },
    {
      title: 'R',
      dataIndex: 'value',
      key: 'value',
      width: '20%',
    },
  ],
  rows: [
    {
      name: 'Потери тепла через 1 м² за отопительный сезон',
      value: 0,
      unit: ' кВт•ч',
      propName: 'conclusion3' as 'conclusion3',
    },
    {
      name: 'Потери тепла через 1 м² за 1 час при температуре самой холодной пятидневки',
      value: 0,
      unit: ' Вт•ч',
      propName: 'conclusion4' as 'conclusion4',
    },
  ],
};

const Result3: React.FC<IResult3Props> = props => {
  const {
    pages: {
      calculation1: {result},
    },
  } = useContext(LocaleContext);
  const {calculations} = useStoreon<IState, IEvents>('calculations');
  const resultValues = calculations[1].resultValues as IResultValuesAtCalculation1;
  const lineDataSource = getLineDataSource(
    resultValues as any,
    result.chartsLegend[2].reduce((acc: any, item) => {
      if (item.name) {
        return {
          ...acc,
          [item.name]: item.color,
        };
      }
      return acc;
    }, {}),
  );

  const conclusionDataSource = result.result3TableGeneration.conclusionParams.dataSource.map(row => ({
    ...row,
    value: `${resultValues[row.propName]} ${row.unit}`,
  }));
  const axis = getAxis(lineDataSource);
  return (
    <Row gutter={[0, 50]}>
      <Col span={24}>
        <Row gutter={[20, 20]}>
          <Col md={12} sm={24}>
            <ChartWrapper>
              <Chart
                canvasId={'chart-result3'}
                type={'outlined'}
                kind={'line'}
                width={450}
                height={350}
                dataSource={lineDataSource}
                dataSourceId={calculations['1'].id}
                withFulcrumLines={true}
                axis={axis}
              />
            </ChartWrapper>
            <MobileWarning />
          </Col>
          <Col md={12} sm={24}>
            <Space size={50} direction={'vertical'}>
              <Legend resultType={2} axis={result.legendAxis[2]} />
              <Space direction={'horizontal'} size={20}>
                <Button
                  icon={<DownloadOutlined />}
                  size={'large'}
                  type={'primary'}
                  onClick={props.onClickAtDownloadReportButton}
                >
                  {'Скачать отчёт'}
                </Button>
                <Col>
                  <Button icon={<ArrowRightOutlined />} size={'large'} href={Routes.calculation2} target={'_blank'}>
                    {'Расчёт материалов'}
                  </Button>
                </Col>
              </Space>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>{'Характеристики объекта'}</Typography.Title>
          </Col>
          <Col span={24}>
            <ResultUserValues />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <MobileWarning />
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Typography.Title level={3}>{'Результат - Тепловые потери'}</Typography.Title>
          </Col>
          <Col span={24}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <ResultHeatLossParamsStyled />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Typography.Title level={3}>{'Выводы'}</Typography.Title>
          </Col>
          <Col span={24}>
            <Table
              showHeader={false}
              dataSource={conclusionDataSource}
              columns={conclusion.columns}
              pagination={false}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>{'Список литературы'}</Typography.Title>
            <ResultLiterature />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const ResultHeatLossParamsStyled = styled(ResultHeatLossParams)`
  @media ${$tablet} {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const ChartWrapper = styled.div`
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default Result3;