import React, {Fragment} from 'react';

import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import {Typography, Row, Col, Table} from 'antd';
import Space from '~src/components/molecules/Space';

const {Title} = Typography;
const {Column} = Table;

interface IContentProps {
  values: {
    key: string;
    title: string;
    value: number;
    unit: string;
  }[];
}

const Trims: React.FC<IContentProps> = values => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {userValues} = calculations[2];
  const tableValues = values.values.filter(item => {
    switch (userValues.trims[0].trimType) {
      case 'Штукатурка для внутренних работ':
        return item.value !== 0 && item.key !== 'N_gkl';
      default:
        return item.value !== 0 && item.key !== 'V_sht_trim' && item.key !== 'M_sht_trim' && item.key !== 'N_sht_trim';
    }
  });
  return (
    <Fragment>
      <Space size={100} direction={'vertical'}>
        <Row gutter={20}>
          <Col span={24}>
            <Title level={3}>{'Внутренняя отделка - ' + userValues.trims[0].trimType}</Title>
          </Col>
          <Col span={24}>
            <Table dataSource={tableValues} pagination={false} showHeader={false} tableLayout="auto">
              <Column title="Свойство" dataIndex="title" key="title" width="80%" />
              <Column title="Значение" dataIndex="value" key="value" align="right" width="10%" />
              <Column title="Единица измерения" dataIndex="unit" key="unit" align="right" width="10%" />
            </Table>
          </Col>
        </Row>
      </Space>
    </Fragment>
  );
};

export default Trims;
