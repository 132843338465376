import React, {useCallback, useContext, useEffect} from 'react';
import {InputNumber, Table, Button, Space} from 'antd';
import {DownOutlined, UpOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';
import {LocaleContext} from '~src/context';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {ILayer} from '~src/services/Calculation1Service';
import {Switch} from 'antd/es';
import {ICalculation1Values} from '~src/store/Materials';

interface ILayersParamsProps {
  onClickAtEditButton: (layerId: number, materialId: string) => void;
  className?: string;
}

const LayersParams: React.FC<ILayersParamsProps> = ({className, onClickAtEditButton}) => {
  const {
    pages: {
      calculation1: {section3},
    },
  } = useContext(LocaleContext);
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {layers, operatingConditions} = calculations['1'].userValues;

  useEffect(() => {
    dispatch('calculations/1/set-values', [
      {
        name: 'containsAirGap',
        value: layers.some(layer => layer.materialType === 'Вентилируемая воздушная прослойка' && layer.isEnabled),
      },
    ]);
  }, [layers]);

  const getHandlerBlurAtInput = useCallback(
    (layerId: number, propName: keyof ICalculation1Values) => (e: React.FocusEvent<HTMLInputElement>) => {
      dispatch('calculations/1/set-values', [
        {
          name: 'layers',
          value: [...layers].map(layer =>
            layer.layerId === layerId ? {...layer, [propName]: Number(e.target.value)} : layer,
          ),
        },
      ]);
    },
    [layers],
  );
  const getHandlerChange = (layerId: number) => (value: boolean) => {
    dispatch('calculations/1/set-values', [
      {
        name: 'layers',
        value: [...layers].map(layer => (layer.layerId === layerId ? {...layer, isEnabled: value} : layer)),
      },
    ]);
  };
  const getHandlerClickAtEditButton = (layerId: number, materialId: string) => () => {
    onClickAtEditButton(layerId, materialId);
  };
  const getHandlerClickAtDeleteButton = (layerId: number) => () => {
    dispatch('calculations/1/set-values', [
      {
        name: 'layers',
        value: layers.reduce<ILayer[]>((acc, layer) => {
          if (layer.layerId < layerId) return [...acc, layer];
          if (layer.layerId === layerId) return acc;
          return [...acc, {...layer, layerId: layer.layerId - 1}];
        }, []),
      },
    ]);
  };
  // TODO: Вынести в стор
  const getHandlerChangeAtPositionButton = (layerId: number, direction: 'inc' | 'decr') => () => {
    let updatedLayers: ILayer[] = [];
    let i = 0;
    while (i < layers.length) {
      const layer = layers[i];
      if (layer.layerId === layerId && direction === 'inc' && i !== layers.length - 1) {
        layer.layerId += 1;
        updatedLayers[i + 1] = layer;
        updatedLayers[i] = layers[i + 1];
        updatedLayers[i].layerId = layerId;
        i += 2;
        continue;
      } else if (layer.layerId === layerId && direction === 'decr' && i > 0) {
        const prevLayer = updatedLayers[i - 1];
        prevLayer.layerId += 1;
        layer.layerId -= 1;
        updatedLayers[i - 1] = layer;
        updatedLayers[i] = prevLayer;
        i += 1;
        continue;
      }
      updatedLayers.push(layer);
      i += 1;
    }
    dispatch('calculations/1/set-values', [
      {
        name: 'layers',
        value: updatedLayers,
      },
    ]);
  };

  const columns = section3.layersParams.columns.reduce(
    (acc: any, column) => {
      if (column.key === 't') {
        return [
          ...acc,
          {
            ...column,
            // eslint-disable-next-line react/display-name
            render: (t: number, layer: ILayer) =>
              layer.layerType === 'airGap' ? (
                <InputNumber name={'t'} value={t} min={0} disabled />
              ) : (
                <InputNumber name={'t'} value={t} onBlur={getHandlerBlurAtInput(layer.layerId, 't')} min={0} />
              ),
          },
        ];
      }
      if (column.key === 'materialTranscalencyA' && operatingConditions === 'А') {
        return [
          ...acc,
          {
            ...column,
            render: (materialTranscalencyA: number, layer: ILayer) => {
              if (layer.layerType !== 'airGap') {
                return (
                  <InputNumber
                    name={'materialTranscalencyA'}
                    value={materialTranscalencyA}
                    onBlur={getHandlerBlurAtInput(layer.layerId, 'materialTranscalencyA')}
                    min={0}
                  />
                );
              }
            },
          },
        ];
      }
      if (column.key === 'materialTranscalencyB' && operatingConditions === 'Б') {
        return [
          ...acc,
          {
            ...column,
            render: (materialTranscalencyB: number, layer: ILayer) => {
              if (layer.layerType !== 'airGap') {
                return (
                  <InputNumber
                    name={'materialTranscalencyB'}
                    value={materialTranscalencyB}
                    onBlur={getHandlerBlurAtInput(layer.layerId, 'materialTranscalencyB')}
                    min={0}
                  />
                );
              }
            },
          },
        ];
      }
      if (column.key === 'materialVaporRermeability') {
        return [
          ...acc,
          {
            ...column,
            render: (materialVaporRermeability: number, layer: ILayer) => {
              if (layer.layerType !== 'airGap') {
                return (
                  <InputNumber
                    name={'materialVaporRermeability'}
                    value={materialVaporRermeability}
                    onBlur={getHandlerBlurAtInput(layer.layerId, 'materialVaporRermeability')}
                    min={0}
                  />
                );
              }
            },
          },
        ];
      }
      if (column.key === 'actions') {
        return [
          ...acc,
          {
            ...column,
            // eslint-disable-next-line react/display-name
            render: (_: any, layer: ILayer) => (
              <Space direction={'horizontal'} size={10}>
                <Switch checked={layer.isEnabled} onChange={getHandlerChange(layer.layerId)} />
                <Button
                  icon={<DownOutlined />}
                  onClick={getHandlerChangeAtPositionButton(layer.layerId, 'inc')}
                  type={'link'}
                  shape={'circle'}
                  disabled={layer.layerId === layers.length}
                />
                <Button
                  icon={<UpOutlined />}
                  onClick={getHandlerChangeAtPositionButton(layer.layerId, 'decr')}
                  type={'link'}
                  shape={'circle'}
                  disabled={layer.layerId === 1}
                />
                <Button
                  icon={<EditOutlined />}
                  shape={'circle'}
                  onClick={getHandlerClickAtEditButton(layer.layerId, layer.id)}
                />
                <Button
                  icon={<DeleteOutlined />}
                  shape={'circle'}
                  disabled={layers.length === 1}
                  onClick={getHandlerClickAtDeleteButton(layer.layerId)}
                />
              </Space>
            ),
          },
        ];
      }
      if (column.key === 'materialTranscalencyA' || column.key === 'materialTranscalencyB') return acc;
      return [...acc, column];
    },
    [calculations['1'].userValues.layers],
  );

  return (
    <Table
      className={className}
      columns={columns}
      dataSource={calculations['1'].userValues.layers}
      pagination={false}
      rowKey={record => record.layerId}
    />
  );
};

export default LayersParams;
