import React, {useContext} from 'react';
import {List} from 'antd';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {getUserValuesByCalculation1} from '~src/utils/calculations';
import {LocaleContext} from '~src/context';

interface IResultUserValuesProps {}

const ResultUserValues: React.FC<IResultUserValuesProps> = () => {
  const {
    pages: {calculation1},
  } = useContext(LocaleContext);
  const {calculations} = useStoreon<IState, IEvents>('calculations');
  const {userValues} = calculations[1];
  const dataSource = getUserValuesByCalculation1(userValues, calculation1);
  return (
    <List
      bordered={false}
      itemLayout={'horizontal'}
      dataSource={dataSource}
      renderItem={item => (
        <ListItemStyled>
          {item.title} — {item.value}
          {item.unit ? item.unit : ''}
        </ListItemStyled>
      )}
    />
  );
};

const ListItemStyled = styled(List.Item)`
  border: none;
  padding-left: 0 !important;
`;

export default ResultUserValues;
