// Армопояс
import armobeltImage from '~src/services/AdditionalImageRenderService/images/Armobelt/armobelt.png';
// Двери
import balkDoorsImage from '~src/services/AdditionalImageRenderService/images/Doors/balkDoors.png';
import brickDoorsImage from '~src/services/AdditionalImageRenderService/images/Doors/brickDoors.png';
import ceramicDoorsImage from '~src/services/AdditionalImageRenderService/images/Doors/ceramicDoors.png';
import foamDoorsImage from '~src/services/AdditionalImageRenderService/images/Doors/foamDoors.png';
// Перегородки
import ceramicPartitionsImage from '~src/services/AdditionalImageRenderService/images/Partitions/partitionsCeramic.png';
import brickPartitionsImage from '~src/services/AdditionalImageRenderService/images/Partitions/partitionsBrick.png';
import foamPartitionsImage from '~src/services/AdditionalImageRenderService/images/Partitions/partitionsFoam.png';
// Отделка
import gklTrimImage from '~src/services/AdditionalImageRenderService/images/Trims/trimsGKL.png';
import plasterTrimImage from '~src/services/AdditionalImageRenderService/images/Trims/trimsPlaster.png';
// Окна
import balkWindowsImage from '~src/services/AdditionalImageRenderService/images/Windows/balkWindows.png';
import brickWindowsImage from '~src/services/AdditionalImageRenderService/images/Windows/brickWindows.png';
import ceramicWindowsImage from '~src/services/AdditionalImageRenderService/images/Windows/ceramicWindows.png';
import foamWindowsImage from '~src/services/AdditionalImageRenderService/images/Windows/foamWindows.png';
// Окна и двери
import balkWindowsDoorsImage from '~src/services/AdditionalImageRenderService/images/Windows_Doors/balkWD.png';
import brickWindowsDoorsImage from '~src/services/AdditionalImageRenderService/images/Windows_Doors/brickWD.png';
import ceramicWindowsDoorsImage from '~src/services/AdditionalImageRenderService/images/Windows_Doors/ceramicWD.png';
import foamWindowsDoorsImage from '~src/services/AdditionalImageRenderService/images/Windows_Doors/foamWD.png';
// Окна, двери и перемычки
import brickWindowsDoorsJumpersImage from '~src/services/AdditionalImageRenderService/images/Windows_Doors_Jumpers/brickWDJ.png';
import ceramicWindowsDoorsJumpersImage from '~src/services/AdditionalImageRenderService/images/Windows_Doors_Jumpers/ceramicWDJ.png';
import foamWindowsDoorsJumpersImage from '~src/services/AdditionalImageRenderService/images/Windows_Doors_Jumpers/foamWDJ.png';
// Фронтоны без выделения
import balkDefaultTriangleImage from '~src/services/AdditionalImageRenderService/images/Gables/Default/balkDefaultTriangle.png';
import balkDefaultTrapezoidalImage from '~src/services/AdditionalImageRenderService/images/Gables/Default/balkDefaultTrapezoidal.png';
import balkDefaultPentaImage from '~src/services/AdditionalImageRenderService/images/Gables/Default/balkDefaultPenta.png';
import brickDefaultTriangleImage from '~src/services/AdditionalImageRenderService/images/Gables/Default/brickDefaultTriangle.png';
import brickDefaultTrapezoidalImage from '~src/services/AdditionalImageRenderService/images/Gables/Default/brickDefaultTrapezoidal.png';
import brickDefaultPentaImage from '~src/services/AdditionalImageRenderService/images/Gables/Default/brickDefaultPenta.png';
import ceramicDefaultTriangleImage from '~src/services/AdditionalImageRenderService/images/Gables/Default/ceramicDefaultTriangle.png';
import ceramicDefaultTrapezoidalImage from '~src/services/AdditionalImageRenderService/images/Gables/Default/ceramicDefaultTrapezoidal.png';
import ceramicDefaultPentaImage from '~src/services/AdditionalImageRenderService/images/Gables/Default/ceramicDefaultPenta.png';
import foamDefaultTriangleImage from '~src/services/AdditionalImageRenderService/images/Gables/Default/foamDefaultTriangle.png';
import foamDefaultTrapezoidalImage from '~src/services/AdditionalImageRenderService/images/Gables/Default/foamDefaultTrapezoidal.png';
import foamDefaultPentaImage from '~src/services/AdditionalImageRenderService/images/Gables/Default/foamDefaultPenta.png';
// Перемычки
import brickJumperImage from '~src/services/AdditionalImageRenderService/images/Jumpers/jumpersBrick.png';
import ceramicJumperImage from '~src/services/AdditionalImageRenderService/images/Jumpers/jumpersCeramic.png';
import foamJumperImage from '~src/services/AdditionalImageRenderService/images/Jumpers/jumpersFoam.png';

export interface IImages {
  armobeltImage: string;
  doorImage: string;
  windowDoorImage: string;
  windowDoorJumperImage: string;
  windowImage: string;
  partitionImage: string;
  trimImage: string;
  gableDefaultImage: string;
  gableBalkDefaultImage: string;
  gableHeightImage: string;
  gableWidthImage: string;
  jumperImage: string;
}

export interface IMaterialTypes {
  bearingWallType: string;
  balkBearingWallType: string;
  trimType: string;
  partitionType: string;
  gableType: number;
}

class AdditionalImageRenderService {
  materialTypes: IMaterialTypes | {} = {};
  resultImage: IImages = {
    armobeltImage: '',
    doorImage: '',
    windowDoorImage: '',
    windowDoorJumperImage: '',
    windowImage: '',
    partitionImage: '',
    trimImage: '',
    gableDefaultImage: '',
    gableBalkDefaultImage: '',
    gableHeightImage: '',
    gableWidthImage: '',
    jumperImage: '',
  };

  calculate(
    bearingWallType: string,
    trimType: string,
    partitionType: string,
    balkBearingWallType: string,
    gableType: number,
    balkGableType?: number,
  ) {
    this.resultImage = {...this.resultImage, armobeltImage: armobeltImage};
    let materialType = '';
    let gableMaterial = '';
    let passedGableType = balkGableType ? balkGableType : gableType;
    if (bearingWallType === '') {
      materialType = balkBearingWallType;
    } else if (balkBearingWallType === '') {
      materialType = bearingWallType;
    }

    switch (materialType) {
      case 'Керамический кирпич':
      case 'Силикатный кирпич':
        this.resultImage = {
          ...this.resultImage,
          doorImage: brickDoorsImage,
          windowImage: brickWindowsImage,
          windowDoorImage: brickWindowsDoorsImage,
          windowDoorJumperImage: brickWindowsDoorsJumpersImage,
          jumperImage: brickJumperImage,
        };
        gableMaterial = 'brick';
        break;
      case 'Керамический блок':
        this.resultImage = {
          ...this.resultImage,
          doorImage: ceramicDoorsImage,
          windowImage: ceramicWindowsImage,
          windowDoorImage: ceramicWindowsDoorsImage,
          windowDoorJumperImage: ceramicWindowsDoorsJumpersImage,
          jumperImage: ceramicJumperImage,
        };
        gableMaterial = 'ceramic';
        break;
      case 'Шлакоблок':
      case 'Газобетонные блоки':
      case 'Пенобетонные блоки':
        this.resultImage = {
          ...this.resultImage,
          doorImage: foamDoorsImage,
          windowImage: foamWindowsImage,
          windowDoorImage: foamWindowsDoorsImage,
          windowDoorJumperImage: foamWindowsDoorsJumpersImage,
          jumperImage: foamJumperImage,
        };
        gableMaterial = 'foam';
        break;
      case 'Брус прямоугольного сечения':
      case 'Брус клееный':
      case 'Брус профилированный':
      case 'Бревно оцилиндрованное':
        this.resultImage = {
          ...this.resultImage,
          doorImage: balkDoorsImage,
          windowImage: balkWindowsImage,
          windowDoorImage: balkWindowsDoorsImage,
        };
        gableMaterial = 'balk';
        break;
      case '':
        this.resultImage = {
          ...this.resultImage,
          doorImage: '',
          windowImage: '',
          windowDoorImage: '',
        };
        break;
    }
    switch (trimType) {
      case 'Гипсокартонный лист':
      case 'Гипсокатонный лист':
        this.resultImage = {...this.resultImage, trimImage: gklTrimImage};
        break;
      case 'Штукатурка для внутренних работ':
        this.resultImage = {...this.resultImage, trimImage: plasterTrimImage};
        break;
      default:
        break;
    }
    switch (partitionType) {
      case 'Керамический кирпич':
      case 'Силикатный кирпич':
        this.resultImage = {
          ...this.resultImage,
          partitionImage: brickPartitionsImage,
        };
        break;
      case 'Керамический блок':
        this.resultImage = {
          ...this.resultImage,
          partitionImage: ceramicPartitionsImage,
        };
        break;
      case 'Шлакоблок':
      case 'Газобетонные блоки':
      case 'Пенобетонные блоки':
        this.resultImage = {
          ...this.resultImage,
          partitionImage: foamPartitionsImage,
        };
        break;
    }
    switch (passedGableType) {
      case 0:
        switch (gableMaterial) {
          case 'brick':
            this.resultImage = {
              ...this.resultImage,
              gableDefaultImage: brickDefaultTriangleImage,
            };
            break;
          case 'ceramic':
            this.resultImage = {
              ...this.resultImage,
              gableDefaultImage: ceramicDefaultTriangleImage,
            };
            break;
          case 'foam':
            this.resultImage = {
              ...this.resultImage,
              gableDefaultImage: foamDefaultTriangleImage,
            };
            break;
          case 'balk':
            this.resultImage = {
              ...this.resultImage,
              gableBalkDefaultImage: balkDefaultTriangleImage,
            };
            break;
        }
        break;
      case 1:
        switch (gableMaterial) {
          case 'brick':
            this.resultImage = {
              ...this.resultImage,
              gableDefaultImage: brickDefaultTrapezoidalImage,
            };
            break;
          case 'ceramic':
            this.resultImage = {
              ...this.resultImage,
              gableDefaultImage: ceramicDefaultTrapezoidalImage,
            };
            break;
          case 'foam':
            this.resultImage = {
              ...this.resultImage,
              gableDefaultImage: foamDefaultTrapezoidalImage,
            };
            break;
          case 'balk':
            this.resultImage = {
              ...this.resultImage,
              gableBalkDefaultImage: balkDefaultTrapezoidalImage,
            };
            break;
        }
        break;
      case 2:
        switch (gableMaterial) {
          case 'brick':
            this.resultImage = {
              ...this.resultImage,
              gableDefaultImage: brickDefaultPentaImage,
            };
            break;
          case 'ceramic':
            this.resultImage = {
              ...this.resultImage,
              gableDefaultImage: ceramicDefaultPentaImage,
            };
            break;
          case 'foam':
            this.resultImage = {
              ...this.resultImage,
              gableDefaultImage: foamDefaultPentaImage,
            };
            break;
          case 'balk':
            this.resultImage = {
              ...this.resultImage,
              gableBalkDefaultImage: balkDefaultPentaImage,
            };
            break;
        }
        break;
      default:
        this.resultImage = {
          ...this.resultImage,
          gableDefaultImage: '',
        };
    }
    return this.resultImage;
  }
}

const additionalImageRenderService = new AdditionalImageRenderService();
export default additionalImageRenderService;
