import React from 'react';
import {Menu as AntdMenu} from 'antd';
import {MenuProps} from 'antd/es/menu';
import {Link} from 'gatsby';
import styled from 'styled-components';

interface IMenuProps extends MenuProps {
  className?: string;
  mode?: 'horizontal' | 'vertical';
  items: {key: string; caption: string}[];
  onClick?: () => void;
}

const Menu: React.FC<IMenuProps> = ({onClick, mode = 'horizontal', className, items}) => {
  return (
    <AntdMenuStyled className={className} mode={mode}>
      {items.map(item => (
        <AntdMenu.Item key={item.key}>
          <Link to={item.key} onClick={onClick}>
            {item.caption}
          </Link>
        </AntdMenu.Item>
      ))}
    </AntdMenuStyled>
  );
};

const AntdMenuStyled = styled(AntdMenu)`
  min-width: 580px;
`;

export default Menu;
