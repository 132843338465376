import React, {useContext} from 'react';
import {LocaleContext} from '~src/context';
import styled from 'styled-components';
import {Link} from 'gatsby';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {windowGlobal} from '~src/utils/dom';

import {Menu, Layout, Row, Col} from 'antd';
import {Logo} from '~src/components';

const {Sider: AntdSider} = Layout;

const Sider = () => {
  const {app} = useStoreon<IState, IEvents>('app');
  const {
    components: {menu},
  } = useContext(LocaleContext);
  return (
    <AntdSiderStyled theme={'light'} width={200}>
      <RowStyled justify={'center'} align={'middle'} gutter={[0, 40]}>
        <Col>
          <Logo color="#096dd9" />
        </Col>
      </RowStyled>
      <Row justify={'center'} gutter={[0, 40]}>
        <Col span={24}>
          <MenuStyled mode="inline" selectedKeys={[windowGlobal?.location.pathname.replace('/admin/', '')]}>
            {menu.admin.reduce<JSX.Element[]>((acc, menuItem) => {
              if (menuItem.key === 'users' && ['manager'].includes(app.account.role)) {
                return acc;
              }
              return [
                ...acc,
                <Menu.Item key={menuItem.key}>
                  <Link to={menuItem.route}>
                    <span>
                      <menuItem.icon />
                      {menuItem.title}
                    </span>
                  </Link>
                </Menu.Item>,
              ];
            }, [])}
          </MenuStyled>
        </Col>
      </Row>
    </AntdSiderStyled>
  );
};

const AntdSiderStyled = styled(AntdSider)`
  border-right: 1px solid #e8e8e8;
`;

const MenuStyled = styled(Menu)`
  border-right: none;
`;

const RowStyled = styled(Row)`
  margin-top: 0px !important;
`;

export default Sider;
