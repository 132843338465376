import React, {useContext} from 'react';
import styled from 'styled-components';
import {Typography, Row, Col, InputNumber, Form} from 'antd';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import Space from '~src/components/molecules/Space';
import {Control} from '~src/components';
import {LocaleContext} from '~src/context';
import {IWall} from '~src/services/Calculation2Service';

const {Title} = Typography;

interface ISection3ControlsControlsProps {
  section3Index: number;
}

const Section3Controls: React.FC<ISection3ControlsControlsProps> = ({section3Index}) => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {userValues} = calculations[2];
  const {
    pages: {
      calculation2: {brickAndBlocksSections},
    },
  } = useContext(LocaleContext);

  const getHandleInputChange = (name: keyof IWall | string) => (value: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'walls',
      values: [
        {
          name,
          value,
          index: section3Index,
        },
      ],
    });
  };

  return (
    <Row>
      <Col span={24}>
        <Title level={2}>{brickAndBlocksSections.section3.title}</Title>
      </Col>
      <RowStyled gutter={[20, 20]}>
        <Col md={6} sm={11} xs={23}>
          <Space size={20} direction={'vertical'}>
            <Control label={brickAndBlocksSections.section3.wallsLength.label}>
              <FormItemStyled
                validateStatus={userValues.isInvalid && userValues.walls[0].wallsLength === null ? 'error' : ''}
                help={userValues.isInvalid && userValues.walls[0].wallsLength === null ? 'Обязательное поле' : ''}
              >
                <InputNumberStyled
                  min={0}
                  size={'large'}
                  placeholder={brickAndBlocksSections.section3.wallsLength.placeholder}
                  onChange={getHandleInputChange(brickAndBlocksSections.section3.wallsLength.name)}
                />
              </FormItemStyled>
            </Control>
            <Control label={brickAndBlocksSections.section3.outerAngles.label}>
              <FormItemStyled
                validateStatus={userValues.isInvalid && userValues.walls[0].wallsOuterAngleCount === -1 ? 'error' : ''}
                help={
                  userValues.isInvalid && userValues.walls[0].wallsOuterAngleCount === -1 ? 'Обязательное поле' : ''
                }
              >
                <InputNumberStyled
                  min={0}
                  size={'large'}
                  placeholder={brickAndBlocksSections.section3.outerAngles.placeholder}
                  onChange={getHandleInputChange(brickAndBlocksSections.section3.outerAngles.name)}
                />
              </FormItemStyled>
            </Control>
          </Space>
        </Col>
        <Col md={6} sm={11} xs={23}>
          <Space size={20} direction={'vertical'}>
            <Control label={brickAndBlocksSections.section3.wallsHeight.label}>
              <FormItemStyled
                validateStatus={userValues.isInvalid && userValues.walls[0].wallsHeight === null ? 'error' : ''}
                help={userValues.isInvalid && userValues.walls[0].wallsHeight === null ? 'Обязательное поле' : ''}
              >
                <InputNumberStyled
                  min={0}
                  size={'large'}
                  placeholder={brickAndBlocksSections.section3.wallsHeight.placeholder}
                  onChange={getHandleInputChange(brickAndBlocksSections.section3.wallsHeight.name)}
                />
              </FormItemStyled>
            </Control>
            <Control label={brickAndBlocksSections.section3.innerAngles.label}>
              <FormItemStyled
                validateStatus={userValues.isInvalid && userValues.walls[0].wallsInnerAngleCount === -1 ? 'error' : ''}
                help={
                  userValues.isInvalid && userValues.walls[0].wallsInnerAngleCount === -1 ? 'Обязательное поле' : ''
                }
              >
                <InputNumberStyled
                  min={0}
                  size={'large'}
                  placeholder={brickAndBlocksSections.section3.innerAngles.placeholder}
                  onChange={getHandleInputChange(brickAndBlocksSections.section3.innerAngles.name)}
                />
              </FormItemStyled>
            </Control>
          </Space>
        </Col>
      </RowStyled>
    </Row>
  );
};

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`;

const RowStyled = styled(Row)`
  width: 100%;
`;

const FormItemStyled = styled(Form.Item)`
  margin-bottom: 0 !important;
`;

export default Section3Controls;
