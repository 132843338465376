import React from 'react';
import {Col, Row, Typography, Space} from 'antd';
import {Formula, StatusMessage} from '~src/components';
import * as colors from '@ant-design/colors';
import styled from 'styled-components';
import ResultUserValues from './ResultUserValues';
import ResultLiterature from './ResultLiterature';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {IResultValues} from '~src/services/Calculation1Service';
import {$phone, deviceSizes} from '~src/theme/media';
import {useMediaQuery} from 'react-responsive';
import FormulaElement from '~src/components/molecules/Formula/FormulaElement';
interface IResult5Props {
  onClickAtDownloadReportButton: () => void;
}
const Result5: React.FC<IResult5Props> = () => {
  const {calculations} = useStoreon<IState, IEvents>('calculations');
  const resultValues = calculations[1].resultValues as IResultValues;
  const isTablet = useMediaQuery({maxWidth: deviceSizes.desktop - 1});

  return (
    <Row gutter={[0, isTablet ? 25 : 50]}>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>{'Характеристики объекта'}</Typography.Title>
          </Col>
          <Col span={24}>
            <ResultUserValues />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Typography.Title level={3}>
              {'Результат расчёта влагонакопления с учётом тепловлажностного режима прослойки'}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Typography.Title level={4}>
                  {'Определение параметров воздухообмена, теплового и влажностного режима воздушной прослойки'}
                </Typography.Title>
                <Typography.Text type={'secondary'}>
                  {`на основе методик, описанных в «Рекомендаций по проектированию и применению для строительства
                    и реконструкции зданий в г. Москва фасадной системы с вентилируемым воздушным зазором 
                    «Краспан ВСт о(н)». 2003.`}
                </Typography.Text>
              </Col>
              <Col span={24}>
                <Typography.Text>
                  {'Расчёт проводится по худшим условиям — января и самой холодной пятидневки'}
                </Typography.Text>
              </Col>
              <Col span={24}>
                <Typography.Title level={5}>
                  {
                    'Расчёт по средней температуре месяца с наибольшим накоплением конденсата (выбрать месяц по таблице, как правило — январь)'
                  }
                </Typography.Title>
                <Typography.Paragraph>{'Скорость и расход воздуха в прослойке:'}</Typography.Paragraph>
                <ResultSection gutter={[20, 20]}>
                  <Col span={24}>
                    <FormulaNameStyled>{'Исходные данные берем по СП.131.13330.2018 '}</FormulaNameStyled>
                    <Formula name={'t_n_greatestCondensate'} value={resultValues.t_n_greatestCondensate} />
                    <Formula name={'t_sr_greatestCondensate'} value={resultValues.t_sr_greatestCondensate} />
                    <Formula name={'fi_sr_greatestCondensate'} value={resultValues.fi_sr_greatestCondensate} />
                  </Col>

                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Максимальную упругость водяного пара в прослойке '}
                      {'E'}
                      <sub>{'ср'}</sub>
                      {' , Па определяем по формуле (8.8) СП.50.13330.2012:'}
                    </FormulaNameStyled>
                    <Formula name={'E_sr'} value={resultValues.E_sr} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Упругость водяного пара в прослойке, Па, определяем по формуле (8.3) СП.50.13330.2012:'}
                    </FormulaNameStyled>
                    <Formula name={'e_sr'} value={resultValues.e_sr} />
                  </Col>
                  <Col span={24}>
                    <Typography.Text type={'secondary'}>
                      {`Раcчёт производится с учётом расположения прослойки на одной стороне здания. Тогда изменением 
                        скорости ветра по высоте можно пренебречь.`}
                    </Typography.Text>
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Скорость движения воздуха в прослойке '}
                      {'V'}
                      <sub>{'пр'}</sub>
                      {' по формуле (7.10), м/с:'}
                    </FormulaNameStyled>
                    <Formula name={'V_pr'} value={resultValues.V_pr} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Плотность воздуха '}
                      {'γ'}
                      <sub>{'пр'}</sub>
                      {' по формуле, кг/м³:'}
                    </FormulaNameStyled>
                    <Formula name={'gamma_pr'} value={resultValues.gamma_pr} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>{'Расход воздуха W по формуле (7.12), кг/п.м.·час:'}</FormulaNameStyled>
                    <Formula name={'W'} value={resultValues.W} />
                  </Col>
                </ResultSection>
              </Col>
              <Col span={24}>
                <Typography.Paragraph>
                  {'Температура воздуха и максимальная упругость водяного пара на выходе из прослойки'}
                </Typography.Paragraph>
                <ResultSection gutter={[20, 20]}>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {
                        'Определим термическое сопротивление прослойки, коэффициенты теплопередачи внутренней и наружной частей стены с учётом движения воздуха'
                      }
                    </FormulaNameStyled>
                    <FormulaNameStyled>
                      {'Коэффициент теплообмена '}
                      {'α'}
                      <sub>{'пр'}</sub>
                      {' по формуле (7.17), Вт/м²·ºС'}
                    </FormulaNameStyled>
                    <Formula name={'alfa_pr'} value={resultValues.alfa_pr} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>{'Коэффициент лучистого теплообмена, Вт/м²·ºС:'}</FormulaNameStyled>
                    <Formula name={'alfa_l'} value={resultValues.alfa_l} />
                    <FormulaNameStyled>
                      {'α'}
                      <sub>{'л'}</sub>
                      {' = 2,44 — условно'}
                    </FormulaNameStyled>
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Термическое сопротивление прослойки '}
                      {'R'}
                      <sub>{'пр'}</sub>
                      {' , м²·°С/Вт, по формуле (7.16):'}
                    </FormulaNameStyled>
                    <Formula name={'R_pr'} value={resultValues.R_pr} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Определим коэффициенты теплопередачи внутренней '}
                      {'κ'}
                      <sub>{'в'}</sub>
                      {' и наружной '}
                      {'κ'}
                      <sub>{'н'}</sub>
                      {' и наружной  частей стены, Вт/м²·°С, с учётом движения воздуха в прослойке'}
                    </FormulaNameStyled>
                    <Formula name={'k_v'} value={resultValues.k_v} />
                    <Formula name={'k_n'} value={resultValues.k_n} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Определим температуру воздуха на выходе из прослойки, °С, по формуле (7.15):'}
                    </FormulaNameStyled>
                    <Formula name={'t_y'} value={resultValues.t_y} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Максимальную упругость водяного пара на выходе из прослойки, Па, рассчитаем по формуле:'}
                    </FormulaNameStyled>
                    <Formula name={'E_y'} value={resultValues.E_y} />
                  </Col>
                </ResultSection>
              </Col>
              <Col span={24}>
                <Typography.Paragraph>
                  {'Температура воздуха и упругость водяного пара по длине прослойки'}
                </Typography.Paragraph>
                <ResultSection gutter={[20, 20]}>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Определим внутренний '}
                      {'M'}
                      <sub>{'в'}</sub>
                      {' и наружный '}
                      {'M'}
                      <sub>{'н'}</sub>
                      {' коэффициенты массопередачи, мг/м²·ч·Па, по формуле (7.19):'}
                    </FormulaNameStyled>
                    <Formula name={'M_v'} value={resultValues.M_v} />
                    <FormulaNameStyled>
                      {'ΣR'}
                      <sub>{'вп'}</sub>
                      {' — сумма сопротивлений паропроницанию от внутренней поверхности до воздушной прослойки'}
                    </FormulaNameStyled>
                    <Formula name={'M_n'} value={resultValues.M_n} />
                    <FormulaNameStyled>
                      {'ΣR'}
                      <sub>{'пн'}</sub>
                      {' — сумма сопротивлений паропроницанию от воздушной прослойки до наружной поверхности;'}
                    </FormulaNameStyled>
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>{'Коэффициент B определяем по формуле (7.20):'}</FormulaNameStyled>
                    <Formula name={'B'} value={resultValues.B} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Упругость водяного пара на выходе из прослойки, Па, определяем по формуле (7.18):'}
                    </FormulaNameStyled>
                    <Formula name={'e_y'} value={resultValues.e_y} />
                  </Col>
                </ResultSection>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Typography.Title level={3}>{'Расчёт по самой холодной пятидневке'}</Typography.Title>
          </Col>
          <Col span={24}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Typography.Paragraph>{'Скорость и расход воздуха в прослойке:'}</Typography.Paragraph>
                <ResultSection gutter={[20, 20]}>
                  <Col span={24}>
                    <FormulaNameStyled>{'Исходные данные берем по СП.131.13330.2018 '}</FormulaNameStyled>
                    <Formula name={'t_n_coldestFiveDays'} value={resultValues.t_n_coldestFiveDays} />
                    <Formula name={'t_sr_coldestFiveDays'} value={resultValues.t_sr_coldestFiveDays} />
                    <Formula name={'fi_sr_coldestFiveDays'} value={resultValues.fi_sr_coldestFiveDays} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Максимальную упругость водяного пара в прослойке '}
                      {'E'}
                      <sub>{'ср'}</sub>
                      {' , Па определяем по формуле (8.8) СП.50.13330.2012:'}
                    </FormulaNameStyled>
                    <Formula name={'E_sr_h'} value={resultValues.E_sr_h} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Упругость водяного пара в прослойке, Па, определяем по формуле (8.3) СП.50.13330.2012:'}
                    </FormulaNameStyled>
                    <Formula name={'e_sr_h'} value={resultValues.e_sr_h} />
                  </Col>
                  <Col span={24}>
                    <Typography.Text type={'secondary'}>
                      {`Раcчёт производится с учётом расположения прослойки на одной стороне здания. Тогда изменением 
                        скорости ветра по высоте можно пренебречь.`}
                    </Typography.Text>
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Скорость движения воздуха в прослойке '}
                      {'V'}
                      <sub>{'пр'}</sub>
                      {' по формуле (7.10), м/с:'}
                    </FormulaNameStyled>
                    <Formula name={'V_pr_h'} value={resultValues.V_pr_h} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Плотность воздуха '}
                      {'γ'}
                      <sub>{'пр'}</sub>
                      {' по формуле, кг/м³:'}
                    </FormulaNameStyled>
                    <Formula name={'gamma_pr_h'} value={resultValues.gamma_pr_h} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>{'Расход воздуха W по формуле (7.12), кг/п.м.·час:'}</FormulaNameStyled>
                    <Formula name={'W_h'} value={resultValues.W_h} />
                  </Col>
                </ResultSection>
              </Col>
              <Col span={24}>
                <Typography.Paragraph>
                  {'Температура воздуха и максимальная упругость водяного пара на выходе из прослойки'}
                </Typography.Paragraph>
                <ResultSection gutter={[20, 20]}>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {
                        'Определим термическое сопротивление прослойки, коэффициенты теплопередачи внутренней и наружной частей стены с учётом движения воздуха'
                      }
                    </FormulaNameStyled>
                    <FormulaNameStyled>
                      {'Коэффициент теплообмена '}
                      {'α'}
                      <sub>{'пр'}</sub>
                      {' по формуле (7.17), Вт/м²·ºС'}
                    </FormulaNameStyled>
                    <Formula name={'alfa_pr_h'} value={resultValues.alfa_pr_h} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>{'Коэффициент лучистого теплообмена, Вт/м²·ºС:'}</FormulaNameStyled>
                    <Formula name={'alfa_l_h'} value={resultValues.alfa_l} />
                    <FormulaNameStyled>
                      {'α'}
                      <sub>{'л'}</sub>
                      {' = 2,44 — условно'}
                    </FormulaNameStyled>
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Термическое сопротивление прослойки '}
                      {'R'}
                      <sub>{'пр'}</sub>
                      {' , м²·°С/Вт, по формуле (7.16):'}
                    </FormulaNameStyled>
                    <Formula name={'R_pr_h'} value={resultValues.R_pr_h} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Определим коэффициенты теплопередачи внутренней '}
                      {'κ'}
                      <sub>{'в'}</sub>
                      {' и наружной '}
                      {'κ'}
                      <sub>{'н'}</sub>
                      {' и наружной  частей стены, Вт/м²·°С, с учётом движения воздуха в прослойке'}
                    </FormulaNameStyled>
                    <Formula name={'k_v_h'} value={resultValues.k_v_h} />
                    <Formula name={'k_n_h'} value={resultValues.k_n_h} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Определим температуру воздуха на выходе из прослойки, °С, по формуле (7.15):'}
                    </FormulaNameStyled>
                    <Formula name={'t_y_h'} value={resultValues.t_y_h} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Максимальную упругость водяного пара на выходе из прослойки, Па, рассчитаем по формуле:'}
                    </FormulaNameStyled>
                    <Formula name={'E_y_h'} value={resultValues.E_y_h} />
                  </Col>
                </ResultSection>
              </Col>
              <Col span={24}>
                <Typography.Paragraph>
                  {'Температура воздуха и упругость водяного пара по длине прослойки'}
                </Typography.Paragraph>
                <ResultSection gutter={[20, 20]}>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Определим внутренний '}
                      {'M'}
                      <sub>{'в'}</sub>
                      {' и наружный '}
                      {'M'}
                      <sub>{'н'}</sub>
                      {' коэффициенты массопередачи, мг/м²·ч·Па, по формуле (7.19):'}
                    </FormulaNameStyled>
                    <Formula name={'M_v_h'} value={resultValues.M_v_h} />
                    <FormulaNameStyled>
                      {'ΣR'}
                      <sub>{'вп'}</sub>
                      {' — сумма сопротивлений паропроницанию от внутренней поверхности до воздушной прослойки'}
                    </FormulaNameStyled>
                    <Formula name={'M_n_h'} value={resultValues.M_n_h} />
                    <FormulaNameStyled>
                      {'ΣR'}
                      <sub>{'пн'}</sub>
                      {' — сумма сопротивлений паропроницанию от воздушной прослойки до наружной поверхности;'}
                    </FormulaNameStyled>
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>{'Коэффициент B определяем по формуле (7.20):'}</FormulaNameStyled>
                    <Formula name={'B_h'} value={resultValues.B_h} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>
                      {'Упругость водяного пара на выходе из прослойки, Па, определяем по формуле (7.18):'}
                    </FormulaNameStyled>
                    <Formula name={'e_y_h'} value={resultValues.e_y_h} />
                  </Col>
                </ResultSection>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Typography.Title level={3}>{'Вывод'}</Typography.Title>
          </Col>

          <Col span={24}>
            <Typography.Paragraph>
              {'Произведём сравнение полученных на этапах 6.1.3 и 6.2.3 значений упругости водяного пара '}
              {'e'}
              <sub>{'y'}</sub>
              {
                ' для средней температуры января со значениями максимальной упругости водяного пара, полученными, на 6.1.2 и 6.2.2 этапах. Если '
              }
              {'E'}
              <sub>{'y'}</sub>
              {' больше — влажностный режим стены в зоне стыковых швов экранов удовлетворителен.'}
            </Typography.Paragraph>
            <ResultSection>
              <Col span={24}>
                <Space direction={'horizontal'} size={15}>
                  <div>
                    <FormulaElement type={'var'} value={'e'} sub={'y'} fontStyle={'italic'} />
                    &nbsp;
                    <FormulaElement type={'symb'} value={resultValues.conclusion7!.symbol} />
                    &nbsp;
                    <FormulaElement type={'symb'} value={'E'} sub={'y'} fontStyle={'italic'} />
                  </div>
                  <div>
                    <FormulaElement type={'symb'} value={'('} />
                    <FormulaElement type={'var'} value={`${resultValues.e_y} Па`} />
                    &nbsp;
                    <FormulaElement type={'symb'} value={resultValues.conclusion7!.symbol} />
                    &nbsp;
                    <FormulaElement type={'symb'} value={`${resultValues.E_y} Па`} />
                    <FormulaElement type={'symb'} value={')'} />
                  </div>
                </Space>
              </Col>
            </ResultSection>
            <Typography.Paragraph>
              <StatusMessage
                type={resultValues.conclusion7!.type}
                description={resultValues.conclusion7!.description}
              />
            </Typography.Paragraph>
          </Col>

          <Col span={24}>
            <Typography.Paragraph>
              {' и для температуры самой холодной пятидневки, соответственно '}
            </Typography.Paragraph>
            <ResultSection>
              <Col span={24}>
                <Space direction={'horizontal'} size={15}>
                  <div>
                    <FormulaElement type={'var'} value={'e'} sub={'y_h'} fontStyle={'italic'} />
                    &nbsp;
                    <FormulaElement type={'symb'} value={resultValues.conclusion8!.symbol} />
                    &nbsp;
                    <FormulaElement type={'symb'} value={'E'} sub={'y_h'} fontStyle={'italic'} />
                  </div>
                  <div>
                    <FormulaElement type={'symb'} value={'('} />
                    <FormulaElement type={'var'} value={`${resultValues.e_y_h} Па`} />
                    &nbsp;
                    <FormulaElement type={'symb'} value={resultValues.conclusion8!.symbol} />
                    &nbsp;
                    <FormulaElement type={'symb'} value={`${resultValues.E_y_h} Па`} />
                    <FormulaElement type={'symb'} value={')'} />
                  </div>
                </Space>
              </Col>
            </ResultSection>
            <Typography.Paragraph>
              <StatusMessage
                type={resultValues.conclusion8!.type}
                description={resultValues.conclusion8!.description}
              />
            </Typography.Paragraph>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Typography.Text>
              {`В действительности, средняя температура воздуха в прослойке будет выше, а скорость и
              расход воздуха больше, что идет в запас. Полученные, в результате расчёта, скорость и
              расход воздуха характерны в районе приточных и вытяжных отверстий. 
              `}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text type={'secondary'}>
              {`Примечание: Расчёт выполнен из расчёта на применение полупроницаемой ветро‐влагозащитной
              мембраны на наружной поверхности слоя изоляции. Без применения мембраны возможно
              отрицательное воздействие инфильтрации, а так же нежелательное разрушение и выветривание
              материала изоляции.`}
            </Typography.Text>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>{'Список литературы'}</Typography.Title>
            <ResultLiterature />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const ResultSection = styled(Row)`
  display: flex;
  align-items: center;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 15px 30px;
  @media ${$phone} {
    padding: 15px 15px;
  }
`;

const FormulaNameStyled = styled(Typography.Paragraph)`
  margin-bottom: 5px !important;
  color: ${colors.grey[1]};
`;

export default Result5;
