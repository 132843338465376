import React, {Fragment} from 'react';
import {Row, Col, Button} from 'antd';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import {FileAddOutlined} from '@ant-design/icons';

import {Space} from '~src/components';
import ArmobeltsControls from './Content';

import AdditionalMaterialPreview from '~src/components/molecules/AdditionalMaterialPreview';

const Armobelts: React.FC = () => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {userValues} = calculations[2];

  const handleAddArmobelt = () => {
    dispatch('calculations/2/add-item', {itemName: 'armobelts'});
  };
  return (
    <Fragment>
      <SpaceStyled size={70} direction="vertical">
        <RowStyled>
          <Col md={12} xs={24}>
            <SpaceStyled size={70} direction="vertical">
              {userValues.armobelts.map((item, index) => {
                return <ArmobeltsControls armobeltIndex={index} armobeltItem={item} key={index} />;
              })}
            </SpaceStyled>
          </Col>
          <Col md={6} sm={24} xs={24}>
            <AdditionalMaterialPreview sectionToRender="armobelt" />
          </Col>
        </RowStyled>
        <Row gutter={20}>
          <Col span={6}>
            <Button onClick={handleAddArmobelt}>
              <FileAddOutlined />
              Добавить армопояс
            </Button>
          </Col>
        </Row>
      </SpaceStyled>
    </Fragment>
  );
};

const SpaceStyled = styled(props => <Space {...props} />)`
  margin-bottom: 50px;
`;

const RowStyled = styled(Row)`
  @media screen and (max-width: 320px) {
    margin-bottom: 25px;
  }

  @media screen and (min-width: 321px) and (max-width: 812px) {
    margin-bottom: 150px;
  }
`;

export default Armobelts;
