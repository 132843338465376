import React, {Fragment} from 'react';
import {Row, Col, Button} from 'antd';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import {FileAddOutlined} from '@ant-design/icons';

import {Space} from '~src/components';
import DoorsControls from './Content';

const Doors: React.FC = () => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {userValues} = calculations[2];

  const handleAddArmobelt = () => {
    dispatch('calculations/2/add-item', {itemName: 'partitionDoors'});
  };
  return (
    <Fragment>
      <SpaceStyled size={70} direction="vertical">
        {userValues.partitionDoors.map((item, index) => {
          return <DoorsControls partitionDoorIndex={index} partitionDoorItem={item} key={index} />;
        })}
        <Row gutter={20}>
          <Col span={6}>
            <Button onClick={handleAddArmobelt}>
              <FileAddOutlined />
              Добавить двери
            </Button>
          </Col>
        </Row>
      </SpaceStyled>
    </Fragment>
  );
};

const SpaceStyled = styled(props => <Space {...props} />)`
  margin-bottom: 50px;
`;

export default Doors;
