import React from 'react';
import styled, {css} from 'styled-components';
import {Typography} from 'antd';
import {$tablet} from '~src/theme/media';

export interface IFormulaItem {
  type: string;
  value: string | IFormulaItem[][];
  fontStyle?: string;
  sub?: string;
  sup?: string;
  postfix?: string;
}

interface IFormulaElementProps extends IFormulaItem {}

const FormulaElement: React.FC<IFormulaElementProps> = ({value, fontStyle, sub, sup, postfix}) => {
  return (
    <TextStyled fontStyle={fontStyle}>
      {value}
      {sub && <sub>{sub}</sub>}
      {sup && <sup>{sup}</sup>}
      {postfix && postfix}
    </TextStyled>
  );
};

export const TextStyled = styled(Typography.Text)<{fontStyle?: IFormulaItem['fontStyle']}>`
  padding: 0 2px;
  font-size: 24px;
  font-family: serif;
  white-space: pre-line;
  color: rgba(0, 0, 0, 0.85);
  ${props =>
    props.fontStyle &&
    css`
      font-style: ${props.fontStyle};
    `};
  sub,
  sup {
    font-style: normal;
    font-size: 14px;
  }

  @media ${$tablet} {
    font-size: 18px;
    sub,
    sup {
      font-style: normal;
      font-size: 10px;
    }
  }
`;

export default FormulaElement;
