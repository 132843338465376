import React, {Fragment, useContext} from 'react';
import {Col, Row, Typography} from 'antd';
import {IUserValuesAtCalculation1} from '~src/store/Calculations';
import {LocaleContext} from '~src/context';

interface IWeatherParamsProps {
  params: {title: string; key: string; unit: string}[];
  values: IUserValuesAtCalculation1;
}

const columnsSizes = [5, 3, 15];
const columnsOffsets = [1, undefined, undefined];

const WeatherParams: React.FC<IWeatherParamsProps> = ({params, values}) => {
  const {
    pages: {
      calculation1: {section1},
    },
  } = useContext(LocaleContext);
  return (
    <Row>
      <Col span={24}>
        <Row>
          {section1.collapse[1].headers.map((header, index) => (
            <Col key={index} offset={columnsOffsets[index]} span={columnsSizes[index]} xs={7}>
              <Typography.Paragraph strong={true}>{header}</Typography.Paragraph>
            </Col>
          ))}
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          {params.map((param, index) => (
            <Fragment key={param.key}>
              <Col offset={columnsOffsets[0]} span={columnsSizes[0]} xs={7}>
                {param.title}
              </Col>
              <Col span={columnsSizes[1]} xs={8}>
                <Typography.Paragraph strong={true}>{values[`t_${param.key}`]}</Typography.Paragraph>
              </Col>
              <Col span={columnsSizes[2]} xs={8}>
                <Typography.Paragraph strong={true}>{values[`p_${param.key}`]}</Typography.Paragraph>
              </Col>
            </Fragment>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default WeatherParams;
