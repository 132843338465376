import React, {Fragment} from 'react';
import FeedbackModal from '~src/components/organisms/Modals/FeedbackModal';
import LoadXlsModal from '~src/components/organisms/Modals/LoadXlsModal';
import {windowGlobal} from '~src/utils/dom';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

export interface IModalsProps {
  navigate: (to: string) => void;
  path: string;
  location: {hash: string};
}

// TODO: не получилось нормально реализовать, т.к. неясно как работать с событиями
const Modals: React.FC<IModalsProps> = ({...props}) => {
  const {modals, dispatch} = useStoreon<IState, IEvents>('modals');
  return (
    <Fragment>
      {modals.feedbackModal && (
        <FeedbackModal
          visible={true}
          onCancel={() => {
            dispatch('modals/show-modal', {key: 'feedbackModal', value: false});
          }}
        />
      )}
      <LoadXlsModal visible={windowGlobal?.location.hash === '#load-xls-modal'} {...props} />
    </Fragment>
  );
};

export default Modals;
