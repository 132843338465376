import React, {useContext} from 'react';
import Line from '~src/components/molecules/Legend/Line';
import Layer from '~src/components/molecules/Legend/Layer';
import styled from 'styled-components';
import {Space} from 'antd';
import {ILayer} from '~src/services/Calculation1Service';
import Zone from '~src/components/molecules/Legend/Zone';
import {LocaleContext} from '~src/context';

interface ILegendProps {
  resultType: 0 | 1 | 2;
  layers?: ILayer[];
  axis: {
    x: string;
    y: string;
  };
}

const Legend: React.FC<ILegendProps> = props => {
  const {
    pages: {
      calculation1: {
        result: {chartsLegend},
      },
    },
  } = useContext(LocaleContext);

  const values = chartsLegend[props.resultType];
  return (
    <div>
      {values.map((value, index) => (
        <LegendLineStyled key={index}>
          <Space direction={'horizontal'} size={10}>
            {value.type === 'zone' ? <Zone /> : <Line color={value.color} />}
            <div>{value.label}</div>
          </Space>
        </LegendLineStyled>
      ))}
      {props.layers?.map((layer, index) => (
        <LegendLineStyled key={index}>
          <Space direction={'horizontal'} size={10}>
            <Layer>{layer.layerId}</Layer>
            <div>{layer.layerName}</div>
          </Space>
        </LegendLineStyled>
      ))}
      <LegendLineStyled>
        <Space direction={'horizontal'} size={10}>
          <AxisNameStyled>X</AxisNameStyled>
          <div>{props.axis.x}</div>
        </Space>
      </LegendLineStyled>
      <LegendLineStyled>
        <Space direction={'horizontal'} size={10}>
          <AxisNameStyled>Y</AxisNameStyled>
          <div>{props.axis.y}</div>
        </Space>
      </LegendLineStyled>
    </div>
  );
};

const LegendLineStyled = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

const AxisNameStyled = styled.div`
  font-weight: bold;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Legend;
