import React from 'react';
import {Typography} from 'antd';
import styled from 'styled-components';

interface IControlProps {
  label?: string;
}

const Control: React.FC<IControlProps> = ({children, className, label}) => {
  return (
    <ControlStyled className={className}>
      {label && (
        <LabelStyled>
          <Typography.Text>{label}</Typography.Text>
        </LabelStyled>
      )}
      {children}
    </ControlStyled>
  );
};

const ControlStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const LabelStyled = styled.div`
  font-weight: 500;
  margin-bottom: 2px;
`;

export default Control;
