import React, {useContext, Fragment} from 'react';
import {Row, Col, InputNumber, Typography, Form} from 'antd';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import {CloseOutlined} from '@ant-design/icons';

import {Control, Space} from '~src/components';
import {LocaleContext} from '~src/context';
import {IPartitionJumpers} from '~src/services/Calculation2Service';

const {Title} = Typography;

interface IJumpersControlsProps {
  partitionJumperIndex: number;
  partitionJumperItem: IPartitionJumpers;
}

const JumpersControls: React.FC<IJumpersControlsProps> = ({partitionJumperIndex, partitionJumperItem}) => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {
    pages: {
      calculation2: {brickAndBlocksSections},
    },
  } = useContext(LocaleContext);

  const {userValues} = calculations[2];

  const getHandleInputChange = (name: keyof IPartitionJumpers) => (value: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'partitionJumpers',
      values: [
        {
          name,
          value,
          index: partitionJumperIndex,
        },
      ],
    });
  };

  const handleRemovePartitionJumper = () => {
    dispatch('calculations/2/remove-item', {itemName: 'partitionJumpers', index: partitionJumperIndex});
  };
  return (
    <Fragment>
      <Space size={10} direction="vertical">
        <Row align="middle">
          <TitleStyled level={4}>Перемычка {partitionJumperIndex + 1}</TitleStyled>
          <CloseOutlined onClick={handleRemovePartitionJumper} />
        </Row>
        <Row gutter={[20, 20]}>
          <Col md={12} xs={24}>
            <Space size={20} direction={'vertical'}>
              <Control label={brickAndBlocksSections.section5.partitions.jumpers.jumpersLength.label}>
                <FormItemStyled
                  validateStatus={
                    userValues.isInvalid &&
                    (userValues.partitionJumpers[partitionJumperIndex].partitionJumperLength === 0 ||
                      userValues.partitionJumpers[partitionJumperIndex].partitionJumperLength === null)
                      ? 'error'
                      : ''
                  }
                  help={
                    userValues.isInvalid &&
                    (userValues.partitionJumpers[partitionJumperIndex].partitionJumperLength === 0 ||
                      userValues.partitionJumpers[partitionJumperIndex].partitionJumperLength === null)
                      ? 'Обязательное поле'
                      : ''
                  }
                >
                  <InputNumberStyled
                    min={0}
                    size={'large'}
                    placeholder={brickAndBlocksSections.section5.partitions.jumpers.jumpersLength.placeholder}
                    onChange={getHandleInputChange(
                      brickAndBlocksSections.section5.partitions.jumpers.jumpersLength.name,
                    )}
                    value={
                      partitionJumperItem &&
                      (partitionJumperItem.partitionJumperLength === 0
                        ? undefined
                        : partitionJumperItem.partitionJumperLength)
                    }
                  />
                </FormItemStyled>
              </Control>
              <Control label={brickAndBlocksSections.section5.partitions.jumpers.jumpersThickness.label}>
                <FormItemStyled
                  validateStatus={
                    userValues.isInvalid &&
                    (userValues.partitionJumpers[partitionJumperIndex].partitionJumperThickness === 0 ||
                      userValues.partitionJumpers[partitionJumperIndex].partitionJumperThickness === null)
                      ? 'error'
                      : ''
                  }
                  help={
                    userValues.isInvalid &&
                    (userValues.partitionJumpers[partitionJumperIndex].partitionJumperThickness === 0 ||
                      userValues.partitionJumpers[partitionJumperIndex].partitionJumperThickness === null)
                      ? 'Обязательное поле'
                      : ''
                  }
                >
                  <InputNumberStyled
                    min={0}
                    size={'large'}
                    placeholder={brickAndBlocksSections.section5.partitions.jumpers.jumpersThickness.placeholder}
                    onChange={getHandleInputChange(
                      brickAndBlocksSections.section5.partitions.jumpers.jumpersThickness.name,
                    )}
                    value={
                      partitionJumperItem &&
                      (partitionJumperItem.partitionJumperThickness === 0
                        ? undefined
                        : partitionJumperItem.partitionJumperThickness)
                    }
                  />
                </FormItemStyled>
              </Control>
            </Space>
          </Col>
          <Col md={12} xs={24}>
            <Space size={20} direction={'vertical'}>
              <Control label={brickAndBlocksSections.section5.partitions.jumpers.jumpersCount.label}>
                <FormItemStyled
                  validateStatus={
                    userValues.isInvalid &&
                    (userValues.partitionJumpers[partitionJumperIndex].partitionJumperCount === 0 ||
                      userValues.partitionJumpers[partitionJumperIndex].partitionJumperCount === null)
                      ? 'error'
                      : ''
                  }
                  help={
                    userValues.isInvalid &&
                    (userValues.partitionJumpers[partitionJumperIndex].partitionJumperCount === 0 ||
                      userValues.partitionJumpers[partitionJumperIndex].partitionJumperCount === null)
                      ? 'Обязательное поле'
                      : ''
                  }
                >
                  <InputNumberStyled
                    min={0}
                    size={'large'}
                    placeholder={brickAndBlocksSections.section5.partitions.jumpers.jumpersCount.placeholder}
                    onChange={getHandleInputChange(
                      brickAndBlocksSections.section5.partitions.jumpers.jumpersCount.name,
                    )}
                    value={
                      partitionJumperItem &&
                      (partitionJumperItem.partitionJumperCount === 0
                        ? undefined
                        : partitionJumperItem.partitionJumperCount)
                    }
                  />
                </FormItemStyled>
              </Control>
            </Space>
          </Col>
        </Row>
      </Space>
    </Fragment>
  );
};

const TitleStyled = styled(Title)`
  text-transform: uppercase;
  letter-spacing: 0.04em !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
  margin-right: 95px;
`;

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`;

const FormItemStyled = styled(Form.Item)`
  margin-bottom: 0 !important;
`;

export default JumpersControls;
