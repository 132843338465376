import React, {useContext, Fragment, useEffect, useState} from 'react';
import {Row, Col, InputNumber, Select, Form} from 'antd';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import {Control, Space} from '~src/components';
import {LocaleContext} from '~src/context';
import {ITrim} from '~src/services/Calculation2Service';
import styled from 'styled-components';

import AdditionalMaterialPreview from '~src/components/molecules/AdditionalMaterialPreview';

interface ITrimControlsProps {
  trimIndex: number;
}

const TrimControls: React.FC<ITrimControlsProps> = ({trimIndex}) => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {materials} = useStoreon<IState, IEvents>('materials');
  const {userValues} = calculations[2];
  const {calculation2} = materials;
  const [trimMaterials, setTrimMaterials] = useState([{label: '', value: '', inputType: 0}]);
  const {
    pages: {
      calculation2: {brickAndBlocksSections},
    },
  } = useContext(LocaleContext);

  const handleTrimTypeChange = (e: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'trims',
      values: [
        {
          name: 'trimType',
          value: e,
          index: trimIndex,
        },
      ],
    });
    dispatch('calculations/2/set-group-values', {
      groupName: 'trims',
      values: [
        {
          name: 'trimThickness',
          value: null,
          index: trimIndex,
        },
      ],
    });
    dispatch('calculations/2/set-group-values', {
      groupName: 'trims',
      values: [
        {
          name: 'trimLength',
          value: null,
          index: trimIndex,
        },
      ],
    });
    dispatch('calculations/2/set-group-values', {
      groupName: 'trims',
      values: [
        {
          name: 'trimWidth',
          value: null,
          index: trimIndex,
        },
      ],
    });
    dispatch('calculations/2/set-group-values', {
      groupName: 'trims',
      values: [
        {
          name: 'trimHeight',
          value: null,
          index: trimIndex,
        },
      ],
    });
    dispatch('calculations/2/set-group-values', {
      groupName: 'trims',
      values: [
        {
          name: 'trimWeight',
          value: null,
          index: trimIndex,
        },
      ],
    });
    dispatch('materials/get-materials-by-id', {
      filterKey: 'trimType',
      filter_value: e,
      calculationType: 2,
    });
  };

  const handleTrimSizeChange = (e: string) => {
    let selectedMaterial = calculation2.trimType.materials.find(item => {
      return item.id === e;
    });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'trims',
        values: [{name: 'trimLength', value: selectedMaterial.trimLength, index: trimIndex}],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'trims',
        values: [{name: 'trimWidth', value: selectedMaterial.trimWidth, index: trimIndex}],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'trims',
        values: [{name: 'trimHeight', value: selectedMaterial.trimHeight, index: trimIndex}],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'trims',
        values: [{name: 'trimWeight', value: selectedMaterial.trimWeight, index: trimIndex}],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'trims',
        values: [{name: 'trimType', value: selectedMaterial.trimType, index: trimIndex}],
      });
  };

  const getHandleInputChange = (name: keyof ITrim | string) => (value: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'trims',
      values: [
        {
          name,
          value,
          index: trimIndex,
        },
      ],
    });
  };

  useEffect(() => {
    let materialLabels = [];
    materialLabels = calculation2.trimType.materials.map(item => {
      let materialLabel =
        item.name +
        ' ' +
        (item.trimLength !== null ? item.trimLength.toString() + 'x' : '') +
        (item.trimWidth !== null ? item.trimWidth.toString() + 'x' : '') +
        (item.trimHeight !== null ? item.trimHeight.toString() + 'мм, ' : '') +
        item.trimWeight.toString() +
        'кг';
      return {
        label: materialLabel,
        value: item.id,
        inputType: item.inputType,
      };
    });
    setTrimMaterials(materialLabels);
  }, [calculation2.trimType.materials]);

  return (
    <Fragment>
      <Row gutter={[20, 100]}>
        <Col md={6} sm={12} xs={24}>
          <Space size={20} direction="vertical">
            <Control label={brickAndBlocksSections.section5.trim.trimType.label}>
              <FormItemStyled
                validateStatus={
                  userValues.isInvalid &&
                  (userValues.trims[trimIndex].trimType === '' || userValues.trims[trimIndex].trimType === null)
                    ? 'error'
                    : ''
                }
                help={
                  userValues.isInvalid &&
                  (userValues.trims[trimIndex].trimType === '' || userValues.trims[trimIndex].trimType === null)
                    ? 'Обязательное поле'
                    : ''
                }
              >
                <Select
                  placeholder={brickAndBlocksSections.section5.trim.trimType.placeholder}
                  size={'large'}
                  options={calculation2.trimType.options}
                  onChange={handleTrimTypeChange}
                />
              </FormItemStyled>
            </Control>
            {trimMaterials.some(item => item.inputType === 1) ? (
              <Control label={brickAndBlocksSections.section5.trim.trimThickness.label}>
                <FormItemStyled
                  validateStatus={
                    userValues.isInvalid && userValues.trims[trimIndex].trimThickness === null ? 'error' : ''
                  }
                  help={
                    userValues.isInvalid && userValues.trims[trimIndex].trimThickness === null
                      ? 'Обязательное поле'
                      : ''
                  }
                >
                  <InputNumberStyled
                    min={0}
                    size="large"
                    placeholder={brickAndBlocksSections.section5.trim.trimThickness.placeholder}
                    onChange={getHandleInputChange(brickAndBlocksSections.section5.trim.trimThickness.name)}
                  />
                </FormItemStyled>
              </Control>
            ) : (
              <Control label={brickAndBlocksSections.section5.trim.trimSize.label}>
                <FormItemStyled
                  validateStatus={
                    userValues.isInvalid &&
                    (userValues.trims[trimIndex].trimHeight === 0 ||
                      userValues.trims[trimIndex].trimWeight === 0 ||
                      userValues.trims[trimIndex].trimWidth === 0 ||
                      userValues.trims[trimIndex].trimLength === 0 ||
                      userValues.trims[trimIndex].trimHeight === null ||
                      userValues.trims[trimIndex].trimWeight === null ||
                      userValues.trims[trimIndex].trimWidth === null ||
                      userValues.trims[trimIndex].trimLength === null)
                      ? 'error'
                      : ''
                  }
                  help={
                    userValues.isInvalid &&
                    (userValues.trims[trimIndex].trimHeight === 0 ||
                      userValues.trims[trimIndex].trimWeight === 0 ||
                      userValues.trims[trimIndex].trimWidth === 0 ||
                      userValues.trims[trimIndex].trimLength === 0 ||
                      userValues.trims[trimIndex].trimHeight === null ||
                      userValues.trims[trimIndex].trimWeight === null ||
                      userValues.trims[trimIndex].trimWidth === null ||
                      userValues.trims[trimIndex].trimLength === null)
                      ? 'Обязательное поле'
                      : ''
                  }
                >
                  <Select
                    placeholder={brickAndBlocksSections.section5.trim.trimSize.placeholder}
                    size={'large'}
                    options={trimMaterials}
                    onChange={handleTrimSizeChange}
                  />
                </FormItemStyled>
              </Control>
            )}
          </Space>
        </Col>
        <ColStyled md={6} sm={24} xs={24}>
          <AdditionalMaterialPreview sectionToRender="trim" />
        </ColStyled>
      </Row>
    </Fragment>
  );
};

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`;

const ColStyled = styled(Col)`
  @media screen and (min-width: 1024px) {
    margin-left: 260px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    margin-left: 160px;
  }
  @media screen and (min-width: 321px) and (max-width: 767px) {
    margin-left: 0px;
  }
`;

const FormItemStyled = styled(Form.Item)`
  margin-bottom: 0 !important;
`;
export default TrimControls;
