import React, {useState} from 'react';
import {Button, Input, Space} from 'antd';
import styled from 'styled-components';

const {TextArea} = Input;

export interface ISubmitCommentEvent {
  userName: string;
  message: string;
}

interface ICommentsProps {
  isSending: boolean;
  onSubmit: (event: ISubmitCommentEvent) => void;
}

const Comments: React.FC<ICommentsProps> = ({onSubmit, isSending}) => {
  const [userName, setUserName] = useState('');
  const [message, setMessage] = useState('');
  const handleChangeUserName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  };
  const handleChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };
  const handleClick = () => {
    onSubmit({userName, message});
  };
  return (
    <SpaceStyled direction={'vertical'} size={16}>
      <InputStyled placeholder={'Ваше имя'} value={userName} disabled={isSending} onChange={handleChangeUserName} />
      <TextArea
        rows={4}
        placeholder={'Ваш комментарий'}
        value={message}
        disabled={isSending}
        onChange={handleChangeComment}
      />
      <Button
        type={'primary'}
        disabled={!userName || !message}
        loading={isSending}
        size={'large'}
        onClick={handleClick}
      >
        Добавить комментарий
      </Button>
    </SpaceStyled>
  );
};

const SpaceStyled = styled(props => <Space {...props} />)`
  width: 100%;
`;

const InputStyled = styled(Input)`
  width: auto;
`;

export default Comments;
