import React, {Fragment, useContext, useState} from 'react';
import styled from 'styled-components';
import {LocaleContext} from '~src/context';
import {Calculation2Types} from '~src/utils/calculations';

import {Typography, Row, Col, Tooltip, Button} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import {Space} from '~src/components';

import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import Calculation2Brick from './Brick';
import Calculation2Balk from './Balk';

const {Title, Paragraph} = Typography;

interface IContentProps {}

interface IStyledButtonContentProps {
  image: {
    defaultImage: string;
    imageSelected: string;
  };
  isSelected: boolean;
}

interface IStyledButtonProps {
  isSelected: boolean;
}

interface IStyledTitleProps {
  isSelected: boolean;
}

const Calculation2Organism: React.FC<IContentProps> = () => {
  const {dispatch} = useStoreon<IState, IEvents>('calculations');
  const {
    pages: {
      calculation2: {header1, description, section0, descriptionButton},
    },
  } = useContext(LocaleContext);
  const [calculationType, setCalculationType] = useState<Calculation2Types>(Calculation2Types.brickAndBlocks);
  const [isShowLearnMore, setShowLearnMore] = useState(false);

  const getHandlerClickAtCalculationTypeButton = (calculationType: Calculation2Types) => () => {
    setCalculationType(calculationType);
    dispatch('calculations/3/set-group-values', {
      groupName: 'bearingWalls',
      values: [
        {
          name: 'balkType',
          value: '',
          index: 0,
        },
      ],
    });
    dispatch('calculations/2/set-group-values', {
      groupName: 'bearingWalls',
      values: [
        {
          name: 'brickType',
          value: '',
          index: 0,
        },
      ],
    });
    dispatch('calculations/2/set-group-values', {
      groupName: 'gables',
      values: [
        {
          name: 'gableType',
          value: 0,
          index: 0,
        },
      ],
    });
    dispatch('calculations/3/set-group-values', {
      groupName: 'gables',
      values: [
        {
          name: 'gableType',
          value: 0,
          index: 0,
        },
      ],
    });
  };
  const handleShowLearnMore = () => {
    setShowLearnMore(!isShowLearnMore);
  };

  return (
    <Fragment>
      <Space size={100} direction={'vertical'}>
        <Row align="middle" justify="center">
          <Col xs={23} md={24}>
            <Space size={64} direction="vertical">
              <Row>
                <Col span={24}>
                  <Title level={1}>{header1}</Title>
                  <Paragraph>{isShowLearnMore ? description[1] : description[0]}</Paragraph>
                  {isShowLearnMore && (
                    <div>
                      <Title level={2}>{description[2]}</Title>
                      <Paragraph>{description[3]}</Paragraph>
                      <ul>
                        {(description[4] as any).map((item: string, index: number) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                      <Paragraph>{description[5]}</Paragraph>
                      <Paragraph>{description[6]}</Paragraph>
                      <Title level={2}>{description[7]}</Title>
                      <Paragraph>{description[8]}</Paragraph>
                      <ul>
                        {(description[9] as any).map((item: string, index: number) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                      <Paragraph>{description[10]}</Paragraph>
                      <Paragraph>{description[11]}</Paragraph>
                      <ol>
                        {(description[12] as any).map((item: string, index: number) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ol>
                      <Paragraph>{description[13]}</Paragraph>
                      <Title level={2}>{description[14]}</Title>
                      <Paragraph>{description[15]}</Paragraph>
                    </div>
                  )}
                  <ButtonStyled type="link" onClick={() => handleShowLearnMore()}>
                    {isShowLearnMore ? descriptionButton.showLess : descriptionButton.showMore}
                  </ButtonStyled>
                </Col>
              </Row>
              <Row>
                <Col md={24} sm={24}>
                  <Title level={2}>{section0.title}</Title>
                  <Row gutter={20}>
                    <Col key={section0.types[0].name} md={12} sm={12} xs={24}>
                      <CalculationTypeButtonStyled
                        onClick={getHandlerClickAtCalculationTypeButton(section0.types[0].name)}
                        isSelected={section0.types[0].name === calculationType}
                      >
                        <CalculationButtonContentStyled
                          image={section0.types[0].image}
                          isSelected={section0.types[0].name === calculationType}
                        >
                          <TitleStyled level={3} isSelected={section0.types[0].name === calculationType}>
                            {section0.types[0].title}
                          </TitleStyled>
                          <Tooltip
                            placement="bottomLeft"
                            title={section0.types[0].tooltipText}
                            overlayStyle={{maxWidth: 200, width: '95%'}}
                          >
                            <InfoCircleOutlined />
                            <span> Информация</span>
                          </Tooltip>
                        </CalculationButtonContentStyled>
                      </CalculationTypeButtonStyled>
                    </Col>
                    <Col key={section0.types[1].name} md={12} sm={12} xs={24}>
                      <CalculationTypeButtonStyled
                        onClick={getHandlerClickAtCalculationTypeButton(section0.types[1].name)}
                        isSelected={section0.types[1].name === calculationType}
                      >
                        <CalculationButton2ContentStyled
                          image={section0.types[1].image}
                          isSelected={section0.types[1].name === calculationType}
                        >
                          <TitleStyled level={3} isSelected={section0.types[1].name === calculationType}>
                            {section0.types[1].title}
                          </TitleStyled>
                          <Tooltip
                            placement="bottomLeft"
                            title={section0.types[1].tooltipText}
                            overlayStyle={{maxWidth: 200, width: '95%'}}
                          >
                            <InfoCircleOutlined />
                            <span> Информация</span>
                          </Tooltip>
                        </CalculationButton2ContentStyled>
                      </CalculationTypeButtonStyled>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {calculationType === Calculation2Types.brickAndBlocks && <Calculation2Brick />}
              {calculationType === Calculation2Types.timber && <Calculation2Balk />}
            </Space>
          </Col>
        </Row>
      </Space>
    </Fragment>
  );
};

const CalculationTypeButtonStyled = styled.div<IStyledButtonProps>`
  width: 100%;
  height: 170px;
  border-radius: 10px;
  background: #f5f5f5;
  color: black;
  transition: all 0.2s ease-out;

  ${({isSelected}) =>
    isSelected &&
    `
    background: #096DD9;
    color: white;
  `}

  @media screen and (max-width: 640px) {
    height: 142px;
  }
`;

const CalculationButtonContentStyled = styled.div<IStyledButtonContentProps>`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: space-between;
  text-align: left;
  max-height: inherit;
  height: 95%;
  padding: 32px;
  background: url(${props => (props.isSelected ? props.image.imageSelected : props.image.defaultImage)}) no-repeat;
  background-position-x: 90%;
  background-position-y: 50%;
  background-size: auto 110px;

  @media screen and (max-width: 640px) {
    padding: 16px;
    background-size: auto 75px;
    background-position-x: 95%;
    background-position-y: 60%;
  }
`;

const CalculationButton2ContentStyled = styled.div<IStyledButtonContentProps>`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: space-between;
  text-align: left;
  max-height: inherit;
  height: 95%;
  padding: 32px;
  background: url(${props => (props.isSelected ? props.image.imageSelected : props.image.defaultImage)}) no-repeat;
  background-position-x: 90%;
  background-position-y: 50%;
  background-size: auto 110px;

  @media screen and (max-width: 640px) {
    padding: 16px;
    background-size: auto 85px;
    background-position-x: 95%;
    background-position-y: 50%;
  }
`;

const TitleStyled = styled(Title)<IStyledTitleProps>`
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.2s ease-out;
  ${({isSelected}) =>
    isSelected &&
    `
    color: white !important;
  `}

  @media screen and (max-width: 640px) {
    font-size: 20px !important;
  }

  @media screen and (min-width: 641px) and (max-width: 768px) {
    width: 50%;
  }
`;

const ButtonStyled = styled(Button)`
  padding-left: 0;
`;

export default Calculation2Organism;
