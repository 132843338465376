import React, {Fragment, useContext, useState, useEffect} from 'react';
import styled from 'styled-components';
import {LocaleContext} from '~src/context';
import {Doors, Gables, Windows} from './AdditionalSections/index';
import ResultsBalk from './Results';

import {Typography, Row, Col, Select, InputNumber, Collapse, Switch, Button, Form, message} from 'antd';
import Space from '~src/components/molecules/Space';
import {Control} from '~src/components';
import InputSelect from '~src/components/molecules/InputSelect';

import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import calculation3Service, {IBalkBearingWall} from '~src/services/Calculation3Service';

import bearingWallBalkProfiled from '~src/services/ImageRenderService/images/bearingWall_balk_profiled.png';
import bearingWallBalkRectangular from '~src/services/ImageRenderService/images/bearingWall_balk_rect.png';
import bearingWallBalkLog from '~src/services/ImageRenderService/images/bearingWall_log.png';

const {Title} = Typography;
const {Panel} = Collapse;

interface IContentProps {}

declare global {
  interface Window {
    ym: any;
  }
}

const Calculation2Balk: React.FC<IContentProps> = () => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {materials} = useStoreon<IState, IEvents>('materials');
  const {enabledFlags} = calculations[3].userValues;
  const {userValues} = calculations[3];
  const {calculation2} = materials;
  const [showCalculation, setShowCalculation] = useState(false);
  const [resultValues, setResultValues] = useState({});
  const [isValidating, setIsValidating] = useState(false);
  const [formIsInvalid, setFormIsInvalid] = useState(false);
  const {
    pages: {
      calculation2: {brickAndBlocksSections, timberSections},
    },
  } = useContext(LocaleContext);

  let resultImage = '';

  const setEnabled = (key: string | string[]) => {
    dispatch('calculations/3/set-values', [{name: 'enabledFlags', value: key}]);
    if (calculations[3].userValues.windows.length === 0) {
      dispatch('calculations/3/add-item', {itemName: 'windows'});
    } else if (calculations[3].userValues.doors.length === 0) {
      dispatch('calculations/3/add-item', {itemName: 'doors'});
    } else if (calculations[3].userValues.gables.length === 0) {
      dispatch('calculations/3/add-item', {itemName: 'gables'});
    }
  };

  const collapseToggle = (panelKey: string) => {
    const isSwitchChecked = enabledFlags.includes(panelKey);
    return <Switch checked={isSwitchChecked} />;
  };

  const getHandleInputChange = (name: keyof IBalkBearingWall | string) => (value: number) => {
    dispatch('calculations/3/set-group-values', {
      groupName: 'bearingWalls',
      values: [
        {
          name,
          value,
          index: 0,
        },
      ],
    });
  };

  const handleDiameterInputChange = () => (value: number) => {
    dispatch('calculations/3/set-group-values', {
      groupName: 'bearingWalls',
      values: [
        {
          name: 'balkWidth',
          value,
          index: 0,
        },
      ],
    });
    dispatch('calculations/3/set-group-values', {
      groupName: 'bearingWalls',
      values: [
        {
          name: 'balkHeight',
          value,
          index: 0,
        },
      ],
    });
  };

  const handleTypeChange = (e: number) => {
    dispatch('calculations/3/set-group-values', {
      groupName: 'bearingWalls',
      values: [
        {
          name: 'balkType',
          value: e,
          index: 0,
        },
      ],
    });
  };

  const handleDensityOptionChange = (e: number) => {
    dispatch('calculations/3/set-group-values', {
      groupName: 'bearingWalls',
      values: [
        {
          name: 'balkDensity',
          value: e,
          index: 0,
        },
      ],
    });
  };

  const determineBalkPreview = () => {
    switch (userValues.bearingWalls[0].balkType) {
      case 'Брус прямоугольного сечения':
      case 'Брус клееный':
        return bearingWallBalkRectangular;
      case 'Брус профилированный':
        return bearingWallBalkProfiled;
      case 'Бревно оцилиндрованное':
        return bearingWallBalkLog;
      default:
        return bearingWallBalkRectangular;
    }
  };

  useEffect(() => {
    if (calculations[3].userValues.windows.length === 0) {
      const removedIndex = enabledFlags.indexOf('balkWindows');
      if (removedIndex !== -1) {
        dispatch('calculations/3/remove-item', {itemName: 'enabledFlags', index: removedIndex});
      }
    }
    if (calculations[3].userValues.doors.length === 0) {
      const removedIndex = enabledFlags.indexOf('balkDoors');
      if (removedIndex !== -1) {
        dispatch('calculations/3/remove-item', {itemName: 'enabledFlags', index: removedIndex});
      }
    }
    if (calculations[3].userValues.gables.length === 0) {
      const removedIndex = enabledFlags.indexOf('balkGables');
      if (removedIndex !== -1) {
        dispatch('calculations/3/remove-item', {itemName: 'enabledFlags', index: removedIndex});
      }
    }
  });

  const [bigForm] = Form.useForm();
  const requiredRule = {required: true};

  const handleClickAtCalculateButton = async () => {
    window.ym(
      68282989,
      'reachGoal',
      '%3cbutton+type%3d%22button%22+class%3d%22ant-btn+ant-btn-primary%22+ant-click-animating-without-extra-node%3d%22false%22%3e%3cspan%3e%d0%a0%d0%b0%d1%81%d1%81%d1%87%d0%b8%d1%82%d0%b0%d1%82%d1%8c%3c%2fspan%3e%3c%2fbutton%3e',
    );

    let formIsInvalid = false;
    userValues.windows.map(additionalItem => {
      for (let property in additionalItem) {
        if (userValues.enabledFlags.find(item => item === 'balkWindows')) {
          if (additionalItem[property] === 0 || additionalItem[property] === null) {
            formIsInvalid = true;
          }
        }
      }
    });

    userValues.doors.map(additionalItem => {
      for (let property in additionalItem) {
        if (userValues.enabledFlags.find(item => item === 'balkDoors')) {
          if (additionalItem[property] === 0 || additionalItem[property] === null) {
            formIsInvalid = true;
          }
        }
      }
    });

    userValues.gables.map(additionalItem => {
      if (userValues.enabledFlags.find(item => item === 'balkGables')) {
        if (additionalItem.gableCount === 0) {
          formIsInvalid = true;
        }
        switch (additionalItem.gableType) {
          case 0:
            if (additionalItem.gableHeight === 0 || additionalItem.gableWidth === 0) {
              formIsInvalid = true;
            }
            break;
          case 1:
            if (
              additionalItem.gableHeight === 0 ||
              additionalItem.gableSmallerWidth === 0 ||
              additionalItem.gableBiggerWidth === 0
            ) {
              formIsInvalid = true;
            }
            break;
          case 2:
            if (
              additionalItem.gableSmallerWidth === 0 ||
              additionalItem.gableBiggerWidth === 0 ||
              additionalItem.gablePentaTriangleHeight === 0 ||
              additionalItem.gablePentaHeightBetween === 0
            ) {
              formIsInvalid = true;
            }
            break;
        }
      }
    });
    setIsValidating(true);
    if (formIsInvalid) {
      dispatch('calculations/3/set-values', [{name: 'isInvalid', value: true}]);
      setShowCalculation(false);
      message.error('Необходимо заполнить все обязательные поля');
    } else {
      dispatch('calculations/3/set-values', [{name: 'isInvalid', value: false}]);
      await bigForm
        .validateFields()
        .then(async () => {
          bigForm.submit();
          setShowCalculation(true);
          dispatch('calculations/3/calculate');
          setResultValues(calculation3Service.calculate(calculations[3].userValues));
        })
        .catch(() => {
          message.error('Необходимо заполнить все обязательные поля');
        });
    }
  };
  const validateMessages = {
    required: 'Обязательное поле',
    types: {
      number: 'Это должно быть число',
    },
  };

  return (
    <Fragment>
      <SpaceStyled size={20} direction={'vertical'}>
        <Form form={bigForm} validateMessages={validateMessages}>
          <Row gutter={[20, 20]} align="top">
            <Col span={24}>
              <Title level={2}>{timberSections.section1.title}</Title>
            </Col>
            <ContentWrapperStyled>
              <Col md={10} sm={12} xs={24}>
                <Row>
                  <Col span={24}>
                    <Row gutter={[20, 20]}>
                      <Col span={24}>
                        <Control label={timberSections.section1.timberMaterial.label}>
                          <Form.Item name={timberSections.section1.timberMaterial.label} rules={[requiredRule]}>
                            <Select
                              size={'large'}
                              placeholder={timberSections.section1.timberMaterial.label}
                              options={calculation2.balkType.options}
                              onChange={handleTypeChange}
                            />
                          </Form.Item>
                        </Control>
                      </Col>
                      <Col
                        md={userValues.bearingWalls[0].balkType !== 'Бревно оцилиндрованное' ? 12 : 24}
                        sm={24}
                        xs={24}
                      >
                        {userValues.bearingWalls[0].balkType !== 'Бревно оцилиндрованное' ? (
                          <Control label={timberSections.section1.timberWidth.label}>
                            <Form.Item name={timberSections.section1.timberWidth.label} rules={[requiredRule]}>
                              <InputNumberStyled
                                min={0}
                                size={'large'}
                                placeholder={timberSections.section1.timberWidth.placeholder}
                                onChange={getHandleInputChange(timberSections.section1.timberWidth.name)}
                              />
                            </Form.Item>
                          </Control>
                        ) : (
                          <Control label={timberSections.section1.timberDiameter.label}>
                            <Form.Item name={timberSections.section1.timberDiameter.label} rules={[requiredRule]}>
                              <InputNumberStyled
                                min={0}
                                size={'large'}
                                placeholder={timberSections.section1.timberDiameter.placeholder}
                                onChange={handleDiameterInputChange()}
                              />
                            </Form.Item>
                          </Control>
                        )}
                      </Col>
                      {userValues.bearingWalls[0].balkType !== 'Бревно оцилиндрованное' ? (
                        <Col md={12} sm={24} xs={24}>
                          <Control label={timberSections.section1.timberLength.label}>
                            <Form.Item name={timberSections.section1.timberLength.label} rules={[requiredRule]}>
                              <InputNumberStyled
                                min={0}
                                size={'large'}
                                placeholder={timberSections.section1.timberLength.placeholder}
                                onChange={getHandleInputChange(timberSections.section1.timberLength.name)}
                              />
                            </Form.Item>
                          </Control>
                        </Col>
                      ) : (
                        ''
                      )}
                      <Col md={12} sm={24} xs={24}>
                        <Control label={timberSections.section1.timberWallsLength.label}>
                          <Form.Item name={timberSections.section1.timberWallsLength.label} rules={[requiredRule]}>
                            <InputNumberStyled
                              min={0}
                              size={'large'}
                              placeholder={timberSections.section1.timberWallsLength.placeholder}
                              onChange={getHandleInputChange(timberSections.section1.timberWallsLength.name)}
                            />
                          </Form.Item>
                        </Control>
                      </Col>
                      <Col md={12} sm={24} xs={24}>
                        <Control label={timberSections.section1.timberHeight.label}>
                          <Form.Item name={timberSections.section1.timberHeight.label} rules={[requiredRule]}>
                            <InputNumberStyled
                              min={0}
                              size={'large'}
                              placeholder={timberSections.section1.timberHeight.placeholder}
                              onChange={getHandleInputChange(timberSections.section1.timberHeight.name)}
                            />
                          </Form.Item>
                        </Control>
                      </Col>
                      <Col md={24} sm={24} xs={24}>
                        <Control label={timberSections.section1.timberDensity.label}>
                          <Form.Item
                            validateStatus={isValidating && userValues.bearingWalls[0].balkDensity === 0 ? 'error' : ''}
                            help={
                              isValidating && userValues.bearingWalls[0].balkDensity === 0 ? 'Обязательное поле' : ''
                            }
                          >
                            <InputSelect
                              inputProps={{
                                size: 'large',
                                placeholder: timberSections.section1.timberDensity.placeholder,
                                inputButton: 'Выбрать значение',
                                min: 0,
                              }}
                              selectProps={{
                                size: 'large',
                                placeholder: timberSections.section1.timberDensity.placeholder,
                                options: timberSections.section1.timberDensity.values,
                                selectButton: 'Ввести значение',
                              }}
                              onInputChange={getHandleInputChange(timberSections.section1.timberDensity.name)}
                              onSelectChange={handleDensityOptionChange}
                            />
                          </Form.Item>
                        </Control>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col md={14} sm={12} xs={24}>
                <ImageBox>
                  <Image src={determineBalkPreview()} alt="" />
                </ImageBox>
              </Col>
            </ContentWrapperStyled>
          </Row>
          <Row>
            <Space size={20} direction="vertical">
              <Col span={24}>
                <Title level={2}>{timberSections.section2.title}</Title>
                <CollapseStyled
                  bordered={false}
                  expandIconPosition="right"
                  onChange={setEnabled}
                  activeKey={enabledFlags}
                >
                  <Panel
                    header="Окна"
                    key="balkWindows"
                    className="collapse-custom-panel"
                    extra={collapseToggle('balkWindows')}
                    showArrow={false}
                  >
                    <Windows />
                  </Panel>
                  <Panel
                    header="Двери"
                    key="balkDoors"
                    className="collapse-custom-panel"
                    extra={collapseToggle('balkDoors')}
                    showArrow={false}
                  >
                    <Doors />
                  </Panel>
                  <Panel
                    header="Фронтоны"
                    key="balkGables"
                    className="collapse-custom-panel"
                    extra={collapseToggle('balkGables')}
                    showArrow={false}
                  >
                    <Gables />
                  </Panel>
                </CollapseStyled>
              </Col>
              <Col span={24}>
                <Space size={140} direction="vertical">
                  <Button type="primary" onClick={handleClickAtCalculateButton}>
                    Рассчитать
                  </Button>
                  {showCalculation && <ResultsBalk values={resultValues} />}
                </Space>
              </Col>
            </Space>
          </Row>
        </Form>
      </SpaceStyled>
    </Fragment>
  );
};

const ImageBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
const Image = styled.img`
  width: 100%;
`;

const CollapseStyled = styled(Collapse)`
  background: #fafafa;
  border: none;
  padding: 0 !important;

  .ant-collapse-header {
    /* margin-bottom: 0.5em; */
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;
    border-top: 1px solid #e8e8e8;
    margin-top: 0 - 1px;
    border-bottom: 1px solid #e8e8e8;
    padding: 16px 25px !important;
  }

  .ant-collapse-content-box {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff !important;
    padding: 20px 25px !important;
  }

  .collapse-custom-panel {
    border: none !important;
  }
`;

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`;

const ContentWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;

  @media screen and (max-width: 320px) {
    flex-direction: column-reverse;
  }
`;

const SpaceStyled = styled(props => <Space {...props} />)`
  margin-bottom: 50px;
`;

export default Calculation2Balk;
