import React, {useContext, Fragment} from 'react';
import {Row, Col, InputNumber, Typography, Form} from 'antd';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import {CloseOutlined} from '@ant-design/icons';

import {Control, Space} from '~src/components';
import {LocaleContext} from '~src/context';
import {IDoor} from '~src/services/Calculation2Service';

const {Title} = Typography;

interface IDoorsControlsProps {
  doorIndex: number;
  doorItem: IDoor;
}

const DoorsControls: React.FC<IDoorsControlsProps> = ({doorIndex, doorItem}) => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {
    pages: {
      calculation2: {brickAndBlocksSections},
    },
  } = useContext(LocaleContext);

  const {userValues} = calculations[2];

  const getHandleInputChange = (name: keyof IDoor | string) => (value: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'doors',
      values: [
        {
          name,
          value,
          index: doorIndex,
        },
      ],
    });
  };

  const handleRemoveDoor = () => {
    dispatch('calculations/2/remove-item', {itemName: 'doors', index: doorIndex});
  };
  return (
    <Fragment>
      <Space size={10} direction="vertical">
        <Row align="middle">
          <TitleStyled level={4}>Дверь {doorIndex + 1}</TitleStyled>
          <CloseOutlined onClick={handleRemoveDoor} />
        </Row>
        <Col md={24} sm={12}>
          <Row gutter={[20, 20]}>
            <Col md={12} sm={24} xs={24}>
              <Space size={20} direction={'vertical'}>
                <Control label={brickAndBlocksSections.section5.doors.doorWidth.label}>
                  <FormItemStyled
                    validateStatus={
                      userValues.isInvalid &&
                      (userValues.doors[doorIndex].doorWidth === 0 || userValues.doors[doorIndex].doorWidth === null)
                        ? 'error'
                        : ''
                    }
                    help={
                      userValues.isInvalid &&
                      (userValues.doors[doorIndex].doorWidth === 0 || userValues.doors[doorIndex].doorWidth === null)
                        ? 'Обязательное поле'
                        : ''
                    }
                  >
                    <InputNumberStyled
                      min={0}
                      size={'large'}
                      placeholder={brickAndBlocksSections.section5.doors.doorWidth.placeholder}
                      onChange={getHandleInputChange(brickAndBlocksSections.section5.doors.doorWidth.name)}
                      value={doorItem && (doorItem.doorWidth === 0 ? undefined : doorItem.doorWidth)}
                    />
                  </FormItemStyled>
                </Control>
                <Control label={brickAndBlocksSections.section5.doors.doorHeight.label}>
                  <FormItemStyled
                    validateStatus={
                      userValues.isInvalid &&
                      (userValues.doors[doorIndex].doorHeight === 0 || userValues.doors[doorIndex].doorHeight === null)
                        ? 'error'
                        : ''
                    }
                    help={
                      userValues.isInvalid &&
                      (userValues.doors[doorIndex].doorHeight === 0 || userValues.doors[doorIndex].doorHeight === null)
                        ? 'Обязательное поле'
                        : ''
                    }
                  >
                    <InputNumberStyled
                      min={0}
                      size={'large'}
                      placeholder={brickAndBlocksSections.section5.doors.doorHeight.placeholder}
                      onChange={getHandleInputChange(brickAndBlocksSections.section5.doors.doorHeight.name)}
                      value={doorItem && (doorItem.doorHeight === 0 ? undefined : doorItem.doorHeight)}
                    />
                  </FormItemStyled>
                </Control>
              </Space>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Control label={brickAndBlocksSections.section5.doors.doorCount.label}>
                <FormItemStyled
                  validateStatus={
                    userValues.isInvalid &&
                    (userValues.doors[doorIndex].doorCount === 0 || userValues.doors[doorIndex].doorCount === null)
                      ? 'error'
                      : ''
                  }
                  help={
                    userValues.isInvalid &&
                    (userValues.doors[doorIndex].doorCount === 0 || userValues.doors[doorIndex].doorCount === null)
                      ? 'Обязательное поле'
                      : ''
                  }
                >
                  <InputNumberStyled
                    min={0}
                    size={'large'}
                    placeholder={brickAndBlocksSections.section5.doors.doorCount.placeholder}
                    onChange={getHandleInputChange(brickAndBlocksSections.section5.doors.doorCount.name)}
                    value={doorItem && (doorItem.doorCount === 0 ? undefined : doorItem.doorCount)}
                  />
                </FormItemStyled>
              </Control>
            </Col>
          </Row>
        </Col>
      </Space>
    </Fragment>
  );
};

const TitleStyled = styled(Title)`
  text-transform: uppercase;
  letter-spacing: 0.04em !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
  margin-right: 95px;
`;

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`;

const FormItemStyled = styled(Form.Item)`
  margin-bottom: 0 !important;
`;

export default DoorsControls;
