import React, {useContext, Fragment} from 'react';
import {Row, Col, InputNumber, Typography, Form} from 'antd';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import {CloseOutlined} from '@ant-design/icons';

import {Control, Space} from '~src/components';
import {LocaleContext} from '~src/context';
import {IWindow} from '~src/services/Calculation3Service';

import AdditionalMaterialPreview from '~src/components/molecules/AdditionalMaterialPreview';

const {Title} = Typography;

interface IWindowsControlsProps {
  windowIndex: number;
  windowItem: IWindow;
}

const WindowsControls: React.FC<IWindowsControlsProps> = ({windowIndex, windowItem}) => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {
    pages: {
      calculation2: {brickAndBlocksSections},
    },
  } = useContext(LocaleContext);

  const {userValues} = calculations[3];

  const getHandleInputChange = (name: keyof IWindow | string) => (value: number) => {
    dispatch('calculations/3/set-group-values', {
      groupName: 'windows',
      values: [
        {
          name,
          value,
          index: windowIndex,
        },
      ],
    });
  };

  const handleRemoveWindow = () => {
    dispatch('calculations/3/remove-item', {itemName: 'windows', index: windowIndex});
  };

  return (
    <Fragment>
      <Space size={10} direction="vertical">
        <Row align="middle">
          <TitleStyled level={4}>Окно {windowIndex + 1}</TitleStyled>
          <CloseOutlined onClick={handleRemoveWindow} />
        </Row>
        <Col sm={12} md={24}>
          <Row gutter={[20, 20]}>
            <Col md={12} sm={24} xs={24}>
              <Space size={20} direction="vertical">
                <Control label={brickAndBlocksSections.section5.windows.windowHeight.label}>
                  <FormItemStyled
                    validateStatus={
                      userValues.isInvalid &&
                      (userValues.windows[windowIndex].windowHeight === 0 ||
                        userValues.windows[windowIndex].windowHeight === null)
                        ? 'error'
                        : ''
                    }
                    help={
                      userValues.isInvalid &&
                      (userValues.windows[windowIndex].windowHeight === 0 ||
                        userValues.windows[windowIndex].windowHeight === null)
                        ? 'Обязательное поле'
                        : ''
                    }
                  >
                    <InputNumberStyled
                      min={0}
                      size={'large'}
                      placeholder={brickAndBlocksSections.section5.windows.windowHeight.placeholder}
                      onChange={getHandleInputChange(brickAndBlocksSections.section5.windows.windowHeight.name)}
                      value={windowItem && (windowItem.windowHeight === 0 ? undefined : windowItem.windowHeight)}
                    />
                  </FormItemStyled>
                </Control>
                <Control label={brickAndBlocksSections.section5.windows.windowWidth.label}>
                  <FormItemStyled
                    validateStatus={
                      userValues.isInvalid &&
                      (userValues.windows[windowIndex].windowWidth === 0 ||
                        userValues.windows[windowIndex].windowWidth === null)
                        ? 'error'
                        : ''
                    }
                    help={
                      userValues.isInvalid &&
                      (userValues.windows[windowIndex].windowWidth === 0 ||
                        userValues.windows[windowIndex].windowWidth === null)
                        ? 'Обязательное поле'
                        : ''
                    }
                  >
                    <InputNumberStyled
                      min={0}
                      size="large"
                      placeholder={brickAndBlocksSections.section5.windows.windowWidth.placeholder}
                      onChange={getHandleInputChange(brickAndBlocksSections.section5.windows.windowWidth.name)}
                      value={windowItem && (windowItem.windowWidth === 0 ? undefined : windowItem.windowWidth)}
                    />
                  </FormItemStyled>
                </Control>
              </Space>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Space size={20} direction={'vertical'}>
                <Control label={brickAndBlocksSections.section5.windows.windowCount.label}>
                  <FormItemStyled
                    validateStatus={
                      userValues.isInvalid &&
                      (userValues.windows[windowIndex].windowCount === 0 ||
                        userValues.windows[windowIndex].windowCount === null)
                        ? 'error'
                        : ''
                    }
                    help={
                      userValues.isInvalid &&
                      (userValues.windows[windowIndex].windowCount === 0 ||
                        userValues.windows[windowIndex].windowCount === null)
                        ? 'Обязательное поле'
                        : ''
                    }
                  >
                    <InputNumberStyled
                      min={0}
                      size="large"
                      placeholder={brickAndBlocksSections.section5.windows.windowCount.placeholder}
                      onChange={getHandleInputChange(brickAndBlocksSections.section5.windows.windowCount.name)}
                      value={windowItem && (windowItem.windowCount === 0 ? undefined : windowItem.windowCount)}
                    />
                  </FormItemStyled>
                </Control>
              </Space>
            </Col>
          </Row>
        </Col>
      </Space>
    </Fragment>
  );
};

const TitleStyled = styled(Title)`
  text-transform: uppercase;
  letter-spacing: 0.04em !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
  margin-right: 95px;
`;

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`;

const FormItemStyled = styled(Form.Item)`
  margin-bottom: 0 !important;
`;

export default WindowsControls;
