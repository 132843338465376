import React, {useState} from 'react';
import {Collapse as AntdCollapse} from 'antd';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import styled from 'styled-components';

const {Panel} = AntdCollapse;

interface ICollapseProps {
  onChange: (key: string) => void;
}

const Collapse: React.FC<ICollapseProps> = ({children, onChange}) => {
  const [selectedFirstLevelKey, setSelectedFirstLevelKey] = useState('');
  const {
    materials: {groupedValues},
  } = useStoreon<IState, IEvents>('materials');

  return (
    <AntdCollapse
      style={{width: '100%'}}
      accordion={true}
      onChange={key => {
        setSelectedFirstLevelKey(key as string);
      }}
    >
      {Object.keys(groupedValues).map(key => {
        return (
          <Panel key={key} header={key}>
            <InnerCollapseStyled
              accordion={true}
              onChange={key => {
                onChange(`${selectedFirstLevelKey}--${key}`);
              }}
            >
              {Object.keys(groupedValues[key]).map(groupKey => (
                <Panel key={groupKey} header={groupKey}>
                  {children}
                </Panel>
              ))}
            </InnerCollapseStyled>
          </Panel>
        );
      })}
    </AntdCollapse>
  );
};

const InnerCollapseStyled = styled(AntdCollapse)`
  .ant-collapse-content-box {
    padding: 0;
  }
`;

export default Collapse;
