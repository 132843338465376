import React, {useContext} from 'react';
import {Col, Row, Typography, Divider} from 'antd';
import styled from 'styled-components';
import {LocaleContext} from '~src/context';

const Contacts: React.FC = () => {
  const {
    pages: {contacts},
  } = useContext(LocaleContext);
  return (
    <Row>
      <Col span={24}>
        <Typography.Title level={1}>{contacts.header1}</Typography.Title>
      </Col>

      <Col span={24}>
        <Row gutter={[0, 4]} itemScope itemType="//schema.org/Organization">
          <Col span={24}>
            <Typography.Text strong>
              Телефон:{' '}
              <LinkStyled href="tel:+79250439944" itemProp="telephone">
                +7 (925) 043 99 44
              </LinkStyled>
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text strong>
              Email:{' '}
              <LinkStyled href="mailto:admin@raschetsten.ru" itemProp="email">
                admin@raschetsten.ru
              </LinkStyled>
            </Typography.Text>
          </Col>
          <Divider style={{margin: '12px 0'}} />
          <Col span={24} itemProp="address" itemScope itemType="//schema.org/PostalAddress">
            <Typography.Paragraph type={'secondary'}>
              Общество с ограниченной ответственностью «Позитив{'\u00A0'}ФМ»
              <br />
              Российская Федерация, <span itemProp="postalCode">117{'\u00A0'}186</span>,{' '}
              <span itemProp="addressLocality">г.{'\u00A0'}Москва</span>,{' '}
              <span itemProp="streetAddress">
                ул.{'\u00A0'}Нагорная, д.{'\u00A0'}18, корп.{'\u00A0'}4, офис{'\u00A0'}208
              </span>
              <br />
              ИНН 7 729 749 182
              <br />
              ОГРН 1 137 746 771 601
              <br />
            </Typography.Paragraph>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const LinkStyled = styled.a`
  && {
    white-space: nowrap;
    &,
    &:hover,
    &:focus {
      color: inherit;
    }
  }
`;

export default Contacts;
