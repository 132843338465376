import React, {Fragment, useContext, useState} from 'react';
import {LocaleContext} from '~src/context';

import {Button, Checkbox, Col, Form, Input, message, Modal, Row, Space, Typography} from 'antd';
import {Control} from '~src/components';
import {CheckboxChangeEvent} from 'antd/es/checkbox';
import pdf from '~src/assets/misc/privacy.pdf';

export interface OnOkArgsAtReportModal {
  userName: string;
  userEmail: string;
}
interface IReportModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (values: OnOkArgsAtReportModal) => void;
}

const validateMessages = {
  required: 'Обязательное поле',
  types: {
    email: 'Некорректный email',
  },
};
const requiredRule = {required: true};

const ReportModal: React.FC<IReportModalProps> = ({visible, navigate, path, onCancel, onOk}) => {
  const {
    components: {modals},
  } = useContext(LocaleContext);
  const [isChecked, setIsChecked] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [form] = Form.useForm();
  const handleCancel = () => {
    onCancel();
  };
  const handleChangeCheckbox = (e: CheckboxChangeEvent) => {
    setIsChecked(e.target.checked);
  };
  const handleClickAtSubmitButton = async () => {
    if (isSuccess) {
      setIsSuccess(false);
      handleCancel();
      return;
    }
    await form
      .validateFields()
      .then(async values => {
        onOk(values as OnOkArgsAtReportModal);
        setIsSuccess(true);
      })
      .catch(() => {
        message.error('Форма содержит ошибку');
      });
  };
  return (
    <Modal visible={visible} footer={null} onCancel={handleCancel} bodyStyle={{padding: '50px'}} width={950}>
      <Form form={form} validateMessages={validateMessages}>
        <Row gutter={[25, 25]}>
          <Col span={24}>
            <Typography.Title>{isSuccess ? modals.report.success.title : modals.report.title}</Typography.Title>
            {!isSuccess && <Typography.Paragraph>{modals.report.caption}</Typography.Paragraph>}
          </Col>
          {!isSuccess && (
            <Fragment>
              <Col span={12}>
                <Control label={modals.report.nameInput.label}>
                  <Form.Item name={'userName'} rules={[requiredRule]}>
                    <Input size={'large'} placeholder={modals.feedback.nameInput.placeholder} />
                  </Form.Item>
                </Control>
              </Col>
              <Col span={12}>
                <Control label={modals.report.emailInput.label}>
                  <Form.Item
                    name={'userEmail'}
                    rules={[requiredRule, {type: 'email'}]}
                    validateTrigger={['onBlur', 'onSubmit']}
                  >
                    <Input size={'large'} placeholder={modals.feedback.emailInput.placeholder} />
                  </Form.Item>
                </Control>
              </Col>
            </Fragment>
          )}
          <Col span={24}>
            <Space size={25}>
              <Button type={'primary'} size={'large'} disabled={!isChecked} onClick={handleClickAtSubmitButton}>
                {isSuccess ? modals.report.success.button : modals.report.button}
              </Button>
              {!isSuccess && (
                <Space>
                  <Checkbox onChange={handleChangeCheckbox} checked={isChecked} />
                  <Typography.Text>
                    {modals.report.checkbox[0]}{' '}
                    <a href={pdf} target="_blank" rel="noreferrer">
                      {modals.report.checkbox[1].title}{' '}
                    </a>
                    {modals.report.checkbox[2]}
                  </Typography.Text>
                </Space>
              )}
            </Space>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ReportModal;
