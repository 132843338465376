import React, {useContext, useState, useEffect} from 'react';
import {Row, Col, Select, InputNumber, Tabs, Form} from 'antd';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import InputSelect from '~src/components/molecules/InputSelect';

import {Control, Space} from '~src/components';
import {LocaleContext} from '~src/context';
import {IFacade} from '~src/services/Calculation2Service';

const {TabPane} = Tabs;

interface ISection2ControlsControlsProps {
  section2Index: number;
}

const VentFacadeInput: React.FC<ISection2ControlsControlsProps> = ({section2Index}) => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {materials} = useStoreon<IState, IEvents>('materials');
  const {calculation2} = materials;
  const {userValues} = calculations[2];
  const [facadeMaterials, setFacadeMaterials] = useState([{label: '', value: 0}]);
  const [chosenOption, setChosenOption] = useState(null);
  const [chosenTrimMarginOption, setChosenTrimMarginOption] = useState(undefined);
  const [chosenSeamThicknessOption, setChosenSeamThicknessOption] = useState(undefined);
  const {
    pages: {
      calculation2: {brickAndBlocksSections},
    },
  } = useContext(LocaleContext);

  const getHandleInputChange = (name: keyof IFacade | string) => (value: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'facades',
      values: [
        {
          name,
          value,
          index: section2Index,
        },
      ],
    });
  };

  const handleSeamThicknessChange = (e: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'facades',
      values: [
        {
          name: 'facadeSeamThickness',
          value: e,
          index: section2Index,
        },
      ],
    });
    setChosenSeamThicknessOption(e);
  };

  const handleDefaultSizeChange = (e: string) => {
    let selectedMaterial = calculation2.facadeType.materials.find(item => {
      return item.id === e;
    });
    selectedMaterial && setChosenOption(selectedMaterial.id);
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'facades',
        values: [
          {
            name: 'facadeDefaultSize',
            value: true,
            index: section2Index,
          },
        ],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'facades',
        values: [
          {
            name: 'facadeBrickLength',
            value: selectedMaterial.facadeBrickLength,
            index: section2Index,
          },
        ],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'facades',
        values: [
          {
            name: 'facadeBrickWidth',
            value: selectedMaterial.facadeBrickWidth,
            index: section2Index,
          },
        ],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'facades',
        values: [
          {
            name: 'facadeBrickHeight',
            value: selectedMaterial.facadeBrickHeight,
            index: section2Index,
          },
        ],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'facades',
        values: [
          {
            name: 'facadeBrickWeight',
            value: selectedMaterial.facadeBrickWeight,
            index: section2Index,
          },
        ],
      });
  };

  const handleTrimMarginOptionChange = (e: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'facades',
      values: [
        {
          name: 'facadeTrimMargin',
          value: e,
          index: section2Index,
        },
      ],
    });
    setChosenTrimMarginOption(e);
  };

  useEffect(() => {
    let materialLabels = [];
    materialLabels = calculation2.facadeType.materials.map(item => {
      let materialLabel =
        item.name +
        ' ' +
        (item.facadeBrickLength !== null && item.facadeBrickLength !== undefined
          ? item.facadeBrickLength.toString() + 'x'
          : '') +
        (item.facadeBrickWidth !== null && item.facadeBrickWidth !== undefined
          ? item.facadeBrickWidth.toString() + 'x'
          : '') +
        (item.facadeBrickHeight !== null && item.facadeBrickHeight !== undefined
          ? item.facadeBrickHeight.toString() + 'мм, '
          : '') +
        (item.facadeBrickWeight !== null && item.facadeBrickWeight !== undefined
          ? item.facadeBrickWeight.toString() + 'кг'
          : '');
      return {
        label: materialLabel,
        value: item.id,
      };
    });
    setFacadeMaterials(materialLabels);
  }, [calculation2.facadeType.materials]);

  return (
    <Row align="top" justify="space-between">
      <ContentWrapperStyled>
        <Col span={24}>
          <Space direction={'vertical'} size={10}>
            <Row gutter={[20, 0]}>
              <Col md={12} xs={24}>
                <Control label={'Длина панели, мм'}>
                  <FormItemStyled
                    validateStatus={
                      userValues.isInvalid && userValues.facades[0].facadePanelLength === 0 ? 'error' : ''
                    }
                    help={
                      userValues.isInvalid && userValues.facades[0].facadePanelLength === 0 ? 'Обязательное поле' : ''
                    }
                  >
                    <InputNumberStyled
                      min={0}
                      size={'large'}
                      placeholder={brickAndBlocksSections.section2.facadePanelLength.placeholder}
                      onChange={getHandleInputChange(brickAndBlocksSections.section2.facadePanelLength.name)}
                      value={userValues.facades[0].facadePanelLength}
                    />
                  </FormItemStyled>
                </Control>
                <Control label={brickAndBlocksSections.section2.facadeWeight.title}>
                  <FormItemStyled
                    validateStatus={userValues.isInvalid && userValues.facades[0].facadeWeight === 0 ? 'error' : ''}
                    help={userValues.isInvalid && userValues.facades[0].facadeWeight === 0 ? 'Обязательное поле' : ''}
                  >
                    <InputNumberStyled
                      min={0}
                      size={'large'}
                      placeholder={brickAndBlocksSections.section2.facadeWeight.placeholder}
                      onChange={getHandleInputChange(brickAndBlocksSections.section2.facadeWeight.name)}
                      value={userValues.facades[0].facadeWeight}
                    />
                  </FormItemStyled>
                </Control>
              </Col>
              <Col md={12} xs={24}>
                <Control label={'Ширина панели, мм'}>
                  <FormItemStyled
                    validateStatus={userValues.isInvalid && userValues.facades[0].facadePanelWidth === 0 ? 'error' : ''}
                    help={
                      userValues.isInvalid && userValues.facades[0].facadePanelWidth === 0 ? 'Обязательное поле' : ''
                    }
                  >
                    <InputNumberStyled
                      min={0}
                      size={'large'}
                      placeholder={brickAndBlocksSections.section2.facadePanelWidth.placeholder}
                      onChange={getHandleInputChange(brickAndBlocksSections.section2.facadePanelWidth.name)}
                      value={userValues.facades[0].facadePanelWidth}
                    />
                  </FormItemStyled>
                </Control>
                <Control label={brickAndBlocksSections.section2.trimMargin.label}>
                  <FormItemStyled
                    validateStatus={
                      userValues.isInvalid &&
                      (userValues.facades[0].facadeTrimMargin === 0 || userValues.facades[0].facadeTrimMargin === null)
                        ? 'error'
                        : ''
                    }
                    help={
                      userValues.isInvalid &&
                      (userValues.facades[0].facadeTrimMargin === 0 || userValues.facades[0].facadeTrimMargin === null)
                        ? 'Обязательное поле'
                        : ''
                    }
                  >
                    <InputSelect
                      inputProps={{
                        size: 'large',
                        placeholder: brickAndBlocksSections.section2.trimMargin.manualInputPlaceholder,
                        inputButton: 'Выбрать значение',
                        min: 0,
                        max: 100,
                      }}
                      selectProps={{
                        size: 'large',
                        placeholder: brickAndBlocksSections.section2.trimMargin.placeholder,
                        options: brickAndBlocksSections.section2.trimMargin.values,
                        selectButton: 'Ввести значение',
                        value: chosenTrimMarginOption,
                      }}
                      onInputChange={getHandleInputChange(brickAndBlocksSections.section2.trimMargin.name)}
                      onSelectChange={handleTrimMarginOptionChange}
                    />
                  </FormItemStyled>
                </Control>
              </Col>
            </Row>
          </Space>
        </Col>
      </ContentWrapperStyled>
    </Row>
  );
};

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`;

const ContentWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;

  @media screen and (max-width: 320px) {
    flex-direction: column-reverse;
  }

  @media screen and (min-width: 321px) and (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

const FormItemStyled = styled(Form.Item)`
  margin-bottom: 0 !important;
`;

export default VentFacadeInput;
