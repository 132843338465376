import React, {useContext} from 'react';
import {Typography} from 'antd';
import styled from 'styled-components';
import {LocaleContext} from '~src/context';

const {Title} = Typography;

interface ILogoLarge {
  color?: string;
}

const LogoLarge: React.FC<ILogoLarge> = color => {
  const {
    components: {logo},
  } = useContext(LocaleContext);

  return (
    <TitleStyled level={3} {...color}>
      {logo.logoText}
    </TitleStyled>
  );
};

const TitleStyled = styled(Title)<ILogoLarge>`
  color: ${props => props.color} !important;
  margin-bottom: 0 !important;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-weight: 900;
`;

export default LogoLarge;
