import React, {Fragment, useContext, useState, useEffect} from 'react';
import {LocaleContext} from '~src/context';
import styled from 'styled-components';
import {Routes} from '~src/utils/routes';

import {Typography, Row, Col, Tag, Button} from 'antd';
import {ArrowRightOutlined, FileAddOutlined} from '@ant-design/icons';
import {Space} from '~src/components';

import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import {
  BearingStructures,
  FacadeMaterials,
  Facades,
  Insulation,
  Partitions,
  Additional,
  BaseLoad,
  Trims,
} from './ResultTables';
import {IResultValues} from '~src/services/Calculation2Service';
import ReportModal from '~src/components/organisms/Modals/ReportModal';
import MaterialPreview from '~src/components/molecules/MaterialPreview';

import imageRenderService from '~src/services/ImageRenderService';

const {Title} = Typography;

interface IContentProps {
  values: {} | IResultValues;
}

interface IOnOkArgsAtReportModal {
  userName: string;
  userEmail: string;
}

const ResultsBrick: React.FC<IContentProps> = values => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const [isShowReportModal, setIsShowReportModal] = useState(false);
  const {userValues} = calculations[2];
  const {
    pages: {
      calculation2: {resultData},
    },
  } = useContext(LocaleContext);

  const handleShowReportModal = () => {
    setIsShowReportModal(true);
    let reportImage = imageRenderService.calculate(
      userValues.bearingWalls[0].brickType,
      userValues.bearingWalls[0].executionType,
      userValues.bearingWalls[0].masonryOption,
      userValues.facades[0].facadeType,
      userValues.insulation[0].insulationType,
    );
    dispatch('calculations/2/set-values', [{name: 'bearingWallImage', value: reportImage.bearingWallImage}]);
    dispatch('calculations/2/set-values', [{name: 'facadeImage', value: reportImage.facadeImage}]);
    dispatch('calculations/2/set-values', [{name: 'insulationImage', value: reportImage.insulationImage}]);
  };

  const handleOkAtReportModal = (values: IOnOkArgsAtReportModal) => {
    dispatch('stats/create-calculation', {...values, calculationType: 2, calculationSubtype: 4});
  };

  const handleCancelAtReportModal = () => {
    setIsShowReportModal(false);
  };

  const determinePassedValues = () => {
    return resultData.tables.map(item => {
      return {
        ...item,
        values: item.values.map(innerItem => {
          switch (innerItem.key) {
            case 'V_st':
              return {...innerItem, value: values.values.V_st};
            case 'S_st':
              return {...innerItem, value: values.values.S_st};
            case 'N_bl_m3':
              return {...innerItem, value: values.values.N_bl_m3};
            case 'N_bl':
              return {...innerItem, value: values.values.N_bl};
            case 'V_bl':
              return {...innerItem, value: values.values.V_bl};
            case 'N_kl_set':
              return {...innerItem, value: values.values.N_kl_set};
            case 'N_bl_zapas':
              return {...innerItem, value: values.values.N_bl_zapas};
            case 'V_p_p':
              return {...innerItem, value: values.values.V_p_p};
            case 'M_p_p':
              return {...innerItem, value: values.values.M_p_p};
            case 'N_p_p':
              return {...innerItem, value: values.values.N_p_p};
            case 'V_sht':
              return {...innerItem, value: values.values.V_sht};
            case 'M_sht':
              return {...innerItem, value: values.values.M_sht};
            case 'N_sht':
              return {...innerItem, value: values.values.N_sht};
            case 'V_ut':
              return {...innerItem, value: values.values.V_ut};
            case 'S_ut':
              return {...innerItem, value: values.values.S_ut};
            case 'V_st_facade':
              return {...innerItem, value: values.values.V_st_facade};
            case 'S_st_facade':
              return {...innerItem, value: values.values.S_st_facade};
            case 'N_bl_m3_facade':
              return {...innerItem, value: values.values.N_bl_m3_facade};
            case 'N_bl_facade':
              return {...innerItem, value: values.values.N_bl_facade};
            case 'V_bl_facade':
              return {...innerItem, value: values.values.V_bl_facade};
            case 'N_kl_set_facade':
              return {...innerItem, value: values.values.N_kl_set_facade};
            case 'N_bl_zapas_facade':
              return {...innerItem, value: values.values.N_bl_zapas_facade};
            case 'V_p_p_facade':
              return {...innerItem, value: values.values.V_p_p_facade};
            case 'M_p_p_facade':
              return {...innerItem, value: values.values.M_p_p_facade};
            case 'N_p_p_facade':
              return {...innerItem, value: values.values.N_p_p_facade};
            case 'V_st_per':
              return {...innerItem, value: values.values.V_st_per};
            case 'S_st_per':
              return {...innerItem, value: values.values.S_st_per};
            case 'N_bl_m3_per':
              return {...innerItem, value: values.values.N_bl_m3_per};
            case 'N_bl_per':
              return {...innerItem, value: values.values.N_bl_per};
            case 'V_bl_per':
              return {...innerItem, value: values.values.V_bl_per};
            case 'N_kl_set_per':
              return {...innerItem, value: values.values.N_kl_set_per};
            case 'N_bl_zapas_per':
              return {...innerItem, value: values.values.N_bl_zapas_per};
            case 'V_p_p_per':
              return {...innerItem, value: values.values.V_p_p_per};
            case 'M_p_p_per':
              return {...innerItem, value: values.values.M_p_p_per};
            case 'N_p_p_per':
              return {...innerItem, value: values.values.N_p_p_per};
            case 'L_ok_slopes':
              return {...innerItem, value: values.values.L_ok_slopes};
            case 'S_ok_slopes':
              return {...innerItem, value: values.values.S_ok_slopes};
            case 'L_dv_slopes':
              return {...innerItem, value: values.values.L_dv_slopes};
            case 'S_dv_slopes':
              return {...innerItem, value: values.values.S_dv_slopes};
            case 'L_dv_per_slopes':
              return {...innerItem, value: values.values.L_dv_per_slopes};
            case 'S_dv_per_slopes':
              return {...innerItem, value: values.values.S_dv_per_slopes};
            case 'N_fund':
              return {...innerItem, value: values.values.N_fund};
            case 'N_fund_per':
              if (userValues.enabledFlags.find(item => item === 'partitions')) {
                return {...innerItem, value: values.values.N_fund_per};
              } else {
                return {...innerItem, value: 0};
              }
            case 'N_gkl':
              return {...innerItem, value: values.values.N_gkl};
            case 'V_sht_trim':
              return {...innerItem, value: values.values.V_sht_trim};
            case 'M_sht_trim':
              return {...innerItem, value: values.values.M_sht_trim};
            case 'N_sht_trim':
              return {...innerItem, value: values.values.N_sht_trim};
            case 'N_fp':
              return {...innerItem, value: values.values.N_fp};
            case 'N_vf':
              return {...innerItem, value: values.values.N_vf};
            default:
              return {...innerItem, value: 'формулы тут нет'};
          }
        }),
      };
    });
  };

  let passedValues = determinePassedValues();

  const determinePreviewSections = () => {
    let sectionsArray = [];

    if (userValues.enabledFlags.find(item => item === 'insulationPanel')) {
      sectionsArray = [
        {
          index: 1,
          title: userValues.bearingWalls[0].brickType,
        },
        {
          index: 2,
          title: userValues.insulation[0].insulationType,
        },
        {
          index: 3,
          title: userValues.facades[0].facadeType,
        },
      ];
    } else {
      sectionsArray = [
        {
          index: 1,
          title: userValues.bearingWalls[0].brickType,
        },
        {
          index: 2,
          title: userValues.facades[0].facadeType,
        },
      ];
    }

    return sectionsArray.map((item, index) => {
      return (
        <h4 key={index}>
          <Tag>{item.index}</Tag> {item.title}
        </h4>
      );
    });
  };

  const determineFacadeType = () => {
    switch (userValues.facades[0].facadeType) {
      case 'Вентилируемый фасад':
      case 'Сайдинг':
      case 'Фасадные панели':
        return true;
      default:
        return false;
    }
  };

  useEffect(() => {
    determinePreviewSections();
  }, [userValues]);

  return (
    <Fragment>
      <Space size={100} direction={'vertical'}>
        <Row gutter={20}>
          <Col span={24}>
            <Title level={2}>Результаты расчета</Title>
          </Col>
          <Col span={24}>
            <Space size={50} direction={'vertical'}>
              {passedValues.map(item => {
                switch (item.title) {
                  case 'Несущие конструкции':
                    return <BearingStructures values={item.values} />;
                  case 'Фасадные материалы':
                    return userValues.facades[0].facadeType === 'Фасадная штукатурка' ? (
                      <FacadeMaterials values={item.values} />
                    ) : null;
                  case 'Фасадные материалы2':
                    return userValues.facades[0].facadeType !== 'Фасадная штукатурка' && !determineFacadeType() ? (
                      <Facades values={item.values} />
                    ) : null;
                  case 'Фасадные материалы3':
                    return userValues.facades[0].facadeType !== 'Фасадная штукатурка' && determineFacadeType() ? (
                      <Facades values={item.values} />
                    ) : null;
                  case 'Теплоизоляция':
                    return userValues.enabledFlags.find(item => item === 'insulation') ? (
                      <Insulation values={item.values} />
                    ) : null;
                  case 'Внутренняя отделка':
                    return userValues.enabledFlags.find(item => item === 'trim') ? (
                      <Trims values={item.values} />
                    ) : null;
                  case 'Перегородки':
                    return userValues.enabledFlags.find(item => item === 'partitions') ? (
                      <Partitions values={item.values} />
                    ) : null;
                  case 'Дополнительно':
                    return userValues.enabledFlags.find(item => item === 'windows') ||
                      userValues.enabledFlags.find(item => item === 'doors') ||
                      userValues.enabledFlags.find(item => item === 'partitionWindows') ||
                      userValues.enabledFlags.find(item => item === 'partitionDoors') ? (
                      <Additional values={item.values} />
                    ) : null;
                  case 'Нагрузка на фундамент':
                    return <BaseLoad values={item.values} />;
                }
              })}
            </Space>
          </Col>
        </Row>
        <PreviewContainerStyled gutter={[10, 40]} justify="space-between" align="middle">
          <ColStyled md={10} sm={12} xs={24}>
            <MaterialPreview sectionToRender="insulation" />
          </ColStyled>
          <Col md={10} sm={12} xs={24}>
            <Space size={40} direction="vertical">
              <ListContainerStyled>{determinePreviewSections()}</ListContainerStyled>
              <Row>
                <ButtonStyled type="primary" onClick={handleShowReportModal}>
                  <FileAddOutlined />
                  Скачать отчет
                </ButtonStyled>
                <a href={Routes.calculation1} target="_blank" rel="noreferrer">
                  <ButtonStyled type="default">
                    Тепловая защита <ArrowRightOutlined />
                  </ButtonStyled>
                </a>
              </Row>
            </Space>
          </Col>
        </PreviewContainerStyled>
      </Space>
      <ReportModal visible={isShowReportModal} onCancel={handleCancelAtReportModal} onOk={handleOkAtReportModal} />
    </Fragment>
  );
};

const PreviewContainerStyled = styled(Row)`
  border: 1px solid #e8e8e8;
`;

const ColStyled = styled(Col)`
  @media screen and (max-width: 415px) {
    margin-left: -35px !important;
  }
`;

const ListContainerStyled = styled.div``;

const ButtonStyled = styled(Button)`
  margin-right: 24px;
  @media screen and (max-width: 640px) {
    margin: 12px 0;
  }
`;

export default ResultsBrick;
