import React from 'react';
import {Typography} from 'antd';
import {formulas} from '~src/services/Calculation1Service';
import styled from 'styled-components';
import FormulaElement, {IFormulaItem, TextStyled} from '~src/components/molecules/Formula/FormulaElement';
import {$tablet} from '~src/theme/media';

interface IFormulaValue {
  name: string;
  value: IFormulaItem[];
  unit: string;
}

interface IFormulaProps {
  name:
    | 't_notr'
    | 'e_notr'
    | 'x_mu'
    | 'termResist'
    | 'z1'
    | 'z2'
    | 'z3'
    | 'to1'
    | 'to2'
    | 'to3'
    | 't1'
    | 't2'
    | 't3'
    | 'E1'
    | 'E2'
    | 'E3'
    | 'R_pn'
    | 'z_o'
    | 't_o'
    | 'E_o'
    | 'r_t'
    | 'r_e'
    | 'r_c'
    | 'r_usl'
    | 'R'
    | 'gsop'
    | 'f_i'
    | 'r_op'
    | 'R_ni'
    | 'E_b'
    | 'e_b'
    | 't_x'
    | 'E'
    | 'E_i'
    | 'E_0'
    | 't_0'
    | 'eta'
    | 'R_tr_n1'
    | 'R_tr_n2'
    // Результат расчёта влагонакопления с учётом неоднородности экрана
    | 'R_prime_p'
    | 'R_p'
    | 'R_pr_p_prime'
    | 'Mu'
    | 'R_prime_op'
    | 'R_tr_n1_prime'
    | 'R_tr_n2_prime'
    | 'eta_prime'
    | 'delta_t'
    | 'e_v'
    | 'delta_e'
    | 'sum_R_ni_section'
    | 'e_tau'
    | 'sum_R_i_section'
    | 'tau_p'
    | 'E_tau'
    | 'Q_v_sl'
    | 'Q_n_sl'
    | 'delta_Q'
    | 'sum_delta_Q'
    // Результат расчёта влагонакопления с учётом тепловлажностного режима прослойки
    | 't_n_greatestCondensate'
    | 't_sr_greatestCondensate'
    | 'fi_sr_greatestCondensate'
    | 'E_sr'
    | 'e_sr'
    | 'V_pr'
    | 'gamma_pr'
    | 'W'
    | 'alfa_l'
    | 'alfa_pr'
    | 'R_pr'
    | 'k_v'
    | 'k_n'
    | 't_y'
    | 'E_y'
    | 'M_v'
    | 'M_n'
    | 'B'
    | 'e_y'
    | 't_n_coldestFiveDays'
    | 't_sr_coldestFiveDays'
    | 'fi_sr_coldestFiveDays'
    | 'E_sr_h'
    | 'e_sr_h'
    | 'V_pr_h'
    | 'gamma_pr_h'
    | 'W_h'
    | 'alfa_l_h'
    | 'alfa_pr_h'
    | 'R_pr_h'
    | 'k_v_h'
    | 'k_n_h'
    | 't_y_h'
    | 'E_y_h'
    | 'M_v_h'
    | 'M_n_h'
    | 'B_h'
    | 'e_y_h';
  value?: string | number;
}

const Formula: React.FC<IFormulaProps> = ({name, value: formulaValue}) => {
  // @ts-ignore
  const formula: IFormulaValue = formulas[name];
  return (
    <FormulaStyled>
      {formula.value.map(({fontStyle, sub, sup, value, type, postfix}, index) => {
        if (!formulaValue && index === formula.value.length - 1) return null;
        if (type === 'fraction') {
          const fractionValues = value as IFormulaItem[][];
          return (
            <FractionWrapperStyled key={index}>
              {fractionValues.map((fractionValue, fractionValueIndex) => {
                return (
                  <FractionLineStyled key={fractionValueIndex}>
                    {fractionValue.map(({value, fontStyle, sub, sup, postfix}, index) => {
                      return (
                        <FormulaElement
                          key={index}
                          type={type}
                          value={value}
                          fontStyle={fontStyle}
                          sub={sub}
                          sup={sup}
                          postfix={postfix}
                        />
                      );
                    })}
                  </FractionLineStyled>
                );
              })}
            </FractionWrapperStyled>
          );
        }
        return (
          <FormulaElement
            key={index}
            type={type}
            value={value}
            fontStyle={fontStyle}
            sub={sub}
            sup={sup}
            postfix={postfix}
          />
        );
      })}
      {formulaValue && <TextStyled>{formulaValue || '0'}</TextStyled>}
      {formulaValue && <UnitStyled>{formula.unit}</UnitStyled>}
    </FormulaStyled>
  );
};

const FractionWrapperStyled = styled(Typography.Text)`
  display: inline-flex;
  flex-direction: column;
  padding: 0 5px;
`;

const FormulaStyled = styled.div`
  display: flex;
  align-items: center;

  @media ${$tablet} {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const FractionLineStyled = styled(Typography.Text)`
  text-align: center;
  &:first-of-type {
    border-bottom: 2px solid #232323;
  }
`;
// @ts-ignore
const UnitStyled = styled(TextStyled)`
  white-space: nowrap;
`;

export default Formula;
