import React from 'react';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import Space from '~src/components/molecules/Space';
import Section1Controls from './Content';

const Section1: React.FC = () => {
  const {calculations} = useStoreon<IState, IEvents>('calculations');
  const {userValues} = calculations[2];

  return (
    <SpaceStyled size={70} direction="vertical">
      {userValues.bearingWalls.map((item, index) => {
        return <Section1Controls section1Index={index} key={index} />;
      })}
    </SpaceStyled>
  );
};

const SpaceStyled = styled(props => <Space {...props} />)`
  margin-bottom: 50px;
`;

export default Section1;
