import React, {useContext} from 'react';
import styled from 'styled-components';
import {LocaleContext} from '~src/context';

import {Col, Layout, Row, Space, Typography} from 'antd';
import {Logo} from '~src/components';

interface IAuthPageProps {}

const Auth: React.FC<IAuthPageProps> = ({children}) => {
  const {
    pages: {
      admin: {auth: authContext},
    },
  } = useContext(LocaleContext);

  return (
    <LayoutStyled>
      <SpaceStyled size={100} direction={'vertical'}>
        <Row justify={'center'} align={'middle'}>
          <ColStyled span={24}>
            <Space direction={'vertical'} size={50}>
              <Row justify={'center'} align={'middle'}>
                <Logo size={'large'} />
              </Row>
              <FormContainerStyled>{children}</FormContainerStyled>
            </Space>
          </ColStyled>
        </Row>
      </SpaceStyled>
      <FooterStyled>
        <Typography.Paragraph>{authContext.misc.copyrightText}</Typography.Paragraph>
      </FooterStyled>
    </LayoutStyled>
  );
};

const LayoutStyled = styled(Layout)`
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
`;

const SpaceStyled = styled(props => <Space {...props} />)`
  display: flex;
  align-content: center;
`;

const ColStyled = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
  padding: 40px 24px;
  background: #fff;
`;

const FooterStyled = styled(Row)`
  position: absolute;
  display: flex;
  justify-content: center;
  left: 0;
  bottom: 0;
  width: 100%;
`;

export default Auth;
