import React from 'react';

import {Modal, Space, Typography} from 'antd';

interface Props {
  visible: boolean;
  onCancel: () => void;
}

const RegionModal: React.FC<Props> = ({visible, onCancel}) => {
  const handleCancel = (e: any) => {
    onCancel();
  };

  return (
    <Modal
      bodyStyle={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      title={'Информация'}
    >
      <Space size={10} direction={'vertical'}>
        <Typography.Title level={4}>Задание населенного пункта</Typography.Title>
        <Typography.Paragraph>
          <Typography.Paragraph>
            Задание населенного пункта При расчете используются климатические параметры, характерные для местности, в
            которой расположен выбранный населенный пункт:
          </Typography.Paragraph>
          <ol>
            <li>
              Температура холодной пятидневки с обеспеченностью 0.92 — при расчете приведенного сопротивления
              теплопередаче и температуры внутренних поверхностей ограждающих конструкций.
            </li>
            <li>
              Продолжительность отопительного периода и средняя температура воздуха отопительного периода — при расчете
              тепловых потерь.
            </li>
            <li>
              Условия эксплуатации помещения — определяют коэффициент теплопроводности материала в зависимости от
              влажностного режима помещения.
            </li>
            <li>
              Количество градусо-суток отопительного периода (ГСОП) — при определении значения требуемого приведенного
              сопротивления теплопередаче.
            </li>
            <li>
              Средние месячные и годовые значения температуры и парциального давления водяного пара — при расчете защиты
              отпереувлажнения ограждающей конструкции.
            </li>
          </ol>
        </Typography.Paragraph>
      </Space>
    </Modal>
  );
};

export default RegionModal;
