import React, {Fragment, useContext, useEffect, useState} from 'react';
import $ from 'jquery';
import styled from 'styled-components';
import find from 'ramda/es/find';
import propEq from 'ramda/es/propEq';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {IUserValuesAtCalculation1} from '~src/store/Calculations';
import {windowGlobal} from '~src/utils/dom';
import {LocaleContext} from '~src/context';
import {cityList, commonCityProps, groupedCityList, ICityItem} from '~src/utils/calculations';
import {InfoCircleFilled, PlusOutlined} from '@ant-design/icons';
import {IMaterial} from '~src/store/Materials';
import {ILayer} from '~src/services/Calculation1Service';

import {Button, Col, Form, message, Row, Select, Tabs, Typography} from 'antd';
import Space from '~src/components/molecules/Space';
import {Collapse, Control, InputNumber, Map} from '~src/components';
import RegionParams from './RegionParams';
import RoomParams from './RoomParams';
import LayersParams from './LayersParams';
import WeatherParams from './WeatherParams';
import Result1 from './Result1';
import Result2 from './Result2';
import Result3 from './Result3';
import Result4 from './Result4';
import Result5 from './Result5';
import MaterialsModal from '~src/components/organisms/Modals/MaterialsModal';
import ReportModal, {OnOkArgsAtReportModal} from '~src/components/organisms/Modals/ReportModal';
import MaterialsParamsModal from '~src/components/organisms/Modals/MaterialsParamsModal';
import RoomParamsModal from '~src/components/organisms/Modals/RoomParamsModal';
import RegionModal from '~src/components/organisms/Modals/RegionModal';
import {$tablet, deviceSizes} from '~src/theme/media';
import {useMediaQuery} from 'react-responsive';
import {Colors} from '~src/components/molecules/Chart/DiagramService';
import Chart from '~src/components/molecules/Chart';
import {getAxis, getBarDataSource} from '~src/utils/chart';
import MobileWarning from '~src/components/organisms/Calculation1Organism/MobileWarning';
import constructionsParams, {ventilationFacadeParams} from '~src/structs/constructionsParams';

const {Title, Paragraph, Text} = Typography;
const {TabPane} = Tabs;
const {Option, OptGroup} = Select;
// const {Panel} = AntdCollapse;

const requiredRule = {required: true};

interface IContentProps {}

const Content: React.FC<IContentProps> = () => {
  const {dispatch, calculations, materials} = useStoreon<IState, IEvents>('calculations', 'materials');
  const [isShowMaterialsModal, setIsShowMaterialsModal] = useState(false);
  const [isShowReportModal, setIsShowReportModal] = useState(false);
  const [editableLayerId, setEditableLayerId] = useState(0);
  const [isShowResult, setIsShowResult] = useState(false);
  const [isShowRegionModal, setIsShowRegionModal] = useState(false);
  const [isShowRoomParamsModal, setIsShowRoomParamsModal] = useState(false);
  const [isShowMaterialsParamsModal, setIsShowMaterialsParamsModal] = useState(false);
  const [calculationSubtype, setCalculationSubtype] = useState('1');
  const [isShowLearnMore, setShowLearnMore] = useState(false);
  const isTablet = useMediaQuery({maxWidth: deviceSizes.desktop - 1});

  const [bigForm] = Form.useForm();
  const {userValues} = calculations['1'];
  const {
    pages: {
      calculation1: {header1, description, section1, section2, descriptionButton},
    },
  } = useContext(LocaleContext);
  const {layers} = calculations[1].userValues;
  const {groupedValues} = materials;
  useEffect(() => {
    dispatch('materials/fetch-materials', {
      page: 1,
      count: 10000,
      orderBy: 'ASC',
      calculationType: 1,
      withGroupedValues: true,
    });
  }, []);

  useEffect(() => {
    if (userValues.typeConstruction === 0) {
      const constructionsParam = userValues.containsAirGap ? ventilationFacadeParams : constructionsParams[0];
      const {n, alfa_h, delta_t_h} = constructionsParam;

      dispatch('calculations/1/set-values', [
        {name: 'n', value: n},
        {name: 'alfa_h', value: alfa_h},
        {name: 'delta_t_h', value: delta_t_h},
      ]);
    }
  }, [userValues.typeConstruction, userValues.containsAirGap]);

  const handleChangeMap = (code: string) => {
    const cityData = find<ICityItem>(propEq('value', code))(cityList);
    dispatch('calculations/1/set-values', [
      {name: 'regionCode', value: code},
      {name: 'regionCity', value: cityData!.label},
      ...commonCityProps.map(prop => ({name: prop, value: cityData![prop]})),
    ]);
  };
  const handleClickAtDownloadReportButton = () => {
    setIsShowReportModal(true);
  };
  const handleClickAtLayerEditButton = (layerId: number) => {
    setEditableLayerId(layerId);
    setIsShowMaterialsModal(true);
  };
  const handleClickAtAddLayerButton = () => {
    setIsShowMaterialsModal(true);
  };
  const handleCancelAtMaterialsModal = () => {
    setIsShowMaterialsModal(false);
    setEditableLayerId(0);
  };
  const handleCancelAtReportModal = () => {
    setIsShowReportModal(false);
  };
  const handleOkAtMaterialsModal = (materialId: string, layerPath: string) => {
    setIsShowMaterialsModal(false);
    const [layerName, materialType] = layerPath.split('--');
    const material = (groupedValues[layerName][materialType] as IMaterial[]).find(
      material => material.id === materialId,
    );

    if (
      materialType === 'Вентилируемая воздушная прослойка' &&
      groupedValues['Фасадные материалы']['Экран'].length > 0
    ) {
      const screen = groupedValues['Фасадные материалы']['Экран'][0];
      screen.t = 10;

      dispatch('calculations/1/set-values', [
        {
          name: 'layers',
          value: editableLayerId
            ? layers
                .slice(0, editableLayerId)
                .map(layer => (layer.layerId === editableLayerId ? {...layer, ...material} : layer))
                .concat({...layers[0], ...screen, layerId: editableLayerId + 1})
                .concat(
                  layers.slice(editableLayerId).map(layer => {
                    return {...layer, layerId: layer.layerId + 1};
                  }),
                )
            : [
                ...layers,
                {...layers[0], ...material, layerId: layers.length + 1},
                {...layers[0], ...screen, layerId: layers.length + 2},
              ],
        },
      ]);
    } else {
      dispatch('calculations/1/set-values', [
        {
          name: 'layers',
          value: editableLayerId
            ? layers.map(layer => (layer.layerId === editableLayerId ? {...layer, ...material} : layer))
            : [...layers, {...layers[0], ...material, layerId: layers.length + 1}],
        },
      ]);
    }

    setEditableLayerId(0);
  };
  const handleOkAtReportModal = (values: OnOkArgsAtReportModal) => {
    const image = document.querySelector<HTMLCanvasElement>(`#chart-result${calculationSubtype}`)?.toDataURL();
    dispatch('stats/create-calculation', {
      ...values,
      calculationSubtype: Number(calculationSubtype),
      calculationType: 1,
      image,
    });
  };
  const getHandlerChangeAtSelectControl = (name: keyof IUserValuesAtCalculation1) => (
    value: string | number,
    option: any,
  ) => {
    if (name === 'regionCity') {
      const regionCode = option.key.split('-')[0];
      const cityData = find<ICityItem>(propEq('value', regionCode))(cityList);
      dispatch('calculations/1/set-values', [
        {name: 'regionCode', value: regionCode},
        {name: 'regionCity', value},
        ...commonCityProps.map(prop => ({name: prop, value: cityData![prop]})),
      ]);
      if (windowGlobal) {
        ($('#vmap') as any).vectorMap('set', 'selectedRegions', [regionCode]);
      }
      return;
    } else if (name === 'typeRoom') {
      const t_bValues = section2.temperatureInRoomsByGost.getValues(value as number);
      const wetInRoom = section2.wetInRoom.getDefaultValue(value as number);
      if (userValues.t_b === -1 || !t_bValues.some(item => item.value === userValues.t_b)) {
        const t_b = t_bValues[0].value;
        dispatch('calculations/1/set-values', [{name: 't_b', value: t_b}]);
        bigForm.setFieldsValue({t_b});
      }
      dispatch('calculations/1/set-values', [{name: 'wetInRoom', value: wetInRoom}]);
      bigForm.setFieldsValue({wetInRoom});
    } else if (name === 'categoryPublicRoom') {
      const t_bValues = section2.temperatureInRoomsByGost.getPublicRoomValues(value as number);
      const wetInRoom = section2.wetInRoom.getPublicRoomDefaultValue(value as number);
      if (userValues.t_b === -1 || !t_bValues.some(item => item.value === userValues.t_b)) {
        const t_b = t_bValues[0].value;
        dispatch('calculations/1/set-values', [{name: 't_b', value: t_b}]);
        bigForm.setFieldsValue({t_b});
      }
      dispatch('calculations/1/set-values', [{name: 'wetInRoom', value: wetInRoom}]);
      bigForm.setFieldsValue({wetInRoom});
    } else if (name === 'typeConstruction') {
      const {n, alfa_h, delta_t_h} = constructionsParams[value as number];
      dispatch('calculations/1/set-values', [
        {name, value},
        {name: 'n', value: n},
        {name: 'alfa_h', value: alfa_h},
        {name: 'delta_t_h', value: delta_t_h},
      ]);
      return;
    }

    dispatch('calculations/1/set-values', [{name, value}]);
  };
  const getHandlerChangeAtInputNumberControl = (name: keyof IUserValuesAtCalculation1) => (
    value?: string | number | undefined,
  ) => {
    if (name === 'h_y' || name === 'b_y') {
      const secondSizeScreenPanel = name === 'h_y' ? userValues.b_y : userValues.h_y;
      const newHeightInletAirGap =
        (25 * (Number(value) * secondSizeScreenPanel)) / 1000000 > 15
          ? Math.round((25 * (Number(value) * secondSizeScreenPanel)) / 1000000)
          : 15;
      dispatch('calculations/1/set-values', [
        {
          value: newHeightInletAirGap || -1,
          name: 'h_gap',
        },
      ]);
      bigForm.setFieldsValue({h_gap: newHeightInletAirGap});
    }

    dispatch('calculations/1/set-values', [
      {
        value: Number(value) || -1,
        name,
      },
    ]);
  };

  const handleClickAtCalculateButton = async () => {
    if (userValues.containsAirGap) {
      const validateAirGapLayers = (layers: ILayer[]) =>
        layers
          .filter(layer => layer.isEnabled)
          .find(
            (layer, index, array) =>
              layer.materialType === 'Вентилируемая воздушная прослойка' && array[index + 1]?.materialType === 'Экран',
          );

      if (!validateAirGapLayers(layers)) {
        message.error('После вентилируемой воздушной прослойки обязательно должен быть экран');
        return;
      }
    }

    await bigForm
      .validateFields()
      .then(async () => {
        bigForm.submit();
        await dispatch('calculations/1/calculate');
        setIsShowResult(true);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('Форма содержит ошибку: ', e);
        message.error('Форма содержит ошибку');
      });
  };
  const validateMessages = {
    required: 'Обязательное поле',
    types: {
      number: 'Это должно быть число',
    },
  };
  const handleShowRegionModal = (value: boolean) => () => {
    setIsShowRegionModal(value);
  };

  const handleShowRoomParamsModal = (value: boolean) => () => {
    setIsShowRoomParamsModal(value);
  };

  const handleShowMaterialsParamsModal = (value: boolean) => () => {
    setIsShowMaterialsParamsModal(value);
  };

  const handleChangeActiveTabByResult = (activeKey: string) => {
    setCalculationSubtype(activeKey);
  };

  const handleShowLearnMore = () => {
    setShowLearnMore(!isShowLearnMore);
  };

  return (
    <Fragment>
      <Form
        form={bigForm}
        validateMessages={validateMessages}
        initialValues={{
          r: userValues.r,
          sum_xi_sh: userValues.sum_xi_sh,
          sum_xi: userValues.sum_xi,
          h_y: userValues.h_y,
          b_y: userValues.b_y,
          h_gap: userValues.h_gap,
          h_all: userValues.h_all,
        }}
      >
        <Space size={isTablet ? 15 : 100} direction={'vertical'}>
          <Row>
            <Col span={24}>
              <Title level={1}>{header1}</Title>
              <Paragraph>{description[0]}</Paragraph>
              <Paragraph>{description[1]}</Paragraph>
              {isShowLearnMore && (
                <div>
                  <Title level={2}>{description[2]}</Title>
                  <Paragraph>{description[3]}</Paragraph>
                  <ul>
                    {(description[4] as any).map((item: string, index: number) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                  <Paragraph>{description[5]}</Paragraph>
                  <ul>
                    {(description[6] as any).map((item: string, index: number) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                  <Title level={2}>{description[7]}</Title>
                  <Paragraph>{description[8]}</Paragraph>
                  <Paragraph>{description[9]}</Paragraph>
                  <TitleStyled level={4}>{description[10]}</TitleStyled>
                  <Paragraph>{description[11]}</Paragraph>
                  <TitleStyled level={4}>{description[12]}</TitleStyled>
                  <Paragraph>{description[13]}</Paragraph>
                  <TitleStyled level={4}>{description[14]}</TitleStyled>
                  <Paragraph>{description[15]}</Paragraph>
                  <TitleStyled level={4}>{description[16]}</TitleStyled>
                  <Paragraph>{description[17]}</Paragraph>
                  <Title level={2}>{description[18]}</Title>
                  <Paragraph>{description[19]}</Paragraph>
                  <Paragraph>{description[20]}</Paragraph>
                </div>
              )}
              <ButtonStyled type="link" onClick={() => handleShowLearnMore()}>
                {isShowLearnMore ? descriptionButton.showLess : descriptionButton.showMore}
              </ButtonStyled>
            </Col>
          </Row>
          <Row gutter={[0, isTablet ? 10 : 20]}>
            <Col span={24}>
              <Title level={2}>1. Выбор региона строительства</Title>
            </Col>
            <Col span={24}>
              <MapWrapperStyled>
                <Map clientWidth={990} selectedRegionCode={userValues.regionCode} onChange={handleChangeMap} />
              </MapWrapperStyled>
            </Col>
            <Col span={24}>
              <Row align={'middle'}>
                <Col md={12} xs={24}>
                  <Typography.Paragraph>
                    Отметьте на карте нужный регион, или выберите его из списка:
                  </Typography.Paragraph>
                </Col>
                <Col md={12} xs={24}>
                  <Control>
                    <Select
                      showSearch
                      placeholder={section2.region.placeholder}
                      size={'large'}
                      value={userValues.regionCity}
                      onChange={getHandlerChangeAtSelectControl(section2.region.name)}
                    >
                      {groupedCityList.map(region => (
                        <OptGroup key={region.index} label={region.label}>
                          {region.children.map((city, cityIndex) => (
                            <Option key={`${city.value}-${cityIndex}`} value={city.label}>
                              {city.label}
                            </Option>
                          ))}
                        </OptGroup>
                      ))}
                    </Select>
                  </Control>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Collapse
                defaultActiveKey={isTablet ? undefined : section1.defaultValueCollapse}
                values={section1.collapse.map((item, index) => ({
                  header: item.header,
                  key: item.key,
                  node:
                    index === 0 ? (
                      <RegionParams params={item.items} values={userValues} />
                    ) : (
                      <WeatherParams params={item.items} values={userValues} />
                    ),
                }))}
              />
            </Col>
            <Col span={24}>
              <Button icon={<InfoCircleFilled />} type={'dashed'} onClick={handleShowRegionModal(true)}>
                Информация
              </Button>
            </Col>
          </Row>
          <Row gutter={[0, isTablet ? 10 : 20]}>
            <Col span={24}>
              <Title level={2}>{section2.title}</Title>
            </Col>
            <Col span={24}>
              <Row gutter={[20, isTablet ? 0 : 20]}>
                <Col md={12} xs={24}>
                  <Control label={section2.typeRoom.label}>
                    <Form.Item name={section2.typeRoom.name} rules={[requiredRule]}>
                      <Select
                        placeholder={section2.typeRoom.placeholder}
                        size={'large'}
                        options={section2.typeRoom.values}
                        onChange={getHandlerChangeAtSelectControl(section2.typeRoom.name)}
                      />
                    </Form.Item>
                  </Control>
                </Col>

                {userValues.typeRoom === 4 && (
                  <Col md={12} xs={24}>
                    <Control label={section2.categoryPublicRoom.label}>
                      <Form.Item name={section2.categoryPublicRoom.name} rules={[requiredRule]}>
                        <Select
                          placeholder={section2.categoryPublicRoom.placeholder}
                          size={'large'}
                          options={section2.categoryPublicRoom.values}
                          onChange={getHandlerChangeAtSelectControl(section2.categoryPublicRoom.name)}
                        />
                      </Form.Item>
                    </Control>
                  </Col>
                )}
                <Col md={12} xs={24}>
                  <Control label={section2.typeConstruction.label}>
                    <Form.Item name={section2.typeConstruction.name} rules={[requiredRule]}>
                      <Select
                        placeholder={section2.typeConstruction.placeholder}
                        size={'large'}
                        options={section2.typeConstruction.values}
                        onChange={getHandlerChangeAtSelectControl(section2.typeConstruction.name)}
                      />
                    </Form.Item>
                  </Control>
                </Col>
                <Col md={12} xs={24}>
                  <Control label={section2.temperatureInRoomsByGost.label}>
                    <Form.Item name={section2.temperatureInRoomsByGost.name} rules={[requiredRule]}>
                      <Select
                        placeholder={section2.temperatureInRoomsByGost.placeholder}
                        size={'large'}
                        value={userValues.t_b === -1 ? undefined : userValues.t_b}
                        disabled={userValues.typeRoom === -1}
                        options={
                          userValues.typeRoom !== 4
                            ? section2.temperatureInRoomsByGost.getValues(userValues.typeRoom)
                            : section2.temperatureInRoomsByGost.getPublicRoomValues(userValues.categoryPublicRoom)
                        }
                        onChange={getHandlerChangeAtSelectControl(section2.temperatureInRoomsByGost.name)}
                      />
                    </Form.Item>
                  </Control>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item name={section2.wetInRoom.name} rules={[requiredRule]}>
                    <Control label={section2.wetInRoom.label}>
                      <InputNumber
                        name={section2.wetInRoom.name}
                        placeholder={section2.wetInRoom.placeholder}
                        size={'large'}
                        value={userValues.t_b === -1 ? undefined : userValues.wetInRoom}
                        disabled={userValues.typeRoom === -1}
                        onChange={getHandlerChangeAtInputNumberControl(section2.wetInRoom.name)}
                        min={1}
                        max={100}
                      />
                    </Control>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {userValues.wetInRoom !== -1 &&
              userValues.typeRoom !== -1 &&
              userValues.typeConstruction !== -1 &&
              userValues.t_b !== -1 && (
                <Col span={24}>
                  <Space direction={'vertical'} size={20}>
                    <RoomParams />
                    <Button icon={<InfoCircleFilled />} type={'dashed'} onClick={handleShowRoomParamsModal(true)}>
                      Информация
                    </Button>
                  </Space>
                </Col>
              )}
          </Row>
          <Row gutter={[0, isTablet ? 10 : 20]}>
            <Col span={24}>
              <Title level={2}>3. Ввод слоев конструкции</Title>
            </Col>
            <Col span={24}>
              <ChartWrapper>
                {userValues.layers.length > 0 && (
                  <Chart
                    axis={getAxis(getBarDataSource(userValues), {x: true})}
                    hiddenAxis={true}
                    type={'filled'}
                    kind={'bar'}
                    width={550}
                    height={400}
                    dataSourceId={Date.now()}
                    dataSource={getBarDataSource(userValues)}
                    columnLinesColors={[Colors.blueDarkness, Colors.blue]}
                    withDiagramBase={true}
                  />
                )}
              </ChartWrapper>
            </Col>
            <MobileWarning />
            <Col span={24}>{<LayersParamsStyled onClickAtEditButton={handleClickAtLayerEditButton} />}</Col>
            <Col span={24}>
              <Row gutter={[0, 10]} justify={'space-between'}>
                <Col md={10} xs={24}>
                  <Space size={10}>
                    <Button icon={<InfoCircleFilled />} type={'link'} onClick={handleShowMaterialsParamsModal(true)} />
                    <Text>
                      Коэф. теплотех. неоднородности, <strong>r</strong>
                    </Text>
                    <Form.Item name={'r'} rules={[requiredRule]} style={{marginBottom: 0}}>
                      <InputNumber name={'r'} onChange={getHandlerChangeAtInputNumberControl('r')} min={0} max={1} />
                    </Form.Item>
                  </Space>
                </Col>
                <LayerParamsButtonCol md={4} xs={24}>
                  <Row justify={'start'}>
                    <Button
                      onClick={handleClickAtAddLayerButton}
                      size={'large'}
                      disabled={layers.length >= 10}
                      icon={<PlusOutlined />}
                    >
                      Добавить слой
                    </Button>
                  </Row>
                </LayerParamsButtonCol>
              </Row>
            </Col>

            {userValues.containsAirGap && (
              <Col span={24}>
                <Row gutter={[0, 10]} justify={'space-between'} flex-direction={'column'}>
                  <Col xs={24}>
                    <Space size={10}>
                      <Text>
                        Сумма коэффициентов местных аэродинамических сопротивлений в стыках-швах,{' '}
                        <strong>
                          ∑ξ<sub>ш</sub>
                        </strong>
                      </Text>
                      <Form.Item name={'sum_xi_sh'} rules={[requiredRule]} style={{marginBottom: 0}}>
                        <InputNumber
                          name={'sum_xi_sh'}
                          onChange={getHandlerChangeAtInputNumberControl('sum_xi_sh')}
                          min={0}
                        />
                      </Form.Item>
                    </Space>
                  </Col>

                  <Col xs={24}>
                    <Space size={10}>
                      <Text>
                        Сумма коэффициентов местных аэродинамических сопротивлений в прослойке, <strong>∑ξ</strong>
                      </Text>
                      <Form.Item name={'sum_xi'} rules={[requiredRule]} style={{marginBottom: 0}}>
                        <InputNumber
                          name={'sum_xi'}
                          onChange={getHandlerChangeAtInputNumberControl('sum_xi')}
                          min={0}
                        />
                      </Form.Item>
                    </Space>
                  </Col>

                  <Col xs={24}>
                    <Space size={10}>
                      <Text>
                        Расстояние между швами по вертикали (мм), <strong>h_y</strong> (min600мм)
                      </Text>
                      <Form.Item name={'h_y'} rules={[requiredRule]} style={{marginBottom: 0}}>
                        <InputNumber name={'h_y'} onChange={getHandlerChangeAtInputNumberControl('h_y')} min={600} />
                      </Form.Item>
                    </Space>
                  </Col>

                  <Col xs={24}>
                    <Space size={10}>
                      <Text>
                        Расстояние между швами по горизонтали (мм), <strong>b_y</strong> (min600мм)
                      </Text>
                      <Form.Item name={'b_y'} rules={[requiredRule]} style={{marginBottom: 0}}>
                        <InputNumber name={'b_y'} onChange={getHandlerChangeAtInputNumberControl('b_y')} min={600} />
                      </Form.Item>
                    </Space>
                  </Col>

                  <Col xs={24}>
                    <Space size={10}>
                      <Text>
                        Высота воздухозаборной щели (мм), <strong>h</strong> (min15мм)
                      </Text>
                      <Form.Item name={'h_gap'} rules={[requiredRule]} style={{marginBottom: 0}}>
                        <InputNumber name={'h_gap'} min={15} disabled />
                      </Form.Item>
                    </Space>
                  </Col>

                  <Col xs={24}>
                    <Space size={10}>
                      <Text>
                        Высота всего экрана (м) (всех этажей здания/высота кассеты), <strong>H</strong>
                      </Text>
                      <Form.Item name={'h_all'} rules={[requiredRule]} style={{marginBottom: 0}}>
                        <InputNumber name={'h_all'} onChange={getHandlerChangeAtInputNumberControl('h_all')} min={1} />
                      </Form.Item>
                    </Space>
                  </Col>
                </Row>
              </Col>
            )}

            <Col span={24}>
              <Form.Item>
                <Button type={'primary'} size={'large'} onClick={handleClickAtCalculateButton}>
                  Рассчитать
                </Button>
              </Form.Item>
            </Col>
          </Row>
          {isShowResult && (
            <Row gutter={[0, 50]}>
              <Col span={24}>
                <Title level={2}>Результаты расчета</Title>
              </Col>
              <Col span={24}>
                <Tabs
                  animated={false}
                  defaultActiveKey={calculationSubtype}
                  onChange={handleChangeActiveTabByResult}
                  type={'card'}
                  size={'large'}
                >
                  <TabPaneStyled tab="1. Тепловая защита" key="1">
                    <Result1 onClickAtDownloadReportButton={handleClickAtDownloadReportButton} />
                  </TabPaneStyled>
                  <TabPaneStyled tab="2. Влагонакопление" key="2">
                    <Result2 onClickAtDownloadReportButton={handleClickAtDownloadReportButton} />
                  </TabPaneStyled>
                  <TabPaneStyled tab="3. Тепловые потери" key="3">
                    <Result3 onClickAtDownloadReportButton={handleClickAtDownloadReportButton} />
                  </TabPaneStyled>
                  {userValues.containsAirGap && (
                    <>
                      <TabPaneStyled tab="4. Влагонакопление с учётом неоднородности экрана" key="4">
                        <Result4 onClickAtDownloadReportButton={handleClickAtDownloadReportButton} />
                      </TabPaneStyled>
                      <TabPaneStyled tab="5. Влагонакопление с учётом тепловлажностного режима прослойки" key="5">
                        <Result5 onClickAtDownloadReportButton={handleClickAtDownloadReportButton} />
                      </TabPaneStyled>
                    </>
                  )}
                </Tabs>
              </Col>
            </Row>
          )}
        </Space>
      </Form>
      {isShowMaterialsModal && (
        <MaterialsModal
          visible={isShowMaterialsModal}
          onCancel={handleCancelAtMaterialsModal}
          onOk={handleOkAtMaterialsModal}
        />
      )}
      {isShowReportModal && (
        <ReportModal visible={isShowReportModal} onCancel={handleCancelAtReportModal} onOk={handleOkAtReportModal} />
      )}
      {isShowRegionModal && <RegionModal visible={isShowRegionModal} onCancel={handleShowRegionModal(false)} />}
      {isShowRoomParamsModal && (
        <RoomParamsModal visible={isShowRoomParamsModal} onCancel={handleShowRoomParamsModal(false)} />
      )}
      {isShowMaterialsParamsModal && (
        <MaterialsParamsModal visible={isShowMaterialsParamsModal} onCancel={handleShowMaterialsParamsModal(false)} />
      )}
    </Fragment>
  );
};

const LayerParamsButtonCol = styled(Col)`
  text-align: right;
`;
const LayersParamsStyled = styled(LayersParams)`
  @media ${$tablet} {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
const TabPaneStyled = styled(TabPane)`
  width: 100%;
`;
const MapWrapperStyled = styled.div`
  @media ${$tablet} {
    display: none;
  }
`;
const ChartWrapper = styled.div`
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const ButtonStyled = styled(Button)`
  padding-left: 0;
`;
const TitleStyled = styled(Title)`
  text-transform: uppercase;
  letter-spacing: 0.04em !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
  margin-right: 95px;
`;

export default Content;
