import React, {Fragment} from 'react';

import {Typography, Row, Col, Table} from 'antd';
import Space from '~src/components/molecules/Space';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

const {Title} = Typography;
const {Column} = Table;

interface IContentProps {
  values: {
    key: string;
    title: string;
    value: number;
    unit: string;
  }[];
}

const BearingStructures: React.FC<IContentProps> = values => {
  const {calculations} = useStoreon<IState, IEvents>('calculations');
  const tableValues = values.values;
  tableValues.map(item => {
    return item.value === 0 ? null : item;
  });
  const {userValues} = calculations[2];
  return (
    <Fragment>
      <Space size={100} direction={'vertical'}>
        <Row gutter={20}>
          <Col span={24}>
            <Title level={3}>Несущие конструкции - {userValues.bearingWalls[0].brickType}</Title>
          </Col>
          <Col span={24}>
            <Table dataSource={tableValues} pagination={false} showHeader={false} tableLayout="auto">
              <Column title="Свойство" dataIndex="title" key="title" width="80" />
              <Column title="Значение" dataIndex="value" key="value" align="right" width="10" />
              <Column title="Единица измерения" dataIndex="unit" key="unit" align="right" width="10" />
            </Table>
          </Col>
        </Row>
      </Space>
    </Fragment>
  );
};

export default BearingStructures;
