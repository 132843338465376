import React, {CSSProperties, useContext, useState} from 'react';
import {LocaleContext} from '~src/context';
import {Button, Modal, Space, Layout} from 'antd';
import Menu from '~src/components/organisms/Modals/MaterialsModal/Menu';
import Table from '~src/components/organisms/Modals/MaterialsModal/Table';
import {useMediaQuery} from 'react-responsive';
import {$tablet, deviceSizes} from '~src/theme/media';
import Collapse from '~src/components/organisms/Modals/MaterialsModal/Collapse';
import styled from 'styled-components';

const {Sider} = Layout;

const bodyStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: 0,
};

interface IMaterialsModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (materialId: string, layerPath: string) => void;
}

const MaterialsModal: React.FC<IMaterialsModalProps> = ({visible, onCancel, onOk}) => {
  const {
    components: {modals},
  } = useContext(LocaleContext);
  const [selectedMenuValues, selectMenuValues] = useState('');
  const [selectedMaterialId, selectMaterialId] = useState('');
  const isTablet = useMediaQuery({maxWidth: deviceSizes.desktop - 1});

  const handleCancel = () => {
    onCancel();
    selectMaterialId('');
    selectMenuValues('');
  };
  const handleClickAtCancelButton = () => {
    onCancel();
    selectMaterialId('');
    selectMenuValues('');
  };
  const handleClickAtOkButton = () => {
    onOk(selectedMaterialId, selectedMenuValues);
    selectMaterialId('');
    selectMenuValues('');
  };
  const handleClickAtMenu = (key: string) => {
    selectMenuValues(key);
  };
  const handleSelectTableRow = (key: string) => {
    selectMaterialId(key);
  };

  return (
    // TODO: решить вопрос с шириной
    <Modal
      bodyStyle={bodyStyle}
      visible={visible}
      footer={
        <ModalFooterStyled>
          <Space size={10}>
            <Button onClick={handleClickAtCancelButton}>{modals.materials.cancelButton}</Button>
            <Button disabled={!selectedMaterialId} type={'primary'} onClick={handleClickAtOkButton}>
              {modals.materials.okButton}
            </Button>
          </Space>
        </ModalFooterStyled>
      }
      onCancel={handleCancel}
      width={1100}
      title={modals.materials.title}
    >
      {isTablet ? (
        <Collapse onChange={handleClickAtMenu}>
          {selectedMenuValues.split('--').every(key => !!key) && (
            <TableStyled type={selectedMenuValues} onSelect={handleSelectTableRow} />
          )}
        </Collapse>
      ) : (
        <Layout>
          <Sider theme={'light'} width={300}>
            <Menu value={selectedMenuValues} onClick={handleClickAtMenu} />
          </Sider>
          <Layout>
            {selectedMenuValues.split('--').every(key => !!key) && (
              <Table type={selectedMenuValues} onSelect={handleSelectTableRow} />
            )}
          </Layout>
        </Layout>
      )}
    </Modal>
  );
};

const TableStyled = styled(Table)`
  @media ${$tablet} {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const ModalFooterStyled = styled.div`
  @media ${$tablet} {
    padding-bottom: 50px;
  }
`;

export default MaterialsModal;
