import React, {Fragment, useContext, useState} from 'react';
import {LocaleContext} from '~src/context';
import styled from 'styled-components';
import {Routes} from '~src/utils/routes';

import {Typography, Row, Col, Table, Button} from 'antd';
import {ArrowRightOutlined, FileAddOutlined} from '@ant-design/icons';
import Space from '~src/components/molecules/Space';
import ReportModal from '~src/components/organisms/Modals/ReportModal';

import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import {IResultValues} from '~src/services/Calculation3Service';

import bearingWallBalkProfiled from '~src/services/ImageRenderService/images/bearingWall_balk_profiled.png';
import bearingWallBalkRectangular from '~src/services/ImageRenderService/images/bearingWall_balk_rect.png';
import bearingWallBalkLog from '~src/services/ImageRenderService/images/bearingWall_log.png';

import imageRenderService from '~src/services/ImageRenderService';

const {Title} = Typography;
const {Column} = Table;

interface IContentProps {
  values: {} | IResultValues;
}

interface IOnOkArgsAtReportModal {
  userName: string;
  userEmail: string;
}

const ResultsBalk: React.FC<IContentProps> = values => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const [isShowReportModal, setIsShowReportModal] = useState(false);
  const {userValues} = calculations[3];
  const {
    pages: {
      calculation2: {balkResultData},
    },
  } = useContext(LocaleContext);

  const determineBalkPreview = () => {
    switch (userValues.bearingWalls[0].balkType) {
      case 'Брус прямоугольного сечения':
      case 'Брус клееный':
        return bearingWallBalkRectangular;
      case 'Брус профилированный':
        return bearingWallBalkProfiled;
      case 'Бревно оцилиндрованное':
        return bearingWallBalkLog;
      default:
        return bearingWallBalkRectangular;
    }
  };

  const handleShowReportModal = () => {
    setIsShowReportModal(true);
    let reportImage = imageRenderService.calculate(userValues.bearingWalls[0].balkType, 0, 0, '', '');
    dispatch('calculations/3/set-values', [{name: 'bearingWallImage', value: reportImage.bearingWallImage}]);
  };

  const handleOkAtReportModal = (values: IOnOkArgsAtReportModal) => {
    dispatch('stats/create-calculation', {...values, calculationType: 3, calculationSubtype: 5});
  };

  const handleCancelAtReportModal = () => {
    setIsShowReportModal(false);
  };

  const selectedBalkType =
    userValues.bearingWalls[0].balkType +
    ' ' +
    userValues.bearingWalls[0].balkWidth +
    'x' +
    userValues.bearingWalls[0].balkHeight +
    'мм';

  const determinePassedValues = () => {
    return balkResultData.values.map(item => {
      switch (item.key) {
        case 'V_br':
          return {...item, value: values.values.V_br, title: selectedBalkType};
        case 'S_st':
          return {...item, value: values.values.S_st};
        case 'M_br':
          return {...item, value: values.values.M_br};
        case 'N_venc':
          return {...item, value: values.values.N_venc};
        case 'L_ut_rul':
          return {...item, value: values.values.L_ut_rul};
        case 'D_nag':
          return {...item, value: values.values.D_nag};
        case 'L_nag':
          return {...item, value: values.values.L_nag};
        case 'N_nag':
          return {...item, value: values.values.N_nag};
        case 'V_prop':
          return {...item, value: values.values.V_prop};
        case 'Delta_usad':
          return {...item, value: values.values.Delta_usad};
        case 'N_fund':
          return {...item, value: values.values.N_fund};
        default:
          return {...item, value: 'формулы тут нет'};
      }
    });
  };

  let passedValues = determinePassedValues().filter(item => {
    return item.value !== 0 && isNaN(item.value) === false;
  });
  return (
    <Fragment>
      <Space size={100} direction={'vertical'}>
        <Row gutter={20}>
          <Col span={24}>
            <Title level={2}>Результаты расчета</Title>
          </Col>
          <Col span={24}>
            <Table dataSource={passedValues} pagination={false} showHeader={false} tableLayout="auto">
              <Column title="Свойство" dataIndex="title" key="title" />
              <Column title="Значение" dataIndex="value" key="value" align="right" />
              <Column title="Единица измерения" dataIndex="unit" key="unit" align="right" />
            </Table>
          </Col>
        </Row>
        <PreviewContainerStyled gutter={[20, 40]} justify="space-between" align="middle">
          <Col md={10} sm={12} xs={24} push={2}>
            <ImageBox>
              <Image src={determineBalkPreview()} alt="" />
            </ImageBox>
          </Col>
          <Col md={10} sm={12} xs={24}>
            <Space size={40} direction="vertical">
              <ListContainerStyled>
                <Title level={2}>
                  {userValues.bearingWalls[0].balkType +
                    ', ' +
                    userValues.bearingWalls[0].balkWidth +
                    'x' +
                    userValues.bearingWalls[0].balkHeight +
                    ' мм'}
                </Title>
              </ListContainerStyled>
              <Row>
                <ButtonStyled type="primary" onClick={handleShowReportModal}>
                  <FileAddOutlined />
                  Скачать отчет
                </ButtonStyled>
                <a href={Routes.calculation1} target="_blank" rel="noreferrer">
                  <ButtonStyled type="default">
                    Тепловая защита <ArrowRightOutlined />
                  </ButtonStyled>
                </a>
              </Row>
            </Space>
          </Col>
        </PreviewContainerStyled>
      </Space>
      <ReportModal visible={isShowReportModal} onCancel={handleCancelAtReportModal} onOk={handleOkAtReportModal} />
    </Fragment>
  );
};

export default ResultsBalk;

const PreviewContainerStyled = styled(Row)`
  border: 1px solid #e8e8e8;
`;

const ButtonStyled = styled(Button)`
  margin-right: 24px;
  @media screen and (max-width: 640px) {
    margin: 12px 0;
  }
`;

const ListContainerStyled = styled.div``;

const ImageBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
const Image = styled.img`
  width: 100%;
`;
