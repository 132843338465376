import React, {Fragment} from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';

interface IBreadcrumbsProps {
  value: string[];
  source: string[];
}

interface IBreadcrumbsItem {
  '@type': string;
  position: number;
  item: {
    '@id'?: string;
    name: string;
  };
}

interface IBreadcrumbsData {
  '@context': string;
  '@type': string;
  itemListElement: IBreadcrumbsItem[];
}

const hasSource = (index: number, source: string[]) => !!source[index];

const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({value, source}) => {
  let breadcrumbData: IBreadcrumbsData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [],
  };
  value.map((item, index) => {
    if (hasSource(index, source)) {
      breadcrumbData.itemListElement.push({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': source[index],
          name: item,
        },
      });
    } else {
      breadcrumbData.itemListElement.push({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          name: item,
        },
      });
    }
  });

  return (
    <BreadcrumbsStyled>
      {value.map((item, index) => (
        <Fragment key={index}>
          <BreadcrumbsValueStyled>
            {hasSource(index, source) ? <Link to={source[index]}>{item}</Link> : item}
          </BreadcrumbsValueStyled>
          {index !== value.length - 1 ? '/' : ''}
        </Fragment>
      ))}

      <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(breadcrumbData)}} />
    </BreadcrumbsStyled>
  );
};

const BreadcrumbsStyled = styled.div`
  display: flex;
`;

const BreadcrumbsValueStyled = styled.div`
  margin: 0 10px;
  &:first-of-type {
    margin-left: 0;
  }
`;

export default Breadcrumbs;
