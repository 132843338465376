import React, {useContext, Fragment} from 'react';
import {Row, Col, InputNumber, Typography, Form} from 'antd';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import {CloseOutlined} from '@ant-design/icons';

import {Control, Space} from '~src/components';
import {LocaleContext} from '~src/context';
import {IArmobelt} from '~src/services/Calculation2Service';

const {Title} = Typography;

interface IArmobeltsControlsProps {
  armobeltIndex: number;
  armobeltItem: IArmobelt;
}

const ArmobeltsControls: React.FC<IArmobeltsControlsProps> = ({armobeltIndex, armobeltItem}) => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {
    pages: {
      calculation2: {brickAndBlocksSections},
    },
  } = useContext(LocaleContext);
  const {userValues} = calculations[2];

  const getHandleInputChange = (name: keyof IArmobelt | string) => (value: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'armobelts',
      values: [
        {
          name,
          value,
          index: armobeltIndex,
        },
      ],
    });
  };

  const handleRemoveArmobelt = () => {
    dispatch('calculations/2/remove-item', {itemName: 'armobelts', index: armobeltIndex});
  };
  return (
    <Fragment>
      <Space size={10} direction="vertical">
        <Row align="middle">
          <TitleStyled level={4}>Армопояс {armobeltIndex + 1}</TitleStyled>
          <CloseOutlined onClick={handleRemoveArmobelt} />
        </Row>
        <Col md={24} sm={12}>
          <Row gutter={[20, 0]}>
            <ColStyled md={12} sm={24} xs={24}>
              <Space size={20} direction={'vertical'}>
                <Control label={brickAndBlocksSections.section5.armobelt.armobeltThickness.label}>
                  <FormItemStyled
                    validateStatus={
                      userValues.isInvalid &&
                      (userValues.armobelts[armobeltIndex].armobeltHeight === 0 ||
                        userValues.armobelts[armobeltIndex].armobeltHeight === null)
                        ? 'error'
                        : ''
                    }
                    help={
                      userValues.isInvalid &&
                      (userValues.armobelts[armobeltIndex].armobeltHeight === 0 ||
                        userValues.armobelts[armobeltIndex].armobeltHeight === null)
                        ? 'Обязательное поле'
                        : ''
                    }
                  >
                    <InputNumberStyled
                      min={0}
                      size={'large'}
                      placeholder={brickAndBlocksSections.section5.armobelt.armobeltThickness.placeholder}
                      onChange={getHandleInputChange('armobeltHeight')}
                      value={
                        armobeltItem && (armobeltItem.armobeltHeight === 0 ? undefined : armobeltItem.armobeltHeight)
                      }
                    />
                  </FormItemStyled>
                </Control>
              </Space>
            </ColStyled>
            <Col md={12} sm={24} xs={24}>
              <Space size={20} direction={'vertical'}>
                <Control label={brickAndBlocksSections.section5.armobelt.armobeltCount.label}>
                  <FormItemStyled
                    validateStatus={
                      userValues.isInvalid &&
                      (userValues.armobelts[armobeltIndex].armobeltCount === 0 ||
                        userValues.armobelts[armobeltIndex].armobeltCount === null)
                        ? 'error'
                        : ''
                    }
                    help={
                      userValues.isInvalid &&
                      (userValues.armobelts[armobeltIndex].armobeltCount === 0 ||
                        userValues.armobelts[armobeltIndex].armobeltCount === null)
                        ? 'Обязательное поле'
                        : ''
                    }
                  >
                    <InputNumberStyled
                      min={0}
                      size={'large'}
                      placeholder={brickAndBlocksSections.section5.armobelt.armobeltCount.placeholder}
                      onChange={getHandleInputChange('armobeltCount')}
                      value={
                        armobeltItem && (armobeltItem.armobeltCount === 0 ? undefined : armobeltItem.armobeltCount)
                      }
                    />
                  </FormItemStyled>
                </Control>
              </Space>
            </Col>
          </Row>
        </Col>
      </Space>
    </Fragment>
  );
};

const TitleStyled = styled(Title)`
  text-transform: uppercase;
  letter-spacing: 0.04em !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
  margin-right: 95px;
`;

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`;

const ColStyled = styled(Col)`
  margin-bottom: 20px;
`;

const FormItemStyled = styled(Form.Item)`
  margin-bottom: 0 !important;
`;
export default ArmobeltsControls;
