import React from 'react';
import {Table as TableAntd} from 'antd';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {Key} from 'antd/lib/table/interface';

const tableData = {
  columns: [
    {
      title: 'Наименование',
      dataIndex: 'materialName',
      key: 'materialName',
      width: '40%',
    },
    {
      title: 'ρ, кг/м³',
      dataIndex: 'materialDensity',
      key: 'materialDensity',
      width: '12.5%',
    },
    {
      title: 'λ(А), Вт/(м•°С)',
      dataIndex: 'materialTranscalencyA',
      key: 'materialTranscalencyA',
      width: '12.5%',
    },
    {
      title: 'λ(Б), Вт/(м•°С)',
      dataIndex: 'materialTranscalencyB',
      key: 'materialTranscalencyB',
      width: '12.5%',
    },
    {
      title: 'μ',
      dataIndex: 'materialVaporRermeability',
      key: 'materialVaporRermeability',
      width: '12.5%',
    },
    {
      title: 'Δw, %',
      dataIndex: 'materialDeltaW',
      key: 'materialDeltaW',
      width: '12.5%',
    },
  ],
};

interface ITableProps {
  className?: string;
  type: string;
  onSelect: (materialId: string) => void;
}

const Table: React.FC<ITableProps> = ({className, type, onSelect}) => {
  const {
    materials: {groupedValues},
  } = useStoreon<IState, IEvents>('materials');
  const [layerType, materialType] = type.split('--');
  const handleChange = (selectedRowKeys: Key[]) => {
    onSelect(String(selectedRowKeys[0]));
  };
  return (
    <TableAntd
      className={className}
      rowKey={'id'}
      size={'small'}
      indentSize={10}
      columns={tableData.columns}
      dataSource={groupedValues[layerType][materialType]}
      pagination={false}
      rowSelection={{
        type: 'radio',
        onChange: handleChange,
      }}
    />
  );
};

export default Table;
