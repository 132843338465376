import React, {useEffect, useRef, useState} from 'react';
import {Axis, Colors, Column, Diagram, DiagramKind, DiagramType, LinePoint} from './DiagramService';
import {IntersectionPoint} from './utils';

interface Props {
  axis?: Axis;
  type: DiagramType;
  kind: DiagramKind;
  width: number;
  height: number;
  dataSource: Column[] | LinePoint[];
  dataSourceId: number;
  intersectionPoints?: IntersectionPoint[];
  withDiagramBase?: boolean;
  columnLinesColors?: Colors[];
  intersectionColor?: Colors;
  canvasId?: string;
  hiddenAxis?: boolean;
  withFulcrumLines?: boolean;
}

const Chart: React.FC<Props> = props => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [chart, setChart] = useState<Diagram>();
  useEffect(() => {
    if (!canvasRef) return;
    setChart(
      new Diagram({
        canvas: canvasRef.current as HTMLCanvasElement,
        kind: props.kind,
        type: props.type,
        width: props.width,
        height: props.height,
        hiddenAxis: props.hiddenAxis,
        axis: props.axis,
        withFulcrumLines: props.withFulcrumLines,
      }),
    );
  }, [canvasRef]);

  useEffect(() => {
    if (!chart) return;
    chart.clear();

    chart.draw(props.dataSource, {
      withDiagramBase: props.withDiagramBase,
      columnLineColors: props.columnLinesColors,
      intersectionPoints: props.intersectionPoints,
      intersectionColor: props.intersectionColor,
      axis: props.axis,
    });
  }, [props.dataSourceId, chart]);
  return (
    <canvas id={props.canvasId || 'canvas'} width={`${props.width}px`} height={`${props.height}px`} ref={canvasRef} />
  );
};

export default Chart;
