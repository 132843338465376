import React, {useContext, Fragment} from 'react';
import {Row, Col, Select, InputNumber, Typography, Form} from 'antd';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import {CloseOutlined} from '@ant-design/icons';

import {Control, Space} from '~src/components';
import {LocaleContext} from '~src/context';

import TriangleGableControls from './TriangleGableControls';
import TrapezeGableControls from './TrapezeGableControls';
import PentaGableControls from './PentaGableControls';
import {IGable} from '~src/services/Calculation3Service';

const {Title} = Typography;

interface IGablesControlsProps {
  gableIndex: number;
  gableItem: IGable;
}

const GablesControls: React.FC<IGablesControlsProps> = ({gableIndex, gableItem}) => {
  const {
    pages: {
      calculation2: {brickAndBlocksSections},
    },
  } = useContext(LocaleContext);
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {userValues} = calculations[3];

  const handleSelectChange = (e: number) => {
    dispatch('calculations/3/set-group-values', {
      groupName: 'gables',
      values: [
        {
          name: 'gableType',
          value: e,
          index: gableIndex,
        },
        {
          name: 'gableHeight',
          value: 0,
          index: gableIndex,
        },
        {
          name: 'gableCount',
          value: 0,
          index: gableIndex,
        },
        {
          name: 'gableWidth',
          value: 0,
          index: gableIndex,
        },
        {
          name: 'gableBiggerWidth',
          value: 0,
          index: gableIndex,
        },
        {
          name: 'gableSmallerWidth',
          value: 0,
          index: gableIndex,
        },
        {
          name: 'gablePentaHeightBetween',
          value: 0,
          index: gableIndex,
        },
        {
          name: 'gablePentaTriangleHeight',
          value: 0,
          index: gableIndex,
        },
      ],
    });
  };

  const determineGableControls = () => {
    switch (userValues.gables[gableIndex].gableType) {
      case 0:
        return <TriangleGableControls gableIndex={gableIndex} gableItem={gableItem} />;

      case 1:
        return <TrapezeGableControls gableIndex={gableIndex} gableItem={gableItem} />;

      case 2:
        return <PentaGableControls gableIndex={gableIndex} gableItem={gableItem} />;
    }
  };

  const getHandleInputChange = (name: keyof IGable | string) => (value: number) => {
    dispatch('calculations/3/set-group-values', {
      groupName: 'gables',
      values: [
        {
          name,
          value,
          index: gableIndex,
        },
      ],
    });
  };

  const handleRemoveGable = () => {
    dispatch('calculations/3/remove-item', {itemName: 'gables', index: gableIndex});
  };

  return (
    <Fragment>
      <Space size={10} direction="vertical">
        <Row align="middle">
          <TitleStyled level={4}>Фронтон {gableIndex + 1}</TitleStyled>
          <CloseOutlined onClick={handleRemoveGable} />
        </Row>
        <Space size={20} direction="vertical">
          <Col sm={24} md={24}>
            <Row gutter={[20, 20]}>
              <Col md={12} sm={24} xs={24}>
                <Space size={20} direction="vertical">
                  <Control label={brickAndBlocksSections.section5.gables.gableType.label}>
                    <Select
                      placeholder={brickAndBlocksSections.section5.gables.gableType.placeholder}
                      size={'large'}
                      options={brickAndBlocksSections.section5.gables.gableType.values}
                      onChange={handleSelectChange}
                      value={gableItem.gableType}
                    />
                  </Control>
                </Space>
              </Col>
              <Col md={12} sm={24} xs={24}>
                <Space size={20} direction={'vertical'}>
                  <Control label={brickAndBlocksSections.section5.gables.gableCount.label}>
                    <FormItemStyled
                      validateStatus={
                        userValues.isInvalid &&
                        (userValues.gables[gableIndex].gableCount === 0 ||
                          userValues.gables[gableIndex].gableCount === null)
                          ? 'error'
                          : ''
                      }
                      help={
                        userValues.isInvalid &&
                        (userValues.gables[gableIndex].gableCount === 0 ||
                          userValues.gables[gableIndex].gableCount === null)
                          ? 'Обязательное поле'
                          : ''
                      }
                    >
                      <InputNumberStyled
                        min={0}
                        size="large"
                        placeholder={brickAndBlocksSections.section5.gables.gableCount.placeholder}
                        onChange={getHandleInputChange(brickAndBlocksSections.section5.gables.gableCount.name)}
                        value={gableItem && (gableItem.gableCount === 0 ? undefined : gableItem.gableCount)}
                      />
                    </FormItemStyled>
                  </Control>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={24}>{determineGableControls()}</Col>
        </Space>
      </Space>
    </Fragment>
  );
};

const TitleStyled = styled(Title)`
  text-transform: uppercase;
  letter-spacing: 0.04em !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
  margin-right: 95px;
`;

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`;

const FormItemStyled = styled(Form.Item)`
  margin-bottom: 0 !important;
`;

export default GablesControls;
