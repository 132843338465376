import React from 'react';
import styled, {css} from 'styled-components';

interface ILineProps {
  color: string;
}

const Line: React.FC<ILineProps> = props => {
  return (
    <LineWrapperStyled>
      <LineStyled color={props.color} />
    </LineWrapperStyled>
  );
};

const LineWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
`;

const LineStyled = styled.div`
  width: 100%;
  height: 4px;
  ${props =>
    props.color &&
    css`
      background-color: ${props.color};
    `};
`;

export default Line;
