import React from 'react';
import styled from 'styled-components';
import {customColors} from '~src/theme/colors';

interface IZoneProps {}

const Zone: React.FC<IZoneProps> = () => {
  return <ZoneStyled />;
};

const ZoneStyled = styled.div`
  width: 22px;
  height: 22px;
  background-color: ${customColors.blue400};
  border-left: 2px solid ${customColors.blue1000};
  border-right: 2px solid ${customColors.blue1000};
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Zone;
