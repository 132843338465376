import React, {useContext} from 'react';
import {LocaleContext} from '~src/context';
import {Table} from 'antd';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {createDataSourceResult5} from '~src/utils/calculations';

interface IResultHeatLossParamsProps {
  className?: string;
}

const ResultHumidityAnnualCycle: React.FC<IResultHeatLossParamsProps> = ({className}) => {
  const {calculations} = useStoreon<IState, IEvents>('calculations');

  const {
    pages: {
      calculation1: {result},
    },
  } = useContext(LocaleContext);

  const {dataSource} = createDataSourceResult5(calculations[1].userValues, calculations[1].resultValues, result);

  return (
    <Table
      className={className}
      dataSource={dataSource}
      columns={result.result5TableGeneration.humidityAnnualCycle.columns}
      bordered
      pagination={false}
      scroll={{x: true}}
    />
  );
};

export default ResultHumidityAnnualCycle;
