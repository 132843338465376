import React, {useContext, useState, useEffect} from 'react';
import {Row, Col, Select, InputNumber, Tabs, Form} from 'antd';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import InputSelect from '~src/components/molecules/InputSelect';

import {Control, Space} from '~src/components';
import {LocaleContext} from '~src/context';
import {IFacade} from '~src/services/Calculation2Service';

const {TabPane} = Tabs;

interface ISection2ControlsControlsProps {
  section2Index: number;
  chosenFacadeOption: IFacade | null;
}

const BrickFacadeInput: React.FC<ISection2ControlsControlsProps> = ({section2Index, chosenFacadeOption}) => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {materials} = useStoreon<IState, IEvents>('materials');
  const {calculation2} = materials;
  const {userValues} = calculations[2];
  const [facadeMaterials, setFacadeMaterials] = useState([{label: '', value: 0}]);
  const [chosenOption, setChosenOption] = useState(null);
  const [chosenTrimMarginOption, setChosenTrimMarginOption] = useState(undefined);
  const [chosenSeamThicknessOption, setChosenSeamThicknessOption] = useState(undefined);
  const {
    pages: {
      calculation2: {brickAndBlocksSections},
    },
  } = useContext(LocaleContext);

  const getHandleInputChange = (name: keyof IFacade | string) => (value: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'facades',
      values: [
        {
          name,
          value,
          index: section2Index,
        },
      ],
    });
  };

  const handleSeamThicknessChange = (e: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'facades',
      values: [
        {
          name: 'facadeSeamThickness',
          value: e,
          index: section2Index,
        },
      ],
    });
    setChosenSeamThicknessOption(e);
  };

  const handleDefaultSizeChange = (e: string) => {
    let selectedMaterial = calculation2.facadeType.materials.find(item => {
      return item.id === e;
    });
    selectedMaterial && setChosenOption(selectedMaterial.id);
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'facades',
        values: [
          {
            name: 'facadeDefaultSize',
            value: true,
            index: section2Index,
          },
        ],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'facades',
        values: [
          {
            name: 'facadeBrickLength',
            value: selectedMaterial.facadeBrickLength,
            index: section2Index,
          },
        ],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'facades',
        values: [
          {
            name: 'facadeBrickWidth',
            value: selectedMaterial.facadeBrickWidth,
            index: section2Index,
          },
        ],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'facades',
        values: [
          {
            name: 'facadeBrickHeight',
            value: selectedMaterial.facadeBrickHeight,
            index: section2Index,
          },
        ],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'facades',
        values: [
          {
            name: 'facadeBrickWeight',
            value: selectedMaterial.facadeBrickWeight,
            index: section2Index,
          },
        ],
      });
  };

  const handleTrimMarginOptionChange = (e: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'facades',
      values: [
        {
          name: 'facadeTrimMargin',
          value: e,
          index: section2Index,
        },
      ],
    });
    setChosenTrimMarginOption(e);
  };

  useEffect(() => {
    let materialLabels = [];
    materialLabels = calculation2.facadeType.materials.map(item => {
      let materialLabel =
        item.name +
        ' ' +
        (item.facadeBrickLength !== null && item.facadeBrickLength !== undefined
          ? item.facadeBrickLength.toString() + 'x'
          : '') +
        (item.facadeBrickWidth !== null && item.facadeBrickWidth !== undefined
          ? item.facadeBrickWidth.toString() + 'x'
          : '') +
        (item.facadeBrickHeight !== null && item.facadeBrickHeight !== undefined
          ? item.facadeBrickHeight.toString() + 'мм, '
          : '') +
        (item.facadeBrickWeight !== null && item.facadeBrickWeight !== undefined
          ? item.facadeBrickWeight.toString() + 'кг'
          : '');
      return {
        label: materialLabel,
        value: item.id,
      };
    });
    setFacadeMaterials(materialLabels);
    setChosenOption(chosenFacadeOption);
  }, [calculation2.facadeType.materials]);

  return (
    <Row align="top" justify="space-between">
      <ContentWrapperStyled>
        <Col span={24}>
          <Space direction={'vertical'} size={10}>
            <Tabs animated={false} defaultActiveKey="1">
              <TabPaneStyled tab={brickAndBlocksSections.section2.facadeSize.defaultSize.label} key="1">
                <Row gutter={[20, 0]}>
                  <Col span={24} xs={24}>
                    <Control label={brickAndBlocksSections.section2.facadeSize.defaultSize.facadeSize.label}>
                      <FormItemStyled
                        validateStatus={
                          userValues.isInvalid && userValues.facades[0].facadeDefaultSize === false ? 'error' : ''
                        }
                        help={
                          userValues.isInvalid && userValues.facades[0].facadeDefaultSize === false
                            ? 'Обязательное поле'
                            : ''
                        }
                      >
                        <Select
                          size={'large'}
                          value={chosenOption}
                          options={facadeMaterials}
                          placeholder={brickAndBlocksSections.section2.facadeSize.defaultSize.facadeSize.placeholder}
                          onChange={handleDefaultSizeChange}
                        />
                      </FormItemStyled>
                    </Control>
                  </Col>
                </Row>
              </TabPaneStyled>
              <TabPaneStyled tab={brickAndBlocksSections.section2.facadeSize.userSize.label} key="2">
                <Row gutter={[20, 20]}>
                  <Col md={12} sm={24} xs={24}>
                    <Space size={20} direction={'vertical'}>
                      <Control label={brickAndBlocksSections.section2.facadeSize.userSize.facadeLength.label}>
                        <InputNumberStyled
                          min={0}
                          size={'large'}
                          placeholder={brickAndBlocksSections.section2.facadeSize.userSize.facadeLength.placeholder}
                          onChange={getHandleInputChange(
                            brickAndBlocksSections.section2.facadeSize.userSize.facadeLength.name,
                          )}
                        />
                      </Control>
                      <Control label={brickAndBlocksSections.section2.facadeSize.userSize.facadeHeight.label}>
                        <InputNumberStyled
                          min={0}
                          size={'large'}
                          placeholder={brickAndBlocksSections.section2.facadeSize.userSize.facadeHeight.placeholder}
                          onChange={getHandleInputChange(
                            brickAndBlocksSections.section2.facadeSize.userSize.facadeHeight.name,
                          )}
                        />
                      </Control>
                    </Space>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Space size={20} direction={'vertical'}>
                      <Control label={brickAndBlocksSections.section2.facadeSize.userSize.facadeWidth.label}>
                        <InputNumberStyled
                          min={0}
                          size={'large'}
                          placeholder={brickAndBlocksSections.section2.facadeSize.userSize.facadeWidth.placeholder}
                          onChange={getHandleInputChange(
                            brickAndBlocksSections.section2.facadeSize.userSize.facadeWidth.name,
                          )}
                        />
                      </Control>
                      <Control label={brickAndBlocksSections.section2.facadeSize.userSize.facadeWeight.label}>
                        <InputNumberStyled
                          min={0}
                          size={'large'}
                          placeholder={brickAndBlocksSections.section2.facadeSize.userSize.facadeWeight.placeholder}
                          onChange={getHandleInputChange(
                            brickAndBlocksSections.section2.facadeSize.userSize.facadeWeight.name,
                          )}
                        />
                      </Control>
                    </Space>
                  </Col>
                </Row>
              </TabPaneStyled>
            </Tabs>
            <Space size={10} direction="vertical">
              <Col>
                <Control label={brickAndBlocksSections.section2.seamThickness.label}>
                  <FormItemStyled
                    validateStatus={
                      userValues.isInvalid &&
                      (userValues.facades[0].facadeSeamThickness === 0 ||
                        userValues.facades[0].facadeSeamThickness === null)
                        ? 'error'
                        : ''
                    }
                    help={
                      userValues.isInvalid &&
                      (userValues.facades[0].facadeSeamThickness === 0 ||
                        userValues.facades[0].facadeSeamThickness === null)
                        ? 'Обязательное поле'
                        : ''
                    }
                  >
                    <Select
                      placeholder={brickAndBlocksSections.section2.seamThickness.placeholder}
                      size={'large'}
                      options={brickAndBlocksSections.section2.seamThickness.values}
                      onChange={handleSeamThicknessChange}
                      value={chosenSeamThicknessOption}
                    />
                  </FormItemStyled>
                </Control>
              </Col>
              <Col>
                <Control label={brickAndBlocksSections.section2.trimMargin.label}>
                  <FormItemStyled
                    validateStatus={
                      userValues.isInvalid &&
                      (userValues.facades[0].facadeTrimMargin === 0 || userValues.facades[0].facadeTrimMargin === null)
                        ? 'error'
                        : ''
                    }
                    help={
                      userValues.isInvalid &&
                      (userValues.facades[0].facadeTrimMargin === 0 || userValues.facades[0].facadeTrimMargin === null)
                        ? 'Обязательное поле'
                        : ''
                    }
                  >
                    <InputSelect
                      inputProps={{
                        size: 'large',
                        placeholder: brickAndBlocksSections.section2.trimMargin.manualInputPlaceholder,
                        inputButton: 'Выбрать значение',
                        min: 0,
                        max: 100,
                      }}
                      selectProps={{
                        size: 'large',
                        placeholder: brickAndBlocksSections.section2.trimMargin.placeholder,
                        options: brickAndBlocksSections.section2.trimMargin.values,
                        selectButton: 'Ввести значение',
                        value: chosenTrimMarginOption,
                      }}
                      onInputChange={getHandleInputChange(brickAndBlocksSections.section2.trimMargin.name)}
                      onSelectChange={handleTrimMarginOptionChange}
                    />
                  </FormItemStyled>
                </Control>
              </Col>
            </Space>
          </Space>
        </Col>
      </ContentWrapperStyled>
    </Row>
  );
};

const TabPaneStyled = styled(TabPane)`
  width: 100%;
`;

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`;

const ContentWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;

  @media screen and (max-width: 320px) {
    flex-direction: column-reverse;
  }

  @media screen and (min-width: 321px) and (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

const FormItemStyled = styled(Form.Item)`
  margin-bottom: 0 !important;
`;

export default BrickFacadeInput;
