import React, {useContext, useEffect} from 'react';
import styled from 'styled-components';
import {Col, Row, Typography, Button} from 'antd/lib';
import {LocaleContext} from '~src/context';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {navigate} from 'gatsby';
import {Routes} from '~src/utils/routes';

const Articles: React.FC = () => {
  const {dispatch, articles} = useStoreon<IState, IEvents>('articles');

  const {
    pages: {articlesPage},
  } = useContext(LocaleContext);

  useEffect(() => {
    dispatch('articles/fetch-articles', {strategy: 'overwrite'});
  }, []);

  const handleClick = (id: number | undefined) => {
    navigate(Routes.article + `?id=${id}`);
  };

  return (
    <Row gutter={20}>
      <Col span={24}>
        <Typography.Title level={1}>{articlesPage.header1}</Typography.Title>
      </Col>

      {articles.values.map(article => (
        <ColStyled xs={24} sm={12} md={8} lg={6} key={article.id}>
          <CardWrapperStyled>
            <CardRowStyled onClick={() => handleClick(article.id)}>
              <ImageWrapperColStyled span={24}>
                <ImageStyled src={article.previewUrl} />
              </ImageWrapperColStyled>
              <Col span={24}>
                <HeaderStyled>{article.title}</HeaderStyled>
              </Col>
              <Col span={24}>
                <TextStyled>{article.description}</TextStyled>
              </Col>
            </CardRowStyled>
            <BottomWrapperStyled>
              <DateStyled>{article.creationTime}</DateStyled>
              <Button onClick={() => handleClick(article.id)}>Подробнее →</Button>
            </BottomWrapperStyled>
          </CardWrapperStyled>
        </ColStyled>
      ))}

      <SpaceStyled span={24} />
    </Row>
  );
};

const CardWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  border-radius: 10px;
  transition: 0.5s;
  &:hover {
    box-shadow: 0 0 8px 1px #223c5033;
  }
`;

const CardRowStyled = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-content: flex-start;
  width: 100%;
  cursor: pointer;
`;

const ColStyled = styled(Col)`
  padding: 5px;
`;

const SpaceStyled = styled(Col)`
  height: 50px;
`;

const ImageWrapperColStyled = styled(Col)`
  position: relative;
  width: 100%;
  height: min-content;
  margin-bottom: 10px;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;

const ImageStyled = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  background: linear-gradient(180deg, rgba(24, 144, 255, 0.2) 0%, rgba(24, 144, 255, 0.2) 100%);
  min-width: 100px;
  min-height: 100px;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  border-radius: 10px;
`;

const HeaderStyled = styled.h2`
  min-height: 51.19px;
  word-break: break-word;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.04em;
  color: #000000;
  text-transform: uppercase;
`;

const TextStyled = styled.span`
  word-break: break-word;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: #27282a;
`;

const DateStyled = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 170%;
  color: #666;
`;

const BottomWrapperStyled = styled.div`
  display: flex;
  width: 100%;
  height: min-content;
  justify-content: space-between;
  align-items: flex-end;
  margin: 15px 0;
`;

export default Articles;
