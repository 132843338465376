import React from 'react';
import $ from 'jquery';

import {MapStyled} from './styled';
import {windowGlobal} from '~src/utils/dom';

interface IMapProps {
  clientWidth: number;
  selectedRegionCode: string;
  onChange: (region: string) => void;
}
interface IMapState {}

class Map extends React.Component<IMapProps, IMapState> {
  state = {
    style: {},
    clientWidth: 0,
    isLoaded: false,
  };

  public shouldComponentUpdate(nextProps: IMapProps, nextState: IMapState) {
    if (this.props.hoveredRegion !== nextProps.hoveredRegion) return false;

    return true;
  }

  public componentDidMount() {
    if (windowGlobal) {
      require('./map/jqvmap/js/jquery.vmap');
      require('./map/jqvmap/maps/jquery.vmap.russia');
    }

    const clientWidth = document.documentElement.clientWidth;

    this.setState({
      clientWidth,
      style: {
        width: `${clientWidth * 0.66}px`,
        height: '600px',
      },
    });
  }

  public componentDidUpdate() {
    const {clientWidth, isLoaded} = this.state;

    if (clientWidth && !isLoaded) {
      this.createMap();
      this.setState({isLoaded: true});
    }
  }

  /*
   * Обработчик клика по региону
   *
   */
  handleClickByRegion = (element: any, code: string): void => {
    this.props.onChange(code);
  };

  /*
   * Наведение курсора на регион
   *
   */
  handleHoverByRegion = (type: 'over' | 'out') => (element: any, code: string, region: string) => {};

  public createMap(): void {
    const options = {
      map: 'russia_en',
      backgroundColor: '#FFFFFF',
      borderColor: '#FFFFFF',
      borderWidth: 2,
      color: '#91D5FF',
      selectedColor: '#1890FF',
      hoverOpacity: 0.7,
      selectedRegions: [this.props.selectedRegionCode],
      enableZoom: true,
      showTooltip: true,
      onRegionClick: this.handleClickByRegion,
      onRegionOver: this.handleHoverByRegion('over'),
      onRegionOut: this.handleHoverByRegion('out'),
    };
    if (windowGlobal) {
      ($('#vmap') as any).vectorMap(options);
    }
  }
  public render(): JSX.Element | null {
    const {style, clientWidth} = this.state;

    if (!clientWidth) return null;

    return <MapStyled id={'vmap'} style={style} />;
  }
}

export default Map;
