import {Axis, Column, ColumnLine, LinePoint} from '~src/components/molecules/Chart/DiagramService';
import {IUserValuesAtCalculation1} from '~src/store/Calculations';
import {IResultValues} from '~src/services/Calculation1Service';

const getTick = (max: number) => {
  let count1000Tick = Math.round(max / 1000);
  let count500Tick = Math.round(max / 500);
  let count100Tick = Math.round(max / 100);
  let count50Tick = Math.round(max / 50);
  let count25Tick = Math.round(max / 25);
  let count20Tick = Math.round(max / 20);
  let count15Tick = Math.round(max / 15);
  let count10Tick = Math.round(max / 10);
  let count5Tick = Math.round(max / 5);
  let count4Tick = Math.round(max / 4);
  let count3Tick = Math.round(max / 3);
  let count2Tick = Math.round(max / 2);
  let count1Tick = Math.round(max);
  let count05tick = Math.round(max / 0.5);
  let count02tick = Math.round(max / 0.2);
  let count01tick = Math.round(max / 0.1);

  const check = (value: number) => {
    return value >= 4 && value <= 8;
  };

  if (check(count1000Tick)) {
    return 1000;
  } else if (check(count500Tick)) {
    return 500;
  } else if (check(count100Tick)) {
    return 100;
  } else if (check(count50Tick)) {
    return 50;
  } else if (check(count25Tick)) {
    return 25;
  } else if (check(count20Tick)) {
    return 20;
  } else if (check(count15Tick)) {
    return 15;
  } else if (check(count10Tick)) {
    return 10;
  } else if (check(count5Tick)) {
    return 5;
  } else if (check(count4Tick)) {
    return 4;
  } else if (check(count3Tick)) {
    return 3;
  } else if (check(count2Tick)) {
    return 2;
  } else if (check(count1Tick)) {
    return 1;
  } else if (check(count05tick)) {
    return 0.5;
  } else if (check(count02tick)) {
    return 0.2;
  } else if (check(count01tick)) {
    return 0.1;
  }
  return 10;
};

const getXValues = (dataSource: (Column | LinePoint)[]) => {
  const getXMax = (dataSource: (Column | LinePoint)[]) => {
    return dataSource.reduce((acc, column) => {
      if ('width' in column) return acc + column.width;
      return Math.max(acc, column.x);
    }, 0);
  };

  const xMax = getXMax(dataSource);
  const xTick = getTick(xMax);
  return {
    xMin: 0,
    xMax: xMax + xTick,
    xTick,
  };
};
const getYValues = (dataSource: (Column | LinePoint)[]) => {
  let isLine = false;
  const allPoints = dataSource
    .reduce((acc: (number | undefined)[], column) => {
      if ('y' in column) {
        isLine = true;
        return [...acc, column.y];
      }
      return [...acc, column.line1?.lineStart, column.line1?.lineEnd, column.line2?.lineStart, column.line2?.lineEnd];
    }, [])
    .filter(value => value) as number[];
  const yMin = isLine ? 0 : Math.round(Math.min(...allPoints));
  const yMax = Math.round(Math.max(...allPoints)) + 5;
  const yTick = getTick(yMax);

  return {
    yMin,
    yMax,
    yTick,
  };
};
export const getAxis = (
  dataSource: (Column | LinePoint)[],
  vectors: {x?: boolean; y?: boolean} = {x: true, y: true},
): Axis => {
  const xValues = vectors.x ? getXValues(dataSource) : {};
  const yValues = vectors.y ? getYValues(dataSource) : {};
  return {
    ...xValues,
    ...yValues,
  };
};

export const getBarDataSource = (
  userValues: IUserValuesAtCalculation1,
  lines?: {line1: ColumnLine; line2: ColumnLine}[],
): Column[] => {
  return userValues.layers
    .filter(layer => layer.isEnabled)
    .map((layer, index) => {
      const linesObj = (lines && lines[index]) || {};
      return {
        width: layer.t,
        index: layer.layerId,
        type: layer.layerType,
        ...linesObj,
      };
    });
};

export const getLinesForResult1 = (
  userValues: IUserValuesAtCalculation1,
  resultValues: IResultValues,
): {line1: ColumnLine; line2: ColumnLine}[] => {
  return userValues.layers.map((layer, index) => ({
    line1: {
      lineStart: resultValues.layersTemperatureWithoutVAG[index],
      lineEnd: resultValues.layersTemperatureWithoutVAG[index + 1],
    },
    line2: {
      lineStart: resultValues.layersDewTemperature[index],
      lineEnd: resultValues.layersDewTemperature[index + 1],
    },
  }));
  // return userValues.layers.map((layer, index) => ({
  //   line1: {
  //     lineStart: resultValues.layersTemperatureWithVAG[index],
  //     lineEnd: resultValues.layersTemperatureWithVAG[index + 1],
  //   },
  //   line2: {
  //     lineStart: resultValues.layersDewTemperatureWithVAG[index],
  //     lineEnd: resultValues.layersDewTemperatureWithVAG[index + 1],
  //   },
  // }));
};

export const getLinesForResult2 = (
  userValues: IUserValuesAtCalculation1,
  resultValues: IResultValues,
): {line1: ColumnLine; line2: ColumnLine}[] => {
  return userValues.layers.map((layer, index) => ({
    line1: {
      lineStart: resultValues.layersE[index],
      lineEnd: resultValues.layersE[index + 1],
    },
    line2: {
      lineStart: resultValues.layersEp[index],
      lineEnd: resultValues.layersEp[index + 1],
    },
  }));
  // return userValues.layers.map((layer, index) => ({
  //   line1: {
  //     lineStart: resultValues.layersEWithVAG[index],
  //     lineEnd: resultValues.layersEWithVAG[index + 1],
  //   },
  //   line2: {
  //     lineStart: resultValues.layersEpWithVAG[index],
  //     lineEnd: resultValues.layersEpWithVAG[index + 1],
  //   },
  // }));
};

export const getLineDataSource = (
  resultValues: IResultValues,
  colors: {
    r_c: string;
    r_e: string;
    r_t: string;
    R: string;
    'r10%': string;
    'r25%': string;
    'r50%': string;
    'r100%': string;
  },
) => {
  const xPoints = [
    {point: resultValues.r_c, color: colors.r_c},
    {point: resultValues.r_e, color: colors.r_e},
    {point: resultValues.r_t, color: colors.r_t},
    {point: resultValues.R, color: colors.R},
    {point: resultValues['r10%'], color: colors['r10%']},
    {point: resultValues['r25%'], color: colors['r10%']},
    {point: resultValues['r50%'], color: colors['r10%']},
    {point: resultValues['r100%'], color: colors['r10%']},
  ];
  const yPoints = [
    resultValues.q1,
    resultValues.q2,
    resultValues.q3,
    resultValues.q,
    resultValues.q4,
    resultValues.q5,
    resultValues.q6,
    resultValues.q7,
  ];

  return xPoints
    .map((xPoint, index) => ({x: xPoint.point, color: xPoint.color, y: yPoints[index]}))
    .sort((a, b) => a.x - b.x);
};
