import React, {useContext} from 'react';
import {LocaleContext} from '~src/context';
import {Table} from 'antd';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {createDataSourceResult4} from '~src/utils/calculations';

interface IResultHeatLossParamsProps {
  className?: string;
}

const ResultHumidityRegime: React.FC<IResultHeatLossParamsProps> = ({className}) => {
  const {calculations} = useStoreon<IState, IEvents>('calculations');
  const {
    pages: {
      calculation1: {result},
    },
  } = useContext(LocaleContext);
  const dataSource = createDataSourceResult4(calculations[1].userValues);
  return (
    <Table
      className={className}
      dataSource={dataSource}
      columns={result.result4TableGeneration.humidityRegime.columns}
      bordered
      pagination={false}
    />
  );
};

export default ResultHumidityRegime;
