import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import imageRenderService from '~src/services/ImageRenderService';

export interface IPreviewImages {
  bearingWallImage: string | undefined;
  facadeImage: string | undefined;
  insulationImage: string | undefined;
}

export interface IMaterialPreviewProps {
  sectionToRender: string;
}

const MaterialPreview: React.FC<IMaterialPreviewProps> = ({sectionToRender}) => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {userValues} = calculations[2];
  const [renderedImage, setRenderedImage] = useState({});

  const setImageForResult = () => {
    dispatch('calculations/2/set-values', [{name: 'bearingWallImage', value: renderedImage.bearingWallImage}]);
    dispatch('calculations/2/set-values', [{name: 'facadeImage', value: renderedImage.facadeImage}]);
    dispatch('calculations/2/set-values', [{name: 'insulationImage', value: renderedImage.insulationImage}]);
  };

  useEffect(() => {
    setRenderedImage(
      imageRenderService.calculate(
        userValues.bearingWalls[0].brickType,
        userValues.bearingWalls[0].executionType,
        userValues.bearingWalls[0].masonryOption,
        userValues.facades[0].facadeType,
        userValues.insulation[0].insulationType,
      ),
    );
  }, [userValues]);

  const determineSectionToRender = () => {
    switch (sectionToRender) {
      case 'bearingWalls':
        return (
          <ImageBox>
            <Image src={renderedImage.bearingWallImage} alt="" />
          </ImageBox>
        );
      case 'facades':
        return (
          <ImageBox>
            <Image src={renderedImage.bearingWallImage} alt="" />
            <Image src={renderedImage.facadeImage} alt="" />
          </ImageBox>
        );
      case 'insulation':
        return (
          <ImageBox>
            <Image src={renderedImage.bearingWallImage} alt="" />
            <Image src={renderedImage.facadeImage} alt="" />
            {userValues.enabledFlags.find(item => item === 'insulationPanel') &&
            userValues.insulation[0].insulationType !== '' ? (
              <Image src={renderedImage.insulationImage} alt="" />
            ) : null}
          </ImageBox>
        );
      case 'balk':
        return (
          <ImageBox>
            <Image src={renderedImage.bearingWallImage} alt="" />
          </ImageBox>
        );
    }
    return renderedImage;
  };
  return determineSectionToRender();
};

const ImageBox = styled.div`
  height: 350px;

  @media screen and (max-width: 320px) {
    height: 150px;
    width: 200px;
  }

  @media screen and (min-width: 321px) and (max-width: 812px) {
    height: 200px;
    width: 250px;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 560px;
  position: absolute;
  &:nth-of-type(1) {
    z-index: 0;
  }

  &:nth-of-type(2) {
    z-index: 2;
  }

  &:nth-of-type(3) {
    z-index: 1;
  }

  @media screen and (max-width: 640px) {
    width: 100%;
  }
`;

export default MaterialPreview;
