import React from 'react';
import styled, {css} from 'styled-components';
import {Typography} from 'antd';
import * as colors from '@ant-design/colors';
import {ExclamationCircleOutlined, CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';
interface IStatusMessageProps {
  type: 'success' | 'warning' | 'error';
  description: string;
}

const StatusMessage: React.FC<IStatusMessageProps> = props => {
  return (
    <TextStyled colortype={props.type}>
      {props.type === 'success' && <CheckCircleOutlined />}
      {props.type === 'warning' && <ExclamationCircleOutlined />}
      {props.type === 'error' && <CloseCircleOutlined />}
      &nbsp;&nbsp;
      {props.description}
    </TextStyled>
  );
};

const TextStyled = styled(Typography.Text)<{colortype: IStatusMessageProps['type']}>`
  ${props =>
    (props.colortype === 'success' &&
      css`
        color: ${colors.green.primary};
      `) ||
    (props.colortype === 'warning' &&
      css`
        color: ${colors.orange.primary};
      `) ||
    (props.colortype === 'error' &&
      css`
        color: ${colors.red.primary};
      `)};
`;

export default StatusMessage;
