import React, {useContext, Fragment} from 'react';
import {Row, Col, InputNumber, Typography, Form} from 'antd';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import {CloseOutlined} from '@ant-design/icons';

import {Control, Space} from '~src/components';
import {LocaleContext} from '~src/context';
import {IJumper} from '~src/services/Calculation2Service';
import AdditionalMaterialPreview from '~src/components/molecules/AdditionalMaterialPreview';

const {Title} = Typography;

interface IJumpersControlsProps {
  jumperIndex: number;
  jumperItem: IJumper;
}

const JumpersControls: React.FC<IJumpersControlsProps> = ({jumperIndex, jumperItem}) => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {
    pages: {
      calculation2: {brickAndBlocksSections},
    },
  } = useContext(LocaleContext);
  const {userValues} = calculations[2];

  const getHandleInputChange = (name: keyof IJumper | string) => (value: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'jumpers',
      values: [
        {
          name,
          value,
          index: jumperIndex,
        },
      ],
    });
  };

  const handleRemoveJumper = () => {
    dispatch('calculations/2/remove-item', {itemName: 'jumpers', index: jumperIndex});
  };
  return (
    <Fragment>
      <Space size={10} direction="vertical">
        <Row align="middle">
          <TitleStyled level={4}>Перемычка {jumperIndex + 1}</TitleStyled>
          <CloseOutlined onClick={handleRemoveJumper} />
        </Row>
        <Col md={24} sm={12}>
          <Row gutter={[20, 20]}>
            <Col md={12} sm={24} xs={24}>
              <Space size={20} direction={'vertical'}>
                <Control label={brickAndBlocksSections.section5.jumpers.jumpersLength.label}>
                  <FormItemStyled
                    validateStatus={
                      userValues.isInvalid &&
                      (userValues.jumpers[jumperIndex].jumperLength === 0 ||
                        userValues.jumpers[jumperIndex].jumperLength === null)
                        ? 'error'
                        : ''
                    }
                    help={
                      userValues.isInvalid &&
                      (userValues.jumpers[jumperIndex].jumperLength === 0 ||
                        userValues.jumpers[jumperIndex].jumperLength === null)
                        ? 'Обязательное поле'
                        : ''
                    }
                  >
                    <InputNumberStyled
                      min={0}
                      size={'large'}
                      placeholder={brickAndBlocksSections.section5.jumpers.jumpersLength.placeholder}
                      onChange={getHandleInputChange(brickAndBlocksSections.section5.jumpers.jumpersLength.name)}
                      value={jumperItem && (jumperItem.jumperLength === 0 ? undefined : jumperItem.jumperLength)}
                    />
                  </FormItemStyled>
                </Control>
                <Control label={brickAndBlocksSections.section5.jumpers.jumpersWidth.label}>
                  <FormItemStyled
                    validateStatus={
                      userValues.isInvalid &&
                      (userValues.jumpers[jumperIndex].jumperWidth === 0 ||
                        userValues.jumpers[jumperIndex].jumperWidth === null)
                        ? 'error'
                        : ''
                    }
                    help={
                      userValues.isInvalid &&
                      (userValues.jumpers[jumperIndex].jumperWidth === 0 ||
                        userValues.jumpers[jumperIndex].jumperWidth === null)
                        ? 'Обязательное поле'
                        : ''
                    }
                  >
                    <InputNumberStyled
                      min={0}
                      size={'large'}
                      placeholder={brickAndBlocksSections.section5.jumpers.jumpersWidth.placeholder}
                      onChange={getHandleInputChange(brickAndBlocksSections.section5.jumpers.jumpersWidth.name)}
                      value={jumperItem && (jumperItem.jumperWidth === 0 ? undefined : jumperItem.jumperWidth)}
                    />
                  </FormItemStyled>
                </Control>
              </Space>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Space size={20} direction={'vertical'}>
                <Control label={brickAndBlocksSections.section5.jumpers.jumpersHeight.label}>
                  <FormItemStyled
                    validateStatus={
                      userValues.isInvalid &&
                      (userValues.jumpers[jumperIndex].jumperHeight === 0 ||
                        userValues.jumpers[jumperIndex].jumperHeight === null)
                        ? 'error'
                        : ''
                    }
                    help={
                      userValues.isInvalid &&
                      (userValues.jumpers[jumperIndex].jumperHeight === 0 ||
                        userValues.jumpers[jumperIndex].jumperHeight === null)
                        ? 'Обязательное поле'
                        : ''
                    }
                  >
                    <InputNumberStyled
                      min={0}
                      size={'large'}
                      placeholder={brickAndBlocksSections.section5.jumpers.jumpersHeight.placeholder}
                      onChange={getHandleInputChange(brickAndBlocksSections.section5.jumpers.jumpersHeight.name)}
                      value={jumperItem && (jumperItem.jumperHeight === 0 ? undefined : jumperItem.jumperHeight)}
                    />
                  </FormItemStyled>
                </Control>
                <Control label={brickAndBlocksSections.section5.jumpers.jumpersCount.label}>
                  <FormItemStyled
                    validateStatus={
                      userValues.isInvalid &&
                      (userValues.jumpers[jumperIndex].jumperCount === 0 ||
                        userValues.jumpers[jumperIndex].jumperCount === null)
                        ? 'error'
                        : ''
                    }
                    help={
                      userValues.isInvalid &&
                      (userValues.jumpers[jumperIndex].jumperCount === 0 ||
                        userValues.jumpers[jumperIndex].jumperCount === null)
                        ? 'Обязательное поле'
                        : ''
                    }
                  >
                    <InputNumberStyled
                      min={0}
                      size={'large'}
                      placeholder={brickAndBlocksSections.section5.jumpers.jumpersCount.placeholder}
                      onChange={getHandleInputChange(brickAndBlocksSections.section5.jumpers.jumpersCount.name)}
                      value={jumperItem && (jumperItem.jumperCount === 0 ? undefined : jumperItem.jumperCount)}
                    />
                  </FormItemStyled>
                </Control>
              </Space>
            </Col>
          </Row>
        </Col>
      </Space>
    </Fragment>
  );
};

const TitleStyled = styled(Title)`
  text-transform: uppercase;
  letter-spacing: 0.04em !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
  margin-right: 95px;
`;

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`;

const FormItemStyled = styled(Form.Item)`
  margin-bottom: 0 !important;
`;
export default JumpersControls;
