import React, {useContext, useEffect} from 'react';
import {Table} from 'antd';
import {LocaleContext} from '~src/context';
import {getHumidityMode, getOperatingConditions} from '~src/utils/calculations';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {IUserValuesAtCalculation1} from '~src/store/Calculations';
import styled from 'styled-components';

interface IRoomParamsProps {}

const RoomParams: React.FC<IRoomParamsProps> = () => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations', 'materials');
  const {userValues} = calculations[1];
  const {
    pages: {
      calculation1: {section2},
    },
  } = useContext(LocaleContext);
  useEffect(() => {
    const operatingConditions = getOperatingConditions(
      getHumidityMode(userValues.t_b, userValues.wetInRoom),
      userValues.zona,
    );
    dispatch('calculations/1/set-values', [
      {
        name: 'operatingConditions',
        value: operatingConditions,
      },
    ]);
  }, [
    userValues.wetInRoom,
    userValues.typeRoom,
    userValues.typeConstruction,
    userValues.t_b,
    userValues.regionCity,
    userValues.regionCode,
  ]);

  const customRows = section2.roomParams.rows.map(row => {
    if (row.variable === 'operatingConditions') {
      return {...row, value: userValues.operatingConditions};
    }
    return {
      ...row,
      value: `${userValues[row.variable as keyof IUserValuesAtCalculation1]}${row.unit && ` ${row.unit}`}`,
    };
  });

  return (
    <TableStyled
      dataSource={customRows}
      columns={section2.roomParams.columns}
      pagination={false}
      rowKey={record => {
        // @ts-ignore
        return record.variable;
      }}
    />
  );
};

const TableStyled = styled(Table)`
  .ant-table-cell:nth-of-type(2) {
    font-weight: bold;
  }
`;

export default RoomParams;
