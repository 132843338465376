import React from 'react';
import styled from 'styled-components';
import {customColors} from '~src/theme/colors';

interface ILayerProps {}

const Layer: React.FC<ILayerProps> = props => {
  return <LayerStyled>{props.children}</LayerStyled>;
};

const LayerStyled = styled.div`
  width: 22px;
  height: 22px;
  background-color: ${customColors.blue700};
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Layer;
