import React, {useContext, useState, useEffect} from 'react';
import {Typography, Row, Col, Select, InputNumber, Collapse, Switch, Form} from 'antd';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import {Control, Space} from '~src/components';
import {LocaleContext} from '~src/context';
import {IInsulation} from '~src/services/Calculation2Service';
import MaterialPreview from '~src/components/molecules/MaterialPreview';
import BrickFacadeInput from '~src/components/organisms/Calculation2Organism/Brick/Sections/Section2/Inputs/brickFacadeInput';
import PlasterFacadeInput from '~src/components/organisms/Calculation2Organism/Brick/Sections/Section2/Inputs/plasterFacadeInput';
import VentFacadeInput from '~src/components/organisms/Calculation2Organism/Brick/Sections/Section2/Inputs/ventFacadeInput';
import SidingFacadeInput from '~src/components/organisms/Calculation2Organism/Brick/Sections/Section2/Inputs/sidingFacadeInput';
import PanelFacadeInput from '~src/components/organisms/Calculation2Organism/Brick/Sections/Section2/Inputs/panelFacadeInput';

const {Title} = Typography;
const {Panel} = Collapse;

const INSULATION_PANEL = 'insulationPanel';

interface ISection2ControlsControlsProps {
  section2Index: number;
}

const Section2Controls: React.FC<ISection2ControlsControlsProps> = ({section2Index}) => {
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {materials} = useStoreon<IState, IEvents>('materials');
  const {calculation2} = materials;
  const {userValues} = calculations[2];
  const [facadeMaterials, setFacadeMaterials] = useState([{label: '', value: 0, inputType: 0}]);
  const {enabledFlags} = calculations[2].userValues;
  const [chosenOption, setChosenOption] = useState(null);
  const [initialInputType, setInitialInputType] = useState(0);
  const [chosenInsulationOption, setChosenInsulationOption] = useState(null);
  const {
    pages: {
      calculation2: {brickAndBlocksSections},
    },
  } = useContext(LocaleContext);

  const setEnabled = (key: string | string[]) => {
    const updatedValue =
      key.length === 0 ? enabledFlags.filter(flag => flag !== INSULATION_PANEL) : [...enabledFlags, INSULATION_PANEL];
    dispatch('calculations/2/set-values', [{name: 'enabledFlags', value: updatedValue}]);
    if (userValues.insulation[0].insulationType !== '') {
      setChosenInsulationOption(null);
      dispatch('calculations/2/set-group-values', {
        groupName: 'insulation',
        values: [
          {
            name: 'insulationType',
            value: '',
            index: section2Index,
          },
        ],
      });
    }
  };

  const collapseToggle = (panelKey: string) => {
    const isSwitchChecked = enabledFlags.includes(panelKey);
    return <Switch checked={isSwitchChecked} />;
  };

  const getHandleInsulationInputChange = (name: keyof IInsulation | string) => (value: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'insulation',
      values: [
        {
          name,
          value,
          index: section2Index,
        },
      ],
    });
  };

  const handleFacadeTypeChange = (e: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'facades',
      values: [
        {
          name: 'facadeType',
          value: e,
          index: section2Index,
        },
      ],
    });
    dispatch('materials/get-materials-by-id', {
      filterKey: 'facadeType',
      filter_value: e,
      calculationType: 2,
    });
    dispatch('calculations/2/set-group-values', {
      groupName: 'facades',
      values: [
        {
          name: 'facadeDefaultSize',
          value: false,
          index: section2Index,
        },
      ],
    });
    dispatch('calculations/2/set-group-values', {
      groupName: 'facades',
      values: [
        {
          name: 'facadeBrickWidth',
          value: null,
          index: section2Index,
        },
      ],
    });
    dispatch('calculations/2/set-group-values', {
      groupName: 'facades',
      values: [
        {
          name: 'facadeBrickLength',
          value: null,
          index: section2Index,
        },
      ],
    });
    dispatch('calculations/2/set-group-values', {
      groupName: 'facades',
      values: [
        {
          name: 'facadeBrickHeight',
          value: null,
          index: section2Index,
        },
      ],
    });
    dispatch('calculations/2/set-group-values', {
      groupName: 'facades',
      values: [
        {
          name: 'facadeBrickWeight',
          value: null,
          index: section2Index,
        },
      ],
    });
    dispatch('calculations/2/set-group-values', {
      groupName: 'facades',
      values: [
        {
          name: 'facadeTrimMargin',
          value: null,
          index: section2Index,
        },
      ],
    });
    dispatch('calculations/2/set-group-values', {
      groupName: 'facades',
      values: [
        {
          name: 'facadeSeamThickness',
          value: null,
          index: section2Index,
        },
      ],
    });
    dispatch('calculations/2/set-group-values', {
      groupName: 'facades',
      values: [
        {
          name: 'facadePanelWidth',
          value: null,
          index: section2Index,
        },
      ],
    });
    dispatch('calculations/2/set-group-values', {
      groupName: 'facades',
      values: [
        {
          name: 'facadePanelLength',
          value: null,
          index: section2Index,
        },
      ],
    });
    dispatch('calculations/2/set-group-values', {
      groupName: 'facades',
      values: [
        {
          name: 'facadePanelSquare',
          value: null,
          index: section2Index,
        },
      ],
    });
    dispatch('calculations/2/set-group-values', {
      groupName: 'facades',
      values: [
        {
          name: 'facadeWeight',
          value: null,
          index: section2Index,
        },
      ],
    });
    setChosenOption(null);
    setInitialInputType(1);
  };

  const handleInsulationTypeChange = (e: string) => {
    setChosenInsulationOption(e);
    dispatch('calculations/2/set-group-values', {
      groupName: 'insulation',
      values: [
        {
          name: 'insulationType',
          value: e,
          index: section2Index,
        },
      ],
    });
  };

  useEffect(() => {
    let materialLabels = [];
    materialLabels = calculation2.facadeType.materials.map(item => {
      let materialLabel =
        item.name +
        ' ' +
        (item.facadeBrickLength !== null && item.facadeBrickLength !== undefined
          ? item.facadeBrickLength.toString() + 'x'
          : '') +
        (item.facadeBrickWidth !== null && item.facadeBrickWidth !== undefined
          ? item.facadeBrickWidth.toString() + 'x'
          : '') +
        (item.facadeBrickHeight !== null && item.facadeBrickHeight !== undefined
          ? item.facadeBrickHeight.toString() + 'мм, '
          : '') +
        (item.facadeBrickWeight !== null && item.facadeBrickWeight !== undefined
          ? item.facadeBrickWeight.toString() + 'кг'
          : '');
      return {
        label: materialLabel,
        value: item.id,
        inputType: item.inputType,
      };
    });
    setFacadeMaterials(materialLabels);
  }, [calculation2, calculation2.facadeType.materials]);

  return (
    <Row align="top" justify="space-between">
      <Col span={24}>
        <Title level={2}>{brickAndBlocksSections.section2.title}</Title>
      </Col>
      <ContentWrapperStyled>
        <Col md={10} sm={12} xs={24}>
          <Space direction={'vertical'} size={10}>
            <Control label={brickAndBlocksSections.section2.claddingType.label}>
              <FormItemStyled
                validateStatus={userValues.isInvalid && userValues.facades[0].facadeType === -1 ? 'error' : ''}
                help={userValues.isInvalid && userValues.facades[0].facadeType === -1 ? 'Обязательное поле' : ''}
              >
                <Select
                  placeholder={brickAndBlocksSections.section2.claddingType.placeholder}
                  size={'large'}
                  options={calculation2.facadeType.options}
                  onChange={handleFacadeTypeChange}
                />
              </FormItemStyled>
            </Control>
            {facadeMaterials.some(item => item.inputType === 0) || initialInputType === 0 ? (
              <BrickFacadeInput section2Index={section2Index} chosenFacadeOption={chosenOption} />
            ) : null}
            {facadeMaterials.some(item => item.inputType === 1) && initialInputType !== 0 ? (
              <PlasterFacadeInput section2Index={section2Index} />
            ) : null}
            {facadeMaterials.some(item => item.inputType === 2) && initialInputType !== 0 ? (
              <VentFacadeInput section2Index={section2Index} />
            ) : null}
            {facadeMaterials.some(item => item.inputType === 3) && initialInputType !== 0 ? (
              <SidingFacadeInput section2Index={section2Index} />
            ) : null}
            {facadeMaterials.some(item => item.inputType === 4) && initialInputType !== 0 ? (
              <PanelFacadeInput section2Index={section2Index} />
            ) : null}
            <CollapseStyled bordered={false} expandIconPosition="right" onChange={setEnabled}>
              <Panel
                header={brickAndBlocksSections.section2.insulation.title}
                key={INSULATION_PANEL}
                className="collapse-custom-panel"
                extra={collapseToggle(INSULATION_PANEL)}
                showArrow={false}
              >
                <Row gutter={[20, 20]}>
                  <Col md={24} sm={24} xs={24}>
                    <Control label={brickAndBlocksSections.section2.insulation.insulationType.label}>
                      <FormItemStyled
                        validateStatus={
                          userValues.isInvalid &&
                          userValues.enabledFlags.find(item => item === INSULATION_PANEL) &&
                          userValues.insulation[0].insulationType === ''
                            ? 'error'
                            : ''
                        }
                        help={
                          userValues.isInvalid &&
                          userValues.enabledFlags.find(item => item === INSULATION_PANEL) &&
                          userValues.insulation[0].insulationType === ''
                            ? 'Обязательное поле'
                            : ''
                        }
                      >
                        <Select
                          placeholder={brickAndBlocksSections.section2.insulation.insulationType.placeholder}
                          size={'large'}
                          options={calculation2.insulationType.options}
                          value={chosenInsulationOption}
                          onChange={handleInsulationTypeChange}
                        />
                      </FormItemStyled>
                    </Control>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Space size={20} direction={'vertical'}>
                      <Control label={brickAndBlocksSections.section2.insulation.insulationThickness.label}>
                        <FormItemStyled
                          validateStatus={
                            userValues.isInvalid &&
                            userValues.enabledFlags.find(item => item === INSULATION_PANEL) &&
                            userValues.insulation[0].insulationThickness === 0
                              ? 'error'
                              : ''
                          }
                          help={
                            userValues.isInvalid &&
                            userValues.enabledFlags.find(item => item === INSULATION_PANEL) &&
                            userValues.insulation[0].insulationThickness === 0
                              ? 'Обязательное поле'
                              : ''
                          }
                        >
                          <InputNumberStyled
                            min={0}
                            size={'large'}
                            placeholder={brickAndBlocksSections.section2.insulation.insulationThickness.placeholder}
                            onChange={getHandleInsulationInputChange(
                              brickAndBlocksSections.section2.insulation.insulationThickness.name,
                            )}
                          />
                        </FormItemStyled>
                      </Control>
                    </Space>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Space size={20} direction={'vertical'}>
                      <Control label={brickAndBlocksSections.section2.insulation.insulationDensity.label}>
                        <FormItemStyled
                          validateStatus={
                            userValues.isInvalid &&
                            userValues.enabledFlags.find(item => item === INSULATION_PANEL) &&
                            userValues.insulation[0].insulationDensity === 0
                              ? 'error'
                              : ''
                          }
                          help={
                            userValues.isInvalid &&
                            userValues.enabledFlags.find(item => item === INSULATION_PANEL) &&
                            userValues.insulation[0].insulationDensity === 0
                              ? 'Обязательное поле'
                              : ''
                          }
                        >
                          <InputNumberStyled
                            min={0}
                            size={'large'}
                            placeholder={brickAndBlocksSections.section2.insulation.insulationDensity.placeholder}
                            onChange={getHandleInsulationInputChange(
                              brickAndBlocksSections.section2.insulation.insulationDensity.name,
                            )}
                          />
                        </FormItemStyled>
                      </Control>
                    </Space>
                  </Col>
                </Row>
              </Panel>
            </CollapseStyled>
          </Space>
        </Col>
        <Col md={14} sm={12} xs={24}>
          <MaterialPreview sectionToRender="insulation" />
        </Col>
      </ContentWrapperStyled>
    </Row>
  );
};

const CollapseStyled = styled(Collapse)`
  background: #fafafa;
  border: none;
  padding: 0 !important;

  .ant-collapse-header {
    /* margin-bottom: 0.5em; */
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;
    border-top: 1px solid #e8e8e8;
    margin-top: 0 - 1px;
    border-bottom: 1px solid #e8e8e8;
    padding: 16px 25px !important;
  }

  .ant-collapse-content-box {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff !important;
    padding: 20px 25px !important;
  }

  .collapse-custom-panel {
    border: none !important;
  }
`;

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`;

const ContentWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;

  @media screen and (max-width: 320px) {
    flex-direction: column-reverse;
  }

  @media screen and (min-width: 321px) and (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

const FormItemStyled = styled(Form.Item)`
  margin-bottom: 0 !important;
`;

export default Section2Controls;
