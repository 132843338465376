import bearingWallCeramicNotVoid05 from '~src/services/ImageRenderService/images/bearingWall_ceramic_notvoid_05.png';
import bearingWallCeramicNotVoid2 from '~src/services/ImageRenderService/images/bearingWall_ceramic_notvoid_2.png';
import bearingWallCeramicNotVoid1 from '~src/services/ImageRenderService/images/bearingWall_ceramic_notvoid-1.png';
import bearingWallCeramicNotVoid15 from '~src/services/ImageRenderService/images/bearingWall_ceramic_notvoid-15.png';
import bearingWallCeramicVoid05 from '~src/services/ImageRenderService/images/bearingWall_ceramic_void_05.png';
import bearingWallCeramicVoid1 from '~src/services/ImageRenderService/images/bearingWall_ceramic_void-1.png';
import bearingWallCeramicVoid15 from '~src/services/ImageRenderService/images/bearingWall_ceramic_void-15.png';
import bearingWallCeramicVoid2 from '~src/services/ImageRenderService/images/bearingWall_ceramic_void-2.png';
import bearingWallCeramicBlock380 from '~src/services/ImageRenderService/images/bearingWall_ceramicBlock-380.png';
import bearingWallFoamBlock from '~src/services/ImageRenderService/images/bearingWall_foamBlock.png';
import bearingWallSelicateNotVoid05 from '~src/services/ImageRenderService/images/bearingWall_selicate_notvoid-05.png';
import bearingWallSelicateNotVoid1 from '~src/services/ImageRenderService/images/bearingWall_selicate_notvoid-1.png';
import bearingWallSelicateNotVoid15 from '~src/services/ImageRenderService/images/bearingWall_selicate_notvoid-15.png';
import bearingWallSelicateNotVoid2 from '~src/services/ImageRenderService/images/bearingWall_selicate_notvoid-2.png';
import bearingWallSelicateVoid05 from '~src/services/ImageRenderService/images/bearingWall_selicate_void-05.png';
import bearingWallSelicateVoid1 from '~src/services/ImageRenderService/images/bearingWall_selicate_void-1.png';
import bearingWallSelicateVoid15 from '~src/services/ImageRenderService/images/bearingWall_selicate_void-15.png';
import bearingWallSelicateVoid2 from '~src/services/ImageRenderService/images/bearingWall_selicate_void-2.png';
import bearingWallBalkProfiled from '~src/services/ImageRenderService/images/bearingWall_balk_profiled.png';
import bearingWallBalkRectangular from '~src/services/ImageRenderService/images/bearingWall_balk_rect.png';
import bearingWallBalkLog from '~src/services/ImageRenderService/images/bearingWall_log.png';
import facadeBrickInsulation from '~src/services/ImageRenderService/images/facade_brick_insulation.png';
import facadeBrickNoInsulation from '~src/services/ImageRenderService/images/facade_brick_noinsulation.png';
import facadePlasterInsulation from '~src/services/ImageRenderService/images/facade_plaster_insulation.png';
import facadePlasterNoInsulation from '~src/services/ImageRenderService/images/facade_plaster_noinsulation.png';
import facadeSidingInsulation from '~src/services/ImageRenderService/images/facadeSidingInsulation.png';
import facadeSidingNoInsulation from '~src/services/ImageRenderService/images/facadeSidingNoInsulation.png';
import facadePanelInsulation from '~src/services/ImageRenderService/images/facadePanelInsulation.png';
import facadePanelNoInsulation from '~src/services/ImageRenderService/images/facadePanelNoInsulation.png';
import facadeVentInsulation from '~src/services/ImageRenderService/images/facadeVentInsulation.png';
import facadeVentNoInsulation from '~src/services/ImageRenderService/images/facadeVentNoInsulation.png';
import insulationMineralCotton from '~src/services/ImageRenderService/images/insulation_minCotton.png';
import insulationPps from '~src/services/ImageRenderService/images/insulation_pps.png';

export interface IImages {
  bearingWallImage: string | undefined;
  facadeImage: string | undefined;
  insulationImage: string | undefined;
}

export interface IMaterialTypes {
  bearingWallType: string;
  facadeType: number | string;
  insulationType: number | string;
}

class ImageRenderService {
  materialTypes: IMaterialTypes | {} = {};
  resultImage: IImages = {
    bearingWallImage: undefined,
    facadeImage: undefined,
    insulationImage: undefined,
  };

  calculate(
    bearingWallType: string,
    voidness: number,
    masonryOption: number,
    facadeType: string,
    insulationType: string,
  ) {
    switch (bearingWallType) {
      case 'Керамический кирпич':
        switch (voidness) {
          case 0:
            switch (masonryOption) {
              case 0.5:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallCeramicNotVoid05};
                break;
              case 1:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallCeramicNotVoid1};
                break;
              case 1.5:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallCeramicNotVoid15};
                break;
              case 2:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallCeramicNotVoid2};
                break;
              default:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallCeramicNotVoid05};
                break;
            }
            break;
          case 1:
            switch (masonryOption) {
              case 0.5:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallCeramicVoid05};
                break;
              case 1:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallCeramicVoid1};
                break;
              case 1.5:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallCeramicVoid15};
                break;
              case 2:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallCeramicVoid2};
                break;
              default:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallCeramicVoid05};
                break;
            }
            break;
        }
        break;
      case 'Силикатный кирпич':
        switch (voidness) {
          case 0:
            switch (masonryOption) {
              case 0.5:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallSelicateNotVoid05};
                break;
              case 1:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallSelicateNotVoid1};
                break;
              case 1.5:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallSelicateNotVoid15};
                break;
              case 2:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallSelicateNotVoid2};
                break;
              default:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallSelicateNotVoid05};
                break;
            }
            break;
          case 1:
            switch (masonryOption) {
              case 0.5:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallSelicateVoid05};
                break;
              case 1:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallSelicateVoid1};
                break;
              case 1.5:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallSelicateVoid15};
                break;
              case 2:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallSelicateVoid2};
                break;
              default:
                this.resultImage = {...this.resultImage, bearingWallImage: bearingWallSelicateVoid05};
                break;
            }
            break;
        }
        break;
      case 'Керамический блок':
        this.resultImage = {...this.resultImage, bearingWallImage: bearingWallCeramicBlock380};
        break;
      case 'Шлакоблок':
      case 'Газобетонные блоки':
      case 'Пенобетонные блоки':
        this.resultImage = {...this.resultImage, bearingWallImage: bearingWallFoamBlock};
        break;
      case 'Брус прямоугольного сечения':
      case 'Брус клееный':
        this.resultImage = {...this.resultImage, bearingWallImage: bearingWallBalkRectangular};
        break;
      case 'Брус профилированный':
        this.resultImage = {...this.resultImage, bearingWallImage: bearingWallBalkProfiled};
        break;
      case 'Бревно оцилиндрованное':
        this.resultImage = {...this.resultImage, bearingWallImage: bearingWallBalkLog};
        break;
      case '':
        this.resultImage = {...this.resultImage, bearingWallImage: ''};
        break;
    }
    switch (facadeType) {
      case 'Облицовочный керамический кирпич':
      case 'Клинкерный облицовочный кирпич':
        switch (insulationType) {
          case 'Базальтовая вата':
          case 'Пенополистирол ПСБ-25Ф':
            this.resultImage = {...this.resultImage, facadeImage: facadeBrickInsulation};
            break;
          default:
            this.resultImage = {...this.resultImage, facadeImage: facadeBrickNoInsulation};
            break;
        }
        break;
      case 'Фасадная штукатурка':
        switch (insulationType) {
          case 'Базальтовая вата':
          case 'Пенополистирол ПСБ-25Ф':
            this.resultImage = {...this.resultImage, facadeImage: facadePlasterInsulation};
            break;
          default:
            this.resultImage = {...this.resultImage, facadeImage: facadePlasterNoInsulation};
            break;
        }
        break;
      case 'Сайдинг':
        switch (insulationType) {
          case 'Базальтовая вата':
          case 'Пенополистирол ПСБ-25Ф':
            this.resultImage = {...this.resultImage, facadeImage: facadeSidingInsulation};
            break;
          default:
            this.resultImage = {...this.resultImage, facadeImage: facadeSidingNoInsulation};
            break;
        }
        break;
      case 'Вентилируемый фасад':
        switch (insulationType) {
          case 'Базальтовая вата':
          case 'Пенополистирол ПСБ-25Ф':
            this.resultImage = {...this.resultImage, facadeImage: facadeVentInsulation};
            break;
          default:
            this.resultImage = {...this.resultImage, facadeImage: facadeVentNoInsulation};
            break;
        }
        break;
      case 'Фасадные панели':
        switch (insulationType) {
          case 'Базальтовая вата':
          case 'Пенополистирол ПСБ-25Ф':
            this.resultImage = {...this.resultImage, facadeImage: facadePanelInsulation};
            break;
          default:
            this.resultImage = {...this.resultImage, facadeImage: facadePanelNoInsulation};
            break;
        }
        break;
    }
    switch (insulationType) {
      case 'Базальтовая вата':
        this.resultImage = {...this.resultImage, insulationImage: insulationMineralCotton};
        break;
      case 'Пенополистирол ПСБ-25Ф':
        this.resultImage = {...this.resultImage, insulationImage: insulationPps};
        break;
    }
    return this.resultImage;
  }
}

const imageRenderService = new ImageRenderService();
export default imageRenderService;
