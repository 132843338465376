export interface Layer {
  id: string;
  isEnabled: boolean;
  layerId: number;
  layerType: 'bearingWall' | 'insulation' | 'facadeMaterial' | 'finishingMaterial' | 'waterproofing' | 'airGap';
  layerName: string;
  materialName: string;
  materialDensity: number;
  materialTranscalencyA: number;
  materialTranscalencyB: number;
  materialVaporRermeability: number;
  materialDeltaW: number;
  t: number;
}
type Line = number[];
export type IntersectionPoint = {x: number; y: number; color: string};

export const round = (n: number, p: number = 1): number => Math.floor(n * p) / p;

export const findIntersectionPoints = (layers: Layer[], lines: Line[]): {intersectionPoints: IntersectionPoint[]} => {
  const intersectionPoints: IntersectionPoint[] = [];
  const xPoints = layers.reduce((acc, layer, index) => [...acc, layer.t + acc[index]], [0]);
  const y1Points = lines[0];
  const y2Points = lines[1];
  const findB = (i: number, yPoints: number[]) =>
    (xPoints[i] * yPoints[i + 1] - yPoints[i] * xPoints[i + 1]) / (xPoints[i] - xPoints[i + 1]);
  const findK = (i: number, b: number, yPoints: number[]) => (yPoints[i + 1] - b) / xPoints[i + 1];

  layers.forEach((value, index) => {
    const b1 = findB(index, y1Points);
    const k1 = findK(index, b1, y1Points);
    const b2 = findB(index, y2Points);
    const k2 = findK(index, b2, y2Points);

    if (k1 === k2 && b1 !== b2) {
      console.log('---', 'Пересечений в слое нет');
      return;
    }

    if (k1 === k2 && b1 === b2) {
      console.log('---', 'Ситуация невозможна');
      return;
    }

    const potentialIntersectionPointX = (b2 - b1) / (k1 - k2);
    const potentialIntersectionPointY = k1 * potentialIntersectionPointX + b1;

    if (xPoints[index] <= potentialIntersectionPointX && potentialIntersectionPointX < xPoints[index + 1]) {
      intersectionPoints.push({x: potentialIntersectionPointX, y: potentialIntersectionPointY});
      return;
    }
  });
  return {intersectionPoints};
};
