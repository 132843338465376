import React from 'react';
import styled from 'styled-components';

interface IContentViewProps {
  className?: string;
}

const ContentView: React.FC<IContentViewProps> = ({children, className}) => {
  return <ContentViewStyled className={className}>{children}</ContentViewStyled>;
};

const ContentViewStyled = styled.div`
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
`;

export default ContentView;
