export const deviceSizes = {
  desktop: 992,
  tablet: 768,
  phone: 576,
};

export const phoneQuery = `max-width: ${deviceSizes.tablet - 1}px`;
export const tabletQuery = `max-width: ${deviceSizes.desktop - 1}px`;
export const $phone = `screen and (${phoneQuery})`;
export const $tablet = `screen and (${tabletQuery})`;
