import React, {useContext, Fragment, useEffect, useState} from 'react';
import {Typography, Row, Col, Select, InputNumber, Radio, Tabs, Form} from 'antd';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import Space from '~src/components/molecules/Space';
import {Control} from '~src/components';
import {LocaleContext} from '~src/context';

import InputSelect from '~src/components/molecules/InputSelect';
import MaterialPreview from '~src/components/molecules/MaterialPreview';
import {IBearingWall} from '~src/services/Calculation2Service';

const {Title} = Typography;
const {TabPane} = Tabs;

interface ISection1ControlsControlsProps {
  section1Index: number;
}

const Section1Controls: React.FC<ISection1ControlsControlsProps> = ({section1Index}) => {
  const {
    pages: {
      calculation2: {brickAndBlocksSections},
    },
  } = useContext(LocaleContext);
  const {dispatch, calculations} = useStoreon<IState, IEvents>('calculations');
  const {materials} = useStoreon<IState, IEvents>('materials');
  const {userValues} = calculations[2];
  const {calculation2} = materials;
  const [brickMaterials, setBrickMaterials] = useState([{label: '', value: '', voidness: false, inputType: 0}]);
  const [chosenOption, setChosenOption] = useState(null);

  const handleInputChange = (e: any) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'bearingWalls',
      values: [
        {
          name: e.target.name,
          value: e.target.value,
          index: section1Index,
        },
      ],
    });
  };

  const getHandleInputChange = (name: keyof IBearingWall | string) => (value: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'bearingWalls',
      values: [
        {
          name,
          value,
          index: section1Index,
        },
      ],
    });
  };

  const handleBrickTypeChange = (e: number | string) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'bearingWalls',
      values: [
        {
          name: 'brickType',
          value: e,
          index: section1Index,
        },
      ],
    });
    dispatch('materials/get-materials-by-id', {
      filterKey: 'brickType',
      filter_value: e,
      calculationType: 2,
    });
    setChosenOption(null);
  };

  const handleDefaultSizeChange = (e: string) => {
    let selectedMaterial = calculation2.brickType.materials.find(item => {
      return item.id === e;
    });
    selectedMaterial && setChosenOption(selectedMaterial.id);
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'bearingWalls',
        values: [
          {
            name: 'brickLength',
            value: selectedMaterial.brickLength,
            index: section1Index,
          },
        ],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'bearingWalls',
        values: [
          {
            name: 'brickWidth',
            value: selectedMaterial.brickWidth,
            index: section1Index,
          },
        ],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'bearingWalls',
        values: [
          {
            name: 'brickHeight',
            value: selectedMaterial.brickHeight,
            index: section1Index,
          },
        ],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'bearingWalls',
        values: [
          {
            name: 'brickWeight',
            value: selectedMaterial.brickWeight,
            index: section1Index,
          },
        ],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'bearingWalls',
        values: [
          {
            name: 'brickDefaultSize',
            value: 1,
            index: section1Index,
          },
        ],
      });
    selectedMaterial &&
      dispatch('calculations/2/set-group-values', {
        groupName: 'bearingWalls',
        values: [
          {
            name: 'voidness',
            value: selectedMaterial.voidness,
            index: section1Index,
          },
        ],
      });
  };

  const handleTrimMarginOptionChange = (e: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'bearingWalls',
      values: [
        {
          name: 'trimMargin',
          value: e,
          index: section1Index,
        },
      ],
    });
  };

  const handleMasonryOptionChange = (e: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'bearingWalls',
      values: [
        {
          name: 'masonryOption',
          value: e,
          index: section1Index,
        },
      ],
    });
  };

  const handleSeamThicknessChange = (e: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'bearingWalls',
      values: [
        {
          name: 'seamThickness',
          value: e,
          index: section1Index,
        },
      ],
    });
  };

  const handleMasonryNetChange = (e: number) => {
    dispatch('calculations/2/set-group-values', {
      groupName: 'bearingWalls',
      values: [
        {
          name: 'masonryNet',
          value: e,
          index: section1Index,
        },
      ],
    });
  };

  useEffect(() => {
    let materialLabels = [];
    materialLabels = calculation2.brickType.materials.map(item => {
      let materialLabel = '';
      materialLabel =
        item.executionType +
        '- ' +
        item.brickLength.toString() +
        'x' +
        item.brickWidth.toString() +
        'x' +
        item.brickHeight.toString() +
        'мм, ' +
        item.brickWeight.toString() +
        'кг';
      return {
        label: materialLabel,
        value: item.id,
        voidness: item.voidness,
        inputType: item.inputType,
      };
    });

    let normalizedExecutionType = userValues.bearingWalls[section1Index].executionType === 0 ? false : true;
    if (materialLabels.every(item => item.inputType === 0)) {
      let filteredMaterialLabels = materialLabels.filter(item => {
        return item.voidness === normalizedExecutionType;
      });
      setBrickMaterials(filteredMaterialLabels);
    } else {
      setBrickMaterials(materialLabels);
    }

    if (materialLabels.every(item => item.inputType === 1)) {
      handleMasonryOptionChange(0.5);
    }
  }, [calculation2, userValues.bearingWalls[0].executionType]);

  const determineExecutionTypeRender = () => {
    if (brickMaterials.every(item => item.inputType === 0)) {
      return (
        <Control label={brickAndBlocksSections.section1.executionType.label}>
          <Radio.Group
            value={userValues.bearingWalls[section1Index].executionType}
            onChange={handleInputChange}
            name="executionType"
          >
            {brickAndBlocksSections.section1.executionType.values.map(item => (
              <Radio key={item.value} value={item.value}>
                {item.label}
              </Radio>
            ))}
          </Radio.Group>
        </Control>
      );
    }
  };

  return (
    <Fragment>
      <Row align="top" justify="space-between">
        <Col span={24}>
          <Title level={2}>{brickAndBlocksSections.section1.title}</Title>
        </Col>
        <ContentWrapperStyled>
          <Col md={10} sm={12} xs={24}>
            <Space direction={'vertical'} size={20}>
              <Control label={brickAndBlocksSections.section1.brickType.label}>
                <FormItemStyled
                  validateStatus={userValues.isInvalid && userValues.bearingWalls[0].brickType === '' ? 'error' : ''}
                  help={userValues.isInvalid && userValues.bearingWalls[0].brickType === '' ? 'Обязательное поле' : ''}
                >
                  <Select
                    placeholder={brickAndBlocksSections.section1.brickType.placeholder}
                    size={'large'}
                    options={calculation2.brickType.options}
                    onChange={handleBrickTypeChange}
                  />
                </FormItemStyled>
              </Control>
              {determineExecutionTypeRender()}
              <Tabs animated={false} defaultActiveKey="1">
                <TabPaneStyled tab={brickAndBlocksSections.section2.facadeSize.defaultSize.label} key="1">
                  <Row gutter={[20, 0]}>
                    <Col span={24} xs={24}>
                      <Control label={brickAndBlocksSections.section1.bearingWallSize.label}>
                        <FormItemStyled
                          validateStatus={
                            userValues.isInvalid && userValues.bearingWalls[0].brickDefaultSize === 0 ? 'error' : ''
                          }
                          help={
                            userValues.isInvalid && userValues.bearingWalls[0].brickDefaultSize === 0
                              ? 'Обязательное поле'
                              : ''
                          }
                        >
                          <Select
                            size={'large'}
                            options={brickMaterials}
                            value={chosenOption}
                            placeholder={brickAndBlocksSections.section1.bearingWallSize.placeholder}
                            onChange={handleDefaultSizeChange}
                          />
                        </FormItemStyled>
                      </Control>
                    </Col>
                  </Row>
                </TabPaneStyled>
                <TabPaneStyled tab={brickAndBlocksSections.section1.bearingWallSize.userSize.label} key="2">
                  <Row gutter={[20, 0]}>
                    <Col md={12} sm={24} xs={24}>
                      <Space size={10} direction={'vertical'}>
                        <Control label={brickAndBlocksSections.section1.bearingWallSize.userSize.brickLength.label}>
                          <InputNumberStyled
                            min={0}
                            size={'large'}
                            placeholder={
                              brickAndBlocksSections.section1.bearingWallSize.userSize.brickLength.placeholder
                            }
                            onChange={getHandleInputChange(
                              brickAndBlocksSections.section1.bearingWallSize.userSize.brickLength.name,
                            )}
                          />
                        </Control>
                        <Control label={brickAndBlocksSections.section1.bearingWallSize.userSize.brickHeight.label}>
                          <InputNumberStyled
                            min={0}
                            size={'large'}
                            placeholder={
                              brickAndBlocksSections.section1.bearingWallSize.userSize.brickHeight.placeholder
                            }
                            onChange={getHandleInputChange(
                              brickAndBlocksSections.section1.bearingWallSize.userSize.brickHeight.name,
                            )}
                          />
                        </Control>
                      </Space>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Space size={10} direction={'vertical'}>
                        <Control label={brickAndBlocksSections.section1.bearingWallSize.userSize.brickWidth.label}>
                          <InputNumberStyled
                            min={0}
                            size={'large'}
                            placeholder={
                              brickAndBlocksSections.section1.bearingWallSize.userSize.brickWidth.placeholder
                            }
                            onChange={getHandleInputChange(
                              brickAndBlocksSections.section1.bearingWallSize.userSize.brickWidth.name,
                            )}
                          />
                        </Control>
                        <Control label={brickAndBlocksSections.section1.bearingWallSize.userSize.brickWeight.label}>
                          <InputNumberStyled
                            min={0}
                            size={'large'}
                            placeholder={
                              brickAndBlocksSections.section1.bearingWallSize.userSize.brickWeight.placeholder
                            }
                            onChange={getHandleInputChange(
                              brickAndBlocksSections.section1.bearingWallSize.userSize.brickWeight.name,
                            )}
                          />
                        </Control>
                      </Space>
                    </Col>
                  </Row>
                </TabPaneStyled>
              </Tabs>
              <Control label={brickAndBlocksSections.section1.trimMargin.label}>
                <FormItemStyled
                  validateStatus={userValues.isInvalid && userValues.bearingWalls[0].trimMargin === 0 ? 'error' : ''}
                  help={userValues.isInvalid && userValues.bearingWalls[0].trimMargin === 0 ? 'Обязательное поле' : ''}
                >
                  <InputSelect
                    inputProps={{
                      size: 'large',
                      placeholder: brickAndBlocksSections.section1.trimMargin.manualInputPlaceholder,
                      inputButton: 'Выбрать значение',
                      min: 0,
                      max: 100,
                    }}
                    selectProps={{
                      size: 'large',
                      placeholder: brickAndBlocksSections.section1.trimMargin.placeholder,
                      options: brickAndBlocksSections.section1.trimMargin.values,
                      selectButton: 'Ввести значение',
                    }}
                    onInputChange={getHandleInputChange(brickAndBlocksSections.section1.trimMargin.name)}
                    onSelectChange={handleTrimMarginOptionChange}
                  />
                </FormItemStyled>
              </Control>
              <Row gutter={[20, 15]} justify="space-between">
                {brickMaterials.every(item => item.inputType === 1) ? (
                  ''
                ) : (
                  <Col md={12} sm={24} xs={24}>
                    <Control label={brickAndBlocksSections.section1.masonryOption.label}>
                      <FormItemStyled
                        validateStatus={
                          userValues.isInvalid && userValues.bearingWalls[0].masonryOption === 0 ? 'error' : ''
                        }
                        help={
                          userValues.isInvalid && userValues.bearingWalls[0].masonryOption === 0
                            ? 'Обязательное поле'
                            : ''
                        }
                      >
                        <Select
                          size={'large'}
                          options={brickAndBlocksSections.section1.masonryOption.values}
                          placeholder={brickAndBlocksSections.section1.masonryOption.placeholder}
                          onChange={handleMasonryOptionChange}
                        />
                      </FormItemStyled>
                    </Control>
                  </Col>
                )}
                <Col md={brickMaterials.every(item => item.inputType === 1) ? 24 : 12} sm={24} xs={24}>
                  <Control label={brickAndBlocksSections.section1.seamThickness.label}>
                    <FormItemStyled
                      validateStatus={
                        userValues.isInvalid && userValues.bearingWalls[0].seamThickness === 0 ? 'error' : ''
                      }
                      help={
                        userValues.isInvalid && userValues.bearingWalls[0].seamThickness === 0
                          ? 'Обязательное поле'
                          : ''
                      }
                    >
                      {brickMaterials.every(item => item.inputType === 1) ? (
                        <Select
                          size={'large'}
                          placeholder={brickAndBlocksSections.section1.seamThickness.placeholder}
                          onChange={handleSeamThicknessChange}
                        >
                          <Select.OptGroup label="Клей">
                            {brickAndBlocksSections.section1.seamThickness.glueValues.map((item, index) => {
                              return (
                                <Select.Option value={item.value} key={index}>
                                  {item.label}
                                </Select.Option>
                              );
                            })}
                          </Select.OptGroup>
                          <Select.OptGroup label="Ц.п. раствор">
                            {brickAndBlocksSections.section1.seamThickness.plasterValues.map((item, index) => {
                              return (
                                <Select.Option value={item.value} key={index}>
                                  {item.label}
                                </Select.Option>
                              );
                            })}
                          </Select.OptGroup>
                        </Select>
                      ) : (
                        <Select
                          size={'large'}
                          options={brickAndBlocksSections.section1.seamThickness.plasterValues}
                          placeholder={brickAndBlocksSections.section1.seamThickness.placeholder}
                          onChange={handleSeamThicknessChange}
                        />
                      )}
                    </FormItemStyled>
                  </Control>
                </Col>
              </Row>
              <Row>
                <Control label={brickAndBlocksSections.section1.masonryNet.label}>
                  <FormItemStyled
                    validateStatus={userValues.isInvalid && userValues.bearingWalls[0].masonryNet === -1 ? 'error' : ''}
                    help={
                      userValues.isInvalid && userValues.bearingWalls[0].masonryNet === -1 ? 'Обязательное поле' : ''
                    }
                  >
                    <Select
                      placeholder={brickAndBlocksSections.section1.masonryNet.placeholder}
                      size={'large'}
                      options={brickAndBlocksSections.section1.masonryNet.values}
                      onChange={handleMasonryNetChange}
                    />
                  </FormItemStyled>
                </Control>
              </Row>
            </Space>
          </Col>
          <Col md={14} sm={12} xs={24}>
            <MaterialPreview sectionToRender="bearingWalls" />
          </Col>
        </ContentWrapperStyled>
      </Row>
    </Fragment>
  );
};

const TabPaneStyled = styled(TabPane)`
  width: 100%;
`;

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`;

const ContentWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;

  @media screen and (max-width: 320px) {
    flex-direction: column-reverse;
  }

  @media screen and (min-width: 321px) and (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

const FormItemStyled = styled(Form.Item)`
  margin-bottom: 0 !important;
`;

export default Section1Controls;
