import React from 'react';
import {useStoreon} from 'storeon/react';
import styled from 'styled-components';
import {IEvents, IState} from '~src/store';
import {Routes} from '~src/utils/routes';

import {Link} from 'gatsby';
import {Layout, Typography, Space} from 'antd';
import {UserOutlined} from '@ant-design/icons';

const {Header: AntdHeader} = Layout;
const {Text} = Typography;

const Header = () => {
  const {app} = useStoreon<IState, IEvents>('app');
  return (
    <React.Fragment>
      <HeaderStyled>
        <UserStyled>
          <Space size={'middle'}>
            <Link to={Routes.account}>
              <UserOutlinedStyled />
              <Text strong>{app.account.userName}</Text>
            </Link>
          </Space>
        </UserStyled>
      </HeaderStyled>
    </React.Fragment>
  );
};

const HeaderStyled = styled(AntdHeader)`
  display: flex;
  justify-content: flex-end;
  background: #fff;
  padding: 0 24px;
  border-bottom: 1px solid #e8e8e8;
`;

const UserStyled = styled.div`
  /* padding: 0 24px; */
`;

const UserOutlinedStyled = styled(UserOutlined)`
  margin-right: 10px;
  color: #1890ff;
`;

export default Header;
