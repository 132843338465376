import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

import additionalImageRenderService from '~src/services/AdditionalImageRenderService';

// Треугольные фронтоны
import gableHeightTriangleImage from '~src/components/molecules/AdditionalMaterialPreview/Outlines/Triangle/gableHeightTriangle.png';
import gableWidthTriangleImage from '~src/components/molecules/AdditionalMaterialPreview/Outlines/Triangle/gableWidthTriangle.png';

// Трапецивидные фронтоны
import gableHeightTrapezoidalImage from '~src/components/molecules/AdditionalMaterialPreview/Outlines/Trapezoidal/gableHeightTrapezoidal.png';
import gableWidthSmallerTrapezoidalImage from '~src/components/molecules/AdditionalMaterialPreview/Outlines/Trapezoidal/gableWidthSmallerTrapezoidal.png';
import gableWidthTrapezoidalImage from '~src/components/molecules/AdditionalMaterialPreview/Outlines/Trapezoidal/gableWidthTrapezoidal.png';

// Пятиугольные фронтоны
import gableHeightRectPentaImage from '~src/components/molecules/AdditionalMaterialPreview/Outlines/Penta/gableHeightRectPenta.png';
import gableHeightTriangularPentaImage from '~src/components/molecules/AdditionalMaterialPreview/Outlines/Penta/gableHeightTriangularPenta.png';
import gableWidthBiggerPentaImage from '~src/components/molecules/AdditionalMaterialPreview/Outlines/Penta/gableWidthBiggerPenta.png';
import gableWidthTriangularPentaImage from '~src/components/molecules/AdditionalMaterialPreview/Outlines/Penta/gableWidthTriangularPenta.png';

export interface IPreviewImages {}

export interface IMaterialPreviewProps {
  sectionToRender: string;
  gableIndex?: number;
}

const AdditionalMaterialPreview: React.FC<IMaterialPreviewProps> = ({sectionToRender, gableIndex}) => {
  const {calculations} = useStoreon<IState, IEvents>('calculations');
  const {userValues} = calculations[2];
  const balkUserValues = calculations[3].userValues;
  let passedGableIndex = gableIndex ? gableIndex : 0;
  let enabledGableInput =
    userValues.gables[passedGableIndex] && userValues.gables[passedGableIndex].enabledGableInput !== undefined
      ? userValues.gables[passedGableIndex].enabledGableInput
      : '';
  let enabledBalkGableInput =
    balkUserValues.gables[passedGableIndex] && balkUserValues.gables[passedGableIndex].enabledGableInput !== undefined
      ? balkUserValues.gables[passedGableIndex].enabledGableInput
      : '';

  let passedGableType =
    userValues.gables[passedGableIndex] && userValues.gables[passedGableIndex].gableType !== undefined
      ? userValues.gables[passedGableIndex].gableType
      : 0;

  let passedBalkGableType =
    balkUserValues.gables[passedGableIndex] && balkUserValues.gables[passedGableIndex].gableType !== undefined
      ? balkUserValues.gables[passedGableIndex].gableType
      : 0;

  const [renderedImage, setRenderedImage] = useState({
    armobeltImage: '',
    doorImage: '',
    windowDoorImage: '',
    windowDoorJumperImage: '',
    windowImage: '',
    partitionImage: '',
    trimImage: '',
    gableDefaultImage: '',
    gableBalkDefaultImage: '',
    gableHeightImage: '',
    gableWidthImage: '',
    jumperImage: '',
  });

  useEffect(() => {
    setRenderedImage(
      additionalImageRenderService.calculate(
        userValues.bearingWalls[0].brickType,
        userValues.trims[0].trimType,
        userValues.partitions[0].partitionBrickType,
        balkUserValues.bearingWalls[0].balkType,
        passedGableType,
        passedBalkGableType,
      ),
    );
  }, [userValues, balkUserValues]);

  const determineOutlineToRender = (gableMaterialToRender: string) => {
    if (gableMaterialToRender === 'balk') {
      switch (balkUserValues.gables[passedGableIndex].gableType) {
        case 0:
          switch (enabledBalkGableInput) {
            case '':
              return null;
            case 'gableHeightTriangle':
              return <Image src={gableHeightTriangleImage} alt="" />;
            case 'gableWidthTriangle':
              return <Image src={gableWidthTriangleImage} alt="" />;
          }
          break;
        case 1:
          switch (enabledBalkGableInput) {
            case '':
              return null;
            case 'gableHeightTrapezoidal':
              return <Image src={gableHeightTrapezoidalImage} alt="" />;
            case 'gableWidthTrapezoidal':
              return <Image src={gableWidthTrapezoidalImage} alt="" />;
            case 'gableWidthSmallerTrapezoidal':
              return <Image src={gableWidthSmallerTrapezoidalImage} alt="" />;
          }
          break;
        case 2:
          switch (enabledBalkGableInput) {
            case '':
              return null;
            case 'gableRectPentaHeight':
              return <Image src={gableHeightRectPentaImage} alt="" />;
            case 'gableTrianglePentaHeight':
              return <Image src={gableHeightTriangularPentaImage} alt="" />;
            case 'gablePentaWidth':
              return <Image src={gableWidthBiggerPentaImage} alt="" />;
            case 'gableTrianglePentaWidth':
              return <Image src={gableWidthTriangularPentaImage} alt="" />;
          }
          break;
      }
    } else if (gableMaterialToRender === 'brick') {
      switch (userValues.gables[passedGableIndex].gableType) {
        case 0:
          switch (enabledGableInput) {
            case '':
              return null;
            case 'gableHeightTriangle':
              return <Image src={gableHeightTriangleImage} alt="" />;
            case 'gableWidthTriangle':
              return <Image src={gableWidthTriangleImage} alt="" />;
          }
          break;
        case 1:
          switch (enabledGableInput) {
            case '':
              return null;
            case 'gableHeightTrapezoidal':
              return <Image src={gableHeightTrapezoidalImage} alt="" />;
            case 'gableWidthTrapezoidal':
              return <Image src={gableWidthTrapezoidalImage} alt="" />;
            case 'gableWidthSmallerTrapezoidal':
              return <Image src={gableWidthSmallerTrapezoidalImage} alt="" />;
          }
          break;
        case 2:
          switch (enabledGableInput) {
            case '':
              return null;
            case 'gableRectPentaHeight':
              return <Image src={gableHeightRectPentaImage} alt="" />;
            case 'gableTrianglePentaHeight':
              return <Image src={gableHeightTriangularPentaImage} alt="" />;
            case 'gablePentaWidth':
              return <Image src={gableWidthBiggerPentaImage} alt="" />;
            case 'gableTrianglePentaWidth':
              return <Image src={gableWidthTriangularPentaImage} alt="" />;
          }
          break;
      }
    }
  };

  const determineSectionToRender = () => {
    switch (sectionToRender) {
      case 'windows':
        if (
          !userValues.enabledFlags.find(item => item === 'doors') &&
          !userValues.enabledFlags.find(item => item === 'jumpers')
        ) {
          return (
            <ImageBox>
              <Image src={renderedImage.windowImage} alt="" />
            </ImageBox>
          );
        } else if (userValues.enabledFlags.find(item => item === 'doors')) {
          return (
            <ImageBox>
              <Image src={renderedImage.windowDoorImage} alt="" />
            </ImageBox>
          );
        } else if (
          userValues.enabledFlags.find(item => item === 'doors') &&
          userValues.enabledFlags.find(item => item === 'jumpers')
        ) {
          return (
            <ImageBox>
              <Image src={renderedImage.windowDoorJumperImage} alt="" />
            </ImageBox>
          );
        } else if (userValues.enabledFlags.find(item => item === 'jumpers')) {
          return (
            <ImageBox>
              <Image src={renderedImage.windowImage} alt="" />
            </ImageBox>
          );
        }
        break;
      case 'doors':
        if (
          !userValues.enabledFlags.find(item => item === 'windows') &&
          !userValues.enabledFlags.find(item => item === 'jumpers')
        ) {
          return (
            <ImageBox>
              <Image src={renderedImage.doorImage} alt="" />
            </ImageBox>
          );
        } else if (userValues.enabledFlags.find(item => item === 'windows')) {
          return (
            <ImageBox>
              <Image src={renderedImage.windowDoorImage} alt="" />
            </ImageBox>
          );
        } else if (
          userValues.enabledFlags.find(item => item === 'windows') &&
          userValues.enabledFlags.find(item => item === 'jumpers')
        ) {
          return (
            <ImageBox>
              <Image src={renderedImage.windowDoorJumperImage} alt="" />
            </ImageBox>
          );
        } else if (userValues.enabledFlags.find(item => item === 'jumpers')) {
          return (
            <ImageBox>
              <Image src={renderedImage.doorImage} alt="" />
            </ImageBox>
          );
        }
        break;
      case 'balkWindows':
        if (!balkUserValues.enabledFlags.find(item => item === 'balkDoors')) {
          return (
            <ImageBox>
              <Image src={renderedImage.windowImage} alt="" />
            </ImageBox>
          );
        } else if (balkUserValues.enabledFlags.find(item => item === 'balkDoors')) {
          return (
            <ImageBox>
              <Image src={renderedImage.windowDoorImage} alt="" />
            </ImageBox>
          );
        }
        break;
      case 'balkDoors':
        if (!balkUserValues.enabledFlags.find(item => item === 'balkWindows')) {
          return (
            <ImageBox>
              <Image src={renderedImage.doorImage} alt="" />
            </ImageBox>
          );
        } else if (balkUserValues.enabledFlags.find(item => item === 'balkWindows')) {
          return (
            <ImageBox>
              <Image src={renderedImage.windowDoorImage} alt="" />
            </ImageBox>
          );
        }
        break;
      case 'gables':
        switch (enabledGableInput) {
          case '':
            return (
              <ImageBox>
                <Image src={renderedImage.gableDefaultImage} alt="" />
              </ImageBox>
            );
          case 'gableHeightTriangle':
            return (
              <ImageBox>
                <Image src={renderedImage.gableDefaultImage} alt="" />
                {determineOutlineToRender('brick')}
              </ImageBox>
            );
          case 'gableWidthTriangle':
            return (
              <ImageBox>
                <Image src={renderedImage.gableDefaultImage} alt="" />
                {determineOutlineToRender('brick')}
              </ImageBox>
            );
          case 'gableHeightTrapezoidal':
            return (
              <ImageBox>
                <Image src={renderedImage.gableDefaultImage} alt="" />
                {determineOutlineToRender('brick')}
              </ImageBox>
            );
          case 'gableWidthTrapezoidal':
            return (
              <ImageBox>
                <Image src={renderedImage.gableDefaultImage} alt="" />
                {determineOutlineToRender('brick')}
              </ImageBox>
            );
          case 'gableWidthSmallerTrapezoidal':
            return (
              <ImageBox>
                <Image src={renderedImage.gableDefaultImage} alt="" />
                {determineOutlineToRender('brick')}
              </ImageBox>
            );
          case 'gableRectPentaHeight':
            return (
              <ImageBox>
                <Image src={renderedImage.gableDefaultImage} alt="" />
                {determineOutlineToRender('brick')}
              </ImageBox>
            );
          case 'gableTrianglePentaHeight':
            return (
              <ImageBox>
                <Image src={renderedImage.gableDefaultImage} alt="" />
                {determineOutlineToRender('brick')}
              </ImageBox>
            );
          case 'gablePentaWidth':
            return (
              <ImageBox>
                <Image src={renderedImage.gableDefaultImage} alt="" />
                {determineOutlineToRender('brick')}
              </ImageBox>
            );
          case 'gableTrianglePentaWidth':
            return (
              <ImageBox>
                <Image src={renderedImage.gableDefaultImage} alt="" />
                {determineOutlineToRender('brick')}
              </ImageBox>
            );
        }
        break;
      case 'balkGables':
        switch (enabledBalkGableInput) {
          case '':
            return (
              <ImageBox>
                <Image src={renderedImage.gableBalkDefaultImage} alt="" />
              </ImageBox>
            );
          case 'gableHeightTriangle':
            return (
              <ImageBox>
                <Image src={renderedImage.gableBalkDefaultImage} alt="" />
                {determineOutlineToRender('balk')}
              </ImageBox>
            );
          case 'gableWidthTriangle':
            return (
              <ImageBox>
                <Image src={renderedImage.gableBalkDefaultImage} alt="" />
                {determineOutlineToRender('balk')}
              </ImageBox>
            );
          case 'gableHeightTrapezoidal':
            return (
              <ImageBox>
                <Image src={renderedImage.gableBalkDefaultImage} alt="" />
                {determineOutlineToRender('balk')}
              </ImageBox>
            );
          case 'gableWidthTrapezoidal':
            return (
              <ImageBox>
                <Image src={renderedImage.gableBalkDefaultImage} alt="" />
                {determineOutlineToRender('balk')}
              </ImageBox>
            );
          case 'gableWidthSmallerTrapezoidal':
            return (
              <ImageBox>
                <Image src={renderedImage.gableBalkDefaultImage} alt="" />
                {determineOutlineToRender('balk')}
              </ImageBox>
            );
          case 'gableRectPentaHeight':
            return (
              <ImageBox>
                <Image src={renderedImage.gableBalkDefaultImage} alt="" />
                {determineOutlineToRender('balk')}
              </ImageBox>
            );
          case 'gableTrianglePentaHeight':
            return (
              <ImageBox>
                <Image src={renderedImage.gableBalkDefaultImage} alt="" />
                {determineOutlineToRender('balk')}
              </ImageBox>
            );
          case 'gablePentaWidth':
            return (
              <ImageBox>
                <Image src={renderedImage.gableBalkDefaultImage} alt="" />
                {determineOutlineToRender('balk')}
              </ImageBox>
            );
          case 'gableTrianglePentaWidth':
            return (
              <ImageBox>
                <Image src={renderedImage.gableBalkDefaultImage} alt="" />
                {determineOutlineToRender('balk')}
              </ImageBox>
            );
        }
        break;
      case 'armobelt':
        if (
          !userValues.enabledFlags.find(item => item === 'doors') &&
          !userValues.enabledFlags.find(item => item === 'jumpers')
        ) {
          return (
            <ImageBox>
              <ArmobeltImage src={renderedImage.armobeltImage} alt="" />
              <Image src={renderedImage.windowImage} alt="" />
            </ImageBox>
          );
        } else if (userValues.enabledFlags.find(item => item === 'doors')) {
          return (
            <ImageBox>
              <ArmobeltImage src={renderedImage.armobeltImage} alt="" />
              <Image src={renderedImage.windowDoorImage} alt="" />
            </ImageBox>
          );
        } else if (
          userValues.enabledFlags.find(item => item === 'doors') &&
          userValues.enabledFlags.find(item => item === 'jumpers')
        ) {
          return (
            <ImageBox>
              <ArmobeltImage src={renderedImage.armobeltImage} alt="" />
              <Image src={renderedImage.windowDoorJumperImage} alt="" />
            </ImageBox>
          );
        } else if (userValues.enabledFlags.find(item => item === 'jumpers')) {
          return (
            <ImageBox>
              <ArmobeltImage src={renderedImage.armobeltImage} alt="" />
              <Image src={renderedImage.windowImage} alt="" />
            </ImageBox>
          );
        }
        break;
      case 'trim':
        return (
          <ImageBox>
            <TrimImage src={renderedImage.trimImage} alt="" />
          </ImageBox>
        );
      case 'partitions':
        return (
          <PartitionImageBox>
            <PartitionImage src={renderedImage.partitionImage} alt="" />
          </PartitionImageBox>
        );
      case 'jumpers':
        return (
          <ImageBox>
            <JumperImage src={renderedImage.jumperImage} alt="" />
          </ImageBox>
        );
      default:
        return;
    }
  };

  return determineSectionToRender();
};
const ImageBox = styled.div`
  @media screen and (max-width: 320px) {
    height: 150px;
    width: 200px;
  }
`;

const PartitionImageBox = styled.div`
  @media screen and (min-width: 0px) and (max-width: 414px) {
    height: 150px;
    width: 200px;
    margin-bottom: 10px;
  }
`;

const Image = styled.img`
  max-width: 560px;
  position: absolute;

  @media screen and (max-width: 640px) {
    width: 100%;
    min-width: 0px;
  }

  @media screen and (min-width: 768px) {
    width: 200%;
    min-width: 0px;
  }
`;

const TrimImage = styled.img`
  max-width: 560px;
  position: absolute;
  top: -5px;

  @media screen and (max-width: 640px) {
    width: 100%;
    top: -50px;
  }

  @media screen and (max-width: 320px) {
    top: 15px;
    left: -10px;
    width: 120%;
  }

  @media screen and (min-width: 321px) and (max-width: 414px) {
    top: -60px;
    left: 10px;
  }

  @media screen and (min-width: 415px) and (max-width: 768px) {
    top: 0px;
    left: 20px;
  }

  @media screen and (min-width: 768px) {
    width: 200%;
    min-width: 0px;
  }
`;

const ArmobeltImage = styled.img`
  /* width: 200%; */
  min-width: 560px;
  max-width: 560px;
  position: absolute;
  z-index: 1;

  @media screen and (max-width: 640px) {
    width: 100%;
    min-width: 0px;
  }

  @media screen and (min-width: 768px) {
    width: 200%;
    min-width: 0px;
  }
`;

const PartitionImage = styled.img`
  max-width: 560px;
  position: absolute;

  @media screen and (max-width: 320px) {
    width: 100%;
  }

  @media screen and (min-width: 321px) and (max-width: 812px) {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    width: 200%;
    min-width: 0px;
  }
`;

const JumperImage = styled.img`
  width: 100%;
  max-width: 560px;
  position: absolute;

  @media screen and (max-width: 320px) {
    width: 100%;
  }

  @media screen and (min-width: 321px) and (max-width: 414px) {
    width: 100%;
    top: 0px;
  }

  @media screen and (min-width: 415px) and (max-width: 768px) {
    width: 150%;
    top: 40px;
  }

  @media screen and (min-width: 768px) {
    width: 200%;
    min-width: 0px;
  }
`;

export default AdditionalMaterialPreview;
