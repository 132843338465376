import React from 'react';
import {Collapse as CollapseAntd} from 'antd';
import {CollapseProps} from 'antd/es/collapse';

const {Panel} = CollapseAntd;

interface ICollapseProps extends CollapseProps {
  values: {
    header: string;
    key: string;
    node: React.ReactNode;
  }[];
}

const Collapse: React.FC<ICollapseProps> = ({values, ...restProps}) => {
  return (
    <CollapseAntd bordered={false} {...restProps}>
      {values.map(value => (
        <Panel header={value.header} key={value.key}>
          {value.node}
        </Panel>
      ))}
    </CollapseAntd>
  );
};

export default Collapse;
