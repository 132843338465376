import React, {Fragment, useContext} from 'react';
import styled from 'styled-components';
import {LocaleContext} from '~src/context';

import {Typography, Row, Col, Table} from 'antd';
import Space from '~src/components/molecules/Space';

const {Title} = Typography;
const {Column} = Table;

interface IContentProps {
  values: {
    key: string;
    title: string;
    value: number;
    unit: string;
  }[];
}

const BaseLoad: React.FC<IContentProps> = values => {
  const tableValues = values.values.filter(item => {
    return item.value !== 0 && isNaN(item.value) === false;
  });
  return (
    <Fragment>
      <Space size={100} direction={'vertical'}>
        <Row gutter={20}>
          <Col span={24}>
            <Title level={3}>Нагрузка на фундамент</Title>
          </Col>
          <Col span={24}>
            <Table dataSource={tableValues} pagination={false} showHeader={false} tableLayout="auto">
              <Column title="Свойство" dataIndex="title" key="title" width="80%" />
              <Column title="Значение" dataIndex="value" key="value" align="right" width="10%" />
              <Column title="Единица измерения" dataIndex="unit" key="unit" align="right" width="10%" />
            </Table>
          </Col>
        </Row>
      </Space>
    </Fragment>
  );
};

export default BaseLoad;
