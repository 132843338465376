import React from 'react';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import styled from 'styled-components';
import {Col, Row} from 'antd';

interface IArticleProps {
  articleID: number;
}

const Article: React.FC<IArticleProps> = ({articleID}) => {
  const {articles} = useStoreon<IState, IEvents>('articles');
  const article = articles.values.find(article => article.id === articleID);
  return (
    <Row gutter={20}>
      <Col md={24} lg={14}>
        <DateStyled>{article && article.creationTime}</DateStyled>
        <HeaderStyled>{article && article.title}</HeaderStyled>
      </Col>
      <ArticleTextColStyled span={24}>
        {article && <div dangerouslySetInnerHTML={{__html: article.text}} />}
      </ArticleTextColStyled>
      <SpaceColStyled span={24} />
    </Row>
  );
};

const ArticleTextColStyled = styled(Col)`
  overflow: hidden;
  & img {
    max-width: 100%;
  }
`;

const DateStyled = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 170%;
  color: #666;
`;

const SpaceColStyled = styled(Col)`
  height: 50px;
`;

const HeaderStyled = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  color: #27282a;
`;

export default Article;
