import React, {useContext, useMemo} from 'react';
import {Col, Row, Typography, Space, Button} from 'antd';
import {Formula, Legend, StatusMessage} from '~src/components';
import * as colors from '@ant-design/colors';
import styled from 'styled-components';
import {DownloadOutlined, ArrowRightOutlined} from '@ant-design/icons';

import FormulaElement from '~src/components/molecules/Formula/FormulaElement';
import ResultUserValues from './ResultUserValues';
import ResultLiterature from './ResultLiterature';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {IResultValues} from '~src/services/Calculation1Service';
import {IUserValuesAtCalculation1} from '~src/store/Calculations';
import {Routes} from '~src/utils/routes';
import {$phone, deviceSizes} from '~src/theme/media';
import {useMediaQuery} from 'react-responsive';
import {Colors} from '~src/components/molecules/Chart/DiagramService';
import {findIntersectionPoints} from '~src/components/molecules/Chart/utils';
import Chart from '~src/components/molecules/Chart';
import {getBarDataSource, getAxis, getLinesForResult1} from '~src/utils/chart';
import {LocaleContext} from '~src/context';
import MobileWarning from '~src/components/organisms/Calculation1Organism/MobileWarning';

interface IResult1Props {
  onClickAtDownloadReportButton: () => void;
}

const Result1: React.FC<IResult1Props> = props => {
  const {
    pages: {
      calculation1: {result},
    },
  } = useContext(LocaleContext);
  const {calculations} = useStoreon<IState, IEvents>('calculations');
  const resultValues = calculations[1].resultValues as IResultValues;
  const userValues = calculations[1].userValues as IUserValuesAtCalculation1;
  const isTablet = useMediaQuery({maxWidth: deviceSizes.desktop - 1});
  const lines = useMemo(() => getLinesForResult1(userValues, resultValues), [userValues, resultValues]);
  const barDataSource = useMemo(() => getBarDataSource(userValues, lines), [userValues, lines]);
  const axis = useMemo(() => getAxis(barDataSource), [barDataSource]);
  // const intersectionPoints = useMemo(
  //   () =>
  //     findIntersectionPoints(userValues.layers, [resultValues.layersTemperature, resultValues.layersDewTemperature])
  //       .intersectionPoints,
  //   [resultValues.layersTemperature, resultValues.layersDewTemperature, userValues.layers],
  // );
  const intersectionPoints = useMemo(
    () =>
      findIntersectionPoints(userValues.layers, [
        resultValues.layersTemperatureWithoutVAG,
        resultValues.layersDewTemperature,
      ]).intersectionPoints,
    [resultValues.layersTemperatureWithoutVAG, resultValues.layersDewTemperature, userValues.layers],
  );
  // const intersectionPoints = useMemo(
  //   () =>
  //     findIntersectionPoints(userValues.layers, [
  //       resultValues.layersTemperatureWithVAG,
  //       resultValues.layersDewTemperatureWithVAG,
  //     ]).intersectionPoints,
  //   [resultValues.layersTemperatureWithVAG, resultValues.layersDewTemperatureWithVAG, userValues.layers],
  // );

  return (
    <Row gutter={[0, isTablet ? 25 : 50]}>
      <Col span={24}>
        <Row gutter={[20, isTablet ? 10 : 20]}>
          <Col md={12} xs={24}>
            <ChartWrapper>
              <Chart
                canvasId={'chart-result1'}
                axis={axis}
                type={'outlined'}
                kind={'bar'}
                width={450}
                height={350}
                dataSource={barDataSource}
                dataSourceId={calculations['1'].id}
                columnLinesColors={[Colors.blueDarkness, Colors.blue]}
                intersectionPoints={intersectionPoints}
              />
            </ChartWrapper>
            <MobileWarning />
          </Col>
          <Col md={12} xs={24}>
            <Space size={50} direction={'vertical'}>
              <Legend resultType={0} layers={userValues.layers} axis={result.legendAxis[0]} />
              <Row gutter={[20, 10]}>
                <Col>
                  <Button
                    icon={<DownloadOutlined />}
                    size={'large'}
                    type={'primary'}
                    onClick={props.onClickAtDownloadReportButton}
                  >
                    {'Скачать отчёт'}
                  </Button>
                </Col>
                <Col>
                  <Button icon={<ArrowRightOutlined />} size={'large'} href={Routes.calculation2} target={'_blank'}>
                    {'Расчёт материалов'}
                  </Button>
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>{'Характеристики объекта'}</Typography.Title>
          </Col>
          <Col span={24}>
            <ResultUserValues />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Typography.Title level={3}>{'Результат теплотехнического расчёта'}</Typography.Title>
          </Col>
          <Col span={24}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Typography.Paragraph>{'Градосутки отопительного периода:'}</Typography.Paragraph>
                <ResultSection>
                  <Col span={24}>
                    <Formula name={'gsop'} value={resultValues.gsop} />
                  </Col>
                </ResultSection>
              </Col>
              <Col span={24}>
                <Typography.Paragraph>
                  {'Требуемые сопротивления теплопередаче ограждающей конструкции:'}
                </Typography.Paragraph>
                <ResultSection gutter={[20, 20]}>
                  <Col span={24}>
                    <FormulaNameStyled>{'Базовое значение поэлементных требований'}</FormulaNameStyled>
                    <Formula name={'r_t'} value={resultValues.r_t} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>{'Санитарно-гигиенические требования'}</FormulaNameStyled>
                    <Formula name={'r_c'} value={resultValues.r_c} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>{'Нормируемое значение поэлементных требований'}</FormulaNameStyled>
                    <Formula name={'r_e'} value={resultValues.r_e} />
                  </Col>
                </ResultSection>
              </Col>
              <Col span={24}>
                <Typography.Paragraph>
                  {'Приведенное сопротивление теплопередаче ограждающей конструкции:'}
                </Typography.Paragraph>
                <ResultSection gutter={[20, 20]}>
                  <Col span={24}>
                    <FormulaNameStyled>{'Условное сопротивление теплопередаче'}</FormulaNameStyled>
                    <Formula name={'r_usl'} value={resultValues.r_usl} />
                  </Col>
                  <Col span={24}>
                    <FormulaNameStyled>{'Приведенное сопротивление теплопередаче'}</FormulaNameStyled>
                    <Formula name={'R'} value={resultValues.R} />
                  </Col>
                </ResultSection>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Typography.Title level={3}>{'Выводы'}</Typography.Title>
          </Col>
          <Col span={24}>
            <Space direction={'vertical'} size={10} style={{display: 'block'}}>
              <ResultSection>
                <Col span={24}>
                  <Row gutter={[15, 0]}>
                    <Col xs={24}>
                      <FormulaElement type={'var'} value={'R'} fontStyle={'italic'} />
                      &nbsp;
                      <FormulaElement type={'symb'} value={resultValues.conclusion1.symbol} />
                      &nbsp;
                      <FormulaElement type={'symb'} value={'R'} sub={'c'} fontStyle={'italic'} />
                    </Col>
                    <Col xs={24}>
                      <FormulaElement type={'symb'} value={'('} />
                      <FormulaElement type={'var'} value={`${resultValues.R} м²°С/Вт`} />
                      &nbsp;
                      <FormulaElement type={'symb'} value={resultValues.conclusion1.symbol} />
                      &nbsp;
                      <FormulaElement type={'symb'} value={`${resultValues.r_c} м²°С/Вт`} />
                      <FormulaElement type={'symb'} value={')'} />
                    </Col>
                  </Row>
                </Col>
              </ResultSection>
              <Typography.Paragraph>
                <StatusMessage
                  type={resultValues.conclusion1.type}
                  description={resultValues.conclusion1.description}
                />
              </Typography.Paragraph>
            </Space>
          </Col>
          <Col span={24}>
            <Space direction={'vertical'} size={10} style={{display: 'block'}}>
              <ResultSection>
                <Col span={24}>
                  <Row gutter={[15, 0]}>
                    <Col xs={24}>
                      <FormulaElement type={'var'} value={'R'} fontStyle={'italic'} />
                      &nbsp;
                      <FormulaElement type={'symb'} value={resultValues.conclusion2.symbol} />
                      &nbsp;
                      <FormulaElement type={'symb'} value={'R'} sub={'т'} fontStyle={'italic'} />
                    </Col>
                    <Col xs={24}>
                      <FormulaElement type={'symb'} value={'('} />
                      <FormulaElement type={'var'} value={`${resultValues.R} м²°С/Вт`} />
                      &nbsp;
                      <FormulaElement type={'symb'} value={resultValues.conclusion2.symbol} />
                      &nbsp;
                      <FormulaElement type={'symb'} value={`${resultValues.r_t} м²°С/Вт`} />
                      <FormulaElement type={'symb'} value={')'} />
                    </Col>
                  </Row>
                </Col>
              </ResultSection>
              <Typography.Paragraph>
                <StatusMessage
                  type={resultValues.conclusion2.type}
                  description={resultValues.conclusion2.description}
                />
              </Typography.Paragraph>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>{'Список литературы'}</Typography.Title>
            <ResultLiterature />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const ResultSection = styled(Row)`
  display: flex;
  align-items: center;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 15px 30px;
  @media ${$phone} {
    padding: 15px 15px;
  }
`;

const FormulaNameStyled = styled(Typography.Paragraph)`
  margin-bottom: 5px !important;
  color: ${colors.grey[1]};
`;

const ChartWrapper = styled.div`
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default Result1;
